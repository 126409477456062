import {Actions, ActionTypes} from './actions';
import {featureAdapter, initialState, State} from './state';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import produce from 'immer';


export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LIST: {
      return {
        ...state,
        isLoading: true,
        isHistorical: coerceBooleanProperty(action.payload.isHistorical),
        error: null
      };
    }
    case ActionTypes.LIST_SUCCESS: {
      return featureAdapter.setAll(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    }
    case ActionTypes.LIST_FOR_ENTITY: {
      return {
        ...state,
        isLoading: true,
        isHistorical: coerceBooleanProperty(action.payload.isHistorical),
        error: null
      };
    }
    case ActionTypes.LIST_FOR_ENTITY_SUCCESS: {
      return featureAdapter.setAll(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    }
    case ActionTypes.GET: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.GET_SUCCESS: {
      return featureAdapter.upsertOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    }
    case ActionTypes.SAVE: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.SAVE_SUCCESS: {
      return featureAdapter.upsertOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    }
    case ActionTypes.SAVE_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }
    case ActionTypes.DELETE: {
      return featureAdapter.removeOne(action.payload.id, {
        ...state,
        isLoading: true,
        error: null
      });
    }
    case ActionTypes.DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.DELETE_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Channel
    case ActionTypes.UPDATE_CHANNEL_NAME: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.UPDATE_CHANNEL_NAME_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        channelName: action.payload,
        error: null
      };
    }
    case ActionTypes.UPDATE_CHANNEL_NAME_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Active Users
    case ActionTypes.UPDATE_ACTIVE_USERS: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.UPDATE_ACTIVE_USERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        activeUsers: action.payload,
        error: null
      };
    }
    case ActionTypes.UPDATE_ACTIVE_USERS_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Save Action
    case ActionTypes.SAVE_ACTION: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.SAVE_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.SAVE_ACTION_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Updates From Socket Messages
    case ActionTypes.RECEIVE_ACTION: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.RECEIVE_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.RECEIVE_ACTION_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Entity Context Updates From Socket Messages
    case ActionTypes.RECEIVE_ENTITY_CONTEXT: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.RECEIVE_ENTITY_CONTEXT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.RECEIVE_ENTITY_CONTEXT_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Sync Focus Group
    case ActionTypes.SYNC: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.SYNC_SUCCESS: {
      return featureAdapter.upsertOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    }
    case ActionTypes.SYNC_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Update Focus Group
    case ActionTypes.UPDATE: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      return featureAdapter.upsertOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    }
    case ActionTypes.UPDATE_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Open Focus Group Socket
    case ActionTypes.OPEN_FOCUS_GROUP_SOCKET: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.OPEN_FOCUS_GROUP_SOCKET_SUCCESS: {
      return featureAdapter.upsertOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    }
    case ActionTypes.OPEN_FOCUS_GROUP_SOCKET_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Open My Evaluation Socket
    case ActionTypes.OPEN_MY_EVALUATION_SOCKET: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.OPEN_MY_EVALUATION_SOCKET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        myEvaluation: action.payload,
        error: null
      };
    }
    case ActionTypes.OPEN_MY_EVALUATION_SOCKET_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Update My Evaluation Channel Name
    case ActionTypes.UPDATE_MY_EVALUATION_CHANNEL_NAME: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.UPDATE_MY_EVALUATION_CHANNEL_NAME_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        myEvaluationChannelName: action.payload,
        error: null
      };
    }
    case ActionTypes.UPDATE_MY_EVALUATION_CHANNEL_NAME_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Update My Evaluation
    case ActionTypes.UPDATE_MY_EVALUATION: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.UPDATE_MY_EVALUATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        myEvaluation: action.payload,
        error: null
      };
    }
    case ActionTypes.UPDATE_MY_EVALUATION_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Save My Evaluation Action
    case ActionTypes.SAVE_MY_EVALUATION_ACTION: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.SAVE_MY_EVALUATION_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.SAVE_MY_EVALUATION_ACTION_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    // Receive My Evaluation Action
    case ActionTypes.RECEIVE_MY_EVALUATION_ACTION: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.RECEIVE_MY_EVALUATION_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.RECEIVE_MY_EVALUATION_ACTION_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
