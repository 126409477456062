import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import {Integrations} from '@sentry/tracing';

import {AppsModule} from './apps';
import {environment} from './environments/environment';
import 'froala-editor/js/plugins.pkgd.min.js';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentryUrl ,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Integrations.BrowserTracing({
      tracingOrigins: [environment.siteUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.8,
});

platformBrowserDynamic().bootstrapModule(AppsModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));
