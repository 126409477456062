import {Action} from '@ngrx/store';

import {UserGroup} from '@models/user-groups';

export enum ActionTypes {
  LIST = '[User Group] List',
  LIST_SUCCESS = '[User Group] List Success',
  LIST_FAILED = '[User Group] List Failed',
  GET = '[User Group] Get',
  GET_SUCCESS = '[User Group] Get Success',
  GET_FAILED = '[User Group] Get Failed',
  SAVE = '[User Group] Save',
  SAVE_SUCCESS = '[User Group] Save Success',
  SAVE_FAILED = '[User Group] Save Failed',
  DELETE = '[User Group] Delete',
  DELETE_SUCCESS = '[User Group] Delete Success',
  DELETE_FAILED = '[User Group] Delete Failed'
}

export class GetUserGroups implements Action {
  public readonly type = ActionTypes.LIST;
}

export class GetUserGroupsSuccess implements Action {
  public readonly type = ActionTypes.LIST_SUCCESS;
  constructor(public payload: UserGroup[]) {}
}

export class GetUserGroupsFailed implements Action {
  public readonly type = ActionTypes.LIST_FAILED;
  constructor(public payload: any) {}
}

export class GetUserGroup implements Action {
  public readonly type = ActionTypes.GET;
  constructor(public payload: number) {}
}

export class GetUserGroupSuccess implements Action {
  public readonly type = ActionTypes.GET_SUCCESS;
  constructor(public payload: UserGroup) {}
}

export class GetUserGroupFailed implements Action {
  public readonly type = ActionTypes.GET_FAILED;
  constructor(public payload: any) {}
}

export class SaveUserGroup implements Action {
  public readonly type = ActionTypes.SAVE;
  constructor(public payload: UserGroup) {}
}

export class SaveUserGroupSuccess implements Action {
  public readonly type = ActionTypes.SAVE_SUCCESS;
  constructor(public payload: UserGroup) {}
}

export class SaveUserGroupFailed implements Action {
  public readonly type = ActionTypes.SAVE_FAILED;
  constructor(public payload: any) {}
}

export class DeleteUserGroup implements Action {
  public readonly type = ActionTypes.DELETE;
  constructor(public payload: UserGroup) {}
}

export class DeleteUserGroupSuccess implements Action {
  public readonly type = ActionTypes.DELETE_SUCCESS;
  constructor() {}
}

export class DeleteUserGroupFailed implements Action {
  public readonly type = ActionTypes.DELETE_FAILED;
  constructor(public payload: any) {}
}

export type Actions = GetUserGroups | GetUserGroupsSuccess | GetUserGroupsFailed | GetUserGroup | GetUserGroupSuccess |
  SaveUserGroup | SaveUserGroupSuccess | SaveUserGroupFailed | DeleteUserGroup | DeleteUserGroupSuccess |
  DeleteUserGroupFailed;
