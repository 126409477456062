import {Action} from '@ngrx/store';

import {FocusGroup} from '@models/focus-groups';
import {FocusGroupGrouping} from '@models/focus-group-groupings';

export enum ActionTypes {
  LIST = '[Focus Group] List',
  LIST_SUCCESS = '[Focus Group] List Success',
  LIST_FAILED = '[Focus Group] List Failed',
  LIST_FOR_ENTITY = '[Focus Group] List for Entity',
  LIST_FOR_ENTITY_SUCCESS = '[Focus Group] List for Entity Success',
  LIST_FOR_ENTITY_FAILED = '[Focus Group] List for Entity Failed',
  GET = '[Focus Group] Get',
  GET_SUCCESS = '[Focus Group] Get Success',
  GET_FAILED = '[Focus Group] Get Failed',
  SAVE = '[Focus Group] Save',
  SAVE_SUCCESS = '[Focus Group] Save Success',
  SAVE_FAILED = '[Focus Group] Save Failed',
  DELETE = '[Focus Group] Delete',
  DELETE_SUCCESS = '[Focus Group] Delete Success',
  DELETE_FAILED = '[Focus Group] Delete Failed',
  REQUEST_INITIAL_FOCUS_GROUP = '[Focus Group] Request Initial Data',
  REQUEST_INITIAL_FOCUS_GROUP_SUCCESS = '[Focus Group] Request Initial Data Success',
  REQUEST_INITIAL_FOCUS_GROUP_FAILED = '[Focus Group] Request Initial Data Failed',
  UPDATE_CHANNEL_NAME = '[Focus Group] Update Channel Name',
  UPDATE_CHANNEL_NAME_SUCCESS = '[Focus Group] Update Channel Name Success',
  UPDATE_CHANNEL_NAME_FAILED = '[Focus Group] Update Channel Name Failed',
  UPDATE_ACTIVE_USERS = '[Focus Group] Update Active Users',
  UPDATE_ACTIVE_USERS_SUCCESS = '[Focus Group] Update Active Users Success',
  UPDATE_ACTIVE_USERS_FAILED = '[Focus Group] Update Active Users Failed',
  SAVE_ACTION = '[Focus Group] Save Action',
  SAVE_ACTION_SUCCESS = '[Focus Group] Save Action Success',
  SAVE_ACTION_FAILED = '[Focus Group] Save Action Failed',
  RECEIVE_ACTION = '[Focus Group] Receive Action',
  RECEIVE_ACTION_SUCCESS = '[Focus Group] Receive Action Success',
  RECEIVE_ACTION_FAILED = '[Focus Group] Receive Action Failed',
  RECEIVE_ENTITY_CONTEXT = '[Focus Group] Receive Entity Context',
  RECEIVE_ENTITY_CONTEXT_SUCCESS = '[Focus Group] Receive Entity Context Success',
  RECEIVE_ENTITY_CONTEXT_FAILED = '[Focus Group] Receive Entity Context Failed',
  REFRESH_CONNECTION = '[Focus Group] Refresh Connection',
  REFRESH_CONNECTION_SUCCESS = '[Focus Group] Refresh Connection Success',
  REFRESH_CONNECTION_FAILED = '[Focus Group] Refresh Connection Failed',
  SYNC = '[Focus Group] Sync',
  SYNC_SUCCESS = '[Focus Group] Sync Success',
  SYNC_FAILED = '[Focus Group] Sync Failed',
  UPDATE = '[Focus Group] Update',
  UPDATE_SUCCESS = '[Focus Group] Update Success',
  UPDATE_FAILED = '[Focus Group] Update Failed',
  OPEN_FOCUS_GROUP_SOCKET = '[Focus Group] Open Socket',
  OPEN_FOCUS_GROUP_SOCKET_SUCCESS = '[Focus Group] Open Socket Success',
  OPEN_FOCUS_GROUP_SOCKET_FAILED = '[Focus Group] Open Socket Failed',
  OPEN_MY_EVALUATION_SOCKET = '[Focus Group] Open My Evaluation Socket',
  OPEN_MY_EVALUATION_SOCKET_SUCCESS = '[Focus Group] Open My Evaluation Socket Success',
  OPEN_MY_EVALUATION_SOCKET_FAILED = '[Focus Group] Open My Evaluation Socket Failed',
  REQUEST_INITIAL_MY_EVALUATION = '[Focus Group] Request Initial My Evaluation',
  REQUEST_INITIAL_MY_EVALUATION_SUCCESS = '[Focus Group] Request Initial My Evaluation Success',
  REQUEST_INITIAL_MY_EVALUATION_FAILED = '[Focus Group] Request Initial My Evaluation Failed',
  UPDATE_MY_EVALUATION_CHANNEL_NAME = '[Focus Group] Update My Evaluation Channel Name',
  UPDATE_MY_EVALUATION_CHANNEL_NAME_SUCCESS = '[Focus Group] Update My Evaluation Channel Name Success',
  UPDATE_MY_EVALUATION_CHANNEL_NAME_FAILED = '[Focus Group] Update My Evaluation Channel Name Failed',
  UPDATE_MY_EVALUATION = '[Focus Group] Update My Evaluation',
  UPDATE_MY_EVALUATION_SUCCESS = '[Focus Group] Update My Evaluation Success',
  UPDATE_MY_EVALUATION_FAILED = '[Focus Group] Update My Evaluation Failed',
  SAVE_MY_EVALUATION_ACTION = '[Focus Group] Save My Evaluation Action',
  SAVE_MY_EVALUATION_ACTION_SUCCESS = '[Focus Group] Save My Evaluation Action Success',
  SAVE_MY_EVALUATION_ACTION_FAILED = '[Focus Group] Save My Evaluation Action Failed',
  RECEIVE_MY_EVALUATION_ACTION = '[Focus Group] Receive My Evaluation Action',
  RECEIVE_MY_EVALUATION_ACTION_SUCCESS = '[Focus Group] Receive My Evaluation Action Success',
  RECEIVE_MY_EVALUATION_ACTION_FAILED = '[Focus Group] Receive My Evaluation Action Failed',
}

export class GetFocusGroups implements Action {
  public readonly type = ActionTypes.LIST;
  constructor(public payload: {isHistorical?: boolean, isTalentPipeline?: boolean} = {}) {}
}

export class GetFocusGroupsSuccess implements Action {
  public readonly type = ActionTypes.LIST_SUCCESS;
  constructor(public payload: FocusGroup[]) {}
}

export class GetFocusGroupsFailed implements Action {
  public readonly type = ActionTypes.LIST_FAILED;
  constructor(public payload: any) {}
}

export class GetEntityFocusGroups implements Action {
  public readonly type = ActionTypes.LIST_FOR_ENTITY;
  constructor(public payload: {id: number, isHistorical?: boolean}) {}
}

export class GetEntityFocusGroupsSuccess implements Action {
  public readonly type = ActionTypes.LIST_FOR_ENTITY_SUCCESS;
  constructor(public payload: FocusGroup[]) {}
}

export class GetEntityFocusGroupsFailed implements Action {
  public readonly type = ActionTypes.LIST_FOR_ENTITY_FAILED;
  constructor(public payload: any) {}
}

export class GetFocusGroup implements Action {
  public readonly type = ActionTypes.GET;
  constructor(public payload: {id: number, versionDate?: string}) {}
}

export class GetFocusGroupSuccess implements Action {
  public readonly type = ActionTypes.GET_SUCCESS;
  constructor(public payload: FocusGroup) {}
}

export class GetFocusGroupFailed implements Action {
  public readonly type = ActionTypes.GET_FAILED;
  constructor(public payload: any) {}
}

export class SaveFocusGroup implements Action {
  public readonly type = ActionTypes.SAVE;
  constructor(public payload: FocusGroup) {}
}

export class SaveFocusGroupSuccess implements Action {
  public readonly type = ActionTypes.SAVE_SUCCESS;
  constructor(public payload: FocusGroup) {}
}

export class SaveFocusGroupFailed implements Action {
  public readonly type = ActionTypes.SAVE_FAILED;
  constructor(public payload: any) {}
}

export class DeleteFocusGroup implements Action {
  public readonly type = ActionTypes.DELETE;
  constructor(public payload: FocusGroup) {}
}

export class DeleteFocusGroupSuccess implements Action {
  public readonly type = ActionTypes.DELETE_SUCCESS;
  constructor() {}
}

export class DeleteFocusGroupFailed implements Action {
  public readonly type = ActionTypes.DELETE_FAILED;
  constructor(public payload: any) {}
}

export class UpdateChannelName implements Action {
  public readonly type = ActionTypes.UPDATE_CHANNEL_NAME;
  constructor(public payload: any) {}
}

export class UpdateChannelNameSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_CHANNEL_NAME_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateChannelNameFailed implements Action {
  public readonly type = ActionTypes.UPDATE_CHANNEL_NAME_FAILED;
  constructor(public payload: any) {}
}

export class RequestInitialFocusGroup implements Action {
  public readonly type = ActionTypes.REQUEST_INITIAL_FOCUS_GROUP;
  constructor(public payload: any) {}
}

export class RequestInitialFocusGroupSuccess implements Action {
  public readonly type = ActionTypes.REQUEST_INITIAL_FOCUS_GROUP_SUCCESS;
  constructor(public payload: any) {}
}

export class RequestInitialFocusGroupFailed implements Action {
  public readonly type = ActionTypes.REQUEST_INITIAL_FOCUS_GROUP_FAILED;
  constructor(public payload: any) {}
}

export class UpdateActiveUsers implements Action {
  public readonly type = ActionTypes.UPDATE_ACTIVE_USERS;
  constructor(public payload: any) {}
}

export class UpdateActiveUsersSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_ACTIVE_USERS_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateActiveUsersFailed implements Action {
  public readonly type = ActionTypes.UPDATE_ACTIVE_USERS_FAILED;
  constructor(public payload: any) {}
}

export class SaveAction implements Action {
  public readonly type = ActionTypes.SAVE_ACTION;
  constructor(public payload: any) {}
}

export class SaveActionSuccess implements Action {
  public readonly type = ActionTypes.SAVE_ACTION_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveActionFailed implements Action {
  public readonly type = ActionTypes.SAVE_ACTION_FAILED;
  constructor(public payload: any) {}
}

export class ReceiveAction implements Action {
  public readonly type = ActionTypes.RECEIVE_ACTION;
  constructor(public payload: any) {}
}

export class ReceiveActionSuccess implements Action {
  public readonly type = ActionTypes.RECEIVE_ACTION_SUCCESS;
  constructor(public payload: any) {}
}

export class ReceiveActionFailed implements Action {
  public readonly type = ActionTypes.RECEIVE_ACTION_FAILED;
  constructor(public payload: any) {}
}

export class ReceiveEntityContext implements Action {
  public readonly type = ActionTypes.RECEIVE_ENTITY_CONTEXT;
  constructor(public payload: any) {}
}

export class ReceiveEntityContextSuccess implements Action {
  public readonly type = ActionTypes.RECEIVE_ENTITY_CONTEXT_SUCCESS;
  constructor(public payload: any) {}
}

export class ReceiveEntityContextFailed implements Action {
  public readonly type = ActionTypes.RECEIVE_ENTITY_CONTEXT_FAILED;
  constructor(public payload: any) {}
}

export class RefreshConnection implements Action {
  public readonly type = ActionTypes.REFRESH_CONNECTION;
  constructor(public payload: any) {}
}

export class RefreshConnectionSuccess implements Action {
  public readonly type = ActionTypes.REFRESH_CONNECTION_SUCCESS;
  constructor(public payload: any) {}
}

export class RefreshConnectionFailed implements Action {
  public readonly type = ActionTypes.REFRESH_CONNECTION_FAILED;
  constructor(public payload: any) {}
}

export class SyncFocusGroup implements Action {
  public readonly type = ActionTypes.SYNC;
  constructor(public payload: any) {}
}

export class SyncFocusGroupSuccess implements Action {
  public readonly type = ActionTypes.SYNC_SUCCESS;
  constructor(public payload: any) {}
}

export class SyncFocusGroupFailed implements Action {
  public readonly type = ActionTypes.SYNC_FAILED;
  constructor(public payload: any) {}
}

export class UpdateFocusGroup implements Action {
  public readonly type = ActionTypes.UPDATE;
  constructor(public payload: any) {}
}

export class UpdateFocusGroupSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateFocusGroupFailed implements Action {
  public readonly type = ActionTypes.UPDATE_FAILED;
  constructor(public payload: any) {}
}

export class OpenFocusGroupSocket implements Action {
  public readonly type = ActionTypes.OPEN_FOCUS_GROUP_SOCKET;
  constructor(public payload: any) {}
}

export class OpenFocusGroupSocketSuccess implements Action {
  public readonly type = ActionTypes.OPEN_FOCUS_GROUP_SOCKET_SUCCESS;
  constructor(public payload: any) {}
}

export class OpenFocusGroupSocketFailed implements Action {
  public readonly type = ActionTypes.OPEN_FOCUS_GROUP_SOCKET_FAILED;
  constructor(public payload: any) {}
}

export class OpenMyEvaluationSocket implements Action {
  public readonly type = ActionTypes.OPEN_MY_EVALUATION_SOCKET;
  constructor(public payload: any) {}
}

export class OpenMyEvaluationSocketSuccess implements Action {
  public readonly type = ActionTypes.OPEN_MY_EVALUATION_SOCKET_SUCCESS;
  constructor(public payload: any) {}
}

export class OpenMyEvaluationSocketFailed implements Action {
  public readonly type = ActionTypes.OPEN_MY_EVALUATION_SOCKET_FAILED;
  constructor(public payload: any) {}
}

export class RequestInitialMyEvaluation implements Action {
  public readonly type = ActionTypes.REQUEST_INITIAL_MY_EVALUATION;
  constructor(public payload: any) {}
}

export class RequestInitialMyEvaluationSuccess implements Action {
  public readonly type = ActionTypes.REQUEST_INITIAL_MY_EVALUATION_SUCCESS;
  constructor(public payload: any) {}
}

export class RequestInitialMyEvaluationFailed implements Action {
  public readonly type = ActionTypes.REQUEST_INITIAL_MY_EVALUATION_FAILED;
  constructor(public payload: any) {}
}

export class UpdateMyEvaluationChannelName implements Action {
  public readonly type = ActionTypes.UPDATE_MY_EVALUATION_CHANNEL_NAME;
  constructor(public payload: any) {}
}

export class UpdateMyEvaluationChannelNameSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_MY_EVALUATION_CHANNEL_NAME_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateMyEvaluationChannelNameFailed implements Action {
  public readonly type = ActionTypes.UPDATE_MY_EVALUATION_CHANNEL_NAME_FAILED;
  constructor(public payload: any) {}
}

export class UpdateMyEvaluation implements Action {
  public readonly type = ActionTypes.UPDATE_MY_EVALUATION;
  constructor(public payload: any) {}
}

export class UpdateMyEvaluationSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_MY_EVALUATION_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateMyEvaluationFailed implements Action {
  public readonly type = ActionTypes.UPDATE_MY_EVALUATION_FAILED;
  constructor(public payload: any) {}
}

export class SaveMyEvaluationAction implements Action {
  public readonly type = ActionTypes.SAVE_MY_EVALUATION_ACTION;
  constructor(public payload: any) {}
}

export class SaveMyEvaluationActionSuccess implements Action {
  public readonly type = ActionTypes.SAVE_MY_EVALUATION_ACTION_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveMyEvaluationActionFailed implements Action {
  public readonly type = ActionTypes.SAVE_MY_EVALUATION_ACTION_FAILED;
  constructor(public payload: any) {}
}

export class ReceiveMyEvaluationAction implements Action {
  public readonly type = ActionTypes.RECEIVE_MY_EVALUATION_ACTION;
  constructor(public payload: any) {}
}

export class ReceiveMyEvaluationActionSuccess implements Action {
  public readonly type = ActionTypes.RECEIVE_MY_EVALUATION_ACTION_SUCCESS;
  constructor(public payload: any) {}
}

export class ReceiveMyEvaluationActionFailed implements Action {
  public readonly type = ActionTypes.RECEIVE_MY_EVALUATION_ACTION_FAILED;
  constructor(public payload: any) {}
}

export type Actions = GetFocusGroups | GetFocusGroupsSuccess | GetFocusGroupsFailed | GetEntityFocusGroups |
  GetEntityFocusGroupsSuccess | GetEntityFocusGroupsFailed | GetFocusGroup |
  GetFocusGroupSuccess | SaveFocusGroup | SaveFocusGroupSuccess | SaveFocusGroupFailed | DeleteFocusGroup |
  DeleteFocusGroupSuccess | DeleteFocusGroupFailed | UpdateChannelName | UpdateChannelNameSuccess | UpdateChannelNameFailed |
  RequestInitialFocusGroup | RequestInitialFocusGroupSuccess | RequestInitialFocusGroupFailed |
  UpdateActiveUsers | UpdateActiveUsersSuccess | UpdateActiveUsersFailed |
  SaveAction | SaveActionSuccess | SaveActionFailed | ReceiveAction | ReceiveActionSuccess | ReceiveActionFailed |
  ReceiveEntityContext | ReceiveEntityContextSuccess | ReceiveEntityContextFailed |
  RefreshConnection | RefreshConnectionSuccess | RefreshConnectionFailed | SyncFocusGroup | SyncFocusGroupSuccess | SyncFocusGroupFailed |
  UpdateFocusGroup | UpdateFocusGroupSuccess | UpdateFocusGroupFailed | OpenFocusGroupSocket | OpenFocusGroupSocketSuccess | OpenFocusGroupSocketFailed |
  OpenMyEvaluationSocket | OpenMyEvaluationSocketSuccess | OpenMyEvaluationSocketFailed |
  RequestInitialMyEvaluation | RequestInitialMyEvaluationSuccess | RequestInitialMyEvaluationFailed | UpdateMyEvaluationChannelName | UpdateMyEvaluationChannelNameSuccess | UpdateMyEvaluationChannelNameFailed |
  UpdateMyEvaluation | UpdateMyEvaluationSuccess | UpdateMyEvaluationFailed | SaveMyEvaluationAction | SaveMyEvaluationActionSuccess | SaveMyEvaluationActionFailed |
  ReceiveMyEvaluationAction | ReceiveMyEvaluationActionSuccess | ReceiveMyEvaluationActionFailed;
