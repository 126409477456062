import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {untilDestroyed, UntilDestroy} from '@ngneat/until-destroy';
import {VideoService} from '@services/video.service';
import * as dashjs from 'dashjs';

@UntilDestroy()
@Component({
  selector: 'bild-court-cams',
  templateUrl: './court-cams.component.html',
  styleUrls: ['./court-cams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CourtCamsComponent implements OnInit, OnDestroy {
  @ViewChild('courtCam', {static: true}) courtCam: ElementRef;
  isMobile: boolean;
  courtCams: any[] = [];
  displayedCourtCam: any;
  player: any;

  constructor(
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected router: Router,
    protected title: Title,
    protected videoService: VideoService
  ) { }

  ngOnInit() {
    this.title.setTitle('Court Cams');

    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe(result => {
      this.isMobile = result.matches;
    });

    this.videoService.getCourtCamStreamUrls().pipe(untilDestroyed(this)).subscribe(
      courtCamsData => {
        this.courtCams = courtCamsData;
        if (!this.displayedCourtCam) {
          this.displayedCourtCam = this.courtCams[0];
        }
        this.initializePlayer();
        this.cdr.markForCheck();
      },
      error => {
        console.log(error);
      }
    );
  }

  initializePlayer() {
    this.player = dashjs.MediaPlayer().create();
    this.player.initialize(this.courtCam.nativeElement, this.displayedCourtCam.url, true);
  }

  updatePlayer() {
    let currentTime = this.courtCam.nativeElement.currentTime;
    this.player.attachSource(this.displayedCourtCam.url, currentTime);
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  }
}
