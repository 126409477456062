import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


import {Announcement} from '@models/moments';
import {BaseService} from '@services/base.service';
import {BroadcastChannelService} from '@services/broadcast-channel.service';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementsService extends BaseService {

  constructor(protected http: HttpClient, protected broadcastChannelService: BroadcastChannelService) {
    super(http, broadcastChannelService);
  }

  getAnnouncement(id: number): Observable<any> {
    const endpoint = `${this.baseUrl}/announcements/${id}`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getAnnouncements(state?: string): Observable<any> {
    const endpoint = `${this.baseUrl}/announcements`;
    let params: HttpParams = new HttpParams();
    if (state) {
      params = params.set('state', state);
    }

    return this.get(endpoint, params).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getAnnouncementTypes(): Observable<any> {
    const endpoint = `${this.baseUrl}/announcements/types`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getDefaultAnnouncement(id: number): Observable<any> {
    const endpoint = `${this.baseUrl}/announcements/types/${id}/defaults`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  saveAnnouncement(announcement: Announcement): Observable<any> {
    let endpoint: string;
    let method: any;

    if (announcement.id) {
      endpoint = `${this.baseUrl}/announcements/${announcement.id}`;
      method = this.put.bind(this);
    } else {
      endpoint = `${this.baseUrl}/announcements`;
      method = this.post.bind(this);
    }

    return method(endpoint, announcement).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  deleteAnnouncement(id: number): Observable<any> {
    const endpoint = `${this.baseUrl}/announcements/${id}`;

    return this.delete(endpoint).pipe(map(
      (data: Object) => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }
}
