import {createEffect, ofType, Actions} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {switchMap, map, catchError, concatMap, mergeMap} from 'rxjs/operators';

import * as actions from './actions';

import {EntitiesService} from '@services/entities.service';
import {Affiliation} from '@models/affiliations';

@Injectable()
export class Effects {

  getAffiliations$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GetAffiliations>(actions.ActionTypes.LIST),
    map(action => action.payload),
    switchMap(payload => {
      return this.affiliationsService.getAffiliations(payload.id).pipe(
        switchMap((affiliations: Affiliation[]) => {
          return of({
            type: actions.ActionTypes.LIST_SUCCESS,
            payload: affiliations
          });
        }),
        catchError(error => {
          return of({
            type: actions.ActionTypes.LIST_FAILED,
            payload: error
          });
        })
      );
    }),
  ));

  saveAffiliations$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SaveAffiliations>(actions.ActionTypes.SAVE),
    map(action => action.payload),
    concatMap(payload => {
      return this.affiliationsService.saveAffiliations(payload.affiliations, payload.id).pipe(
        mergeMap(affiliations => {
          return of({
            type: actions.ActionTypes.SAVE_SUCCESS,
            payload: affiliations
          });
        }),
        catchError(error => {
          return of({
            type: actions.ActionTypes.SAVE_FAILED,
            payload: error
          });
        })
      );
    })
  ));

  constructor(private affiliationsService: EntitiesService, private actions$: Actions) { }
}
