import * as moment from 'moment-timezone';


import {Transform, Type, plainToClass} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Attachment} from './attachments';
import {AuthService} from '@services/auth.service';
import {COMMUNICATION_PURPOSES, CommunicationPurpose} from './constants/communication-purposes';
import {COMMUNICATION_SETTINGS, CommunicationSetting} from './constants/communication-settings';
import {Comment} from './comments';
import {Distribution, DISTRIBUTIONS} from './constants/distributions';
import {EVALUATION_SETTINGS, EvaluationSetting} from './constants/evaluation-settings';
import {Entity} from './entities';
import {EvaluationSettingDetail} from './evaluation-setting-details';
import {LOC, LOCSProbabilities} from './locs';
import {Level, LEVELS} from './constants/levels';
import {POST_CATEGORIES, PostCategory} from './constants/post-categories';
import {Person} from '@models/people';
import {PostType, POST_TYPES, POST_TYPE_IDS} from './constants/post-types';
import {Role, ROLES} from './constants/roles';
import {Tier, TIERS} from './constants/tiers';
import {TRADE_ASSETS, TradeAsset} from './constants/trade-assets';
import {forwardRefConstant, forwardRefConstants, forwardRefMoment} from '@helpers/class-transformer.helper';
import {PlayerDevelopmentObjective} from '@models/player-development-objectives';
import {PlayerThunderStandards} from '@models/player-thunder-standards';
import {MedicalRating} from 'apps/_models/medical-ratings';
import {INCOMING_SETTINGS, IncomingSetting} from '@models/constants/incoming-settings';
import {PlayerInterest} from '@models/player-interest';
import {SkillAssessment} from '@models/skill-assessments';
import {REALGM_AUTHOR_ID} from '@models/articles';

export const PostErrorKeyMap = {
  'about': 'About',
  'assessment': 'Assessment',
  'assessmentDatetime': 'Assessment Date',
  'attendees': 'Attendees',
  'author': 'Author',
  'coachingStrategies': 'Coaching Strategies',
  'communicationPurpose': 'Source setting',
  'communicationSetting': 'Conversation type',
  'evaluationSetting': 'Setting',
  'evaluationSettingDetails': 'Setting details',
  'incomingSetting': 'Incoming/Outgoing',
  'locs': 'LOCS',
  'medicalRating': 'Medical Rating',
  'message': 'Message',
  'postCategories': 'Tags',
  'sourceDescription': 'Source',
  'sources': 'Source Person and Affiliation',
  'strongestBehaviors': 'Strongest Behaviors',
  'summary': 'Summary',
  'topic': 'Headline'
};

const IconMap = {
  1: 'bild-evaluation',
  2: 'bild-musings',
  3: 'bild-gossip-rumor-news-rumor-news',
  4: 'bild-medical-expert-summary',
  5: 'bild-representation',
  6: 'bild-trade',
  9: 'bild-development',
  10: 'bild-correspondence',
  12: 'bild-evaluation',
};

export const SCOUTING_REPORT_ID = 1097;

export class Post {

  @Type(forwardRef(() => Entity) as any)
  about: Entity[] = [];
  @Transform(forwardRefConstants(TRADE_ASSETS), { toClassOnly: true })
  affiliateTradeAssets: TradeAsset[] = [];
  @Type(forwardRef(() => Attachment) as any)
  attachments: Attachment[] = [];
  @Type(forwardRef(() => Entity) as any)
  attendees: Entity[] = [];
  @Type(forwardRef(() => Entity) as any)
  author: Entity = null;
  @Type(forwardRef(() => Comment) as any)
  comments: Comment[] = [];
  @Transform(forwardRefConstant(COMMUNICATION_PURPOSES), { toClassOnly: true })
  communicationPurpose: CommunicationPurpose;
  @Transform(forwardRefConstant(COMMUNICATION_SETTINGS), { toClassOnly: true })
  communicationSetting: CommunicationSetting;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  createDatetime: moment.Moment;
  @Transform(forwardRefConstant(DISTRIBUTIONS), { toClassOnly: true })
  distribution: Distribution;
  @Type(forwardRef(() => Person) as any)
  distributionEntities: Person[] = [];
  // TODO Setup a real type for this
  distributionUserGroups: any[] = [];
  // TODO Setup a real type for this
  highlights: any[] = [];
  infoReliability: any;
  @Type(forwardRef(() => LOCSProbabilities) as any)
  locsProbabilities: LOCSProbabilities;
  overridePermissions: boolean = false;
  @Type(forwardRef(() => Entity) as any)
  editor: Entity = null;
  @Transform(forwardRefConstant(EVALUATION_SETTINGS), { toClassOnly: true })
  evaluationSetting: EvaluationSetting;
  @Type(forwardRef(() => EvaluationSettingDetail) as any)
  evaluationSettingDetails: EvaluationSettingDetail[] = [];
  @Type(forwardRef(() => PlayerThunderStandards) as any)
  thunderStandards: PlayerThunderStandards = plainToClass(PlayerThunderStandards, {});
  @Transform(forwardRefConstant(INCOMING_SETTINGS), { toClassOnly: true })
  incomingSetting: IncomingSetting;
  isActive: boolean = true;
  isBookmarkedByUser: boolean = false;
  isPlusOnedByUser: boolean = false;
  isPosted: boolean = false;
  isDraft: boolean = false;
  @Transform(forwardRefConstant(LEVELS), { toClassOnly: true })
  level: Level;
  @Type(forwardRef(() => LOC) as any)
  locs: LOC = plainToClass(LOC, {});
  @Transform(forwardRefConstant(TIERS), { toClassOnly: true })
  tier: Tier;
  @Type(forwardRef(() => MedicalRating) as any)
  medicalRating: MedicalRating = plainToClass(MedicalRating, {});
  memos: any[] = [];
  message: string[] = [''];
  messageSnippet: string = '';
  numComments: number = 0;
  numPlusOnes: number = 0;
  plusOneBy: string[] = [];
  commenters: string[] = [];
  @Type(forwardRef(() => Entity) as any)
  originalLeague: Entity = null;
  @Type(forwardRef(() => PlayerInterest) as any)
  playerInterest: PlayerInterest[];
  @Transform(forwardRefConstants(POST_CATEGORIES), { toClassOnly: true })
  postCategories: PostCategory[] = [];
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  postDatetime: moment.Moment;
  postID: number;
  @Transform(forwardRefConstant(POST_TYPES), { toClassOnly: true })
  postType: PostType;
  @Transform(forwardRefMoment(moment.ISO_8601))
  retroEndDatetime: moment.Moment;
  @Transform(forwardRefMoment(moment.ISO_8601))
  retroStartDatetime: moment.Moment;
  revisionID: number;
  @Transform(forwardRefConstants(ROLES), { toClassOnly: true })
  roles: Role[] = [];
  @Type(forwardRef(() => SkillAssessment) as any)
  skillAssessment: SkillAssessment;
  skills: any[] = [];
  skillAreas: any[] = [];
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  sortDatetime: moment.Moment;
  sources: any[] = [];
  sourceDescription: string = '';
  sourceReliability: any;
  sourceUrl: string = '';
  suppressNotification: boolean = false;
  @Type(forwardRef(() => Entity) as any)
  taggedEntities: Entity[] = [];
  tags: any[] = [];
  @Transform(forwardRefConstants(TRADE_ASSETS), { toClassOnly: true })
  thunderTradeAssets: TradeAsset[] = [];
  topic: string = '';
  transactionID: number;
  @Type(forwardRef(() => Post) as any)
  linkedPosts: Post[] = [];
  video: any[] = [];

  // TODO Would probably be better if we just had the API return the
  // permissions already setup on the object for the currently signed in user
  editPermissions(authService: AuthService): string [] {
    return authService.getPermissionsForAttribute('Posts', this, 'Edit');
  }
  editDeletePermissions(authService: AuthService): string [] {
    return authService.getPermissionsForAttribute('Posts', this, 'EditDelete');
  }
  deletePermissions(authService: AuthService): string[] {
    return authService.getPermissionsForAttribute('Posts', this, 'Delete');
  }

  get hasLinkableEntities(): boolean {
    return (this.postType !== POST_TYPES[POST_TYPE_IDS.MEDICAL_EXPERT_SUMMARY]);
  }

  get subjectLine(): string {
    let subjectLine: string = this.topic || '(No headline)';

    switch (this.postType?.id) {
      case POST_TYPE_IDS.EVALUATION:
      case POST_TYPE_IDS.ADVANCED_EVALUATION:
        subjectLine = this.aboutNames || '(Unnamed evaluation)';
        break;
    }

    return subjectLine;
  }

  get distributionDescription(): string {
    const customDescription: string[] = [];
    const description: string = 'Default Settings';

    if (this.distribution.id !== 1200) {
      customDescription.push(this.distribution.name);
    }

    if (this.editor && this.editor.id !== this.author.id) {
      customDescription.push(this.author.name);
    }

    if (this.postDatetime) {
      customDescription.push(this.postDatetimeFormatted);
    }

    if (this.suppressNotification) {
      customDescription.push('Suppress Notification');
    }

    return customDescription.length > 0 ? customDescription.join(' ') : description;
  }

  get icon() {
    return IconMap[this.postType?.id];
  }

  get hasLOCS() {
    return !!(this.locs && (this.locs.now || this.locs.low || this.locs.mid || this.locs.high));
  }

  get isScoutingReport() {
    return this.evaluationSettingDetails?.length && this.evaluationSettingDetails[0]['setting']?.id === SCOUTING_REPORT_ID;
  }

  get displayScoutingReportLink() {
    return this.evaluationSettingDetails[0].league && this.evaluationSettingDetails[0].gameId &&
      this.evaluationSettingDetails[0].awayTeam.name && this.evaluationSettingDetails[0].homeTeam.name;
  }

  get locsDisplay(): string {
    let locs: string = '';

    if (this.hasLOCS) {
      if (this.locs.now) {
        locs = `${this.locs.now.name}&nbsp;&nbsp;&nbsp;`;
      }

      if (this.locs.low) {
        locs = `${locs}${this.locs.low.name} | `;
      } else {
        locs = `${locs}&nbsp;&nbsp; | `;
      }

      if (this.locs.mid) {
        locs = `${locs}${this.locs.mid.name} | `;
      } else {
        locs = `${locs}&nbsp;&nbsp; | `;
      }

      if (this.locs.high) {
        locs = `${locs}${this.locs.high.name}`;
      } else {
        locs = `${locs}&nbsp;&nbsp;`;
      }

      if (this.locs.noThunder) {
        locs = `${locs}&nbsp;&nbsp;&nbsp;NT`;
      }
    }

    return locs;
  }

  get aboutNames() {
    return this.about.map(about => about.name).join(', ');
  }

  get categoryNames() {
    return this.postCategories.map(postCategory => postCategory.name).join(', ');
  }

  get postDatetimeFormatted() {
    return this.formatDatetime('postDatetime');
  }

  get createDatetimeFormatted() {
    return this.formatDatetime('createDatetime');
  }

  get sortDatetimeFormatted() {
    return this.formatDatetime('sortDatetime');
  }

  get thunderTradeAssetsNames(): string {
    return this.thunderTradeAssets.map(tradeAsset => tradeAsset.shortName).join(', ');
  }

  get affiliateTradeAssetsNames(): string {
    return this.affiliateTradeAssets.map(tradeAsset => tradeAsset.shortName).join(', ');
  }

  get isSpotlight(): Boolean {
    return Boolean(this.author && this.postType && this.author.id == REALGM_AUTHOR_ID && this.postType.id == POST_TYPE_IDS.GOSSIP_RUMOR_NEWS);
  }

  formatDatetime(prop: string): string {
    const now = moment();
    const dt = moment(this[prop]);
    let formattedDatetime: string = '';

    switch (true) {
      case now.isSame(dt, 'day'):
        formattedDatetime = dt.format('h:mm A');
        break;
      case now.isSame(dt, 'year'):
        formattedDatetime = dt.format('MMM D');
        break;
      default:
        formattedDatetime = dt.format('MMM D, YYYY');
    }

    return formattedDatetime;
  }

  asTemplate(): Post {
    let templatePost: Post;
    templatePost = plainToClass(Post, {
      postType: this.postType,
      level: this.level,
      distribution: this.distribution,
      distributionEntities: [...this.distributionEntities],
      distributionUserGroups: [...this.distributionUserGroups]
    });

    switch (this.postType) {
      case POST_TYPES[POST_TYPE_IDS.DEVELOPMENT]:
        templatePost.postCategories = [...this.postCategories];
        break;
      case POST_TYPES[POST_TYPE_IDS.EVALUATION]:
      case POST_TYPES[POST_TYPE_IDS.ADVANCED_EVALUATION]:
        templatePost.evaluationSetting = this.evaluationSetting;
        templatePost.evaluationSettingDetails = this.evaluationSettingDetails;
        break;
      case POST_TYPES[POST_TYPE_IDS.GOSSIP_RUMOR_NEWS]:
        templatePost.sources = [...this.sources];
        templatePost.sourceDescription = this.sourceDescription;
        templatePost.sourceReliability = this.sourceReliability;
        templatePost.infoReliability = this.infoReliability;
        templatePost.postCategories = [...this.postCategories];
        break;
      case POST_TYPES[POST_TYPE_IDS.MEDICAL_EXPERT_SUMMARY]:
        templatePost.sources = [...this.sources];
        templatePost.sourceDescription = this.sourceDescription;
        break;
      case POST_TYPES[POST_TYPE_IDS.MUSING]:
        templatePost.postCategories = [...this.postCategories];
        templatePost.attendees = [...this.attendees];
        break;
      case POST_TYPES[POST_TYPE_IDS.REPRESENTATION]:
        templatePost.sources = [...this.sources];
        templatePost.sourceDescription = this.sourceDescription;
        templatePost.communicationSetting = this.communicationSetting;
        templatePost.communicationPurpose = this.communicationPurpose;
        break;
      case POST_TYPES[POST_TYPE_IDS.TRADE]:
        templatePost.sources = [...this.sources];
        templatePost.sourceDescription = this.sourceDescription;
        templatePost.communicationSetting = this.communicationSetting;
        templatePost.affiliateTradeAssets = [...this.affiliateTradeAssets];
        templatePost.thunderTradeAssets = [...this.thunderTradeAssets];
        break;
      case POST_TYPES[POST_TYPE_IDS.CORRESPONDENCE]:
        templatePost.sources = [...this.sources];
        templatePost.sourceDescription = this.sourceDescription;
        templatePost.communicationSetting = this.communicationSetting;
        break;
    }

    return templatePost;
  }

  stripWhitespace(message) {
    return message.replace(/^\s+/, '').replace(/\s+$/, '');
  }

  stripTags(message) {
    const pattern = '<\\w+(\\s+("[^"]*"|\\\'[^\\\']*\'|[^>])+)?>|<\\/\\w+>';
    const reg = new RegExp(pattern, 'gi');
    return message.replace(reg, '');
  }

  imageReadTime(message) {
    const IMAGE_TAGS = ['img', 'Image'];
    const combinedImageTags = IMAGE_TAGS.join('|');
    const pattern = `<(${combinedImageTags})([\\w\\W]+?)[\\/]?>`;
    const reg = new RegExp(pattern, 'g');
    const count = (message.match(reg) || []).length;
    // * 12 seconds per image and / 60 seconds per minute => / 5
    return count / 5;
  }

  wordsReadTime(message) {
    const WORDS_PER_MIN = 235;
    const pattern = '\\w+';
    const reg = new RegExp(pattern, 'g');
    const wordCount = (message.match(reg) || []).length;
    return wordCount / WORDS_PER_MIN;
  }

  get readTime() {

    let message = this.message.join('');
    if (this.linkedPosts.length > 0) {
      const linkedPostMessage = this.linkedPosts[0].message;
      message = message.concat(linkedPostMessage.join(''));
    } else if (this.postType === POST_TYPES[POST_TYPE_IDS.MEDICAL_EXPERT_SUMMARY]) {
      if (this.medicalRating.generalEvaluation) {
        message = message.concat(this.medicalRating.generalEvaluation.join(''));
      }
      if (this.medicalRating.performanceSummary) {
        message = message.concat(this.medicalRating.performanceSummary.join(''));
      }
      if (this.medicalRating.medicalHistory) {
        message = message.concat(this.medicalRating.medicalHistory.join(''));
      }
      if (this.medicalRating.medicalPresentation) {
        message = message.concat(this.medicalRating.medicalPresentation.join(''));
      }
    }
    const postImageTime = this.imageReadTime(message);
    const postNakedString = this.stripTags(this.stripWhitespace(message));
    const postWordTime = this.wordsReadTime(postNakedString);
    return postImageTime + postWordTime;
  }
}
