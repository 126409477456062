import * as _ from 'lodash';
import {Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import * as moment from 'moment-timezone';
import {forwardRefMoment} from '@helpers/class-transformer.helper';
import {Person} from '@models/people';


export enum SkillArea {
  OVERALL = 'Overall',
  SHOT_CREATION = 'Shot Creation',
	KNOWS_HOW_TO_PLAY = 'Knows How to Play',
	PLAYS_HARD = 'Plays Hard',
	ATHLETICISM = 'Athleticism',
	SHOOTING = 'Shooting',
  DEFENSIVE_IMPACT = 'Defensive Impact',
  FINISHING = 'Finishing',
  SIZE = 'Size',
  LIVE = 'Live',
  OTHER = 'Other'
}

export enum Skill {
  OVERALL = 'Overall',
  ADVANTAGE_PASSING = 'Advantage Passing',
  TRANSFER_PASSING = 'Transfer Passing',
  TRANSITION_PASSING = 'Transition Passing',
  IN_ACTION_READS = 'In-Action Reads',
  ACTIVE_SPACING = 'Active Spacing',
  PNR_OFFENSE = 'PNR Offense',
  PLAY_AND_THINK = 'Play & Think',
  PLAYMAKING_HANDLE = 'Playmaking Handle',
  INTERIOR_PLAY = 'Interior Play',
  COMPETITIVE_MENTALITY = 'Competitive Mentality',
  CONTINUOUS_EFFORT = 'Continuous Effort',
  OFFENSIVE_REBOUNDING = 'Offensive Rebounding',
  DEFENSIVE_REBOUNDING = 'Defensive Rebounding',
  PERIMETER_BASIC = 'Perimeter Basic',
  PERIMETER_ADVANCED = 'Perimeter Advanced',
  VERTICAL_ATHLETICISM = 'Vertical Athleticism',
  LATERAL_SPEED = 'Lateral Speed',
  SPRINT_SPEED = 'Sprint Speed',
  BALL_SPEED = 'Ball Speed',
  INDIVIDUAL_ISOLATION = 'Individual Isolation',
  INDIVIDUAL_CLOSEOUT = 'Individual Closeout',
  INDIVIDUAL_SHOT_CONTEST = 'Individual Shot Contest/Rim Protection',
  TEAM_HELP_AWARENESS = 'Team Load/Help Awareness',
  PNR_DEFENSE = 'PNR Defense',
}

export const SkillAreaToAbbrev = {
  [SkillArea.OVERALL]: 'OV',
  [SkillArea.KNOWS_HOW_TO_PLAY]: 'KHTP',
  [SkillArea.SHOT_CREATION]: 'SC',
  [SkillArea.PLAYS_HARD]: 'PH',
  [SkillArea.SHOOTING]: 'SH',
  [SkillArea.FINISHING]: 'FIN',
  [SkillArea.ATHLETICISM]: 'ATH',
  [SkillArea.DEFENSIVE_IMPACT]: 'DEF',
  [SkillArea.SIZE]: 'SZ',
};

export const MyEvaluationSkillHeader = {
  [Skill.INDIVIDUAL_SHOT_CONTEST]: 'Shot Contest/Rim Protection'
}

export enum PositionGroup {
  POSITIONLESS = 'Positionless',
  GUARD = 'Guard',
	WING = 'Wing',
	HYBRID = 'Hybrid',
	BIG = 'Big',
	CENTER = 'Center'
}

enum PrimaryFunction {
  AM_EVAL = 'Amateur Evaluation',
  BASKETBALL =  'Basketball & Team Performance',
  DATA = 'Data Science & Solutions',
  PLAYER = 'Human & Player Performance',
  PRO_EVAL = 'Pro Evaluation',
  STRAT = 'Strategic Planning',
}

export const PositionGroups = [
  PositionGroup.POSITIONLESS, PositionGroup.GUARD, PositionGroup.WING,
  PositionGroup.HYBRID, PositionGroup.BIG, PositionGroup.CENTER
];

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

export const SkillAreaSkills: EnumDictionary<SkillArea, any[]> = {
  [SkillArea.OVERALL]: [
    SkillArea.SHOT_CREATION, SkillArea.KNOWS_HOW_TO_PLAY, SkillArea.PLAYS_HARD,
    SkillArea.ATHLETICISM, SkillArea.SHOOTING, SkillArea.FINISHING,
    SkillArea.DEFENSIVE_IMPACT
  ],
  [SkillArea.SHOT_CREATION]: [
    Skill.OVERALL, Skill.PLAYMAKING_HANDLE, Skill.INTERIOR_PLAY
  ],
  [SkillArea.KNOWS_HOW_TO_PLAY]: [
    Skill.OVERALL, Skill.ADVANTAGE_PASSING, Skill.TRANSFER_PASSING, Skill.IN_ACTION_READS,
    Skill.ACTIVE_SPACING, Skill.PNR_OFFENSE, Skill.PLAY_AND_THINK
  ],
  [SkillArea.PLAYS_HARD]: [
    Skill.OVERALL, Skill.COMPETITIVE_MENTALITY, Skill.CONTINUOUS_EFFORT, Skill.OFFENSIVE_REBOUNDING,
    Skill.DEFENSIVE_REBOUNDING
  ],
  [SkillArea.ATHLETICISM]: [
    Skill.OVERALL, Skill.VERTICAL_ATHLETICISM, Skill.LATERAL_SPEED,
    Skill.SPRINT_SPEED, Skill.BALL_SPEED
  ],
  [SkillArea.SHOOTING]: [
    Skill.OVERALL, Skill.PERIMETER_BASIC, Skill.PERIMETER_ADVANCED
  ],
  [SkillArea.DEFENSIVE_IMPACT]: [
    Skill.OVERALL, Skill.INDIVIDUAL_ISOLATION, Skill.INDIVIDUAL_CLOSEOUT, Skill.INDIVIDUAL_SHOT_CONTEST,
    Skill.TEAM_HELP_AWARENESS, Skill.PNR_DEFENSE
  ],
  [SkillArea.FINISHING]: [
    Skill.OVERALL
  ],
  [SkillArea.SIZE]: [
    Skill.OVERALL
  ],
  [SkillArea.LIVE]: [
    Skill.OVERALL
  ],
  [SkillArea.OTHER]: [
    Skill.OVERALL
  ]
};

export class SkillIDName {
  id: number;
  name: string;
}

export class SkillDetailID {
  id: number;
  skillID: number;
  skillArea: SkillArea;
  skill: Skill;
}

export class SkillDetail {
  skillArea: SkillArea;
  skill: Skill;
}

export const SkillDetailOptions: SkillDetail[] = [
  {skillArea: SkillArea.SHOT_CREATION, skill: Skill.PLAYMAKING_HANDLE},
  {skillArea: SkillArea.SHOT_CREATION, skill: Skill.INTERIOR_PLAY},
  {skillArea: SkillArea.SHOT_CREATION, skill: Skill.OVERALL},
  {skillArea: SkillArea.KNOWS_HOW_TO_PLAY, skill: Skill.ADVANTAGE_PASSING},
  {skillArea: SkillArea.KNOWS_HOW_TO_PLAY, skill: Skill.TRANSFER_PASSING},
  {skillArea: SkillArea.KNOWS_HOW_TO_PLAY, skill: Skill.TRANSITION_PASSING},
  {skillArea: SkillArea.KNOWS_HOW_TO_PLAY, skill: Skill.IN_ACTION_READS},
  {skillArea: SkillArea.KNOWS_HOW_TO_PLAY, skill: Skill.ACTIVE_SPACING},
  {skillArea: SkillArea.KNOWS_HOW_TO_PLAY, skill: Skill.PNR_OFFENSE},
  {skillArea: SkillArea.KNOWS_HOW_TO_PLAY, skill: Skill.PLAY_AND_THINK},
  {skillArea: SkillArea.KNOWS_HOW_TO_PLAY, skill: Skill.OVERALL},
  {skillArea: SkillArea.PLAYS_HARD, skill: Skill.COMPETITIVE_MENTALITY},
  {skillArea: SkillArea.PLAYS_HARD, skill: Skill.CONTINUOUS_EFFORT},
  {skillArea: SkillArea.PLAYS_HARD, skill: Skill.OFFENSIVE_REBOUNDING},
  {skillArea: SkillArea.PLAYS_HARD, skill: Skill.DEFENSIVE_REBOUNDING},
  {skillArea: SkillArea.PLAYS_HARD, skill: Skill.OVERALL},
  {skillArea: SkillArea.ATHLETICISM, skill: Skill.VERTICAL_ATHLETICISM},
  {skillArea: SkillArea.ATHLETICISM, skill: Skill.LATERAL_SPEED},
  {skillArea: SkillArea.ATHLETICISM, skill: Skill.SPRINT_SPEED},
  {skillArea: SkillArea.ATHLETICISM, skill: Skill.BALL_SPEED},
  {skillArea: SkillArea.ATHLETICISM, skill: Skill.OVERALL},
  {skillArea: SkillArea.SHOOTING, skill: Skill.PERIMETER_BASIC},
  {skillArea: SkillArea.SHOOTING, skill: Skill.PERIMETER_ADVANCED},
  {skillArea: SkillArea.SHOOTING, skill: Skill.OVERALL},
  {skillArea: SkillArea.DEFENSIVE_IMPACT, skill: Skill.INDIVIDUAL_ISOLATION},
  {skillArea: SkillArea.DEFENSIVE_IMPACT, skill: Skill.INDIVIDUAL_CLOSEOUT},
  {skillArea: SkillArea.DEFENSIVE_IMPACT, skill: Skill.INDIVIDUAL_SHOT_CONTEST},
  {skillArea: SkillArea.DEFENSIVE_IMPACT, skill: Skill.TEAM_HELP_AWARENESS},
  {skillArea: SkillArea.DEFENSIVE_IMPACT, skill: Skill.PNR_DEFENSE},
  {skillArea: SkillArea.DEFENSIVE_IMPACT, skill: Skill.OVERALL},
  {skillArea: SkillArea.FINISHING, skill: Skill.OVERALL},
  {skillArea: SkillArea.LIVE, skill: Skill.OVERALL},
]

export const SkillAreaDrivers = {
  [SkillArea.OVERALL]: '',
  [SkillArea.SHOT_CREATION]: 'Playmaking Handle, Interior Play',
  [SkillArea.KNOWS_HOW_TO_PLAY]: 'Reading the Game, Game Awareness, Passing',
  [SkillArea.PLAYS_HARD]: 'Competitive Mentality, Continuous Effort, Physicality',
  [SkillArea.ATHLETICISM]: 'Vertical Athleticism, Lateral Speed, Sprint Speed, Ball Speed',
  [SkillArea.SHOOTING]: 'Perimeter Basic, Perimeter Advanced',
  [SkillArea.DEFENSIVE_IMPACT]: 'Individual, Team, Pick and Roll',
  [SkillArea.FINISHING]: '',
  [SkillArea.SIZE]: ''
};

export class PlayerDevelopmentSkillAssessment {
  @Type(forwardRef(() => Person) as any)
  about: Person;
  @Type(forwardRef(() => Person) as any)
  author: Person;
  mid: number;
  now: number;
  @Type(forwardRef(() => Skill) as any)
  skill: Skill;
  @Type(forwardRef(() => SkillArea) as any)
  skillArea: SkillArea;
  @Type(forwardRef(() => PrimaryFunction) as any)
  primaryFunction: PrimaryFunction;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  postDatetime: moment.Moment;
  text: string;

  get upside(): number {
    return this.mid - this.now;
  }

  get postDatetimeFormatted(): string {
    const now = moment();
    let formattedDatetime: string = '';

    switch (true) {
      case this.postDatetime.isSame(now, 'day'):
        formattedDatetime = this.postDatetime.format('h:mm A');
        break;
      case this.postDatetime.isSame(now, 'year'):
        formattedDatetime = this.postDatetime.format('MMM D');
        break;
      default:
        formattedDatetime = this.postDatetime.format('MMM D, YYYY');
    }

    return formattedDatetime;
  }
}

export class PlayerDevelopmentSkillAssessmentsForSkill {
  skill: SkillArea | Skill;
  @Type(forwardRef(() => PlayerDevelopmentSkillAssessment) as any)
  entries: PlayerDevelopmentSkillAssessment[];

  _activeEntries: PlayerDevelopmentSkillAssessment[];
  get activeEntries(): PlayerDevelopmentSkillAssessment[] {
    if (this._activeEntries) {
      return this._activeEntries;
    }
    const latestEntry = this.entries.length > 0 ? this.entries[0] : null;
    if (latestEntry?.skillArea == SkillArea.OVERALL || latestEntry?.skill == Skill.OVERALL) {
      let filteredEntries = this.entries.filter(entry =>
        entry.primaryFunction == PrimaryFunction.BASKETBALL ||
        entry.primaryFunction == PrimaryFunction.PRO_EVAL);
      filteredEntries = filteredEntries.length > 0 ? filteredEntries : this.entries;
      let monthWindowPreferences = [
        {months: 6, count: 5}, {months: 9, count: 5}, {months: 12, count: 5},
        {months: 6, count: 3}, {months: 9, count: 3}, {months: 12, count: 3}, {months: 15, count: 3}, {months: 18, count: 3},
        {months: 6, count: 1}, {months: 9, count: 1}, {months: 12, count: 1}, {months: 15, count: 1}, {months: 18, count: 1}
      ]
      let monthsWindow = 18;
      for (let monthWindowPreference of monthWindowPreferences) {
        if (this.getDistinctAuthorsCount(this.getEntriesAfterNumberMonths(filteredEntries, monthWindowPreference.months)) > monthWindowPreference.count) {
          monthsWindow = monthWindowPreference.months;
          break;
        }
      }
      filteredEntries = filteredEntries.filter(entry => entry.postDatetime.isAfter(moment().subtract(monthsWindow, 'months')));
      const authorIDs = new Set();
      const recentEntries = [];
      filteredEntries.forEach(entry => {
        if (!authorIDs.has(entry.author.id)) {
          authorIDs.add(entry.author.id);
          recentEntries.push(entry);
        }
      });
      this._activeEntries = recentEntries;
      return recentEntries;
    }
    else if (latestEntry) {
      const recentEntries = this.entries.filter(entry => entry.postDatetime.isSame(latestEntry.postDatetime));
      this._activeEntries = recentEntries;
      return recentEntries;
    }
    return [];
  }

  get nowMin(): number {
    return Math.min(...this.activeEntries.map(entry => entry.now));
  }

  get nowMax(): number {
    return Math.max(...this.activeEntries.map(entry => entry.now));
  }

  get nowAvg(): number {
    return _.meanBy(this.activeEntries, 'now');
  }

  get midMin(): number {
    return Math.min(...this.activeEntries.map(entry => entry.mid));
  }

  get midMax(): number {
    return Math.max(...this.activeEntries.map(entry => entry.mid));
  }

  get midAvg(): number {
    return _.meanBy(this.activeEntries, 'mid');
  }

  get text(): string {
    return _.last(this.activeEntries.map(entry => entry.text));
  }

  get upsideMin(): number {
    return Math.min(...this.activeEntries.map(entry => entry.upside));
  }

  get upsideMax(): number {
    return Math.max(...this.activeEntries.map(entry => entry.upside));
  }

  get upsideAvg(): number {
    return _.meanBy(this.activeEntries, 'upside');
  }

  valColor(val): string {
    if (val >= 0) {
      return '#629c44' + Math.round((Math.min(val / 2, 1) * 0.5 + 0.5) * 255).toString(16);
    } else {
      return '#b31515' + Math.round((Math.min(Math.abs(val) / 2, 1) * 0.5 + 0.5) * 255).toString(16);
    }
  }

  isEntryActive(entry: PlayerDevelopmentSkillAssessment): boolean {
    return this.activeEntries.indexOf(entry) >= 0;
  }

  getEntriesAfterNumberMonths(entries: PlayerDevelopmentSkillAssessment[], numMonths: number) {
    return [...entries.filter(entry => entry.postDatetime.isAfter(moment().subtract(numMonths, 'months')))];
  }

  getDistinctAuthorsCount(entries: PlayerDevelopmentSkillAssessment[]) {
    return [...new Set(entries.map(entry => entry.author.id))].length;
  }
}

export class PlayerDevelopmentWeights {
  @Type(forwardRef(() => PositionGroup) as any)
  positionGroup: PositionGroup;
  @Type(forwardRef(() => Skill) as any)
  skill: Skill;
  @Type(forwardRef(() => SkillArea) as any)
  skillArea: SkillArea;
  weight: number;
}

export class PlayerTSAEvaluation {
  allAuthors: string[];
  evals: any[];
  id: number;
  summary: Object;
  @Type(forwardRef(() => PlayerDevelopmentWeights) as any)
  weight: PlayerDevelopmentWeights[];
}
