import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, map, catchError, concatMap, mergeMap, filter } from 'rxjs/operators';

import * as actions from './actions';

import { EntitiesService } from '@services/entities.service';
import { InjuryOccurrence } from '@models/physical/injuryOccurrences';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { MergedRoute } from 'apps/route-serializer';

@Injectable()
export class Effects {

  getEntityInjuryOccurrences$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GetInjuryOccurences>(actions.ActionTypes.LIST),
    map(action => action.payload),
    switchMap(payload => {
      return this.entitiesService.getEntityInjuryOccurrences(payload.id).pipe(
        switchMap((injuries: InjuryOccurrence[]) => {
          return of({
            type: actions.ActionTypes.LIST_SUCCESS,
            payload: injuries
          });
        }),
        catchError(error => {
          return of({
            type: actions.ActionTypes.LIST_FAILED,
            payload: error
          });
        })
      );
    }),
  ));

  saveEntityInjuryOccurrences$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SaveInjuryOccurences>(actions.ActionTypes.SAVE),
    map(action => action.payload),
    concatMap(payload => {
      return this.entitiesService.saveEntityInjuryOccurrences(payload.injuryOccurences, payload.id).pipe(
        mergeMap(injuries => {
          return of({
            type: actions.ActionTypes.SAVE_SUCCESS,
            payload: injuries
          });
        }),
        catchError(error => {
          return of({
            type: actions.ActionTypes.SAVE_FAILED,
            payload: error
          });
        })
      );
    })
  ));

  entityInjuryOccurrencesRouted$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    filter((action: RouterNavigationAction<MergedRoute>) => {
      return action.payload.routerState.path === '/bild/entities/people/:id/physical/injuries';
    }),
    concatMap((action: RouterNavigationAction<MergedRoute>) => {
      return of(new actions.GetInjuryOccurences({ id: action.payload.routerState.params.id }));
    })
  ));

  constructor(private entitiesService: EntitiesService, private actions$: Actions) { }
}
