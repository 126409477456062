import { VERSION } from './version';

export const environment = {
  envName: 'prod',
  production: true,
  siteUrl: 'https://bild.okcthunder.com',
  apiUrl: 'https://bild.okcthunder.com/api/v1',
  socketHost: 'wss://bild.okcthunder.com',
  notificationsHost: 'wss://bild.okcthunder.com',
  froalaEditorKey: 'FSC2H-9B1A3A4B5D3C1C1sGXh1WWTDSGXYOUKc1KINLe1OC1c1D-17D2E2F2G1E4F1B2B8E7D6==',
  typingDebounceTime: 250,
  autoSaveDebounceTime: 2000,
  entityInsertHTML: '<a entity-link data-entity-id="${id}" data-entity-name="${name}" data-entity-type="${type}">${name}</a>',
  sentryUrl: 'https://2de12cf300aa40ef8546b478ba555035@sentry.io/287767',
  sentryRelease: `${VERSION.env}-${VERSION.version}-${VERSION.hash}`,
  DATETIME_FORMATS: {
    SHORT: 'M/D/YY',
    MD: 'MMM D'
  },
  timezone: 'America/Chicago',
  adfsLogoutUrl: 'https://fs.okcthunder.com/adfs/ls/?wa=wsignout1.0&wreply=bild.okcthunder.com',
  newsMaxDays: 3,
  googleAPIKey: 'AIzaSyAWCV4daX7FrZiuK7nfLrasVwPunY13tK0'
};
