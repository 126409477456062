<div class="toolbar">
  <div class="generate-context">
    <span class="section-header generate-header">Generate Playlist</span>
    <span class="generate-sub-header">Define what you want to see with filters</span>
  </div>

  <div class="generate-buttons-container">
    <video-filter-panel *ngIf="filtersData?.filters?.length"
                        [isGeneratePlaylist]="true"
                        [filters]="displayedFilters"
                        [filtersData]="filtersData"
    ></video-filter-panel>
    <button
    class="generate-action-button"
    mat-flat-button
    [disabled]="!clearToGenerate()"
    [matTooltip]="!clearToGenerate() ? getTooltip() : ''"
    matTooltipPosition="above"
    (click)="generatePlaylist()"
  >
    <div class="inner-button-container">
      <mat-icon>video_settings</mat-icon> <span>Generate</span>
    </div>
  </button>
  </div>
</div>

<div class="generate-container">
  <div class="generate-section">
    <div class="section-header first-column-control">League</div>
    <div class="generate-chip-list first-column-control">
      <mat-chip-list>
        <mat-chip class="generate-chip" [ngClass]="{active: selectedLeague === 'NCAA'}" (click)="updateLeague('NCAA', 101)">
          <div class="name">NCAA</div>
        </mat-chip>
        <mat-chip class="generate-chip" [ngClass]="{active: selectedLeague === 'NBA'}" (click)="updateLeague('NBA', 1)">
          <div class="name">NBA</div>
        </mat-chip>
        <mat-chip class="generate-chip" [ngClass]="{active: selectedLeague === 'GLG'}" (click)="updateLeague('GLG', 2)">
          <div class="name">G-League</div>
        </mat-chip>
        <mat-chip class="generate-chip" [ngClass]="{active: selectedLeague === 'OTHER'}" (click)="updateLeague('OTHER', null)">
          <div class="name">Non-NCAA Synergy</div>
        </mat-chip>
      </mat-chip-list>
    </div>
    <div class="section-header action first-column-control">Event</div>

    <div class="generate-chip-list first-column-control">
      <mat-chip-list>
        <ng-container *ngFor="let filterOption of videoEventFilterOptions">
          <mat-chip class="generate-chip" *ngIf="filterOption.leagues.includes(selectedLeague)"
                    (click)="changeFilterOption(filterOption)"
                    [ngClass]="{active: filterOption.value === filtersData.subject}">
            <div class="name">{{filterOption.name}}</div>
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </div>
    <div class="section-header action first-column-control" *ngIf="isNCAA">Actions</div>

    <div class="generate-chip-list first-column-control">
      <mat-chip-list>
        <ng-container *ngFor="let filterOption of videoActionFilterOptions">
          <mat-chip class="generate-chip" *ngIf="filterOption.leagues.includes(selectedLeague)"
                    (click)="toggleActionFilter(filterOption.value)"
                    [ngClass]="{active: getActionFilter(filterOption.value)}">
            <div class="name">{{filterOption.name}}</div>
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </div>
    <div class="recent-generated-playlists-container" *ngIf="userGeneratedPlaylists?.length" [class.expanded]="isRecentlyGeneratedExpanded">
      <div class="section-header first-column-control">
        Recently Generated Playlists
        <button mat-icon-button class="expand-toggle-button" (click)="isRecentlyGeneratedExpanded = !isRecentlyGeneratedExpanded">
          <mat-icon>{{ isRecentlyGeneratedExpanded ? 'expand_more' : 'expand_less' }}</mat-icon>
        </button>
      </div>
      <mat-card class="recent-generated-playlists-card" *ngFor="let gp of userGeneratedPlaylists" (mouseleave)="onMouseLeave()">
        <div class="generated-playlist-metadata">
          <div class="subject" *ngIf="!getGeneratedPlaylistPrimaryEntity(gp)">{{determineGPHeader(gp)}}</div>
          <div class="entity-subject-container" *ngIf="getGeneratedPlaylistPrimaryEntity(gp)">
            <entity-avatar class="avatar" [fitCrop]="true" [entity]="getGeneratedPlaylistPrimaryEntity(gp)" [size]="24"></entity-avatar>
            <div class="context">
              <span class="entity-name">{{getGeneratedPlaylistPrimaryEntity(gp).name}}</span>
              <span class="subject-name">{{determineGPHeader(gp, false)}}</span>
            </div>

          </div>
          <div class="date">{{domHelper.getTimeSince(gp.createDatetime)}}</div>
        </div>
        <div class="generate-buttons-container">
          <video-filter-panel *ngIf="gp.filtersData?.filters?.length"
                              [isGeneratePlaylist]="true"
                              [isRecentGeneratedPlaylist]="true"
                              [filters]="gp.filters"
                              [filtersData]="gp.filtersData"
          ></video-filter-panel>
          <button class="generate-action-button is-recent-generated-playlists" mat-flat-button (click)="openRecentGeneratedPlaylist(gp.id)">
            <div class="inner-button-container">
              <mat-icon>video_settings</mat-icon>
            </div>
          </button>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="generate-section" *ngIf="selectedFilterOption">
    <div class="section-header" *ngIf="selectedFilterOption.actionItems?.length">Event</div>
    <div class="action-item"
         *ngFor="let actionItem of selectedFilterOption.actionItems"
         (click)="selectedFilterOption.selectedAction = actionItem"
         [ngClass]="{active: selectedFilterOption.selectedAction?.name === actionItem.name && !selectedFilterOption.selectedAction?.isAction}"
    >{{ actionItem.name }}</div>

    <div class="section-header">Event Context</div>
    <div class="action-item" (click)="selectedFilterOption.selectedAction = getCorrectPCItem()"
         [ngClass]="{active: selectedFilterOption.selectedAction?.name === getCorrectPCItem().name}">{{isNCAA ? 'Events' : 'Possessions & Chances'}}</div>
    <div class="action-item"
         *ngFor="let actionContextItem of selectedFilterOption.actionContextItems"
         (click)="selectedFilterOption.selectedAction = actionContextItem"
         [ngClass]="{active: selectedFilterOption.selectedAction?.name === actionContextItem.name}"
    >{{ actionContextItem.name }}</div>

    <ng-container *ngIf="displayedFilters.actions?.length">
      <div class="section-header">Action Player</div>
      <div class="action-item"
           (click)="selectedFilterOption.selectedAction = abstractActionItem"
           [ngClass]="{active: selectedFilterOption.selectedAction?.name === abstractActionItem.name && selectedFilterOption.selectedAction?.isAction}"
      >{{ abstractActionItem.name }}</div>

      <div class="section-header">Action Context</div>
      <ng-container *ngFor="let option of selectedActionOptions">
        <div class="action-item" *ngFor="let actionItem of option.actionItems"
             (click)="selectedFilterOption.selectedAction = actionItem"
             [ngClass]="{active: selectedFilterOption.selectedAction?.name === actionItem.name}"
        >{{ actionItem.name }}</div>
      </ng-container>
    </ng-container>

    <div class="section-header">Game Context</div>
    <div class="action-item"
         (click)="selectedFilterOption.selectedAction = gamesActionItem"
         [ngClass]="{active: selectedFilterOption.selectedAction?.name === gamesActionItem.name}">Games</div>
    <!--    TODO: Fix and add back-->
<!--    <div class="action-item"-->
<!--         (click)="selectedFilterOption.selectedAction = teamsActionItem"-->
<!--         [ngClass]="{active: selectedFilterOption.selectedAction?.name === teamsActionItem.name}">Teams</div>-->
    <div class="action-item"
         (click)="selectedFilterOption.selectedAction = timeActionItem"
         [ngClass]="{active: selectedFilterOption.selectedAction?.name === timeActionItem.name}">Time</div>
  </div>
  <div class="generate-section" *ngIf="selectedFilterOption?.selectedAction">
    <ng-container *ngFor="let actionItem of selectedFilterOption.selectedAction.actionItems">
      <div class="section-header">{{ actionItem.header }}</div>
      <div class="actions-grid">
        <ng-container *ngFor="let option of actionItem.options">
          <ng-container *ngIf="!(isNCAA && option.isNCAAExcluded) && !(!isNCAA && option.isNBAExcluded)">
            <div class="action-item grid-item" *ngIf="selectedFilterOption?.value !== VideoSubject.PERIODS || !option.excludeOnPeriods"
                 (click)="selectedFilterOption.selectedAction.selectedSubAction = option"
                 [ngClass]="{active: selectedFilterOption.selectedAction.selectedSubAction?.name === option.name}"
            >{{ option.displayOverride || option.name }}</div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="generate-section no-border" *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction">
    <div class="section-header">{{ selectedFilterOption?.selectedAction?.selectedSubAction?.name }}</div>
    <ng-container *ngFor="let option of selectedFilterOption?.selectedAction?.selectedSubAction.subOptions">
      <div class="action-item" *ngIf="!(isNCAA && option.isNCAAExcluded) && !(!isNCAA && option.isNBAExcluded)">
        <div *ngIf="option?.isAdvancedCheckbox" class="generate-filter-container">
          <mat-checkbox [ngClass]="{'exclude': displayedFilters[option.columnName] == 0 || (displayedFilters[option.columnName]?.length && displayedFilters[option.columnName][0] == false), 'include': displayedFilters[option.columnName] == 1 || (displayedFilters[option.columnName]?.length && displayedFilters[option.columnName][0] == true)}"
                        (click)="updateCheckboxValue($event, option)"></mat-checkbox>
        </div>
        <div *ngIf="option?.isAggregatedAdvancedCheckbox" class="generate-filter-container">
          <mat-checkbox [ngClass]="{'exclude': (displayedFilters[option.group + option.value + option.columnName] == 0 || displayedFilters[option.columnName] == 0), 'include': (displayedFilters[option.group + option.value + option.columnName] == 1 || checkDefenseItems(option.columnName))}"
                        (click)="updateAggregatedCheckboxValue($event, option)"></mat-checkbox>
        </div>
        <div *ngIf="option?.isSimpleCheckbox" class="generate-filter-container">
          <mat-checkbox [ngClass]="{'include': isSimpleCheckboxIncluded(option, selectedFilterOption?.selectedAction?.selectedSubAction), 'nested': option?.isSubOption}"
                        (click)="updateSimpleCheckbox(option, selectedFilterOption?.selectedAction?.selectedSubAction)"></mat-checkbox>
        </div>
        {{option.displayOverride || option.name}}
      </div>
    </ng-container>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isDistance" class="generate-filter-container">
      <div class="slider-container">
        <ngx-slider [(value)]="displayedFilters.minDistance" [(highValue)]="displayedFilters.maxDistance" [options]="distanceOptions" (userChangeEnd)="updateDistance()"></ngx-slider>
      </div>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isQEFG" class="generate-filter-container">
      <div class="slider-container">
        <ngx-slider [(value)]="displayedFilters.minQEFG" [(highValue)]="displayedFilters.maxQEFG" [options]="qEfgOptions" (userChangeEnd)="updateQEFG()"></ngx-slider>
      </div>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isXEFG" class="generate-filter-container">
      <div class="slider-container">
        <ngx-slider [(value)]="displayedFilters.minXEFG" [(highValue)]="displayedFilters.maxXEFG" [options]="xEfgOptions" (userChangeEnd)="updateXEFG()"></ngx-slider>
      </div>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isGameClock" class="generate-filter-container">
      <div class="slider-container">
        <ngx-slider *ngIf="selectedLeague == 'NCAA'" [(value)]="displayedFilters.minGameclock" [(highValue)]="displayedFilters.maxGameclock" [options]="ncaaGameclockSliderOptions" (userChangeEnd)="updateGameClock()"></ngx-slider>
        <ngx-slider *ngIf="selectedLeague != 'NCAA'" [(value)]="displayedFilters.minGameclock" [(highValue)]="displayedFilters.maxGameclock" [options]="proGameclockSliderOptions" (userChangeEnd)="updateGameClock()"></ngx-slider>
      </div>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isShotClock" class="generate-filter-container">
      <div class="slider-container">
        <ngx-slider *ngIf="selectedLeague == 'NCAA'" [(value)]="displayedFilters.minShotclock" [(highValue)]="displayedFilters.maxShotclock" [options]="ncaaShotclockSliderOptions" (userChangeEnd)="updateShotClock()"></ngx-slider>
        <ngx-slider *ngIf="selectedLeague != 'NCAA'" [(value)]="displayedFilters.minShotclock" [(highValue)]="displayedFilters.maxShotclock" [options]="proShotclockSliderOptions" (userChangeEnd)="updateShotClock()"></ngx-slider>
      </div>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isDribbles" class="generate-filter-container">
      <div class="slider-container">
        <ngx-slider [(value)]="displayedFilters.touchMinDribbles" [(highValue)]="displayedFilters.touchMaxDribbles" [options]="touchDribblesSliderOptions" (userChangeEnd)="updateDribbles()"></ngx-slider>
      </div>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isTouchTime" class="generate-filter-container">
      <div class="slider-container">
        <ngx-slider [(value)]="displayedFilters.touchMinDuration" [(highValue)]="displayedFilters.touchMaxDuration" [options]="touchDurationSliderOptions" (userChangeEnd)="updateDuration()"></ngx-slider>
      </div>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isPeriods" class="generate-filter-container">
      <mat-form-field>
        <mat-select multiple [(ngModel)]="displayedFilters.periods" [ngModelOptions]="{standalone: true}" (selectionChange)="updatePeriod($event)">
          <ng-container *ngFor="let periodOption of periodOptions">
            <mat-option [value]="periodOption">
              {{ periodOption }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isDates" class="generate-filter-container">
      <mat-form-field class="date-field">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [(ngModel)]="displayedFilters.minDate" (dateChange)="updateDate()">
          <input matEndDate [(ngModel)]="displayedFilters.maxDate" (dateChange)="updateDate()">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isPossChance" class="generate-filter-container">
      <mat-form-field class="text-area-field">
        <textarea matInput [placeholder]="selectedFilterOption?.selectedAction?.selectedSubAction?.label"
                  [ngModel]="displayedFilters[selectedFilterOption?.selectedAction?.selectedSubAction?.columnName]"
                  (change)="updateTextArea($event, selectedFilterOption?.selectedAction?.selectedSubAction.columnName)"></textarea>
      </mat-form-field>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isSeasons" class="generate-filter-container">
      <mat-form-field>
        <mat-select multiple [(ngModel)]="displayedFilters.seasons" [ngModelOptions]="{standalone: true}" (selectionChange)="updateSeason($event)">
          <ng-container *ngFor="let seasonOption of seasonOptions">
            <mat-option [value]="seasonOption">
              {{ seasonOption }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isGames" class="generate-filter-container">

      <mat-form-field class="games-field">
        <mat-select multiple [ngModel]="displayedFilters.ctgGames" [compareWith]="compareGameIDs" [ngModelOptions]="{standalone: true}">
          <mat-select-trigger>
          </mat-select-trigger>
          <div class="game-search">
            <mat-icon>search</mat-icon>
            <input matInput type="text" placeholder="Search" [(ngModel)]="gameSearchFilterValue" (keydown)="stopPropagation($event)" (ngModelChange)="filterGameOptions()">
          </div>
          <ng-container *ngFor="let gameOption of filteredGameOptions">
            <mat-option [value]="gameOption" (click)="updateGames(gameOption)">
              {{ gameOption.gameStartTime | date: 'shortDate' }}: {{ gameOption.awayTeamName }} @ {{ gameOption.homeTeamName }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-chip-list>
        <ng-container *ngFor="let game of displayedFilters.ctgGames">
          <mat-chip class="game-chip" (removed)="updateGames(game)">
            <div class="date-time-details">
              <div class="date">{{ game.gameStartTime | date: 'longDate' }}</div>
              <mat-icon class="remove-game-chip" matChipRemove>cancel</mat-icon>
            </div>
            <div class="team-details" [class.emphasis]="game.awayFinalPoints > game.homeFinalPoints">
              <entity-avatar class="logo" [imageUrl]="'https://docs-public.imgix.net/' + game.awayTeamImage" [size]="16" [maskCorners]="true"></entity-avatar>
              <div class="name">{{ game.awayTeamName }}</div>
              <div class="points">{{ game.awayFinalPoints }}</div>
            </div>
            <div class="team-details" [class.emphasis]="game.homeFinalPoints > game.awayFinalPoints">
              <entity-avatar class="logo" [imageUrl]="'https://docs-public.imgix.net/' + game.homeTeamImage" [size]="16" [maskCorners]="true"></entity-avatar>
              <div class="name">{{ game.homeTeamName }}</div>
              <div class="points">{{ game.homeFinalPoints }}</div>
            </div>
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </div>
    <div *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isAutocomplete" class="generate-filter-container">
      <ng-container *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isAbstractEntity">
        <input #abstractEntityInput matInput [matChipInputFor]="abstractEntityChipList" [matAutocomplete]="abstractEntityAutocomplete" [formControl]="abstractEntityControl">
        <mat-chip-list class="entity-chip-list" #abstractEntityChipList>
          <mat-chip *ngIf="displayedFilters.abstractEntity && displayedFilters.abstractEntity?.length == null" class="entity-chip" (removed)="removeAbstractEntity()">
            <entity-avatar class="avatar" [entity]="displayedFilters.abstractEntity" [size]="24"></entity-avatar>
            <div class="name">{{ displayedFilters.abstractEntity.name }}</div>
            <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
          </mat-chip>
          <ng-container *ngIf="displayedFilters.abstractEntity?.length">
            <mat-chip *ngFor="let entity of displayedFilters.abstractEntity" class="entity-chip" (removed)="removeAbstractEntity()">
             <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              <div class="name">{{ entity.name }}</div>
              <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
        </mat-chip-list>
        <mat-autocomplete #abstractEntityAutocomplete="matAutocomplete" (optionSelected)="addAbstractEntity($event, selectedFilterOption?.selectedAction?.selectedSubAction)">
          <mat-option *ngFor="let entity of filteredAbstractEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
      <ng-container *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isPrimaryEntity">
        <input #primaryEntityInput matInput [matChipInputFor]="primaryEntityChipList" [matAutocomplete]="primaryEntityAutocomplete" [formControl]="primaryEntityControl">
        <mat-chip-list class="entity-chip-list" #primaryEntityChipList>
          <mat-chip *ngIf="displayedFilters.primaryEntity && displayedFilters.primaryEntity?.length == null" class="entity-chip" (removed)="removePrimaryEntity(selectedFilterOption?.selectedAction?.selectedSubAction?.columnName)">
            <entity-avatar class="avatar" [entity]="displayedFilters.primaryEntity" [size]="24"></entity-avatar>
            <div class="name">{{ displayedFilters.primaryEntity.name }}</div>
            <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
          </mat-chip>
          <ng-container *ngIf="displayedFilters.primaryEntity?.length">
            <mat-chip *ngFor="let entity of displayedFilters.primaryEntity" class="entity-chip" (removed)="removePrimaryEntity(selectedFilterOption?.selectedAction?.selectedSubAction?.columnName)">
             <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              <div class="name">{{ entity.name }}</div>
              <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
        </mat-chip-list>
        <mat-autocomplete #primaryEntityAutocomplete="matAutocomplete" (optionSelected)="addPrimaryEntity($event, selectedFilterOption?.selectedAction?.selectedSubAction)">
          <mat-option *ngFor="let entity of filteredPrimaryEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
      <ng-container *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isSecondaryEntity && !(isNCAA && selectedFilterOption?.selectedAction?.selectedSubAction.isNCAAExcluded)">
        <input #secondaryEntityInput matInput [matChipInputFor]="secondaryEntityChipList" [matAutocomplete]="secondaryEntityAutocomplete" [formControl]="secondaryEntityControl">
        <mat-chip-list class="entity-chip-list" #secondaryEntityChipList>
          <mat-chip *ngIf="displayedFilters.secondaryEntity && displayedFilters.secondaryEntity.length == null" class="entity-chip" (removed)="removeSecondaryEntity(selectedFilterOption?.selectedAction?.selectedSubAction?.columnName)">
            <entity-avatar class="avatar" [entity]="displayedFilters.secondaryEntity" [size]="24"></entity-avatar>
            <div class="name">{{ displayedFilters.secondaryEntity.name }}</div>
            <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
          </mat-chip>
          <ng-container *ngIf="displayedFilters.secondaryEntity?.length">
            <mat-chip *ngFor="let entity of displayedFilters.secondaryEntity" class="entity-chip" (removed)="removeSecondaryEntity(selectedFilterOption?.selectedAction?.selectedSubAction?.columnName)">
              <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              <div class="name">{{ entity.name }}</div>
              <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
        </mat-chip-list>
        <mat-autocomplete #secondaryEntityAutocomplete="matAutocomplete" (optionSelected)="addSecondaryEntity($event, selectedFilterOption?.selectedAction?.selectedSubAction?.columnName)">
          <mat-option *ngFor="let entity of filteredSecondaryEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
      <ng-container *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isOnCourt">
        <mat-chip-list class="entity-chip-list" #onCourtEntitiesChipList>
          <ng-container *ngFor="let entity of displayedFilters.onCourtEntities">
            <mat-chip class="entity-chip" (removed)="removeOnOffCourtEntity(entity, false)">
              <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              <div class="name">{{ entity.name }}</div>
              <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <input #onCourtEntitiesInput matInput [matChipInputFor]="onCourtEntitiesChipList" [matAutocomplete]="onCourtEntitiesAutocomplete" [formControl]="onCourtEntitiesControl">
        </mat-chip-list>
        <mat-autocomplete #onCourtEntitiesAutocomplete="matAutocomplete" (optionSelected)="addOnOffCourtEntity($event, true)">
          <mat-option *ngFor="let entity of filteredOnCourtEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
      <ng-container *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isOffCourt">
        <mat-chip-list class="entity-chip-list" #offCourtEntitiesChipList>
          <ng-container *ngFor="let entity of displayedFilters.offCourtEntities">
            <mat-chip class="entity-chip" (removed)="removeOnOffCourtEntity(entity, true)">
              <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              <div class="name">{{ entity.name }}</div>
              <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <input #offCourtEntitiesInput matInput [matChipInputFor]="offCourtEntitiesChipList" [matAutocomplete]="offCourtEntitiesAutocomplete" [formControl]="offCourtEntitiesControl">
        </mat-chip-list>
        <mat-autocomplete #offCourtEntitiesAutocomplete="matAutocomplete" (optionSelected)="addOnOffCourtEntity($event, false)">
          <mat-option *ngFor="let entity of filteredOffCourtEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
      <ng-container *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isOffensiveLineup">
        <mat-chip-list class="entity-chip-list" #offensiveLineupEntitiesChipList>
          <ng-container *ngFor="let entity of displayedFilters.offensiveLineupEntities">
            <mat-chip class="entity-chip" (removed)="removeLineupEntity(entity, true)">
              <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              <div class="name">{{ entity.name }}</div>
              <ng-container *ngIf="selectedFilterOption.name !== 'Defense' && selectedLeague === 'NBA'">
                <mat-select class="position-select" [(value)]="entity.selectedPosition" (selectionChange)="onPositionChange(entity, true)">
                  <mat-select-trigger>
                    {{ getSelectedPosition(entity, true) === 'ANY' ? '' : getSelectedPosition(entity, true) }}
                  </mat-select-trigger>
                  <mat-option *ngFor="let position of positions" [value]="position">{{ position }}</mat-option>
                </mat-select>
              </ng-container>
              <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <div *ngIf="displayedFilters?.offensiveLineupEntities?.length < 5">
            <input
              #offensiveLineupEntitiesInput
              matInput
              [matChipInputFor]="offensiveLineupEntitiesChipList"
              [matAutocomplete]="offensiveLineupEntitiesAutocomplete"
              [formControl]="offensiveLineupEntitiesControl"
            >
          </div>
        </mat-chip-list>
        <mat-autocomplete #offensiveLineupEntitiesAutocomplete="matAutocomplete" (optionSelected)="addLineupEntity($event, true)">
          <mat-option *ngFor="let entity of filteredOffensiveLineupEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
      <ng-container *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isDefensiveLineup">
        <mat-chip-list class="entity-chip-list" #defensiveLineupEntitiesChipList>
          <ng-container *ngFor="let entity of displayedFilters.defensiveLineupEntities">
            <mat-chip class="entity-chip" (removed)="removeLineupEntity(entity, false)">
              <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              <div class="name">{{ entity.name }}</div>
              <ng-container *ngIf="selectedFilterOption.name !== 'Defense' && selectedLeague === 'NBA'">
                <mat-select class="position-select" [(value)]="entity.selectedPosition" (selectionChange)="onPositionChange(entity, false)">
                  <mat-select-trigger>
                    {{ getSelectedPosition(entity, false) === 'ANY' ? '' : getSelectedPosition(entity, false) }}
                  </mat-select-trigger>
                  <mat-option *ngFor="let position of positions" [value]="position">{{ position }}</mat-option>
                </mat-select>
              </ng-container>
              <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <div *ngIf="(!selectedFilterOption?.selectedAction?.selectedSubAction?.singular && displayedFilters?.defensiveLineupEntities?.length < 5) || displayedFilters?.defensiveLineupEntities?.length === 0">
            <input
              #defensiveLineupEntitiesInput
              matInput
              [matChipInputFor]="defensiveLineupEntitiesChipList"
              [matAutocomplete]="defensiveLineupEntitiesAutocomplete"
              [formControl]="defensiveLineupEntitiesControl"
            >
          </div>
        </mat-chip-list>
        <mat-autocomplete #defensiveLineupEntitiesAutocomplete="matAutocomplete" (optionSelected)="addLineupEntity($event, false)">
          <mat-option *ngFor="let entity of filteredDefensiveLineupEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
      <ng-container *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isPrimaryDefender">
        <mat-chip-list class="entity-chip-list" #primaryDefensivePlayerChipList>
          <mat-chip *ngIf="displayedFilters.primaryDefensivePlayer && displayedFilters.primaryDefensivePlayer?.length == null" class="entity-chip" (removed)="removePrimaryDefensivePlayer(selectedFilterOption?.selectedAction?.selectedSubAction?.columnName)">
            <entity-avatar class="avatar" [entity]="displayedFilters.primaryDefensivePlayer" [size]="24"></entity-avatar>
            <div class="name">{{ displayedFilters.primaryDefensivePlayer.name }}</div>
            <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
          </mat-chip>
          <ng-container *ngIf="displayedFilters.primaryDefensivePlayer?.length">
            <mat-chip *ngFor="let entity of displayedFilters.primaryDefensivePlayer" class="entity-chip" (removed)="removePrimaryDefensivePlayer(selectedFilterOption?.selectedAction?.selectedSubAction?.columnName)">
             <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              <div class="name">{{ entity.name }}</div>
              <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <input #primaryDefensivePlayerInput matInput [matChipInputFor]="primaryDefensivePlayerChipList" [matAutocomplete]="primaryDefensivePlayerAutocomplete" [formControl]="primaryDefensivePlayerControl">
        </mat-chip-list>
        <mat-autocomplete #primaryDefensivePlayerAutocomplete="matAutocomplete" (optionSelected)="addPrimaryDefensivePlayer($event, selectedFilterOption?.selectedAction?.selectedSubAction)">
          <mat-option *ngFor="let entity of filteredPrimaryDefensivePlayers" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
      <ng-container *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isTeams">
        <mat-chip-list class="entity-chip-list" #teamEntitiesChipList>
          <ng-container *ngFor="let entity of displayedFilters.teamEntities">
            <mat-chip class="entity-chip" (removed)="removeTeamEntity(entity, selectedFilterOption?.selectedAction?.selectedSubAction)">
              <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              <div class="name">{{ entity.name }}</div>
              <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <input #teamEntitiesInput matInput [matChipInputFor]="teamEntitiesChipList" [matAutocomplete]="teamEntitiesAutocomplete" [formControl]="teamEntitiesControl">
        </mat-chip-list>
        <mat-autocomplete #teamEntitiesAutocomplete="matAutocomplete" (optionSelected)="addTeamEntity($event, selectedFilterOption?.selectedAction?.selectedSubAction)">
          <mat-option *ngFor="let entity of filteredTeamEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
      <ng-container *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isDefensiveTeam">
        <mat-chip-list class="entity-chip-list" #defensiveTeamEntitiesChipList>
          <ng-container *ngFor="let entity of displayedFilters.defensiveTeamEntities">
            <mat-chip class="entity-chip" (removed)="removeTeamEntity(entity, selectedFilterOption?.selectedAction?.selectedSubAction)">
              <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              <div class="name">{{ entity.name }}</div>
              <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <input #defensiveTeamEntitiesInput matInput [matChipInputFor]="defensiveTeamEntitiesChipList" [matAutocomplete]="defensiveTeamEntitiesAutocomplete" [formControl]="defensiveTeamEntitiesControl">
        </mat-chip-list>
        <mat-autocomplete #defensiveTeamEntitiesAutocomplete="matAutocomplete" (optionSelected)="addTeamEntity($event, selectedFilterOption?.selectedAction?.selectedSubAction)">
          <mat-option *ngFor="let entity of filteredDefensiveTeamEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
      <ng-container *ngIf="selectedFilterOption?.selectedAction?.selectedSubAction?.isOffensiveTeam">
        <mat-chip-list class="entity-chip-list" #offensiveTeamEntitiesChipList>
          <ng-container *ngFor="let entity of displayedFilters.offensiveTeamEntities">
            <mat-chip class="entity-chip" (removed)="removeTeamEntity(entity, selectedFilterOption?.selectedAction?.selectedSubAction)">
              <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              <div class="name">{{ entity.name }}</div>
              <mat-icon class="remove-chip" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <input #offensiveTeamEntitiesInput matInput [matChipInputFor]="offensiveTeamEntitiesChipList" [matAutocomplete]="offensiveTeamEntitiesAutocomplete" [formControl]="offensiveTeamEntitiesControl">
        </mat-chip-list>
        <mat-autocomplete #offensiveTeamEntitiesAutocomplete="matAutocomplete" (optionSelected)="addTeamEntity($event, selectedFilterOption?.selectedAction?.selectedSubAction)">
          <mat-option *ngFor="let entity of filteredOffensiveTeamEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
    </div>
  </div>
</div>
