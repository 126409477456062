import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {InjuryOccurrence} from '@models/physical/injuryOccurrences';

export const featureAdapter: EntityAdapter<InjuryOccurrence> = createEntityAdapter<InjuryOccurrence>({
  selectId: model => {
    return model.id || 0;
  },
  sortComparer: false
});

export interface State extends EntityState<InjuryOccurrence> {
  isLoading?: boolean;
  error?: any;
  date?: string;
}

export const initialState: State = featureAdapter.getInitialState(
  {
    isLoading: false,
    error: null,
    date: null,
  }
);
