import {Actions, ActionTypes} from './actions';
import {featureAdapter, initialState, State} from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SAVE_LINEUP: {
        return {
          ...state,
          isLoading: false,
          error: null
        };
      }
      case ActionTypes.SAVE_LINEUP_SUCCESS: {
        return featureAdapter.upsertOne(action.payload, {
          ...state,
          isLoading: false,
          error: null
        });
      }
      case ActionTypes.SAVE_LINEUP_FAILED: {
        return {
          ...state,
          isLoading: false,
          error: action.payload
        };
      }
      default: {
        return state;
      }
    }
}
