
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';

import {AuthService} from 'apps/_services/auth.service';
import * as moment from 'moment-timezone';
import * as Sentry from '@sentry/angular';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {}

  canActivate(): Observable<boolean> {
    if (this.authService.shouldRefresh && this.authService.canRefresh) {
      const s = this.authService.jwt().pipe(map(
        () => {
          return true;
        }), catchError(
        () => {
          localStorage.setItem('logoutMethod 1', new Date().toString());
          console.log('1: Logging out');
          this.authService.logout(true, false, true);
          return observableOf(false);
        }), );

      if (!this.authService.refreshingToken) {
        this.authService.refreshingToken = true;
        this.authService.refreshJwt();
        Sentry.setUser({email: this.authService.user.email});
      }

      return s;
    } else if (this.authService.loggedIn()) {
      const returnUrl = sessionStorage.getItem('returnUrl');
      Sentry.setUser({email: this.authService.user.email});

      if (returnUrl) {
        this.router.navigate([returnUrl]);
      }

      sessionStorage.removeItem('returnUrl');
      return observableOf(true);
    } else {
      const url = window.location.pathname;
      sessionStorage.setItem('returnUrl', url);
      this.router.navigate(['/sign-in'], { queryParams: { returnUrl: url }});
      return observableOf(false);
    }
  }
}
