/* Pulled from Covalent project and added MatAutocomplete functionality to match Bild needs */

import {debounceTime} from 'rxjs/operators';
import {
  Component, EventEmitter, Input, OnInit, Optional, Output, ViewEncapsulation, ViewChild,
  ChangeDetectionStrategy, ElementRef
} from '@angular/core';
import {Dir} from '@angular/cdk/bidi';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss' ],
  animations: [
    trigger('searchState', [
      state('hide-left', style({
        transform: 'translateX(-150%)',
        display: 'none',
      })),
      state('hide-right', style({
        transform: 'translateX(150%)',
        display: 'none',
      })),
      state('show',  style({
        transform: 'translateX(0%)',
        display: 'block',
      })),
      transition('* => show', animate('200ms ease-in')),
      transition('show => *', animate('200ms ease-out')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent implements OnInit {

  @ViewChild(MatInput, { static: true }) _input: MatInput;
  @ViewChild(MatAutocompleteTrigger, { static: true }) matAutocompleteTrigger: MatAutocompleteTrigger;

  @ViewChild('autocompleteSearch', { static: true }) autocompleteSearch: MatAutocomplete;

  value: string;
  clickedMouseEvent: MouseEvent = null;

  /**
   * showUnderline?: boolean
   * Sets if the input underline should be visible. Defaults to 'false'.
   */
  @Input('showUnderline') showUnderline: boolean = false;

  /**
   * debounce?: number
   * Debounce timeout between keypresses. Defaults to 500.
   */
  @Input('debounce') debounce: number = 500;

  /**
   * placeholder?: string
   * Placeholder for the underlying input component.
   */
  @Input('placeholder') placeholder: string;

  /**
   * clearIcon?: string
   * The icon used to clear the search input.
   * Defaults to 'cancel' icon.
   */
  @Input('clearIcon') clearIcon: string = 'cancel';

  @Input('searchResults') searchResults: any[];

  @Input('trackByKey') trackByKey: string;

  /**
   * searchDebounce: function($log)
   * Event emitted after the [debounce] timeout.
   */
  @Output('searchDebounce') onSearchDebounce: EventEmitter<any> = new EventEmitter<any>();

  /**
   * search: function($log)
   * Event emitted after the key enter has been pressed.
   */
  @Output('search') onSearch: EventEmitter<string> = new EventEmitter<string>();

  /**
   * clear: function()
   * Event emitted after the clear icon has been clicked.
   */
  @Output('clear') onClear: EventEmitter<void> = new EventEmitter<void>();

  /**
   * blur: function()
   * Event emitted after the blur log has been called in underlying input.
   */
  @Output('blur') onBlur: EventEmitter<void> = new EventEmitter<void>();

  @Output('optionSelected') onOptionSelected: EventEmitter<{event: MatAutocompleteSelectedEvent, mouseEvent: MouseEvent}> = new EventEmitter<{event: MatAutocompleteSelectedEvent, mouseEvent: MouseEvent}>();

  get isRTL(): boolean {
    if (this._dir) {
      return this._dir.dir === 'rtl';
    }
    return false;
  }

  constructor(
    @Optional() private _dir: Dir,
    public element: ElementRef,
  ) {}

  ngOnInit(): void {
    this._input.ngControl.valueChanges.pipe(
      debounceTime(this.debounce),
      untilDestroyed(this), )
      .subscribe((value: string) => {
        this._searchTermChanged(value);
      });
  }

  /**
   * Method to focus to underlying input.
   */
  focus(): void {
    this._input.focus();
  }

  handleBlur(): void {
    this.onBlur.emit(undefined);
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  handleSearch(event: Event): void {
    this.stopPropagation(event);
    this.onSearch.emit(this.value);
  }

  handleOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.clickedMouseEvent = null;
    setTimeout(() => {
      this.onOptionSelected.emit({event: event, mouseEvent: this.clickedMouseEvent});
      this.clickedMouseEvent = null;
    });
  }

  trackClick(event: MouseEvent) {
    this.clickedMouseEvent = event;
  }

  clearSearch(): void {
    this.value = '';
    this.onClear.emit(undefined);
  }

  searchResultsTrackBy(index, item): number {
    if (this.trackByKey) {
      return item[this.trackByKey];
    } else {
      return null;
    }
  }

  _searchTermChanged(value: string): void {
    this.onSearchDebounce.emit({value: value, autocomplete: this.autocompleteSearch});
  }
}
