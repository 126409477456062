import {IdName} from '../id-names/_id-names';
import {plainToClass} from 'class-transformer';

export const COMMUNICATION_PURPOSE_IDS = {
  GENERAL: 59,
  NEGOTIATION: 60
};

export class CommunicationPurpose extends IdName {}

export const COMMUNICATION_PURPOSES = {};
COMMUNICATION_PURPOSES[COMMUNICATION_PURPOSE_IDS.GENERAL] = plainToClass(CommunicationPurpose, {id: COMMUNICATION_PURPOSE_IDS.GENERAL, name: 'General'});
COMMUNICATION_PURPOSES[COMMUNICATION_PURPOSE_IDS.NEGOTIATION] = plainToClass(CommunicationPurpose, {id: COMMUNICATION_PURPOSE_IDS.NEGOTIATION, name: 'Negotiation'});
