
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {select, Store} from '@ngrx/store';

import {AuthService} from 'apps/_services/auth.service';
import {AutocompleteService} from 'apps/_services/autocomplete.service';
import {EntitiesService} from 'apps/_services/entities.service';
import {FocusGroupsService} from 'apps/_services/focus-groups.service';
import {MaterialHelper} from 'apps/_helpers/material.helper';
import {OnDestroy} from '@angular/core';
import {RootStoreState} from 'apps/_store';
import {SelectionModel} from '@angular/cdk/collections';
import {UserGroupStoreActions, UserGroupStoreSelectors} from '@store/user-group-store';
import {UserGroup} from '@models/user-groups';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bild-user-groups-select-dialog',
  templateUrl: './user-groups-select.dialog.component.html',
  styleUrls: ['./user-groups-select.dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserGroupsSelectDialogComponent implements OnInit, OnDestroy {

  displayedColumns = ['select', 'name', 'members'];
  title: string;
  selection: SelectionModel<any>;
  allUserGroups: UserGroup[];
  userGroups: UserGroup[];
  userGroupsDataSource: MatTableDataSource<UserGroup> = new MatTableDataSource<UserGroup>([]);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    protected autocompleteService: AutocompleteService,
    protected authService: AuthService,
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<UserGroupsSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected materialHelper: MaterialHelper,
    protected entitiesService: EntitiesService,
    protected focusGroupsService: FocusGroupsService,
    protected snackBar: MatSnackBar,
    protected store$: Store<RootStoreState.State>
  ) {
    this.userGroups = data.userGroups || [];
  }

  ngOnInit() {
    // Now that we have the data source setup, pre-select any as needed
    this.selection = new SelectionModel<any>(true);

    this.selection.changed.pipe(untilDestroyed(this)).subscribe(n => {
      this.cdr.detectChanges();
    });

    this.userGroupsDataSource.connect().pipe(untilDestroyed(this)).subscribe(n => {
      this.cdr.detectChanges();
    });

    this.store$.pipe(select(UserGroupStoreSelectors.selectUserGroups), untilDestroyed(this)).subscribe(userGroups => {
      this.allUserGroups = userGroups;

      this.userGroupsDataSource.data = userGroups;
      this.userGroupsDataSource.paginator = this.paginator;
      this.userGroupsDataSource.sort = this.sort;

      this.cdr.detectChanges();

      this.selection.select(...this.userGroups.map(userGroup => userGroup.id));

      this.cdr.detectChanges();
    });

    this.store$.dispatch(new UserGroupStoreActions.GetUserGroups());
  }

  get isAllSelected(): boolean {
    return this.selection.selected.length === this.userGroupsDataSource.data.length;
  }

  masterToggle() {
    this.isAllSelected ?
      this.selection.clear() :
      this.userGroupsDataSource.data.forEach(row => {
        this.selection.select(row.id);
      });
  }

  displayLastNames(members: any[]) {
    if (!members.length) {
      return 'No members';
    } else if (members.length === 1) {
      return members[0].name;
    } else {
      return `${members[0].name} +${members.length - 1} others`;
    }
  }

  getNamesListHTML(members: any[]) {
    return members.map(member => member.name).join('<br/>');
  }

  close() {
    const selectedUserGroups = this.allUserGroups
      .filter(userGroup => this.selection.selected.indexOf(userGroup.id) >= 0);
    this.dialogRef.close(selectedUserGroups);
  }

  ngOnDestroy() {
    // Catch the case where they don't click on the X
    this.close();
  }
}
