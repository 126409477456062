import {ActivatedRoute, Router} from '@angular/router';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter, Input,
} from '@angular/core';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {VideoService} from '@services/video.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SavedPlaylistDialogComponent} from '@bild-dialogs/saved-playlist/saved-playlist.component';
import {take, filter} from 'rxjs/operators';
import * as _ from 'lodash';
import {RootStoreState} from 'apps/_store';
import {VideoStoreActions, VideoStoreSelectors} from '@store/video-store';
import {Store, select} from '@ngrx/store';
import {VideoNavigationTypes, VideoExploreTypes, VideoTabTypes} from '@models/video';
import * as actions from '@store/video-store/actions';
import {Actions, ofType} from '@ngrx/effects';

enum PLAYLIST_SORTING {
  RECENT,
  ASC,
  DESC,
}

enum PLAYLIST_AUTHORS {
  ALL,
  MINE,
  SHARED,
}

@UntilDestroy()
@Component({
  selector: 'playlist-menu',
  templateUrl: './playlist-menu.component.html',
  styleUrls: ['./playlist-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
  })
export class PlaylistMenuComponent implements OnInit, OnDestroy {
  @ViewChild('playlistSearch') playlistSearch;
  @Input() isHotkeyMenu: boolean;
  @Input() isPostMenu: boolean;

  currentSorting: number = PLAYLIST_SORTING.RECENT;
  currentAuthor: number = PLAYLIST_AUTHORS.ALL;
  filteredPlaylists = [];
  isSearchActive = false;
  playlists = [];
  searchValue = '';
  selectedPlaylist;
  sessionMetadata: any = null;
  chainID: any = null;

  readonly playlistAuthors = PLAYLIST_AUTHORS;

  @Output() updateHotkey: EventEmitter<any> = new EventEmitter();
  @Output() updatePostMenuPlaylist: EventEmitter<any> = new EventEmitter();

  get sortingHeader() {
    switch (this.currentSorting) {
      case PLAYLIST_SORTING.RECENT:
        return 'Recent';
      case PLAYLIST_SORTING.ASC:
        return 'A -> Z';
      case PLAYLIST_SORTING.DESC:
        return 'Z -> A';
    }
  }

  get authorDisplay() {
    switch (this.currentAuthor) {
      case this.playlistAuthors.ALL:
        return 'All Authors';
      case this.playlistAuthors.MINE:
        return 'By you';
      case this.playlistAuthors.SHARED:
        return 'Shared';
    }
  }

  constructor(
    protected actions$: Actions,
    protected cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected router: Router,
    protected store$: Store<RootStoreState.State>,
    protected videoService: VideoService,
  ) {
  }

  ngOnInit() {
    this.store$.pipe(
      select(VideoStoreSelectors.selectSession),
      untilDestroyed(this)
    ).subscribe(session => {
      this.sessionMetadata = _.cloneDeep(session);
      // this.setupSelectedPlaylist();
      this.cdr.markForCheck();
    });

    this.store$.pipe(
      select(VideoStoreSelectors.selectPlaylists),
      filter(playlists => playlists?.isLoaded),
      untilDestroyed(this),
      ).subscribe(playlists => {
      if (playlists.playlists) {
        this.updatePlaylists(_.cloneDeep(playlists.playlists));
        // this.setupSelectedPlaylist();
        this.cdr.markForCheck();
      }
    });

    this.videoService.selectedPlaylistSubject.pipe(untilDestroyed(this)).subscribe((playlist) => {
      this.changeSelectedPlaylist(playlist, false, (playlist && !playlist?.id));
    });

    this.actions$.pipe(
      ofType<actions.SavePlaylist>(actions.ActionTypes.SAVE_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      console.log(payload);
      let existingPlaylistIndex = this.playlists.findIndex(playlist => playlist.id == payload.id);
      if (existingPlaylistIndex != -1) {
        this.playlists[existingPlaylistIndex] = {...this.playlists[existingPlaylistIndex], ...payload};
        this.updatePlaylists(_.cloneDeep(this.playlists));
      }
      else {
        this.playlists.unshift(payload);
        this.updatePlaylists(_.cloneDeep(this.playlists));
        if (this.chainID == chainID) {
          this.changeSelectedPlaylist(payload);
        }
      }
      this.cdr.markForCheck();
    });

    this.actions$.pipe(
      ofType<actions.DeletePlaylist>(actions.ActionTypes.DELETE_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      this.updatePlaylists(this.playlists.filter(playlist => playlist.id != payload.id));
    });
  }

  updatePlaylists(playlists) {
    this.playlists = playlists;
    this.filteredPlaylists = _.cloneDeep(playlists);
    this.currentSorting = PLAYLIST_SORTING.RECENT;
    this.cdr.markForCheck();
  }

  createNewPlaylist() {
    const dialogConfig: MatDialogConfig = {
      data: {name: ''},
    };
    const dialogRef = this.dialog.open(SavedPlaylistDialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe((newName) => {
      if (newName) {
        if (newName) {
          let newPlaylistData = {name: newName, sharedUsers: []};
          this.chainID = this.videoService.generateChainID();
          this.store$.dispatch(new actions.SaveAction({'playlistSaved': newPlaylistData, 'chainID': this.chainID}));
        }
      }
    });
  }

  filterPlaylists(authorValue?) {
    this.filterPlaylistsByName();
    this.filterAuthor(authorValue);
    this.cdr.markForCheck();
  }

  filterAuthor(filterValue?) {
    if (filterValue != null) {
      this.currentAuthor = filterValue;
    }

    let authorSharedValue;

    switch (this.currentAuthor) {
      case this.playlistAuthors.ALL:
        return this.filteredPlaylists;
      case this.playlistAuthors.SHARED:
        authorSharedValue = true;
        break;
      case this.playlistAuthors.MINE:
        authorSharedValue = false;
        break;
    }
    this.filteredPlaylists = _.filter(this.filteredPlaylists, (playlist) => {
      return playlist.isShared == authorSharedValue;
    });
  }

  filterPlaylistsByName() {
    if (this.searchValue === '') {
      this.filteredPlaylists = _.cloneDeep(this.playlists);
    } else {
      const searchValue = this.searchValue.toLowerCase();
      this.filteredPlaylists = _.filter(this.playlists, (playlist) => {
        return playlist.name.toLowerCase().includes(searchValue);
      });
    }
  }

  reorderPlaylists(ascending) {
    if (ascending != null) {
      this.filteredPlaylists = _.orderBy(this.filteredPlaylists, ['name'], ascending ? 'asc' : 'desc');
    } else {
      this.filteredPlaylists = _.cloneDeep(this.playlists);
    }
  }

  toggleSorting() {
    switch (this.currentSorting) {
      case PLAYLIST_SORTING.RECENT:
        this.currentSorting = PLAYLIST_SORTING.ASC;
        this.reorderPlaylists(true);
        break;
      case PLAYLIST_SORTING.ASC:
        this.currentSorting = PLAYLIST_SORTING.DESC;
        this.reorderPlaylists(false);
        break;
      case PLAYLIST_SORTING.DESC:
        this.currentSorting = PLAYLIST_SORTING.RECENT;
        this.reorderPlaylists(null);
        break;
    }
  }

  setSearchActive(val, event?) {
    if (val || event.relatedTarget?.classList?.contains('playlist-search')) {
      this.isSearchActive = true;
      setTimeout(() => {
        this.playlistSearch.nativeElement.focus();
        this.cdr.markForCheck();
      }, 20);
    } else if (this.searchValue == '') {
      this.isSearchActive = false;
    }
  }

  findActivePlaylist(playlist) {
    switch (playlist) {
      case this.sessionMetadata.playlist1ID:
        return VideoNavigationTypes.PLAYLIST_1;
      case this.sessionMetadata.playlist2ID:
        return VideoNavigationTypes.PLAYLIST_2;
      case this.sessionMetadata.playlist3ID:
        return VideoNavigationTypes.PLAYLIST_3;
      case this.sessionMetadata.playlist4ID:
        return VideoNavigationTypes.PLAYLIST_4;
      case this.sessionMetadata.playlist5ID:
        return VideoNavigationTypes.PLAYLIST_5;
      case this.sessionMetadata.playlist6ID:
        return VideoNavigationTypes.PLAYLIST_6;
    }
  }

  changeSelectedPlaylist(playlist, forwardUpdate = true, allSavedVideos = false) {
    if (playlist == null && allSavedVideos) {
      playlist = {id: -1, name: 'All Saved Videos'};
    }
    this.selectedPlaylist = playlist;
    if (this.isHotkeyMenu && forwardUpdate) {
      this.updateHotkey.emit(playlist);
    } else if (this.isPostMenu) {
      this.updatePostMenuPlaylist.emit(playlist);
    } else if (forwardUpdate) {
      this.videoService.selectedPlaylistSubject.next(playlist);
      this.videoService.selectedTabSubject.next(VideoTabTypes.BROWSE_VIDEO);
      if (playlist?.id && [this.sessionMetadata.playlist1ID, this.sessionMetadata.playlist2ID, this.sessionMetadata.playlist3ID,
        this.sessionMetadata.playlist4ID, this.sessionMetadata.playlist5ID, this.sessionMetadata.playlist6ID].includes(playlist.id)) {
        this.store$.dispatch(new actions.SaveAction({'navigationChange': this.findActivePlaylist(playlist.id)}));
      } else {
        this.store$.dispatch(new actions.SaveAction({'exploreTypeChange': VideoExploreTypes.PLAYLIST, 'chainID': this.videoService.generateChainID()}));
        this.store$.dispatch(new actions.SaveAction({'explorePlaylistChange': {id: this.selectedPlaylist?.id, 'chainID': this.videoService.generateChainID()}}));
        this.store$.dispatch(new actions.SaveAction({'navigationChange': VideoNavigationTypes.EXPLORE, 'chainID': this.videoService.generateChainID()}));
      }
    }
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
  }
}
