<div class="clip-upload" *ngIf="!uploadedVideo">
  <div class="upload-container">
    <input type="file" id="clip-input" accept="video/mp4" (change)="videoUpdated($event)" hidden>
    <label class="upload-label" for="clip-input">
      <div class="upload-label-content">
        <mat-icon>add_circle_outline</mat-icon>
        <p>Upload Clip (.mp4)</p>
      </div>
    </label>
  </div>
</div>

<div *ngIf="uploadedVideo" class="browse-video-container">
  <div class="clips-table-container">
    <div class="clip-detail">
      <video-player #videoPlayer
        [currentClip1]="uploadedVideo"
        [currentVideoIndex]="0"
        (onMetadataLoaded)="onVideoMetadataLoaded()"
      ></video-player>
    </div>
  </div>

  <div #savePanel class="save-panel">
    <div class="toolbar">
      <div class="header">Save Video</div>
      <button class="close-button" mat-mini-fab (click)="resetVideo()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="currentClip">
      <div class="label">Playlists</div>
      <mat-form-field>
        <mat-select multiple [(ngModel)]="currentClip.playlists" [compareWith]="compareIDs"
          [ngModelOptions]="{standalone: true}">
          <div class="new-playlist" (click)="createPlaylist()">
            <div class="add-playlist-container">
              <div><mat-icon class="add-playlist-button">add</mat-icon></div>
              <div>New Playlist</div>
            </div>
          </div>
          <ng-container *ngFor="let userPlaylist of userPlaylists">
            <mat-option *ngIf="canEditPlaylist(userPlaylist)" [value]="userPlaylist">
              {{ userPlaylist.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <div class="label">Name *</div>
      <mat-form-field>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20" [(ngModel)]="currentClip.name"
          [ngModelOptions]="{standalone: true}"></textarea>
      </mat-form-field>
      <div class="label">Commentary</div>
      <mat-form-field>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20"
          [(ngModel)]="currentClip.commentary" [ngModelOptions]="{standalone: true}"></textarea>
      </mat-form-field>
      <div class="label">Game Data *</div>
      <div class="game-data-container">
        <div class="game-data-input">
          <div class="label">Home Team</div>
          <div class="options-container">
            <mat-chip-list #homeTeamEntitiesChipList>
              <mat-chip class="entity-chip" (removed)="removeTeamEntity('home')" *ngIf="selectedHomeTeam">
                <entity-avatar class="avatar" [entity]="selectedHomeTeam" [size]="24"></entity-avatar>
                <div class="name">{{ selectedHomeTeam.name }}</div>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input #homeTeamEntitiesInput matInput [matChipInputFor]="homeTeamEntitiesChipList" [matAutocomplete]="homeTeamEntitiesAutocomplete" [formControl]="homeTeamEntitiesControl" [hidden]="selectedHomeTeam">
            </mat-chip-list>
            <mat-autocomplete #homeTeamEntitiesAutocomplete="matAutocomplete" (optionSelected)="addTeamEntity($event, 'home')">
              <mat-option *ngFor="let entity of filteredHomeTeamEntities" [value]="entity">
                <entity-context [entity]="entity"></entity-context>
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <div class="game-data-input">
          <div class="label">Away Team</div>
          <div class="options-container">
            <mat-chip-list #awayTeamEntitiesChipList>
              <mat-chip class="entity-chip" (removed)="removeTeamEntity('away')" *ngIf="selectedAwayTeam">
                <entity-avatar class="avatar" [entity]="selectedAwayTeam" [size]="24"></entity-avatar>
                <div class="name">{{ selectedAwayTeam.name }}</div>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input #awayTeamEntitiesInput matInput [matChipInputFor]="awayTeamEntitiesChipList" [matAutocomplete]="awayTeamEntitiesAutocomplete" [formControl]="awayTeamEntitiesControl" [hidden]="selectedAwayTeam">
            </mat-chip-list>
            <mat-autocomplete #awayTeamEntitiesAutocomplete="matAutocomplete" (optionSelected)="addTeamEntity($event, 'away')">
              <mat-option *ngFor="let entity of filteredAwayTeamEntities" [value]="entity">
                <entity-context [entity]="entity"></entity-context>
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <div class="game-data-input">
          <div class="label">Date</div>
          <mat-form-field>
            <input matInput [matDatepicker]="date" (dateChange)="changeDate($event)" [formControl]="dateControl" [value]="dateControl.value">
            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
            <mat-datepicker #date></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="label">Players ({{ currentClip.additionalTaggedEntities.length }} selected)</div>
      <div class="options-container">
        <mat-chip-list #entitiesChipList>
          <ng-container *ngFor="let entity of currentClip.additionalTaggedEntities">
            <mat-chip class="entity-chip" (removed)="removeTaggedEntity(entity)">
              <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              {{ entity.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <input #entitiesInput matInput placeholder="Add Players" [matChipInputFor]="entitiesChipList" [matAutocomplete]="entitiesAutocomplete" [formControl]="entitiesControl">
        </mat-chip-list>
        <mat-autocomplete #entitiesAutocomplete="matAutocomplete" (optionSelected)="addTaggedEntity($event)">
          <mat-option *ngFor="let entity of filteredEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="label">Notes (Private)</div>
      <mat-form-field>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20"
          [(ngModel)]="currentClip.notes" [ngModelOptions]="{standalone: true}"></textarea>
      </mat-form-field>
      <div class="label">Label (Private)</div>
      <mat-form-field>
        <mat-select disableRipple [(value)]="currentClip.label">
          <mat-option></mat-option>
          <mat-option class="save-panel__color-option" value="Blue"><div class="color-indicator" style="background-color: #9acce3"></div> Blue</mat-option>
          <mat-option class="save-panel__color-option" value="Green"><div class="color-indicator" style="background-color: #13c16d"></div> Green</mat-option>
          <mat-option class="save-panel__color-option" value="Red"><div class="color-indicator" style="background-color: #e27061"></div> Red</mat-option>
          <mat-option class="save-panel__color-option" value="Yellow"><div class="color-indicator" style="background-color: #ecea17"></div> Yellow</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="options-toolbar">
        <button class="save-button" mat-flat-button color="accent" (click)="confirmSave()"
          [class.disabled]="savingInProgress">{{ savingInProgress ? (currentClip?.id ? 'Updating...' : 'Saving...') :
          (currentClip?.id ? 'Update' : 'Save') }}</button>
      </div>
    </ng-container>
  </div>
</div>
