import {Action} from '@ngrx/store';

import {Article} from '@models/articles';

export enum ActionTypes {
  LIST = '[News Article] List',
  LIST_SUCCESS = '[News Article] List Success',
  LIST_FAILED = '[News Article] List Failed'
}

export class GetNewsArticles implements Action {
  public readonly type = ActionTypes.LIST;
  constructor(public payload: {aboutID?: number, league: number, maxDays: number, page: string, pageID: string, limit: number, injuryOccurrences?: number[]}) {}
}

export class GetNewsArticlesSuccess implements Action {
  public readonly type = ActionTypes.LIST_SUCCESS;
  constructor(public payload: {aboutID: number, leagueID: number, prevPageDateToken: string, nextPageDateToken: string, prevPageIDToken: string, nextPageIDToken: string, results: Article[], filteredIds: number[]}) {}
}

export class GetNewsArticlesFailed implements Action {
  public readonly type = ActionTypes.LIST_FAILED;
  constructor(public payload: any) {}
}

export type Actions = GetNewsArticles | GetNewsArticlesSuccess | GetNewsArticlesFailed;
