import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {FocusGroup} from '@models/focus-groups';

export const featureAdapter: EntityAdapter<FocusGroup> = createEntityAdapter<FocusGroup>({
  selectId: model => {
    return model.id || 0;
  },
  sortComparer: (a: any, b: any): number => {
    return Date.parse(b.editDatetime) - Date.parse(a.editDatetime);
  }
});

export interface State extends EntityState<FocusGroup> {
  isLoading?: boolean;
  isHistorical?: boolean;
  isTalentPipeline?: boolean;
  channelName: string;
  myEvaluationChannelName: string;
  myEvaluation: any;
  activeUsers: any[];
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState(
  {
    isLoading: false,
    isHistorical: false,
    isTalentPipeline: false,
    channelName: null,
    myEvaluationChannelName: null,
    myEvaluation: null,
    activeUsers: [],
    error: null,
  }
);
