import {EntityType} from '@models/constants/entity-types';
import {Expose, Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Entity} from './entities';
import {Team} from 'apps/_models/teams';
import * as moment from 'moment-timezone';
import {forwardRefMoment} from '@helpers/class-transformer.helper';


export class PersonType {
  name: string;
  id: number;
}

// TODO Add types
export class Address {
  street1 = '';
  street2 = '';
  city = '';
  state = '';
  country = '';
  postalCode = '';
  addressType = '';
}

export class PhoneNumber {
  phoneType = '';
  phoneNumber = '';
  country = 'us';
}

export class EmailAddress {
  emailAddress = '';
  emailType = '';
}

export class Relationship {
  name = '';
  relationshipType = '';
  relationshipDate = '';
  emergencyContact = false;
  phoneNumber = '';
}
export class Position {
  id: number;
  name: string;
}

export enum ImageType {
  HEADSHOT = 'headshot'
}

export interface IPerson {
  firstNameInitial: string;
  id: number;
  imageType: ImageType;
  imageUrl: string;
  lastName: string;
  name: string;
  nameDisplay: string;
  type: EntityType;
  userID: null;
  position: string;
}

export class Person extends Entity {
  @Type(forwardRef(() => PersonType) as any)
  primaryPersonType: PersonType;
  additionalPersonTypes: any[] = [];
  imageType: string;
  lastMod: number;
  @Type(forwardRef(() => Team) as any)
  primaryAffiliation: Team;
  @Type(forwardRef(() => Team) as any)
  officialAffiliation: Team;
  @Type(forwardRef(() => Entity) as any)
  additionalAffiliations: Entity[] = [];
  nbaIDs: any[] = [];
  sportradarIDs: any[] = [];
  draftExpressIDs: any[] = [];
  rotowireIDs: any[] = [];
  realGMIDs: any[] = [];
  catapultIDs: any[] = [];
  noahIDs: any[] = [];
  synergyIDs: any[] = [];
  nameDisplay: string;
  firstNameOfficial: string;
  firstNameInitial: string;
  middleNameOfficial: string;
  lastNameOfficial: string;
  prefixNameOfficial: string;
  suffixNameOfficial: string;
  firstNameDisplay: string;
  lastNameDisplay: string;
  lastName: string;
  suffixNameDisplay: string;
  formerNames: any[] = [];
  nicknames: any[] = [];
  nationality: string;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  birthdate: moment.Moment;
  birthYear: number;
  birthCity: string;
  birthState: string;
  birthCountry: string;
  jobTitle: string;
  company: string;
  gender: string;
  height: number;
  weight: number;
  isLeftHanded: boolean;
  isInTalentPipeline: boolean;
  draftYear: number;
  draftPick: number;
  draftRound: number;
  expectedDraftYear: number;
  isDraftedEarly: boolean;
  position1: number;
  position2: number;
  @Type(forwardRef(() => Position) as any)
  positionGroup: Position;
  @Type(forwardRef(() => Address) as any)
  addresses: Address[] = [];
  @Type(forwardRef(() => EmailAddress) as any)
  emailAddresses: EmailAddress[] = [];
  @Type(forwardRef(() => PhoneNumber) as any)
  phoneNumbers: PhoneNumber[] = [];
  relationships: any[] = [];
  isSensitiveInformationPrivate: boolean;
  allowedToSeeSensitiveInformation: boolean;
  isActiveUser: boolean;
  summary: string;
  deceasedDate: moment.Moment;
  talentFunctions: any[] = [];
  talentHirePotential: string;
  talentHireInterest: string;
  talentHireRole: string;
  talentEvaluationResults: string;
  educationCredentials: string;
  graduationYear: string;
  talentSkills: string;
  linkedinURL: string;
  hasContent: boolean;
  resumePath: string;

  @Expose({toClassOnly: true})
  get firstInitialLastName(): string {
    return `${this.firstNameInitial}. ${this.lastName}`;
  }

  @Expose({toClassOnly: true})
  get heightFeet(): number {
    return Math.floor(this.height / 12);
  }

  @Expose({toClassOnly: true})
  get heightInches(): number {
    return this.height % 12;
  }

  hasSubtype(subtype: number): boolean {
    return Boolean(
      this.primaryPersonType.id === subtype ||
      this.additionalPersonTypes.map((pt) => pt.id).includes(subtype)
    );
  }
}

export class DraftPerson extends Person {
  @Type(forwardRef(() => Team) as any)
  latestTeam: Team;
  latestTeamName: string;
  latestSeason: number;

  // Unsure why, but without duplicating Person getters here, plainToClass(obj, DraftPerson) fails
  @Expose({toClassOnly: true})
  get firstInitialLastName(): string {
    return `${this.firstNameInitial}. ${this.lastName}`;
  }

  @Expose({toClassOnly: true})
  get heightFeet(): number {
    return Math.floor(this.height / 12);
  }

  @Expose({toClassOnly: true})
  get heightInches(): number {
    return this.height % 12;
  }
}
