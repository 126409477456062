import {createEffect, ofType, Actions} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {of, Observable} from 'rxjs';
import {plainToClass} from 'class-transformer';
import {switchMap, map, catchError, mergeMap, concatMap} from 'rxjs/operators';

import * as actions from './actions';

import {UserGroupsService} from '@services/user-groups.service';
import {UserGroup} from '@models/user-groups';

@Injectable()
export class Effects {

  getUserGroup$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GetUserGroup>(actions.ActionTypes.GET),
    map(action => action.payload),
    switchMap(payload => this.userGroupsService.getUserGroup(payload)),
    switchMap(userGroup => of(plainToClass(UserGroup, userGroup))),
    switchMap((userGroup: any) => of(new actions.GetUserGroupSuccess(userGroup)))
  ));

  getUserGroups$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GetUserGroups>(actions.ActionTypes.LIST),
    switchMap(() => this.userGroupsService.getUserGroups()),
    switchMap(userGroups => of(plainToClass(UserGroup, userGroups))),
    switchMap((userGroups: any[]) => of(new actions.GetUserGroupsSuccess(userGroups))),
    catchError(error => {
      return of({
        type: actions.ActionTypes.LIST
      });
    })
  ));

  saveUserGroup$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SaveUserGroup>(actions.ActionTypes.SAVE),
    map(action => action.payload),
    concatMap(payload => this.userGroupsService.saveUserGroup(payload)),
    mergeMap(userGroup => {
      userGroup = plainToClass(UserGroup, userGroup);
      return of(new actions.SaveUserGroupSuccess(userGroup));
    }),
    catchError(error => {
      return of({
        type: actions.ActionTypes.SAVE_FAILED,
        payload: error
      });
    })
  ));

  deleteUserGroup$ = createEffect(() => this.actions$.pipe(
    ofType<actions.DeleteUserGroup>(actions.ActionTypes.DELETE),
    map(action => action.payload),
    mergeMap(payload => this.userGroupsService.deleteUserGroup(payload)),
    mergeMap(() => of(new actions.DeleteUserGroupSuccess())),
    catchError(error => {
      return of({
        type: actions.ActionTypes.DELETE_FAILED,
        payload: error
      });
    })
  ));

  constructor(private userGroupsService: UserGroupsService, private actions$: Actions) {}
}
