import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {BaseService} from './base.service';
import {EntitiesService} from './entities.service';
import {classToPlain} from 'class-transformer';
import {UserGroup} from '@models/user-groups';
import {BroadcastChannelService} from '@services/broadcast-channel.service';

@Injectable({
  providedIn: 'root',
})
export class UserGroupsService extends BaseService {

  constructor(protected http: HttpClient, protected broadcastChannelService: BroadcastChannelService, protected entitiesService: EntitiesService) {
    super(http, broadcastChannelService);
  }

  getUserGroups(): Observable<any[]> {
    const endpoint = `${this.baseUrl}/userGroups`;

    return this.get(endpoint);
  }

  getUserGroup(id: number): Observable<any> {
    const endpoint = `${this.baseUrl}/userGroups/${id}`;

    return this.get(endpoint);
  }

  saveUserGroup(userGroup: UserGroup): Observable<any> {
    let endpoint: string;
    let method: any;

    if (userGroup.id) {
      endpoint = `${this.baseUrl}/userGroups/${userGroup.id}`;
      method = this.put.bind(this);
    } else {
      endpoint = `${this.baseUrl}/userGroups`;
      method = this.post.bind(this);
    }

    // TODO: Should we move all plain-to-class transforms to store layer?
    const payload = classToPlain(userGroup);
    return method(endpoint, payload);
  }

  deleteUserGroup(userGroup: UserGroup): Observable<any> {
    const endpoint = `${this.baseUrl}/userGroups/${userGroup.id}`;

    return this.delete(endpoint);
  }
}
