import {Injectable} from '@angular/core';

import {EntitySubtype, ENTITY_SUBTYPES} from '@models/constants/entity-subtypes';
import {IdNamesService} from '@services/id-names/id-names.service';

@Injectable()
export class EntitySubtypesService extends IdNamesService {

  constructor() {
    super(EntitySubtype, ENTITY_SUBTYPES);

    this.getList();
  }
}
