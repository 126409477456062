import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {featureAdapter, State} from './state';
import {InjuryOccurrence} from '@models/physical/injuryOccurrences';
import {plainToClass} from 'class-transformer';
import * as _ from 'lodash';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const selectInjuryOccurrenceState: MemoizedSelector<object, State> = createFeatureSelector<State>('injuryOccurrence');
export const selectAllInjuryOccurrences: (state: object) =>
  InjuryOccurrence[] = featureAdapter.getSelectors(selectInjuryOccurrenceState).selectAll;

export const selectInjuryOccurrences = createSelector(
  selectAllInjuryOccurrences,
  (injuries: InjuryOccurrence[]) => {
    const returnedInjuryOccurrences = injuries;
    return plainToClass(InjuryOccurrence, returnedInjuryOccurrences);
  }
);

export const selectInjuryOccurrencesError: MemoizedSelector<object, any> = createSelector(selectInjuryOccurrenceState, getError);
