import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {take} from 'rxjs/operators';

import {AuthService} from 'apps/_services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-adfs-callback',
  templateUrl: './adfs-callback.component.html',
  styleUrls: ['./adfs-callback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ADFSCallbackComponent implements OnInit, OnDestroy {
  code: string;
  state: string;
  error: string;

  constructor(private route: ActivatedRoute, protected router: Router, protected authService: AuthService) { }

  ngOnInit() {
    this.route.queryParams.pipe(take(1), untilDestroyed(this)).subscribe(params => {
      this.code = params.code;
      this.state = params.state;

      this.authService.login(undefined, undefined, this.code).pipe(take(1), untilDestroyed(this)).subscribe(
        result => {
          this.router.navigate(['/bild']);
        }
      );
    });
  }

  ngOnDestroy() {
  }
}
