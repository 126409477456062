import {plainToClass} from 'class-transformer';

import {IdName} from '../id-names/_id-names';

export const EVALUATION_SETTING_DETAIL_EVENT_IDS = {
  DRAFT_COMBINE: 80,
  PORTSMOUTH: 81,
  AGENT_WORKOUT: 82,
  GROUP_WORKOUT: 84,
  OKC_VISIT: 83,
  G_LEAGUE_ELITTE_MINI_CAMP: 85
};

export class EvaluationSettingDetailEvent extends IdName {}

export const EVALUATION_SETTING_DETAIL_EVENTS = {};
EVALUATION_SETTING_DETAIL_EVENTS[EVALUATION_SETTING_DETAIL_EVENT_IDS.DRAFT_COMBINE] = plainToClass(EvaluationSettingDetailEvent, {id: EVALUATION_SETTING_DETAIL_EVENT_IDS.DRAFT_COMBINE, name: 'Draft Combine'});
EVALUATION_SETTING_DETAIL_EVENTS[EVALUATION_SETTING_DETAIL_EVENT_IDS.PORTSMOUTH] = plainToClass(EvaluationSettingDetailEvent, {id: EVALUATION_SETTING_DETAIL_EVENT_IDS.PORTSMOUTH, name: 'Portsmouth'});
EVALUATION_SETTING_DETAIL_EVENTS[EVALUATION_SETTING_DETAIL_EVENT_IDS.AGENT_WORKOUT] = plainToClass(EvaluationSettingDetailEvent, {id: EVALUATION_SETTING_DETAIL_EVENT_IDS.AGENT_WORKOUT, name: 'Agent Workout'});
EVALUATION_SETTING_DETAIL_EVENTS[EVALUATION_SETTING_DETAIL_EVENT_IDS.GROUP_WORKOUT] = plainToClass(EvaluationSettingDetailEvent, {id: EVALUATION_SETTING_DETAIL_EVENT_IDS.GROUP_WORKOUT, name: 'Group Workout'});
EVALUATION_SETTING_DETAIL_EVENTS[EVALUATION_SETTING_DETAIL_EVENT_IDS.OKC_VISIT] = plainToClass(EvaluationSettingDetailEvent, {id: EVALUATION_SETTING_DETAIL_EVENT_IDS.OKC_VISIT, name: 'OKC Visit'});
EVALUATION_SETTING_DETAIL_EVENTS[EVALUATION_SETTING_DETAIL_EVENT_IDS.G_LEAGUE_ELITTE_MINI_CAMP] = plainToClass(EvaluationSettingDetailEvent, {id: EVALUATION_SETTING_DETAIL_EVENT_IDS.OKC_VISIT, name: 'G League Elite Mini-Camp'});
