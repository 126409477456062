import {NavOption} from '@models/nav-option';
import {LEAGUE_IDS} from '@models/constants/leagues';
import {VideoTabTypes} from '@models/video';

export class VideoNavOption extends NavOption {
  name = VideoTabTypes.BROWSE_VIDEO;
  shouldHide = false;
  activeRootPath = ['', 'bild', 'video', 'browse-video'];
  link = ['', 'bild', 'video', 'browse-video'];
  isVideoTab = true;
}

export class GenerateNavOption extends NavOption {
  name = VideoTabTypes.GENERATE_PLAYLIST;
  shouldHide = false;
  activeRootPath = ['', 'bild', 'video', 'generate'];
  link = ['', 'bild', 'video', 'generate'];
  isVideoTab = true;
}

export class LiveNavOption extends NavOption {
  name = VideoTabTypes.COURT_CAMS;
  shouldHide = false;
  activeRootPath = ['', 'bild', 'video', 'court-cams'];
  link = ['', 'bild', 'video', 'court-cams'];
  isVideoTab = true;
}

export class UploadNavOption extends NavOption {
  name = VideoTabTypes.UPLOAD;
  shouldHide = false;
  activeRootPath = ['', 'bild', 'video', 'clip-upload'];
  link = ['', 'bild', 'video', 'clip-upload'];
  isVideoTab = true;
}

export class DiagnosticsNavOption extends NavOption {
  name = VideoTabTypes.VIDEO_DIAGNOSTICS;
  shouldHide = false;
  activeRootPath = ['', 'bild', 'video', 'video-diagnostics'];
  link = ['', 'bild', 'video', 'video-diagnostics'];
  isVideoTab = true;
}
