import {Injectable} from '@angular/core';

import {BILD_USER_TYPES, BildUserType} from '@models/constants/bild-user-types';
import {IdNamesService} from '@services/id-names/id-names.service';

@Injectable()
export class BildUserTypesService extends IdNamesService {

  constructor() {
    super(BildUserType, BILD_USER_TYPES);

    this.getList();
  }
}
