import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';

export class Message {
  public name: string;
  public properties: any;

  constructor(args) {
    const {name, properties} = args;
    Object.assign(this, args);
  }
}

@Injectable()
export class PostComposerService {
  // Observable postComposer source
  protected _postComposerSource = new ReplaySubject<Message>();

  // Observable postComposer stream
  postComposer = this._postComposerSource.asObservable();

  openNewPostComposer(state= 'default', post= null, isDraft= false) {
    this._postComposerSource.next(new Message({
      name: 'openNewPostComposer',
      properties: {state: state, post: post, isDraft: isDraft}
    }));
  }

  maximizePostComposer(postComposer, isDraft) {
    if (!postComposer.isModal) {
      this.closePostComposer(postComposer);
      this.openNewPostComposer('maximized', postComposer.post, isDraft);
    } else {
      postComposer.state = 'maximized';
    }
  }

  minimizePostComposer(postComposer, isDraft) {
    if (postComposer.isModal) {
      this.closePostComposer(postComposer);
      this.openNewPostComposer('minimized', postComposer.post, isDraft);
    } else {
      postComposer.state = 'minimized';
    }
  }

  restorePostComposer(postComposer, isDraft) {
    if (postComposer.isModal) {
      this.closePostComposer(postComposer);
      this.openNewPostComposer('default', postComposer.post, isDraft);
    } else {
      postComposer.state = 'default';
    }
  }

  closePostComposer(postComposer) {
    this._postComposerSource.next(new Message({name: 'closePostComposer', properties: {postComposer: postComposer}}));
  }
}
