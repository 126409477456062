import {Entity} from '@models/entities';
import {Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

export class UserGroup {
  id: number;
  name: string;
  @Type(forwardRef(() => Entity) as any)
  members: Entity[];
  isEditor: boolean;
  isEvaluator: boolean;
  isMainViewer: boolean;

  // Client-side
  isDeleted: boolean = false;
}
