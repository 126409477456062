import * as moment from 'moment-timezone';
import {Expose, Transform, Type, Exclude} from 'class-transformer';
import {forwardRef} from '@angular/core';
import {forwardRefMoment} from '@helpers/class-transformer.helper';

export enum PostsSummaryTypes {
    ALL = 'All',
    L6M = 'Last 6 Months',
    L1Y = 'Last 1 Year',
    L2Y = 'Last 2 Years'
}

export class PostsSummary {
    entityId: number;
    count: number;
    countL6M: number;
    countL1Y: number;
    countL2Y: number;
    @Type(forwardRef(() => moment) as any)
    @Transform(forwardRefMoment(moment.ISO_8601))
    lastPostDatetime: moment.Moment;
}
