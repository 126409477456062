import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {featureAdapter, State} from './state';
import {Affiliation} from '@models/affiliations';
import {TYPE_IDS, PERSON_LEAGUE_TYPE_IDS, PERSON_TEAM_TYPE_IDS} from '@models/constants/affiliations';
import {plainToClass} from 'class-transformer';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const selectAffiliationState: MemoizedSelector<object, State> = createFeatureSelector<State>('affiliation');
export const selectAllAffiliations: (state: object) => Affiliation[] = featureAdapter.getSelectors(selectAffiliationState).selectAll;

export const selectAffiliationsForEntity = (entityID: number) => {
  return createSelector(
    selectAllAffiliations,
    (affiliations: Affiliation[]) => {
      const returnedAffiliations = affiliations.filter(a => a.entity.id == entityID);
      return plainToClass(Affiliation, returnedAffiliations);
    }
  );
};

export const selectAgencyAffiliationsForEntity = (entityID: number) => {
  return createSelector(
    selectAllAffiliations,
    (affiliations: Affiliation[]) => {
      const returnedAffiliations = affiliations.filter(a => a.entity.id == entityID && a.affiliationType.id === TYPE_IDS.PERSON_AGENCY);
      return plainToClass(Affiliation, returnedAffiliations);
    }
  );
}

export const selectTeamAffiliationsForEntity = (entityID: number) => {
  return createSelector(
    selectAllAffiliations,
    (affiliations: Affiliation[]) => {
      const returnedAffiliations = affiliations.filter(a => a.entity.id == entityID && PERSON_TEAM_TYPE_IDS.concat(PERSON_LEAGUE_TYPE_IDS).includes(a.affiliationType.id));
      return plainToClass(Affiliation, returnedAffiliations);
    }
  );
}

export const selectAffiliationsError: MemoizedSelector<object, any> = createSelector(selectAffiliationState, getError);
export const selectAffiliationsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectAffiliationState, getIsLoading);
