import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';

import {Article} from '@models/articles';
import {adapter, State} from './state';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getNextPageToken = (state: State): any => state.nextPageDateToken;
export const getPrevPageToken = (state: State): any => state.prevPageDateToken;
export const getNextPageIDToken = (state: State): any => state.nextPageIDToken;
export const getPrevPageIDToken = (state: State): any => state.prevPageIDToken;
export const getFilteredIds = (state: State): number[] => state.filteredIds;

export const selectTransactionArticleState: MemoizedSelector<object, State> = createFeatureSelector<State>('transaction-article');

export const selectAllTransactionArticles: (state: object) => Article[] = adapter.getSelectors(selectTransactionArticleState).selectAll;

export const selectTransactionArticleFilteredIds: MemoizedSelector<object, number[]> = createSelector(selectTransactionArticleState, getFilteredIds);

export const selectFilteredTransactionArticles = createSelector(
  selectAllTransactionArticles,
  selectTransactionArticleFilteredIds,
  (articles: Article[], ids: number[]) => {
    return articles.filter(article => ids.indexOf(article.postID) >= 0);
  }
);

export const selectTransactionArticleNextPage: MemoizedSelector<object, any> = createSelector(selectTransactionArticleState, getNextPageToken);
export const selectTransactionArticlePrevPage: MemoizedSelector<object, any> = createSelector(selectTransactionArticleState, getPrevPageToken);
export const selectTransactionArticleNextPageID: MemoizedSelector<object, any> = createSelector(selectTransactionArticleState, getNextPageIDToken);
export const selectTransactionArticlePrevPageID: MemoizedSelector<object, any> = createSelector(selectTransactionArticleState, getPrevPageIDToken);
