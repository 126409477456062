import {Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {BILD_USER_TYPES, BildUserType} from './constants/bild-user-types';
import {Person} from '@models/people';
import {forwardRefConstant} from '@helpers/class-transformer.helper';

export class User {
  id: number;
  firstName: string;
  lastName: string;
  isActive: boolean;
  email: string;
  @Type(forwardRef(() => Person) as any)
  entity: Person;
  @Transform(forwardRefConstant(BILD_USER_TYPES), { toClassOnly: true })
  bildUserType: BildUserType;
  isADFSOnly: boolean;
  talentPortalDisplay: boolean;
}


export class UserForAuth {
  isADFSOnly: boolean;
}
