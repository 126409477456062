<div class="columns two" cdkScrollable>
  <div class="left-shoulder">
    <video-menu></video-menu>
  </div>
  <div class="middle browse-animation-container" [@animateBrowse]="showPanel">
    <bild-browse-video *ngIf="currentTab == tab.BROWSE_VIDEO || currentTab == tab.GENERATE_PLAYLIST"
                       (onCloseDialog)="closeDialog(true)"></bild-browse-video>
    <bild-court-cams *ngIf="currentTab == tab.COURT_CAMS"></bild-court-cams>
    <bild-clip-upload *ngIf="currentTab == tab.UPLOAD"></bild-clip-upload>
    <bild-video-diagnostics *ngIf="currentTab == tab.VIDEO_DIAGNOSTICS"></bild-video-diagnostics>
  </div>
</div>
