
import {finalize} from 'rxjs/operators';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {parseURL, URL} from 'universal-parse-url';
import {LoadingBarService} from '@ngx-loading-bar/core';

@Injectable()
export class NetworkActivityInterceptor implements HttpInterceptor {

  protected pending = new Subject();
  protected pendingRequests: number = 0;

  public constructor(protected loadingBarService: LoadingBarService) {
    // this.loadingBarService.interval = 250;
    // this.pending.subscribe((progress: any) => {
    //   if (progress.started) {
    //     setTimeout(_ => {
    //       this.loadingBarService.progress = 5;
    //       this.loadingBarService.start();
    //     });
    //   }
    //   if (progress.completed) {
    //     setTimeout(_ => this.loadingBarService.complete());
    //   }
    // });
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url: URL = parseURL(req.url);
    // Strip off any trailing numeric in path as those are unique IDs, filtering won't be that specific
    const urlPathname = url.pathname.replace(/\/\d+\/?$/, '');

    // if (!urlPathname.startsWith('/api/') || EXCLUDED_REQUEST_PATHNAMES.indexOf(urlPathname) >= 0) {
    //   return next.handle(req);
    // } else {
    //   this.requestStarted();
      return next.handle(req).pipe(finalize(
        () => this.requestEnded()
      ));
    // }
  }

  protected requestStarted() {
    this.pending.next({
      started: this.pendingRequests === 0,
      pendingRequests: ++this.pendingRequests,
    });
  }

  protected requestEnded() {
    this.pending.next({
      completed: this.pendingRequests === 1,
      pendingRequests: --this.pendingRequests,
    });
  }
}
