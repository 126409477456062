import * as moment from 'moment-timezone';

import {Attachment} from './attachments';
import {Entity} from './entities';
import {Exclude, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';
import {AuthService} from '@services/auth.service';

export class Comment {
  commentID: number;
  revisionID: string;
  postID: number;
  @Type(forwardRef(() => Entity) as any)
  author: Entity;
  @Type(forwardRef(() => Date) as any)
  createDatetime: Date;
  depth: number;
  @Type(forwardRef(() => Entity) as any)
  editor: Entity;
  isActive: boolean;
  isPosted: boolean;
  isDeleted: boolean;
  message: string;
  messageSnippet: string;
  numReplies: number;
  @Type(forwardRef(() => Comment) as any)
  replies: Comment[];
  @Type(forwardRef(() => Date) as any)
  postDatetime: Date;
  @Type(forwardRef(() => Attachment) as any)
  attachments: Attachment[];

  imgSrcSet(size= 36) {
    const radius = String(size / 2);
    return `${this.author.optimizedImageUrl}&h=${size}&w=${size}&corner-radius=${radius}&dpr=1 1x,
      ${this.author.optimizedImageUrl}&h=${size}&w=${size}&corner-radius=${radius}&dpr=2 2x,
      ${this.author.optimizedImageUrl}&h=${size}&w=${size}&corner-radius=${radius}&dpr=3 3x,`;
  }

  get createDatetimeFormatted() {
    return this.formatDatetime('createDatetime');
  }

  get postDatetimeFormatted() {
    return this.formatDatetime('postDatetime');
  }

  // TODO Would probably be better if we just had the API return the
  // permissions already setup on the object for the currently signed in user
  editPermissions(authService: AuthService): string [] {
    return authService.getPermissionsForAttribute('Posts', this, 'Edit');
  }
  editDeletePermissions(authService: AuthService): string [] {
    return authService.getPermissionsForAttribute('Posts', this, 'EditDelete');
  }
  deletePermissions(authService: AuthService): string[] {
    return authService.getPermissionsForAttribute('Posts', this, 'Delete');
  }

  @Exclude()
  protected formatDatetime(prop: string): string {
    const now = moment();
    const dt = moment(this[prop]);
    let formattedDatetime = '';

    switch (true) {
      case now.isSame(dt, 'day'):
        formattedDatetime = dt.format('h:mm A');
        break;
      case now.isSame(dt, 'year'):
        formattedDatetime = dt.format('MMM D');
        break;
      default:
        formattedDatetime = dt.format('MMM D, YYYY');
    }

    return formattedDatetime;
  }
}
