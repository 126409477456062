<mat-card [class.passwordStep]="stepper.selectedIndex == 1">
  <div class="header">
    <img class="bild-logo" src="assets/images/bild-loading.v1.svg" [height]="220"/>
    <h1>One Account.<br/>All of bild.</h1>
  </div>
  <ng-container *ngIf="stepper.selectedIndex === 1">
    <div class="user">
      <div class="user-chip mat-elevation-z1" (click)="startOver()">
        <ng-container *ngIf="stepper.selectedIndex === 1 && !currentUser">
          <mat-icon class="user-avatar">account_circle</mat-icon>
        </ng-container>
        <ng-container *ngIf="stepper.selectedIndex === 1 && currentUser && currentUser.imageUrl">
          <img class="user-avatar" mat-card-avatar [src]= "currentUser.imageUrl" />
        </ng-container>
        <div class="email">{{ emailFormControl.value }}</div>
        <mat-icon class="expand-more">expand_more</mat-icon>
      </div>
    </div>
  </ng-container>
  <form [formGroup]="signInForm" #form="ngForm" (ngSubmit)="signIn()">
    <mat-horizontal-stepper #stepper linear>
      <mat-step [stepControl]="signInForm.controls.email">
        <div class="step-email">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input #emailControl matInput placeholder="Email" formControlName="email" (keyup.enter)="goToStep2()">
          </mat-form-field>
          <div class="button">
            <button mat-raised-button color="primary" type="button" (click)="goToStep2()">Next</button>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="step-password">
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input #passwordControl matInput type="password" autocomplete="off" placeholder="Password" formControlName="password" (keyup.enter)="form.ngSubmit.emit()">
          </mat-form-field>
          <div class="button">
            <button mat-raised-button color="primary" type="button" (click)="form.ngSubmit.emit()">Sign In</button>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</mat-card>
