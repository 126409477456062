<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
  <div class="confirm-dialog-text">{{ message }}</div>
  <mat-form-field *ngIf="confirmName">
    <input matInput type="text" [(ngModel)]="confirmInput">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">{{ cancelButtonTitle }}</button>
  <button mat-raised-button [color]="buttonClass || 'primary'" (click)="accept()" [disabled]="confirmName && confirmName != confirmInput">
    {{ acceptButtonTitle }}
    <mat-icon *ngIf="buttonClass === 'warn'">warning</mat-icon>
  </button>
</mat-dialog-actions>
