import {plainToClass} from 'class-transformer';

import {IdName} from '@models/id-names/_id-names';

export const EVALUATION_SETTING_IDS = {
  LIVE_GAME: 1,
  LIVE_PRACTICE: 2,
  LIVE_SHOOTAROUND: 3,
  LIVE_DRAFT_EVENT: 4,
  LIVE_FREE_AGENT_EVENT: 5,
  DIGITAL_GAME: 6,
  DIGITAL_PRACTICE: 7,
  DIGITAL_SHOOTAROUND: 8,
  DIGITAL_DRAFT_EVENT: 9,
  DIGITAL_FREE_AGENT_EVENT: 10,
  INTERNAL_EVALUATION: 11,
  DIGITAL_CLIPS: 1098,
  DATA_ANALYSIS: 1099
};

export class EvaluationSetting extends IdName {}

export const EVALUATION_SETTINGS = {};
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.LIVE_GAME] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.LIVE_GAME, name: 'Game - Live'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.LIVE_PRACTICE] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.LIVE_PRACTICE, name: 'Practice - Live'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.LIVE_SHOOTAROUND] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.LIVE_SHOOTAROUND, name: 'Shootaround - Live'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.LIVE_DRAFT_EVENT] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.LIVE_DRAFT_EVENT, name: 'Draft Related Event - Live'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.LIVE_FREE_AGENT_EVENT] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.LIVE_FREE_AGENT_EVENT, name: 'Free Agent Related Event - Live'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.DIGITAL_GAME] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.DIGITAL_GAME, name: 'Game - Digital'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.DIGITAL_PRACTICE] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.DIGITAL_PRACTICE, name: 'Practice - Digital'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.DIGITAL_SHOOTAROUND] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.DIGITAL_SHOOTAROUND, name: 'Shootaround - Digital'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.DIGITAL_DRAFT_EVENT] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.DIGITAL_DRAFT_EVENT, name: 'Draft Related Event - Digital'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.DIGITAL_FREE_AGENT_EVENT] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.DIGITAL_FREE_AGENT_EVENT, name: 'Free Agent Related Event - Digital'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.INTERNAL_EVALUATION] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.INTERNAL_EVALUATION, name: 'Internal Evaluation'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.DIGITAL_CLIPS] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.DIGITAL_CLIPS, name: 'Clips - Digital'});
EVALUATION_SETTINGS[EVALUATION_SETTING_IDS.DATA_ANALYSIS] = plainToClass(EvaluationSetting, {id: EVALUATION_SETTING_IDS.DATA_ANALYSIS, name: 'Data Analysis'});

export const EvaluationSettingsToEvaluationEvents = {
  4: [80, 81, 82, 83, 84],
  5: [82, 83, 84, 85],
  9: [80, 81, 82, 83, 84],
  10: [82, 83, 84, 85]
};
