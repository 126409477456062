import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';

import {UserGroup} from '@models/user-groups';
import {featureAdapter, State} from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectUserGroupState: MemoizedSelector<object, State> = createFeatureSelector<State>('user-group');

export const selectAllUserGroups: (state: object) => UserGroup[] = featureAdapter.getSelectors(selectUserGroupState).selectAll;

export const selectUserGroups = createSelector(
  selectAllUserGroups,
  (userGroups: UserGroup[]) => {
    return userGroups.filter(p => p.isDeleted === false);
  }
);

export const selectUserGroup = (id: number) => {
  return createSelector(selectAllUserGroups, (userGroups: UserGroup[]) => {
    return userGroups.find(p => p.id === id);
  });
};

export const selectUserGroupError: MemoizedSelector<object, any> = createSelector(selectUserGroupState, getError);

export const selectUserGroupIsLoading: MemoizedSelector<object, boolean> = createSelector(selectUserGroupState, getIsLoading);
