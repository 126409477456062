import {Action} from '@ngrx/store';

import {Article} from '@models/articles';

export enum ActionTypes {
  LIST = '[Spotlight Article] List',
  LIST_SUCCESS = '[Spotlight Article] List Success',
  LIST_FAILED = '[Spotlight Article] List Failed'
}

export class GetSpotlightArticles implements Action {
  public readonly type = ActionTypes.LIST;
  constructor(public payload: {aboutID?: number, league: number, maxDays: number, page: string, pageID: string, limit: number}) {}
}

export class GetSpotlightArticlesSuccess implements Action {
  public readonly type = ActionTypes.LIST_SUCCESS;
  constructor(public payload: {aboutID: number, leagueID: number, prevPageDateToken: string, nextPageDateToken: string, prevPageIDToken: string, nextPageIDToken: string, results: Article[], filteredIds: number[]}) {}
}

export class GetSpotlightArticlesFailed implements Action {
  public readonly type = ActionTypes.LIST_FAILED;
  constructor(public payload: any) {}
}

export type Actions = GetSpotlightArticles | GetSpotlightArticlesSuccess | GetSpotlightArticlesFailed;
