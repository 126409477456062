import {BehaviorSubject, Subject, Observable, throwError, timer} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {NgxPermissionsService, NgxRolesService} from 'ngx-permissions';
import {catchError, map, share, take} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';


import {BaseService} from '@services/base.service';
import {PermissionOverrides} from '@models/permission-overrides';
import {UsersService} from '@services/users.service';
import {User} from '@models/users';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {environment} from '../../environments/environment';
import {BroadcastChannelService} from '@services/broadcast-channel.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

declare var ga: Function;

@UntilDestroy()
@Injectable()
export class AuthService extends BaseService {
  currentUserDataSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public expLeeway: number = 0;
  public refreshingToken: boolean = false;
  public kioskUserIDs: number[] = [106, 144, 155];

  protected _jwt$: Subject<any> = new Subject<any>();
  public _claims: any;
  protected loggedIn$: Subject<void> = new Subject<void>();

  get accessToken(): string | null {
    return localStorage.getItem('access');
  }

  set accessToken(val) {
    localStorage.setItem('access', val);
  }

  get refreshToken(): string | null {
    return localStorage.getItem('refresh');
  }

  set refreshToken(val) {
    localStorage.setItem('refresh', val);
  }

  get didLogIn(): Observable<void> {
    if (this._claims && this._claims.user && this._claims.user.id) {
      ga('set', 'userId', String(this._claims.user.id));
      ga('set', 'dimension1', String(this._claims.user.id));
    }
    return this.loggedIn$.pipe(share());
  }

  constructor(protected http: HttpClient,
              protected broadcastChannelService: BroadcastChannelService,
              protected jwtHelper: JwtHelperService,
              protected permissionsService: NgxPermissionsService,
              protected rolesService: NgxRolesService,
              protected usersService: UsersService) {
    super(http, broadcastChannelService);

    if (this.accessToken) {
      this.unloadClaimsFromToken(this.refreshToken);
    }
    this.initStream();
  }

  get user(): any {
    if (!this._claims) {
      return null;
    }

    const user = this._claims.user;
    // Add imageUrl to this object since it's buried in entity
    user.imageUrl = this._claims.user.entity.imageUrl;
    user.optimizedImageUrl = this._claims.user.entity.optimizedImageUrl;
    return user;
  }

  get entity(): any {
    return this._claims.user.entity;
  }

  get permissions(): any {
    let permissions: any;
    if (this._claims) {
      permissions = this._claims.permissions;
    }

    return permissions;
  }

  get permissionOverrides(): PermissionOverrides {
    let permissionOverrides: PermissionOverrides;
    if (this._claims) {
      permissionOverrides = this._claims.permissionOverrides;
    }

    return permissionOverrides;
  }

  get shouldRefresh(): boolean {
    let shouldRefresh = true;
    if (this.accessToken) {
      shouldRefresh = this.jwtHelper.isTokenExpired(this.accessToken, this.expLeeway);
    } else {
    }
    return shouldRefresh;
  }

  get canRefresh(): boolean {
    return !!this.refreshToken && !this.jwtHelper.isTokenExpired(this.refreshToken);
  }

  get currentUserData(): Observable<any> {
    this.currentUserDataSubject.next(this.user);
    return this.currentUserDataSubject.asObservable();
  }

  protected unloadClaimsFromToken(token): void {
    this._claims = this.jwtHelper.decodeToken(token);
    this._claims.user = plainToClass(User, this._claims.user);
  }

  protected setLocalStorage(accessToken, refreshToken, user): void {
    localStorage.setItem('access', accessToken);
    localStorage.setItem('refresh', refreshToken);
    localStorage.setItem('user', JSON.stringify(user));
  }

  jwt(): Observable<any> {
    return this._jwt$.pipe(share());
  }

  initStream(): void {
    timer(0, 2 * 60 * 1000).pipe(untilDestroyed(this)).subscribe( () => {
      if (this.canRefresh && !this.refreshingToken){
        this.refreshingToken = true;
        this.refreshJwt(true);
      }
    });
  }

  refreshJwt(fromRefresh: boolean = false): void {
    this.refreshingToken = true;

    const endpoint = `${this.baseUrl}/jwt-token/refresh`;
    this.post(endpoint, {refresh: this.refreshToken}).pipe(map(
      res => {
        this.refreshingToken = false;
        res = this.unloadAuthResponse(res, false, fromRefresh);
        this._jwt$.next(res);
        return res;
      },
      error => {
        this.refreshingToken = false;
        this._jwt$.error(error);
        return error;
      }
    ), take(1), ).subscribe();
  }

  login(username?: string, password?: string, code?: string): Observable<any> {
    const endpoint = `${this.baseUrl}/jwt-token`;
    return this.post(endpoint, { username: username, password: password, code: code }).pipe(
      map(
        res => {
          return this.unloadAuthResponse(res);
        }
      ), catchError(error => {
        return throwError(error);
      }));
  }

  getADFSConfig(): Observable<any> {
    const endpoint = `${this.baseUrl}/adfs-config`;
    return this.get(endpoint).pipe(
      map(
        res => {
          return res;
        },
        error => {
          return error;
        }
      ));
  }

  logout(shouldRedirect: boolean = true, isUserInitiated: boolean = false, isError: boolean = false): void {
    console.log(this.user);
    if (!isError && !isUserInitiated && this.kioskUserIDs.includes(this.user?.id)) {
      return;
    }
    // clear claims from authService and tokens from local storage but leave user in
    // local storage to make it easier to log back in
    this._claims = undefined;

    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('parentUser');
    localStorage.removeItem('autoLogoutDisabled');

    const isLoggedInViaADFS = coerceBooleanProperty(localStorage.getItem('isLoggedInViaADFS'));

    this.permissionsService.flushPermissions();

    // Hard refresh for now so that user gets new version of
    // the app until we can send them a message in real-time
    // Using setTimeout so Material animations have time
    // to finish and don't look odd when full page refresh starts to happen
    if (shouldRedirect) {
      if (isUserInitiated && isLoggedInViaADFS) {
        setTimeout(_ => window.location.href = environment.adfsLogoutUrl, 250);
      } else {
        setTimeout(_ => window.location.href = '/sign-in', 250);
      }
    }
  }

  loggedIn() {
    return this.canRefresh;
  }

  logInAsUser(userId: number): Observable<any> {
    const endpoint = `${this.baseUrl}/switchuser/${userId}`;
    return this.post(endpoint, {}).pipe(
      map(
        res => {
          res.switchUser = true;
          res = this.unloadAuthResponse(res);
          delete res.switchUser;
          return res;
        },
        error => {
          return error;
        }
      ));
  }

  protected unloadAuthResponse = (res: any, isFromLogIn: boolean = true, isFromRefreshTimer: boolean = false) => {
    const token: string = res && res.access;
    if (token) {
      if (res.switchUser) {
        localStorage.setItem('parentUser', JSON.stringify({
          accessToken: this.accessToken,
          refreshToken: this.refreshToken
        }));
      }
      this.accessToken = res.access;
      this.refreshToken = res.refresh;
      this.unloadClaimsFromToken(this.accessToken);
      this.setLocalStorage(res.access, res.refresh, this.user);
      if (!isFromRefreshTimer) {
        this.permissionsService.flushPermissions();
        this.rolesService.flushRoles();
      }
      if (isFromLogIn) {
        this.loggedIn$.next();
      }
      this.setupPermissions();
    }

    return res;
  }

  setupPermissions(): void {
    const permissions: any = this.permissions[0];

    if (permissions.userType) {
      this.permissionsService.addPermission(permissions.userType.name);
    }

    permissions.attributes.filter(permission => {
      return permission.isEnabled;
    }).forEach(permission => {
      const permissionName = permission.name.replace(/\s/g, '');
      const permissionOwnerVerbs = this.getPermissionsVerbs(permission.permissions.owner);
      const permissionAllVerbs = this.getPermissionsVerbs(permission.permissions.all);

      permissionOwnerVerbs.map(permissionVerb => {
        const permissionOwnerName = permissionVerb + 'Owner' + permissionName;

        this.permissionsService.addPermission(permissionOwnerName, (permissionName, permissionStore) => {
          return true;
        });
      });

      permissionAllVerbs.map(permissionVerb => {
        const permissionAllName = permissionVerb + 'All' + permissionName;

        this.permissionsService.addPermission(permissionAllName, (permissionName, permissionStore) => {
          return true;
        });
      });
    });

    // Setup permission override
    if (this.permissionOverrides.postCreation) {
      this.permissionsService.addPermission('overridePostCreation');
    }
    if (this.permissionOverrides.commentCreation) {
      this.permissionsService.addPermission('overrideCommentCreation');
    }
  }

  getPermissionsVerbs(permissionNumber) {
    let permissionVerb;
    switch (permissionNumber) {
      case 1: {
        return permissionVerb = ['view'];
      }
      case 2: {
        return permissionVerb = ['input'];
      }
      case 3: {
        return permissionVerb = ['view', 'input'];
      }
      case 4: {
        return permissionVerb = ['edit'];
      }
      case 5: {
        return permissionVerb = ['view', 'edit'];
      }
      case 6: {
        return permissionVerb = ['input', 'edit'];
      }
      case 7: {
        return permissionVerb = ['view', 'input', 'edit'];
      }
      case 8: {
        return permissionVerb = ['delete'];
      }
      case 9: {
        return permissionVerb = ['view', 'delete'];
      }
      case 10: {
        return permissionVerb = ['input', 'delete'];
      }
      case 11: {
        return permissionVerb = ['view', 'input', 'delete'];
      }
      case 12: {
        return permissionVerb = ['edit', 'delete'];
      }
      case 13: {
        return permissionVerb = ['view', 'edit', 'delete'];
      }
      case 14: {
        return permissionVerb = ['input', 'edit', 'delete'];
      }
      case 15: {
        return permissionVerb = ['view', 'input', 'edit', 'delete'];
      }
      default: {
        return permissionVerb = ['deny'];
      }
    }
  }

  public getPermissionsForAttribute(attributeType?: string, attributeObject?: any, permissionType?: string) {
    let userID;

    if (attributeObject == 'TeamInformation' || attributeObject == 'PersonalInformation') {
      if (attributeObject.userID) {
        userID = attributeObject.userID;
      } else {
        userID = null;
      }
    } else if (attributeObject === 'FocusGroup') {
      if (attributeObject.owner && attributeObject.owner.userID) {
        userID = attributeObject.owner.userID;
      } else {
        userID = null;
      }
    } else {
      if (attributeObject && attributeObject.author) {
        userID = attributeObject.author.userID;
      } else {
        userID = null;
      }
    }

    let permissions;
    if (this.user && userID == this.user.id) {
      if (permissionType == 'Admin') {
        permissions = ['viewOwner', 'inputOwner', 'editOwner', 'deleteOwner'].map(p => p + attributeType.replace(/\s/g, ''));
      } else if (permissionType == 'Input') {
        permissions = ['inputOwner' + attributeType];
      } else if (permissionType == 'Edit') {
        permissions = ['editOwner' + attributeType];
      } else if (permissionType == 'Delete') {
        permissions = ['deleteOwner' + attributeType];
      } else if (permissionType == 'EditDelete') {
        const edits = ['editOwner' + attributeType];
        const deletes = ['deleteOwner' + attributeType];
        permissions = edits.concat(deletes);
      }
    } else {
      if (permissionType == 'Admin') {
        permissions = ['viewAll', 'inputAll', 'editAll', 'deleteAll'].map(p => p + attributeType.replace(/\s/g, ''));
      } else if (permissionType == 'Input') {
        permissions = ['inputAll' + attributeType];
      } else if (permissionType == 'Edit') {
        permissions = ['editAll' + attributeType];
      } else if (permissionType == 'Delete') {
        permissions = ['deleteAll' + attributeType];
      } else if (permissionType == 'EditDelete') {
        const edits = ['editAll' + attributeType];
        const deletes = ['deleteAll' + attributeType];
        permissions = edits.concat(deletes);
      }
    }

    // Final check to see if user is still set, there are cases where
    // results might be being unloaded as a user is logged out
    if (!this.user) {
      permissions = [];
    }
    return permissions;
  }
}
