import {IdName} from 'apps/_models/id-names/_id-names';
import {plainToClass} from 'class-transformer';

export const ROLE_IDS = {
  CHARACTER: 20,
  CONNECTOR: 21,
  CREATOR: 22,
  DEFENDER: 23,
  FINISHER: 24,
  NGE: 25,
  PASSER: 26,
  PLAY_MAKER: 27,
  REBOUNDER: 28,
  SCORER: 29,
  SHOOTER: 30,
  SHOT_BLOCKER: 31,
  WINNER: 32
};

export class Role extends IdName {}

export const ROLES = {};
ROLES[ROLE_IDS.CHARACTER] = plainToClass(Role, {id: ROLE_IDS.CHARACTER, name: 'Character'});
ROLES[ROLE_IDS.CONNECTOR] = plainToClass(Role, {id: ROLE_IDS.CONNECTOR, name: 'Connector'});
ROLES[ROLE_IDS.CREATOR] = plainToClass(Role, {id: ROLE_IDS.CREATOR, name: 'Creator'});
ROLES[ROLE_IDS.DEFENDER] = plainToClass(Role, {id: ROLE_IDS.DEFENDER, name: 'Defender'});
ROLES[ROLE_IDS.FINISHER] = plainToClass(Role, {id: ROLE_IDS.FINISHER, name: 'Finisher'});
ROLES[ROLE_IDS.NGE] = plainToClass(Role, {id: ROLE_IDS.NGE, name: 'NGE'});
ROLES[ROLE_IDS.PASSER] = plainToClass(Role, {id: ROLE_IDS.PASSER, name: 'Passer'});
ROLES[ROLE_IDS.PLAY_MAKER] = plainToClass(Role, {id: ROLE_IDS.PLAY_MAKER, name: 'Play Maker'});
ROLES[ROLE_IDS.REBOUNDER] = plainToClass(Role, {id: ROLE_IDS.REBOUNDER, name: 'Rebounder'});
ROLES[ROLE_IDS.SCORER] = plainToClass(Role, {id: ROLE_IDS.SCORER, name: 'Scorer'});
ROLES[ROLE_IDS.SHOOTER] = plainToClass(Role, {id: ROLE_IDS.SHOOTER, name: 'Shooter'});
ROLES[ROLE_IDS.SHOT_BLOCKER] = plainToClass(Role, {id: ROLE_IDS.SHOT_BLOCKER, name: 'Shot Blocker'});
ROLES[ROLE_IDS.WINNER] = plainToClass(Role, {id: ROLE_IDS.WINNER, name: 'Winner'});
