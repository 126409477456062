import {NavOption} from '@models/nav-option';
import {LEAGUE_IDS} from '@models/constants/leagues';

export class TeamSalaryNavOption extends NavOption {
  name= 'Team Salary';
  shouldHide= false;
  activeRootPath = ['', 'contract', 'team'];
  link = ['', 'contract', 'team', 'sportscode'];
}

export class LeagueSalaryNavOption extends NavOption {
  name= 'League Salary';
  shouldHide= false;
  activeRootPath = ['', 'contract', 'league', 'salary'];
  link = ['', 'contract', 'league', 'salary'];
}
