<div class="bild-tooltip"
     [ngClass]="tooltipClass"
     [class.mat-tooltip-handset]="(_isHandset | async)?.matches"
     [@state]="_visibility"
     (@state.start)="_animationStart()"
     (@state.done)="_animationDone($event)">
  <div class="bild-tooltip-html"
       [innerHTML]="message"
       *ngIf="isHTML; else messageString;">
  </div>
  <ng-template #messageString>
    {{message}}
  </ng-template>
</div>
