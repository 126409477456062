<div class="search-input-component">
  <div class="search-input">
      <input matInput class="input-field"
             #searchElement
             type="search"
             [(ngModel)]="value"
             [placeholder]="placeholder"
             (blur)="handleBlur()"
             (search)="stopPropagation($event)"
             [matAutocomplete]="autocompleteSearch"/>
    <mat-autocomplete #autocompleteSearch="matAutocomplete" class="search-input-component autocomplete" (optionSelected)="handleOptionSelected($event)" disableRipple="true" [panelWidth]="element.nativeElement.offsetWidth">
      <mat-option *ngFor="let searchResult of searchResults; trackBy: searchResultsTrackBy" [value]="searchResult" (click)="trackClick($event)">
        <entity-context [entity]="!searchResult.isTag ? searchResult : undefined" [tag]="searchResult.isTag ? searchResult : undefined"></entity-context>
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
