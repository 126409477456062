import {Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Entity} from './entities';
import {League} from '@models/leagues';

export class TeamOwner {
  @Type(forwardRef(() => Entity) as any)
  entity: Entity;
  ownershipStatus: string;
}

export class Team extends Entity {
  alternateNames: any[] = [];
  @Type(forwardRef(() => Entity) as any)
  additionalAffiliations: Entity[] = [];
  locationDisplay: string;
  abbrev: string;
  arenaAddress: string;
  latitude: number;
  longitude: number;
  mascot: string;
  @Type(forwardRef(() => Entity) as any)
  primaryAffiliation: League;
  arenaName: string;
  draftExpressIDs: any[] = [];
  formerNames: any[] = [];
  nbaIDs: any[] = [];
  realGMIDs: any[] = [];
  synergyIDs: any[] = [];
  sportslogoTeamID: string;
  nameDisplay: string;
  formalName: string;
  @Type(forwardRef(() => TeamOwner) as any)
  ownershipGroup: TeamOwner[];
}

export class DraftTeam extends Team {
  @Type(forwardRef(() => League) as any)
  latestLeague: League;
  latestLeagueName: string;
  latestSeason: number;
}
