<div class="chip-header">{{ header }}</div>
<mat-chip 
  *ngIf="!games && !(minDate || maxDate)" 
  class="filter-chip" 
  [ngClass]="{'entity-chip': entity}"
  [matTooltip]="containsSeasons() && !areYearsContinuous(years) ? getTooltipContent() : null"
  matTooltipPosition="above">
  <entity-avatar *ngIf="entity" class="avatar" [entity]="entity" [size]="24"></entity-avatar>
  <div class="name" *ngIf="entity">{{ formattedDisplayText }}</div>
  <span *ngIf="!entity">{{ formattedDisplayText }}</span>
</mat-chip>

<mat-chip-list *ngIf="games?.length">
  <ng-container *ngFor="let game of games">
    <mat-chip class="game-chip">
      <div class="date-time-details">
        <div class="date">{{ game.gameStartTime | date: 'longDate' }}</div>
      </div>
      <div class="team-details" [class.emphasis]="game.awayFinalPoints > game.homeFinalPoints">
        <entity-avatar class="logo" [imageUrl]="'https://docs-public.imgix.net/' + game.awayTeamImage" [size]="16" [maskCorners]="true"></entity-avatar>
        <div class="name">{{ game.awayTeamName }}</div>
        <div class="points">{{ game.awayFinalPoints }}</div>
      </div>
      <div class="team-details" [class.emphasis]="game.homeFinalPoints > game.awayFinalPoints">
        <entity-avatar class="logo" [imageUrl]="'https://docs-public.imgix.net/' + game.homeTeamImage" [size]="16" [maskCorners]="true"></entity-avatar>
        <div class="name">{{ game.homeTeamName }}</div>
        <div class="points">{{ game.homeFinalPoints }}</div>
      </div>
    </mat-chip>
  </ng-container>
</mat-chip-list>

<mat-chip *ngIf="minDate || maxDate" class="filter-chip">
  <div *ngIf="minDate && maxDate && isMoment(minDate) && minDate.isSame(maxDate, 'day')"> {{minDate.format('MMM D, YYYY')}}</div>
  <div *ngIf="minDate && maxDate && isMoment(minDate) && !minDate.isSame(maxDate, 'day')"> {{minDate.format('MMM D, YYYY')}} - {{maxDate.format('MMM D, YYYY')}}</div>
  <div *ngIf="minDate && maxDate && !isMoment(minDate)">{{minDate}} - {{maxDate}}</div>
  <div *ngIf="minDate && !maxDate"> Min: {{minDate.format('MMM D, YYYY')}}</div>
  <div *ngIf="maxDate && !minDate"> Max: {{maxDate.format('MMM D, YYYY')}}</div>
</mat-chip>
