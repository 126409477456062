import * as moment from 'moment-timezone';
import * as _ from 'lodash';

import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';

import {featureAdapter, State} from './state';
import {Announcement} from '@models/moments';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectAnnouncementState: MemoizedSelector<object, State> = createFeatureSelector<State>('announcement');

export const selectAllAnnouncements: (state: object) => Announcement[] = featureAdapter.getSelectors(selectAnnouncementState).selectAll;

export const selectAnnouncements = createSelector(
  selectAllAnnouncements,
  (announcements: Announcement[]) => {
    const date = moment().startOf('day');
    return _.cloneDeep(announcements.filter(p => date.isBetween(p.minDate, p.maxDate, null, '[]')));
  }
);

export const selectAnnouncement = (id: number) => {
  return createSelector(selectAllAnnouncements, (announcements: Announcement[]) => {
    return _.cloneDeep(announcements.find(p => p.id === id));
  });
};

export const selectAnnouncementError: MemoizedSelector<object, any> = createSelector(selectAnnouncementState, getError);

export const selectAnnouncementIsLoading: MemoizedSelector<object, boolean> = createSelector(selectAnnouncementState, getIsLoading);
