<div *ngIf="!isMobile && !useCDKOverlay">
  <mat-icon #filterDialogButton class="filter-dialog-button" (click)="toggleFilterDisplay()">more_vert</mat-icon>
  <div class="overlay-container">
    <div class="filter-container" #filterContainer *ngIf="showFilter && visible" [ngStyle]="{top: yOffset, left: xOffset, width: toggleLabel ? '140px' : showPlaycallFilter ? '420px' : null}" [formGroup]="filterForm">
      <div class="action-button" [ngStyle]="{cursor: isChanged ? 'pointer' : 'default'}" *ngIf="!toggleLabel">
        <input *ngIf="showCheckboxes && (isLocs || isTSA)" matInput [formControlName]="'filterOptions'" (keypress)="filterOptions($event)" (keyup)="filterOptions($event)" placeholder="Filter Authors">
        <mat-icon (click)="closeFilter()">clear</mat-icon>
      </div>
      <div class="dates-container" *ngIf="showDateFilters">
        <mat-form-field class="date-inputs">
          <input matInput [matDatepicker]="minDatePicker" [min]="minDate" [max]="maxDate" placeholder="Start" autocomplete="off" formControlName="minDate">
          <mat-datepicker-toggle matSuffix [for]="minDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #minDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="date-inputs">
          <input matInput [matDatepicker]="maxDatePicker" [min]="minDate" [max]="maxDate" placeholder="End" autocomplete="off" formControlName="maxDate">
          <mat-datepicker-toggle matSuffix [for]="maxDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #maxDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="slider-container" *ngIf="showSlider">
        <ngx-slider [(value)]="minSliderValue" [(highValue)]="maxSliderValue" [options]="rangeSliderOptions"></ngx-slider>
      </div>
      <div class="checkbox-container" *ngIf="showCheckboxes">
        <mat-checkbox [formControlName]="'selectAll'" (change)="toggleSelectAll()" [indeterminate]="selectAllIndeterminate">Select All</mat-checkbox>
        <mat-checkbox [formControlName]="value" *ngFor="let value of values; let i = index" (change)="toggleIndividual(value)">
          <div class="checkbox-name-container">
            <entity-avatar class="checkbox-avatar" *ngIf="checkboxImages?.length && checkboxImages[i]" [imageUrl]="checkboxImages[i]" [size]="24" [maskCorners]="true" [lazy]="true"></entity-avatar>
            <span> {{value}}</span>
          </div>
        </mat-checkbox>
      </div>
      <div class="playcall-filter-container" *ngIf="showPlaycallFilter">
        <div class="included-playcalls">
          <mat-form-field class="include-playcall-input">
            <mat-label class="playcall-label">Included Playcalls</mat-label>
            <mat-chip-list #includePlaycallChips>
              <ng-container *ngFor="let pc of playcallsToInclude; let i = index">
                <mat-chip (removed)="removeIncludedPlaycall(i)">
                  {{ pc }} <mat-icon class="cancel-icon" matChipRemove>cancel</mat-icon>
                </mat-chip>
              </ng-container>
              <input #includePlaycallInput [matChipInputFor]="includePlaycallChips" aria-label="Playcall" [matAutocomplete]="includedPlaycallsAutocomplete" [formControl]="playcallFilterControl">
            </mat-chip-list>
            <mat-autocomplete #includedPlaycallsAutocomplete="matAutocomplete" class="entities" (optionSelected)="handleIncludedPlaycallAdded($event)">
              <mat-option *ngFor="let entity of filteredEntities" [value]="entity">
                <entity-context [entity]="entity"></entity-context>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="excluded-playcalls">
          <mat-form-field class="exclude-playcall-input">
            <mat-label class="playcall-label">Excluded Playcalls</mat-label>
            <mat-chip-list #excludePlaycallChips>
              <ng-container *ngFor="let pc of playcallsToExclude; let i = index">
                <mat-chip (removed)="removeExcludedPlaycall(i)">
                  {{ pc }} <mat-icon class="cancel-icon" matChipRemove>cancel</mat-icon>
                </mat-chip>
              </ng-container>
              <input #excludePlaycallInput [matChipInputFor]="excludePlaycallChips" aria-label="Playcall" [matAutocomplete]="excludedPlaycallsAutocomplete" [formControl]="playcallFilterControl">
            </mat-chip-list>
            <mat-autocomplete #excludedPlaycallsAutocomplete="matAutocomplete" class="entities" (optionSelected)="handleExcludedPlaycallAdded($event)">
              <mat-option *ngFor="let entity of filteredEntities" [value]="entity">
                <entity-context [entity]="entity"></entity-context>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div class="filter-footer" *ngIf="!toggleLabel">
        <div class="buttons-container">
          <button mat-raised-button (click)="clearFilters()">Clear</button>
          <button class="apply-button" mat-raised-button (click)="filterByValue()">Apply</button>
        </div>
      </div>
      <div class="toggle-container" *ngIf="toggleLabel">
        <mat-slide-toggle class="view-table-selector" [(ngModel)]="toggleValue" [ngModelOptions]="{standalone: true}" (change)="filterByValue()">{{toggleLabel}}</mat-slide-toggle>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isMobile && useCDKOverlay">
  <mat-icon class="filter-dialog-button" (click)="toggleFilterDisplay()"
             cdkOverlayOrigin #filterDialogButton="cdkOverlayOrigin">more_vert
  </mat-icon>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="filterDialogButton"
    [cdkConnectedOverlayOpen]="showFilter"
    [cdkConnectedOverlayPositions]="positions">
    <div class="table-filter__overlay-container">
      <div class="filter-container" #filterContainer *ngIf="showFilter && visible" tabindex="1" (blur)="blurFilterContainer($event)"
           [ngClass]="{'dark-mode': isDarkMode}"
           [ngStyle]="{width: toggleLabel ? '140px' : showPlaycallFilter ? '420px' : null}" [formGroup]="filterForm">
        <div class="action-button" [ngStyle]="{cursor: isChanged ? 'pointer' : 'default'}" *ngIf="!toggleLabel">
          <input *ngIf="showCheckboxes && (isLocs || isTSA)" matInput [formControlName]="'filterOptions'" (keypress)="filterOptions($event)" (keyup)="filterOptions($event)" placeholder="Filter Authors">
          <mat-icon (click)="closeFilter()">clear</mat-icon>
        </div>
        <div class="dates-container" *ngIf="showDateFilters">
          <mat-form-field class="date-inputs">
            <input matInput [matDatepicker]="minDatePicker" [min]="minDate" [max]="maxDate" placeholder="Start" autocomplete="off" formControlName="minDate">
            <mat-datepicker-toggle matSuffix [for]="minDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #minDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="date-inputs">
            <input matInput [matDatepicker]="maxDatePicker" [min]="minDate" [max]="maxDate" placeholder="End" autocomplete="off" formControlName="maxDate">
            <mat-datepicker-toggle matSuffix [for]="maxDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #maxDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="slider-container" *ngIf="showSlider">
          <ngx-slider [(value)]="minSliderValue" [(highValue)]="maxSliderValue" [options]="rangeSliderOptions"></ngx-slider>
        </div>
        <div class="search-container" *ngIf="showSearch">
          <input class="search-input" matInput placeholder="Search Input" autocomplete="off" formControlName="searchInput">
        </div>
        <div class="checkbox-container" *ngIf="showCheckboxes" [ngStyle]="{maxHeight: maxHeight}">
          <mat-checkbox [formControlName]="'selectAll'" (change)="toggleSelectAll()" [indeterminate]="selectAllIndeterminate">Select All</mat-checkbox>
          <mat-checkbox [formControlName]="value" *ngFor="let value of values; let i = index" (change)="toggleIndividual(value)">
            <div class="checkbox-name-container">
              <entity-avatar class="checkbox-avatar" *ngIf="checkboxImages?.length && checkboxImages[i]" [imageUrl]="checkboxImages[i]" [size]="24" [maskCorners]="true" [lazy]="true"></entity-avatar>
              <span> {{value}}</span>
            </div>
          </mat-checkbox>
        </div>
        <div class="playcall-filter-container" *ngIf="showPlaycallFilter">
          <div class="included-playcalls">
            <mat-form-field class="include-playcall-input">
              <mat-label class="playcall-label">Included Playcalls</mat-label>
              <mat-chip-list #includePlaycallChips>
                <ng-container *ngFor="let pc of playcallsToInclude; let i = index">
                  <mat-chip (removed)="removeIncludedPlaycall(i)">
                    {{ pc }} <mat-icon class="cancel-icon" matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </ng-container>
                <input #includePlaycallInput [matChipInputFor]="includePlaycallChips" aria-label="Playcall" [matAutocomplete]="includedPlaycallsAutocomplete" [formControl]="playcallFilterControl">
              </mat-chip-list>
              <mat-autocomplete #includedPlaycallsAutocomplete="matAutocomplete" class="entities" (optionSelected)="handleIncludedPlaycallAdded($event)">
                <mat-option *ngFor="let entity of filteredEntities" [value]="entity">
                  <entity-context [entity]="entity"></entity-context>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="excluded-playcalls">
            <mat-form-field class="exclude-playcall-input">
              <mat-label class="playcall-label">Excluded Playcalls</mat-label>
              <mat-chip-list #excludePlaycallChips>
                <ng-container *ngFor="let pc of playcallsToExclude; let i = index">
                  <mat-chip (removed)="removeExcludedPlaycall(i)">
                    {{ pc }} <mat-icon class="cancel-icon" matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </ng-container>
                <input #excludePlaycallInput [matChipInputFor]="excludePlaycallChips" aria-label="Playcall" [matAutocomplete]="excludedPlaycallsAutocomplete" [formControl]="playcallFilterControl">
              </mat-chip-list>
              <mat-autocomplete #excludedPlaycallsAutocomplete="matAutocomplete" class="entities" (optionSelected)="handleExcludedPlaycallAdded($event)">
                <mat-option *ngFor="let entity of filteredEntities" [value]="entity">
                  <entity-context [entity]="entity"></entity-context>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="filter-footer" *ngIf="!toggleLabel">
          <div class="buttons-container">
            <button class="clear-button" mat-raised-button (click)="clearFilters()">Clear</button>
            <button class="apply-button" mat-raised-button (click)="filterByValue()">Apply</button>
          </div>
        </div>
        <div class="toggle-container" *ngIf="toggleLabel">
          <mat-slide-toggle class="view-table-selector" [(ngModel)]="toggleValue" [ngModelOptions]="{standalone: true}" (change)="filterByValue()">{{toggleLabel}}</mat-slide-toggle>
        </div>
      </div>
    </div>
  </ng-template>
</div>
