import * as moment from 'moment-timezone';

import {Transform, Type, Exclude} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {forwardRefMoment} from '@helpers/class-transformer.helper';
import {PlayerDevelopmentKeyResult} from '@models/player-development-key-results';

export class PlayerDevelopmentKeyResultLog {

  id: number;
  isActive: boolean = true;
  keyResult: PlayerDevelopmentKeyResult;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment())
  createDatetime: moment.Moment;
  logSuccesses: number;
  logAttempts: number;
  logNumber: number;
  logRatio: number;
  ratio: number;
  movingAverage: number;
  recentChange: number;
  successes: number;
  attempts: number;
  trend: number;
  YTDRatio: number;
  YTDNumber: number;

  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  endDate: moment.Moment;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  startDate: moment.Moment;

  week(keyResult: PlayerDevelopmentKeyResult): number {
    return Math.ceil(this.startDate.diff(keyResult.weeksStartDate, 'days') / 7) + 1;
  }
}
