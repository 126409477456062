import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtModule} from '@auth0/angular-jwt';
import {NgIdleModule} from '@ng-idle/core';
import {ErrorHandler, NgModule, Injectable, PLATFORM_ID} from '@angular/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {Router, RouterModule} from '@angular/router';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LOADING_BAR_CONFIG, LoadingBarModule} from '@ngx-loading-bar/core';
import {LayoutModule} from '@angular/cdk/layout';
import {HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';

// Helpers
import {AtjsHelper} from '@helpers/atjs.helper';
import {DOMHelper} from '@helpers/dom.helper';
import {MaterialHelper} from '@helpers/material.helper';

import {AppsComponent} from './apps.component';
import {AuthGuard} from './_guards/auth.guard';
import {BildInterceptor} from './_interceptors/bild.interceptor';
import {NetworkActivityInterceptor} from './_interceptors/network-activity.interceptor';
import {ScrollDirective} from './bild/_shared/directives/scroll.directive';
import {SharedModule} from './bild/_shared/shared.module';
import {SignInModule} from './sign-in/sign-in.module';
import {environment} from '../environments/environment';
import {routing} from './apps.routing';

// Services
import {AuthService} from './_services/auth.service';
import {AutocompleteService} from '@services/autocomplete.service';
import {BildUserTypesService} from '@services/id-names/bild-user-types.service';
import {EntitySubtypesService} from '@services/id-names/entity-subtypes.service';
import {PrimaryToolbarService} from './bild/_shared/primary-toolbar/primary-toolbar.service';
import {UsersService} from './_services/users.service';

// Store
import {RootStoreModule} from '@store/root-store.module';

// Config
import {APPS_CONFIG, APPS_DI_CONFIG} from './apps-config.constants';
import {PostHelper} from "@helpers/post.helper";

export function tokenGetter() {
  return localStorage.getItem('access');
}

@Injectable()
export class AppsHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // swipe: {direction: Hammer.DIRECTION_HORIZONTAL},
    pan: {
      direction: 6
    },
    pinch: {
      enable: false
    },
    rotate: {
      enable: false
    }
  };
}

const providers: any[] = [
    { provide: HTTP_INTERCEPTORS, useClass: BildInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NetworkActivityInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: AppsHammerConfig },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({showDialog: false})},
    { provide: Sentry.TraceService, deps: [Router], useValue: undefined },
];

if (environment.envName !== 'dev' && environment.envName !== 'stag') {
  // providers.push(
  //   { provide: ErrorHandler, useClass: RavenErrorHandler }
  // );
}

@NgModule({
  exports: [
    ScrollDirective,

    HttpClientModule,
    NgIdleModule,
    NgxPermissionsModule,
    SharedModule,
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule, BrowserTransferStateModule, CommonModule, RouterModule,
    FormsModule, HttpClientModule, ReactiveFormsModule,
    FlexLayoutModule,

    NgIdleModule.forRoot(),
    NgxPermissionsModule.forRoot(),

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['localhost:4200', 'localhost:8000', 'stage.bildlab.com', 'bildlab.com']
      }
    }),

    SignInModule,

    LayoutModule,

    LoadingBarHttpClientModule,
    LoadingBarModule,

    SharedModule.forRoot(),

    routing,

    RootStoreModule,
  ],
  declarations: [ AppsComponent ],
  providers: [
    AuthGuard,
    AuthService,
    AutocompleteService,
    BildUserTypesService,
    EntitySubtypesService,
    UsersService,

    AtjsHelper,
    DOMHelper,
    PostHelper,
    MaterialHelper,

    PrimaryToolbarService,

    {
      provide: APPS_CONFIG,
      useValue: APPS_DI_CONFIG
    },

    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 50 } },

  providers
  ],
  bootstrap: [ AppsComponent ]
})
export class AppsModule {
  constructor(trace: Sentry.TraceService) {}
}
