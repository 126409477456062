import * as _ from 'lodash';

import {HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpParameterCodec} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';


import {environment} from 'environments/environment';
import {BroadcastChannelService} from '@services/broadcast-channel.service';

export class BaseService {

  errorKeyMap: any = {};

  protected baseUrl = environment.apiUrl;
  protected defaultLimit: number = 25;

  EXCLUDED_REQUEST_PATHNAMES: string[] = [
    // Notifications
    '/notifications',
    '/notifications/count',
    // Refreshing auth
    '/jwt-token/refresh',
    // Fetches for draft list or saving drafts
    '/posts/drafts',
    '/entities/autocomplete',
    // Community Board fetch
    '/communityEvents',
  ];

  protected get headers(): HttpHeaders {
    return new HttpHeaders();
  }

  constructor(protected http: HttpClient, protected broadcastChannelService: BroadcastChannelService) {
    this.EXCLUDED_REQUEST_PATHNAMES.forEach((path, index) => {
      this.EXCLUDED_REQUEST_PATHNAMES[index] = this.baseUrl + path;
    });
  }

  protected get(url: string, params?: HttpParams): Observable<any> {
    const options = {headers: this.getHeaders(url), params: params};

    // Ignore polling requests when preventing logout
    if (!url.includes('notifications') && !url.includes('scoreboard')) {
      this.broadcastChannelService.postMessage('Non-polling get call');
    }
    return this.http.get(url, options).pipe(catchError(this.getErrorHandler()));
  }

  protected getHeaders(url): HttpHeaders {
    if (this.EXCLUDED_REQUEST_PATHNAMES.includes(url)) {
      return new HttpHeaders({ ignoreLoadingBar: '' });
    } else {
      return new HttpHeaders();
    }
  }

  protected post(url: string, data: {}, params?: HttpParams): Observable<any> {
    const options = {headers: this.headers};

    this.broadcastChannelService.postMessage('Post call');
    return this.http.post(url, data, options).pipe(catchError(this.getErrorHandler()));
  }

  protected put(url: string, data: {}, options?: any): Observable<any> {
    options = options || {headers: this.headers};

    this.broadcastChannelService.postMessage('Put call');
    return this.http.put(url, data, options).pipe(catchError(this.getErrorHandler()));
  }

  protected patch(url: string, data: {}, options?: any): Observable<any> {
    options = options || {headers: this.headers};

    this.broadcastChannelService.postMessage('Patch call');
    return this.http.patch(url, data, options).pipe(catchError(this.getErrorHandler()));
  }

  protected delete(url: string, options?: any): Observable<any> {
    this.broadcastChannelService.postMessage('Delete call');
    return this.http.delete(url, options).pipe(catchError(this.getErrorHandler()));
  }

  protected getErrorHandler() {
    return res => {
      if (res.status === 401) {
        // TODO
        // that.logOut();
      }

      const errorPairs: [string, string][] = _(res.error).toPairs().value();
      const errorMessages: string[] = errorPairs.map(error => {
        const keyName: string = this.errorKeyMap[error[0]] || error[0];
        return `${keyName}: ${error[1]}`;
      });

      return throwError(<HttpErrorResponse>{
        status: res.status,
        response: res,
        errorMessages: errorMessages
      });
    };
  }
}

export class HttpErrorResponse {
  status: number;
  response: HttpResponse<any>;
  errorMessages: string[];
}

export class CustomHttpParamEncoder implements HttpParameterCodec {
  // Use this class when you want to encode any of: '@', ':', '$', ',', ';', '+', '=', '?', '/' in the query parameters of an HTTP request
  // Usage: let params: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() })
  // Example use case: Query parameter with a timestamp in UTC+ timezone. Django will interpret the '+' as a space if not encoded as '%2b%'
  // Default Angular behavior is to not encode these characters in order to comply with RFC 3986
  // See https://github.com/angular/angular/blame/540c29cd6bfc4124a30f5f8e7b599d326baf6af4/packages/common/http/src/params.ts#L90

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
