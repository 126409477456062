import {Directive, ElementRef, EventEmitter, Input, Output, ViewContainerRef} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[virtHeader]',
})
export class VirtualHeaderDirective {

  constructor(
    private elementRef: ElementRef,
  ) {}

  @Input() set virtHeader(column: any) {
    if (!this.elementRef.nativeElement.classList.contains('table-header-cell')) {
      this.elementRef.nativeElement.classList.add('table-header-cell');
      this.elementRef.nativeElement.classList.add('table-column-' + column.matColumnDef);
    }
    if (!this.elementRef.nativeElement.classList.contains('sortable') && column.isSortable) {
      this.elementRef.nativeElement.classList.add('sortable');
      this.elementRef.nativeElement.onclick = ($event) => {
        column.isSortable ? this.onSort.emit(column.matColumnDef) : null;
      };
    }
  }

  @Input() set top(val: string) {
    this.elementRef.nativeElement.style.top = val;
  }

  @Output() onSort: EventEmitter<number> = new EventEmitter();
}
