import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { debounceTime, take, filter } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { VideoService } from '@services/video.service';
import { FormControl } from '@angular/forms';
import { AutocompleteService } from 'apps/_services/autocomplete.service';
import { User } from '@models/users';
import { AuthService } from '@services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import * as actions from '@store/video-store/actions';
import * as _ from 'lodash';
import { RootStoreState } from 'apps/_store';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {SavedPlaylistDialogComponent} from "@bild-dialogs/saved-playlist/saved-playlist.component";
import { VideoStoreSelectors } from '@store/video-store';
import { VideoSharingTypes } from '@models/video';

@UntilDestroy()
@Component({
  selector: 'bild-clip-upload',
  templateUrl: './clip-upload.component.html',
  styleUrls: ['./clip-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClipUploadComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayer') videoPlayer;
  @ViewChild('entitiesInput') entitiesInput: ElementRef;
  @ViewChild('viewersInput') viewersInput: ElementRef;
  @ViewChild('videoControls') videoControls;
  @ViewChild('homeTeamEntitiesInput') homeTeamEntitiesInput: ElementRef;
  @ViewChild('awayTeamEntitiesInput') awayTeamEntitiesInput: ElementRef;

  isMobile: boolean;
  user: User;

  uploadedVideo: any;
  currentClip: any = null;
  currentVideoTime: number = 0;
  volume = 0;

  filteredUsers: any[];
  viewersControl: FormControl = new FormControl();
  filteredEntities: any[];
  entitiesControl: FormControl = new FormControl();
  filteredHomeTeamEntities: any[];
  homeTeamEntitiesControl: FormControl = new FormControl();
  filteredAwayTeamEntities: any[];
  awayTeamEntitiesControl: FormControl = new FormControl();
  dateControl: FormControl = new FormControl();
  userPlaylists: any[] = [];
  isPrivateOptionSelected = true;
  savingInProgress = false;
  isVideoSaved = false;
  selectedHomeTeam: any;
  selectedAwayTeam: any;
  selectedDate: Date;
  formData: FormData = new FormData();
  chainID: any = null;


  constructor(
    protected actions$: Actions,
    protected authService: AuthService,
    protected autocompleteService: AutocompleteService,
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected snackBar: MatSnackBar,
    protected store$: Store<RootStoreState.State>,
    protected title: Title,
    protected videoService: VideoService
  ) { }

  ngOnInit() {
    this.title.setTitle('Clip Upload');

    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe(result => {
      this.isMobile = result.matches;
    });

    this.authService.currentUserData.pipe(take(1), untilDestroyed(this)).subscribe((user: User) => {
      this.user = user;
    });

    this.viewersControl.valueChanges.pipe(
      debounceTime(environment.typingDebounceTime),
      untilDestroyed(this), )
      .subscribe(
        q => {
          this.filterUsers(q);
        }
      );

    this.entitiesControl.valueChanges.pipe(
      debounceTime(environment.typingDebounceTime),
      untilDestroyed(this), )
      .subscribe(
        q => {
          this.filterEntities(q);
        }
      );

    this.homeTeamEntitiesControl.valueChanges.pipe(
      debounceTime(environment.typingDebounceTime),
      untilDestroyed(this), )
      .subscribe(
        q => {
          this.filterTeamEntities(q, 'home');
        }
      );

    this.awayTeamEntitiesControl.valueChanges.pipe(
      debounceTime(environment.typingDebounceTime),
      untilDestroyed(this), )
      .subscribe(
        q => {
          this.filterTeamEntities(q, 'away');
        }
      );

    this.store$.pipe(
      select(VideoStoreSelectors.selectPlaylists),
      filter(playlists => playlists?.isLoaded),
      untilDestroyed(this),
      take(1),
    ).subscribe(playlists => {
      this.userPlaylists = _.cloneDeep(playlists.playlists);
      this.cdr.markForCheck();
    });

    this.actions$.pipe(
      ofType<actions.SavePlaylist>(actions.ActionTypes.SAVE_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      let existingPlaylistIndex = this.userPlaylists.findIndex(userPlaylist => userPlaylist.id == payload.id);
      if (existingPlaylistIndex != -1) {
        this.userPlaylists[existingPlaylistIndex] = {...this.userPlaylists[existingPlaylistIndex], ...payload};
      }
      else {
        this.userPlaylists.unshift(_.cloneDeep(payload));
      }
      this.cdr.markForCheck();
    });
  }

  resetVideo() {
    this.uploadedVideo = null;
    this.currentClip = null;
    this.selectedAwayTeam = null;
    this.selectedHomeTeam = null;
    this.selectedDate = null;
    this.dateControl.setValue(null);
    this.isVideoSaved = false;
  }

  videoUpdated($event) {
    if (!$event.target?.files) {
      this.resetVideo();
    } else {
      this.uploadedVideo = window.URL.createObjectURL($event.target.files[0]);
      this.cdr.detectChanges();
      this.videoPlayer.video1.nativeElement.src = this.uploadedVideo;
      // this.changeVolume(this.volume);
      this.videoPlayer.video1.nativeElement.play();

      this.currentClip = {
        name: '',
        commentary: '',
        additionalTaggedEntities: [],
        playlists: [],
        sharedUsers: [],
        startPadding: 0,
        endPadding: 0,
        clip: $event.target.files[0],
      }
    }
  }

  onVideoMetadataLoaded() {
    this.currentClip.startPadding = Math.ceil(this.videoPlayer.video.nativeElement.duration);
  }

  addSharingEntity(event): void {
    let entity = event.option.value;
    this.currentClip.sharedUsers.push(entity);
    this.viewersInput.nativeElement.value = '';
  }

  removeSharingEntity(entity: any): void {
    this.currentClip.sharedUsers = this.currentClip.sharedUsers.filter(sharedUser => sharedUser.id != entity.id);
  }

  addTaggedEntity(event): void {
    let entity = event.option.value;
    this.currentClip.additionalTaggedEntities.push(entity);
    this.entitiesInput.nativeElement.value = '';
  }

  removeTaggedEntity(entity: any): void {
    this.currentClip.additionalTaggedEntities = this.currentClip.additionalTaggedEntities.filter(taggedEntity => taggedEntity.id != entity.id);
  }

  addTeamEntity(event, homeAway): void {
    let entity = event.option.value;
    if (homeAway == 'home') {
      this.homeTeamEntitiesInput.nativeElement.value = '';
      this.selectedHomeTeam = entity;
    } else if (homeAway == 'away') {
      this.awayTeamEntitiesInput.nativeElement.value = '';
      this.selectedAwayTeam = entity;
    }
  }

  removeTeamEntity(homeAway): void {
    if (homeAway == 'home') {
      this.selectedHomeTeam = undefined;
    } else if (homeAway == 'away') {
      this.selectedAwayTeam = undefined;
    }
  }

  filterUsers(q: string): void {
    this.filteredUsers = undefined;
    if (q) {
      this.autocompleteService
        .getEntities(q, null, true).pipe(take(1), untilDestroyed(this))
        .subscribe(
          entities => {
            this.filteredUsers = entities.filter(entity => (entity.id != this.user?.entity?.id) &&
              (this.currentClip?.sharedUsers.findIndex(sharedUser => sharedUser.id == entity.id) == -1));
            this.cdr.markForCheck();
          }
        );
    }
  }

  filterEntities(q: string): void {
    this.filteredEntities = undefined;
    if (q) {
      this.autocompleteService
        .getEntities(q).pipe(take(1), untilDestroyed(this))
        .subscribe(
          entities => {
            this.filteredEntities = entities.filter(entity => (entity.id != this.user?.entity?.id) &&
              (this.currentClip.additionalTaggedEntities.findIndex(taggedEntity => taggedEntity.id == entity.id) == -1));
          }
        );
    }
  }

  filterTeamEntities(q: string, homeAway: string): void {
    if (homeAway == 'home') {
      this.filteredHomeTeamEntities = undefined;
      if (q) {
        this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
            entities => {
              this.filteredHomeTeamEntities = entities.filter(entity => entity.type == 'Team');
              this.cdr.markForCheck();
            }
          );
      }
    } else if (homeAway == 'away') {
      this.filteredAwayTeamEntities = undefined;
      if (q) {
        this.autocompleteService
          .getEntities(q).pipe(take(1), untilDestroyed(this))
          .subscribe(
            entities => {
              this.filteredAwayTeamEntities = entities.filter(entity => entity.type == 'Team');
              this.cdr.markForCheck();
            }
          );
      }
    }
  }

  changeDate($event) {
    this.selectedDate = moment($event.value).toDate();
  }

  compareIDs(a, b) {
    return a && b && a.id === b.id;
  }

  canEditPlaylist(playlist) {
    return playlist.id && playlist.id != -1 && (playlist.author.userID == this.user.id || (playlist.editability != VideoSharingTypes.PRIVATE && playlist.sharedUsers.findIndex(sharedUser => sharedUser.userID == this.user.id && sharedUser.isEditor) != -1));
  }


  createPlaylist() {
    const dialogConfig: MatDialogConfig = {
      data: {name: ''}
    };
    const dialogRef = this.dialog.open(SavedPlaylistDialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(newName => {
      if (newName) {
        let newPlaylistData = {name: newName, sharedUsers: []};
        this.chainID = this.videoService.generateChainID();
        this.store$.dispatch(new actions.SaveAction({'playlistSaved': newPlaylistData, 'chainID': this.chainID}));
      }
    });
  }

  fetchSavedPlaylists() {
    this.videoService.getSavedPlaylists().pipe(untilDestroyed(this)).subscribe(
      savedPlaylists => {
        this.userPlaylists = savedPlaylists;
        this.cdr.markForCheck();
      },
      error => {
        console.log(error);
      }
    );
  }

  confirmSave() {
    this.formData.append('videoSource', 'UPLOAD');
    this.formData.append('startPadding', this.currentClip.startPadding);
    this.formData.append('endPadding', this.currentClip.endPadding);
    this.formData.append('name', this.currentClip.name);
    this.formData.append('commentary', this.currentClip.commentary);
    if (this.currentClip.clip) {
      const index = this.currentClip.clip.type.lastIndexOf('/');
      let fileType = this.currentClip.clip.type.substring(index + 1);
      this.formData.append('clip', this.currentClip.clip);
      this.formData.append('fileType', fileType);
    }
    if (this.currentClip.id) {
      this.formData.append('id', this.currentClip.id);
      this.formData.append('isUploaded', 'true');
    }
    if (this.currentClip.additionalTaggedEntities) {
      this.formData.append('taggedPlayers', this.currentClip.additionalTaggedEntities.map(ent => ent.id).join(','));
    }
    if (this.currentClip.playlists) {
      this.formData.append('playlists', this.currentClip.playlists.map(playlist => playlist.id).join(','));
    }
    if (this.currentClip.sharedUsers) {
      this.formData.append('sharedUsers',  this.isPrivateOptionSelected ? [] : this.currentClip.sharedUsers.map(user => user.userID).join(','));
    }
    if (this.selectedHomeTeam) {
      this.formData.append('homeTeamID', this.selectedHomeTeam.id);
    }
    if (this.selectedAwayTeam) {
      this.formData.append('awayTeamID', this.selectedAwayTeam.id);
    }
    if (this.selectedDate) {
      this.formData.append('date', this.selectedDate.getFullYear() + '-' + (this.selectedDate.getMonth() + 1) + '-' + this.selectedDate.getDate());
    }
    if (this.currentClip.notes) {
      this.formData.append('notes', this.currentClip.notes);
    }
    if (this.currentClip.label) {
      this.formData.append('label', this.currentClip.label);
    }

    this.savingInProgress = true;
    this.videoService.saveVideo(this.formData, this.currentClip.id).pipe(untilDestroyed(this)).subscribe(
      savedVideo => {
        this.sendSaveMessage(savedVideo);
        this.resetVideo();
        this.cdr.markForCheck();
        this.snackBar.open('Clip Successfully Saved', 'Close', {
          duration: 5000,
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  sendSaveMessage(savedVideo) {
    let savedVideoData = {
      id: savedVideo.id,
      league: savedVideo.league,
      nbaGameID: savedVideo.nbaGameID,
      eagleGameID: savedVideo.eagleGameID,
      sportradarGameID: savedVideo.sportradarGameID,
      gameID: savedVideo.gameID,
      nbaChanceID: savedVideo.nbaChanceID,
      eagleChanceID: savedVideo.eagleChanceID,
      synergyEventID: savedVideo.synergyEventID,
      sportradarPlayByPlayID: savedVideo.sportradarPlayByPlayID,
      period: savedVideo.period,
      startGameclock: savedVideo.startGameclock,
      startTimestamp: savedVideo.startTimestamp,
      startPadding: savedVideo.startPadding,
      endPadding: savedVideo.endPadding,
      startTrim: savedVideo.startTrim,
      endTrim: savedVideo.endTrim,
      name: savedVideo.name,
      commentary: savedVideo.commentary,
      notes: savedVideo.notes,
      taggedPlayers: savedVideo.playersTagged,
      playlists: savedVideo.playlists,
      sharedUsers: savedVideo.sharedUsers,
      rawUrl: savedVideo.rawUrl,
      videoSource: savedVideo.videoSource,
      label: savedVideo.label,
    };

    this.store$.dispatch(new actions.SaveAction({'videoSaved': savedVideoData, 'chainID': this.videoService.generateChainID()}));
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.uploadedVideo);
  }
}
