import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation, ChangeDetectorRef} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

import {Entity} from '@models/entities';
import {Person} from '@models/people';
import {Team} from '@models/teams';
import {plainToClass} from 'class-transformer';

import * as _ from 'lodash';

@Component({
  selector: 'entity-avatar',
  templateUrl: './entity-avatar.component.html',
  styleUrls: ['./entity-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityAvatarComponent implements OnInit {

  _size: number = 36;
  _imgixSize: number = 72;
  @Input('size') set size(val: number) {
    this._size = val;
    this._imgixSize = val * 4;
    this.cornerRadius = this.imgixSize / 8;
    this.setup();
    this.cdr.markForCheck();
  }
  get size(): number {
    return this._size;
  }
  get imgixSize(): number {
    return this._imgixSize;
  }
  _entity: Entity | Person | Team;
  @Input('entity') set entity(val: Entity | Person | Team) {
    this._optimizedImageUrl = null;
    this._entity = _.cloneDeep(val);
    this._imageUrl = val?.imageUrl;
    this.setup();
    this.cdr.markForCheck();
  }
  get entity(): Entity | Person | Team {
    return this._entity;
  }
  _imageUrl: string;
  @Input('imageUrl') set imageUrl(val: string) {
    this._optimizedImageUrl = null;
    this._imageUrl = val;
    this.setup();
    this.cdr.markForCheck();
  }
  get imageUrl(): string {
    return this._imageUrl;
  }
  _lastMod: number;
  @Input('lastMod') set lastMod(val: number) {
    this._lastMod = val;
    this.setup();
    this.cdr.markForCheck();
  }
  get lastMod(): number {
    return this._lastMod;
  }
  @Input() maskCorners: boolean = false;
  @Input() useCornerRadius: boolean = true;
  @Input() fitFill: boolean = true;
  @Input() fitClamp: boolean = false;
  @Input() fitCrop: boolean = false;
  @Input() cropFaces: boolean = false;
  @Input() lazy: boolean = false;
  @Input() fillColor: string;

  protected _optimizedImageUrl: string;

  showPrimaryAffiliationOverlay: boolean = false;
  placeholderEntity: Entity;
  cornerRadius: number = 18;
  lastModTimestamp: number;
  style: any;
  sharp: number = 0;

  overlaySize: number = 28;
  styleOverlay: any;
  overlayCornerRadius: number = 6;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.fitCrop || this.fitClamp) {
      this.fitFill = false;
    }
    this.setup();
  }

  setup() {
    this.placeholderEntity = this.entity;
    if (this.size <= 36) {
      this.sharp = 50;
    }

    this.cornerRadius = this.useCornerRadius ? this.imgixSize / 2 : 0;
    this.lastModTimestamp = this.lastMod ? this.lastMod : this.placeholderEntity?.lastMod;
    if (this.fitFill) {
      this.style = this.domSanitizer.bypassSecurityTrustStyle(
        'flex: 0 0 ' + this.size + 'px; width: ' + this.size + 'px; height: ' + this.size + 'px;'
      );
    }
    else {
      this.style = this.domSanitizer.bypassSecurityTrustStyle(
       'max-width: ' + this.size + 'px; max-height: ' + this.size + 'px;'
      );
    }
    this.overlayCornerRadius = this.useCornerRadius ? this.overlaySize / 2 : 0;
    this.styleOverlay = this.domSanitizer.bypassSecurityTrustStyle(
      'flex: 0 0 ' + this.overlaySize + 'px; width: ' + this.overlaySize + 'px; height: ' + this.overlaySize + 'px;'
    );
  }

  get optimizedImageUrl() {
    if (this._optimizedImageUrl) {
      return this._optimizedImageUrl;
    }

    let url = `${this.imageUrl}?`;

    // Enhance auto can cause a bleeding effect on league and team avatars
    if (this.fitCrop) {
      url += 'auto=enhance';
    }

    if (this.fillColor) {
      url += '&bg=' + this.fillColor;
    } else {
      url += '&bg=0FFF';
    }

    if (this.fitClamp) {
      url += '&fit=clamp';
    } else if (this.fitFill) {
      url += '&fit=fill';
    } else if (this.fitCrop) {
      url += '&fit=crop';
    }

    if (this.cropFaces) {
      url += '&crop=faces'
    }

    if (this.maskCorners) {
      url += '&mask=corners';
    }

    this._optimizedImageUrl = url;

    return url;
  }
}
