import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'unitFormat'
})
export class UnitFormatPipe implements PipeTransform {

  constructor() {}

  convert_to_height(measurement: number, decimalPlaces: number, unit: string) {
    if (measurement > 0) {
      if (unit === 'feet') {
        const inches = measurement % 12;
        const feet = Math.floor(measurement / 12);
        return `${feet}' ${inches.toFixed(decimalPlaces)}"`;
      } else {
        const measurement_string = measurement.toFixed(decimalPlaces);
        return `${measurement_string}${unit}`;
      }
    } else if (measurement === 0) {
      return measurement.toFixed(decimalPlaces);
    } else {
      return '-';
    }
  }

  public transform(measurement: number, decimalPlaces: number, unit: string, metric?: string): string {
    if (metric === 'a-score') {
      return '';
    }
    if (measurement > 0) {  // Used in new physical overview
      if (metric !== undefined) {
        const metric_lower = metric.toLowerCase();
        if (metric_lower === 'body fat (skinfold)' || metric_lower === 'body fat (dexa)') {
          return measurement.toFixed(1) + '%';
        } else if (metric_lower === 'height w/o shoes' || metric_lower === 'reach' || metric_lower === 'wingspan') {
          return this.convert_to_height(measurement, 1, 'feet');
        } else if (metric_lower === 'weight') {
          return measurement.toFixed(0) + ' lbs';
        } else if (metric_lower === 'lane agility' || metric_lower === 'short agility' || metric_lower === '3/4 court sprint') {
          return measurement.toFixed(1) + 's';
        } else if (metric_lower === 'reactivity') {
          return measurement.toFixed(0);
        } else if (metric_lower === 'vert jump ht' || metric_lower === 'appr jump tch ht') {
          return measurement.toFixed(1) + '"';
        } else {
          return '-';
        }
      } else {
        return this.convert_to_height(measurement, decimalPlaces, unit);
      }
    } else {
      return this.convert_to_height(measurement, decimalPlaces, unit);
    }
  }
}
