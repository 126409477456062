<mat-form-field>
  <mat-select [(ngModel)]="displayedCourtCam" [ngModelOptions]="{standalone: true}" (selectionChange)="updatePlayer()">
    <ng-container *ngFor="let courtCam of courtCams">
      <mat-option [value]="courtCam">
        {{ courtCam.label }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>

<div class="court-cams">
  <video #courtCam class="player" autoplay></video>

  <!-- <ng-container *ngFor="let courtCamNumber of courtCams">
    <div class="court-cam">
      <img [src]="'http://court' + courtCamNumber + '.thunder-nba.net/cgi-bin/mjpeg?resolution=640x360&framerate=30&quality=1'">
    </div>
  </ng-container> -->
</div>
