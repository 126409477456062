import {forwardRef} from '@angular/core';
import {plainToClass, Type} from 'class-transformer';

import {Entity} from '@models/entities';
import {UserGroup} from '@models/user-groups';
import {User} from '@models/users';

export const DISTRIBUTION_IDS = {
  PUBLIC: 1200,
  PRIVATE: 1201,
  CUSTOM: 1202,
  CUSTOM_OVERRIDE: 1203,
  PUBLIC_OVERRIDE: 1204
};

export class Distribution {
  id: number;
  @Type(forwardRef(() => User) as any)
  users: Entity[] = [];
  @Type(forwardRef(() => UserGroup) as any)
  userGroups: UserGroup[] = [];

  eq(obj: Distribution): boolean {
    return this.id === obj.id;
  }

  get name(): string {
    switch (this.id) {
      case DISTRIBUTION_IDS.PUBLIC:
        return 'Standard Access';
      case DISTRIBUTION_IDS.PRIVATE:
        return 'Only Me';
      case DISTRIBUTION_IDS.CUSTOM:
        return 'Limited Access';
      case DISTRIBUTION_IDS.CUSTOM_OVERRIDE:
        return 'Limited Access+';
      case DISTRIBUTION_IDS.PUBLIC_OVERRIDE:
        return 'Standard Access+';
      default:
        return 'Distribution';
    }
  }
}

// TODO Since we're moving to having users/userGroups on the Distribution object, the old constants way won't continue
// to work well as it has in the past beccause of the two fields (user/userGroups) that change per piece of content
// these are still here until we move Posts and anything else that uses Distribution to the same format as FocusGroups
export const DISTRIBUTIONS = {};
DISTRIBUTIONS[DISTRIBUTION_IDS.PUBLIC] = plainToClass(Distribution,
  {id: DISTRIBUTION_IDS.PUBLIC, name: 'Standard Access'});
DISTRIBUTIONS[DISTRIBUTION_IDS.PRIVATE] = plainToClass(Distribution,
  {id: DISTRIBUTION_IDS.PRIVATE, name: 'Only Me'});
DISTRIBUTIONS[DISTRIBUTION_IDS.CUSTOM] = plainToClass(Distribution,
  {id: DISTRIBUTION_IDS.CUSTOM, name: 'Limited Access'});
DISTRIBUTIONS[DISTRIBUTION_IDS.CUSTOM_OVERRIDE] = plainToClass(Distribution,
  {id: DISTRIBUTION_IDS.CUSTOM_OVERRIDE, name: 'Limited Access+'});
DISTRIBUTIONS[DISTRIBUTION_IDS.PUBLIC_OVERRIDE] = plainToClass(Distribution,
  {id: DISTRIBUTION_IDS.PUBLIC_OVERRIDE, name: 'Standard Access+'});
