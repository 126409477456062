import {Entity} from '@models/entities';
import {Expose, Type, Exclude} from 'class-transformer';

import {Person} from '@models/people';
import {PlayerContract} from '@models/player-contract';
import {PostsSummary} from '@models/posts-summary';
import {EventSummary} from '@models/event-summary';
import {forwardRef} from '@angular/core';
import {LOC_RATING_POINTS} from './locs';

import * as _ from 'lodash';

export enum NameDisplayTypes {
  ABBREVIATED = 'Abbreviated',
  FULL = 'Full Name'
}

export class Position {
  id: number;
  name: string;
}

export class LOCSSlice {
  calculatedHigh: number;
  calculatedMid: number;
  calculatedLow: number;
  calculatedNow: number;

  get lowLabel(): string {
    return LOC_RATING_POINTS[Math.round(this.calculatedLow)];
  }
  get midLabel(): string {
    return LOC_RATING_POINTS[Math.round(this.calculatedMid)];
  }
  get highLabel(): string {
    return LOC_RATING_POINTS[Math.round(this.calculatedHigh)];
  }
  get nowLabel(): string {
    return LOC_RATING_POINTS[Math.round(this.calculatedNow)];
  }
}

export class LOCSSuperscout {
  usingRealPick: boolean;
  draftPick: number;
  mockPick: number;
  model: string;
  probML: number;
  probRO: number;
  probRE: number;
  probKR: number;
  probS: number;
  probTS: number;
  probC: number;
  probF: number;
  fiv: number;

  get probMinML(): number {
    return Math.min(0.999,
      this.probML + this.probRO + this.probRE + this.probKR +
      this.probS + this.probTS + this.probC + this.probF);
  }

  get probMinRO(): number {
    return Math.min(0.999,
      this.probRO + this.probRE + this.probKR + this.probS +
      this.probTS + this.probC + this.probF);
  }

  get probMinRE(): number {
    return Math.min(0.999,
      this.probRE + this.probKR + this.probS + this.probTS +
      this.probC + this.probF);
  }

  get probMinKR(): number {
    return Math.min(0.999,
      this.probKR + this.probS + this.probTS + this.probC +
      this.probF);
  }

  get probMinS(): number {
    return Math.min(0.999,
      this.probS + this.probTS + this.probC + this.probF);
  }

  get probMinTS(): number {
    return Math.min(0.999,
      this.probTS + this.probC + this.probF);
  }

  get probMinC(): number {
    return Math.min(0.999, this.probC + this.probF);
  }

  get probMinF(): number {
    return Math.min(0.999, this.probF);
  }

  get bullValue(): number {
    return 1*this.probML + 2*this.probRO + 3*this.probRE + 4*this.probKR +
           5*this.probS + 6*this.probTS + 7*this.probC + 8*this.probF;
  }

  get bullLabel(): string {
    return LOC_RATING_POINTS[Math.round(this.bullValue)];
  }

  get tooltip(): string {
    if (this.usingRealPick) {
      return 'Draft Pick: ' + this.draftPick;
    }
    else if (this.model.includes('Pro')) {
      return 'Est. Draft Pick: ' + this.draftPick;
    }
    else {
      return 'Mock Pick: ' + (Math.round(this.mockPick * 10)/10);
    }
  }

  pillFillColor(prob): string {
    if (prob <= 0.05) {
      return '#F9FDEB' + Math.round((prob/0.05/2 + 0.5)*255).toString(16);
    }
    else if (prob <= 0.5) {
      return '#9BD6AC' + Math.round(((prob-0.05)/0.45/2 + 0.5)*255).toString(16);
    }
    else {
      return '#35B0AB' + Math.round(((prob-0.5)/0.5/2 + 0.5)*255).toString(16);
    }
  }

  pillTextColor(prob): string {
    return '#000000';
  }
}

export class LOCSDraftModel {
  probML: number;
  probRO: number;
  probRE: number;
  probKR: number;
  probS: number;
  probTS: number;
  probC: number;
  probF: number;

  get probMinML(): number {
    return Math.min(0.999,
      this.probML + this.probRO + this.probRE + this.probKR +
      this.probS + this.probTS + this.probC + this.probF);
  }

  get probMinRO(): number {
    return Math.min(0.999,
      this.probRO + this.probRE + this.probKR + this.probS +
      this.probTS + this.probC + this.probF);
  }

  get probMinRE(): number {
    return Math.min(0.999,
      this.probRE + this.probKR + this.probS + this.probTS +
      this.probC + this.probF);
  }

  get probMinKR(): number {
    return Math.min(0.999,
      this.probKR + this.probS + this.probTS + this.probC +
      this.probF);
  }

  get probMinS(): number {
    return Math.min(0.999,
      this.probS + this.probTS + this.probC + this.probF);
  }

  get probMinTS(): number {
    return Math.min(0.999,
      this.probTS + this.probC + this.probF);
  }

  get probMinC(): number {
    return Math.min(0.999, this.probC + this.probF);
  }

  get probMinF(): number {
    return Math.min(0.999, this.probF);
  }

  get bullValue(): number {
    return 1*this.probML + 2*this.probRO + 3*this.probRE + 4*this.probKR +
           5*this.probS + 6*this.probTS + 7*this.probC + 8*this.probF;
  }

  get bullLabel(): string {
    return LOC_RATING_POINTS[Math.round(this.bullValue)];
  }

  pillFillColor(prob): string {
    if (prob <= 0.05) {
      return '#F9FDEB' + Math.round((prob/0.05/2 + 0.5)*255).toString(16);
    }
    else if (prob <= 0.5) {
      return '#9BD6AC' + Math.round(((prob-0.05)/0.45/2 + 0.5)*255).toString(16);
    }
    else {
      return '#35B0AB' + Math.round(((prob-0.5)/0.5/2 + 0.5)*255).toString(16);
    }
  }

  pillTextColor(prob): string {
    return '#000000';
  }
}

export class TalentInfo {
  functions: any;
  hirePotential: string;
  hireInterest: string;
  role: string;

  get functionsString(): string {
    return _.join(this.functions.map(t => t.name), ', ');
  }
}

class RankedPersonEvaluations {
  @Type(forwardRef(() => LOCSDraftModel) as any)
  draftModel: LOCSDraftModel;
  locs: any;
  locsPast: any;
  locsProbabilities: any;
  ranks: any;
  ranksPast: any;
  reservePrices: any;
  superscout: any;
  tsa: any;
}

export class RankedPerson {
  id: number;
  rank: number;
  displayRank: number;
  positionRank: number;
  @Type(forwardRef(() => Person) as any)
  agent: Person;
  MRS: number;
  @Type(forwardRef(() => LOCSSlice) as any)
  locsSlice: LOCSSlice;
  @Type(forwardRef(() => LOCSSuperscout) as any)
  locsSuperscout: LOCSSuperscout;
  // @ts-ignore
  @Type(forwardRef(() => Position) as any)
  position: Position;
  age: number;
  @Type(forwardRef(() => Entity) as any)
  affiliation: Entity;
  userID: number;
  name: string;
  lastName: string;
  firstNameInitial: string;
  type: string;
  imageType: string;
  imageUrl: string;
  careerStage: string;
  yearsOfService: number;
  groupingName: string;
  @Type(forwardRef(() => PlayerContract) as any)
  contract: PlayerContract;
  @Type(forwardRef(() => PostsSummary) as any)
  evalPostSummary: PostsSummary;
  @Type(forwardRef(() => PostsSummary) as any)
  intelPostSummary: PostsSummary;
  @Type(forwardRef(() => RankedPersonEvaluations) as any)
  evaluations: RankedPersonEvaluations;
  isSelected: boolean;
  isHidden: boolean;
  lastUpdated: moment.Moment;
  @Type(forwardRef(() => TalentInfo) as any)
  talent: TalentInfo;
  hasContent: boolean;
  @Type(forwardRef(() => EventSummary) as any)
  eventSummary: EventSummary;

  @Expose()
  get firstInitialLastName(): string {
    return `${this.firstNameInitial}. ${this.lastName}`;
  }

  get careerStageID(): number {
    switch (this.careerStage) {
      case 'Pre-Draft':
        return 1;
      case 'Young Pro':
        return 2;
      case 'Prime':
        return 3;
      case 'Post Prime':
        return 4;
      case 'End of Career':
        return 5;
      default:
        return 6;
    }
  }

  @Exclude()
  protected _optimizedImageUrl: string;

  get optimizedImageUrl() {
    if (this._optimizedImageUrl) {
      return this._optimizedImageUrl;
    }

    let url: string = `${this.imageUrl}?`;

    if (this.type === 'FocusGroup') {
      return null;
    } else {
      url += 'auto=enhance';

      if (this.type === 'Person') {
        url += '&fit=crop&crop=faces&mask=corners';
      } else {
        url += '&pad=2&bg=ffffff&fit=fill';
      }

      this._optimizedImageUrl = url;

      return url;
    }
  }
}
