import {plainToClass} from 'class-transformer';

import {IdName} from '@models/id-names/_id-names';

export const INCOMING_SETTING_IDS = {
  UNSPECIFIED: 0,
  INCOMING: 1,
  OUTGOING: 2,
};

export class IncomingSetting extends IdName {}

export const INCOMING_SETTINGS = {};
INCOMING_SETTINGS[INCOMING_SETTING_IDS.INCOMING] = plainToClass(IncomingSetting, {id: INCOMING_SETTING_IDS.INCOMING, name: 'Incoming'});
INCOMING_SETTINGS[INCOMING_SETTING_IDS.OUTGOING] = plainToClass(IncomingSetting, {id: INCOMING_SETTING_IDS.OUTGOING, name: 'Outgoing'});
INCOMING_SETTINGS[INCOMING_SETTING_IDS.UNSPECIFIED] = plainToClass(IncomingSetting, {id: INCOMING_SETTING_IDS.UNSPECIFIED, name: 'Unspecified'});
