import * as moment from 'moment-timezone';

import {Type, Transform} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Location} from '@models/id-names/locations';
import {Person} from '@models/people';
import {forwardRefMoment} from '@helpers/class-transformer.helper';

export class PhysicalActivity {

  @Type(forwardRef(() => Person) as any)
  about: Person;
  id: number;
  @Type(forwardRef(() => Location) as any)
  location: Location;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(), { toClassOnly: true })
  postDate: moment.Moment;
  table: string;
}
