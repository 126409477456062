import {Actions, ActionTypes} from './actions';
import {featureAdapter, initialState, State} from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LIST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        date: null,
      };
    }
    case ActionTypes.LIST_SUCCESS: {
      return featureAdapter.setAll(action.payload.posts, {
        ...state,
        isLoading: false,
        error: null,
        date: null,
      });
    }
    case ActionTypes.GET: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.GET_SUCCESS: {
      return featureAdapter.addOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    }
    case ActionTypes.SAVE: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.SAVE_SUCCESS: {
      return featureAdapter.upsertOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    }
    case ActionTypes.SAVE_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }
    case ActionTypes.DELETE: {
      return featureAdapter.removeOne(action.payload.id, {
        ...state,
        isLoading: true,
        error: null
      });
    }
    case ActionTypes.DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.DELETE_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
