import {ofType, Actions, createEffect} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {of, Observable} from 'rxjs';
import {plainToClass} from 'class-transformer';
import {switchMap, map, catchError, mergeMap, concatMap} from 'rxjs/operators';

import * as actions from './actions';

import {AnnouncementsService} from '@services/announcements.service';
import {Announcement} from '@models/moments';

@Injectable()
export class Effects {

  getAnnouncement$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GetAnnouncement>(actions.ActionTypes.GET),
    map(action => action.payload),
    switchMap(payload => this.announcementsService.getAnnouncement(payload)),
    switchMap(announcement => of(plainToClass(Announcement, announcement))),
    switchMap((announcement: any) => of(new actions.GetAnnouncementSuccess(announcement)))
  ));

  getAnnouncements$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GetAnnouncements>(actions.ActionTypes.LIST),
    switchMap(() => this.announcementsService.getAnnouncements('sitemanagement')),
    switchMap(data => of({date: data.date, posts: plainToClass(Announcement, data.posts)})),
    switchMap((announcements: any) => of(new actions.GetAnnouncementsSuccess(announcements))),
    catchError(error => {
      return of({
        type: actions.ActionTypes.LIST
      });
    })
  ));

  saveAnnouncement$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SaveAnnouncement>(actions.ActionTypes.SAVE),
    map(action => action.payload),
    concatMap(payload => this.announcementsService.saveAnnouncement(payload)),
    mergeMap(announcement => {
      announcement = plainToClass(Announcement, announcement);
      return of(new actions.SaveAnnouncementSuccess(announcement));
    }),
    catchError(error => {
      return of({
        type: actions.ActionTypes.SAVE_FAILED,
        payload: error
      });
    })
  ));

  deleteAnnouncement$ = createEffect(() => this.actions$.pipe(
    ofType<actions.DeleteAnnouncement>(actions.ActionTypes.DELETE),
    map(action => action.payload),
    mergeMap(payload => this.announcementsService.deleteAnnouncement(payload.id)),
    mergeMap(() => of(new actions.DeleteAnnouncementSuccess())),
    catchError(error => {
      return of({
        type: actions.ActionTypes.DELETE_FAILED,
        payload: error
      });
    })
  ));

  constructor(private announcementsService: AnnouncementsService, private actions$: Actions) {}
}
