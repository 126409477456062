import {Directive, Input, OnDestroy, TemplateRef, ViewContainerRef, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {untilDestroyed, UntilDestroy} from '@ngneat/until-destroy';

import {AuthService} from '@services/auth.service';
import {User} from '@models/users';

export const playerExplorerIds = [
  2, // Wynn Sullivan
  26, // Ezra Van Negri
  27, // Thomas Van de Houten
  32, // Will Burger
  107, // Boon Palipatana
  128, // Kapil Kashyap
  130, // Allen Shufer
  146, // Vince Rozman
  166, // George Brosky
  204, // Shreyas Raikhelkar
  211, // Matt Whelan
  214, // Nick Viamin
  220, // James Kasakyan
];

export const scheduleIds = [
  2, // Wynn Sullivan
  5, // Donnie Strack
  20, // Mike Wilks
  23, // Tony Katzenmeier
  27, // Thomas Van de Houten
  32, // Will Burger
  36, // AP
  38, // Blake McLean
  39, // Jenny Strickler
  40, // Kevin Hyde
  55, // Grant Gibbs
  56, // DA
  58, // Mark Daigneault
  62, // Kam Woods
  63, // Dave Bliss
  91, // Eric Maynor
  103, // Darnell Foreman
  105, // Taylor Randle
  107, // Boon Palipatana
  109, // Obi Okolie
  115, // Zoe Vernon
  120, // Connor Johnson
  121, // DeVon Walker
  128, // Kapil Kashyap
  130, // Allen Shufer
  141, // Kale-ane Igboh
  143, // Daniel Dixon
  145, // Tobi Owotomo
  147, // Chip Engelland
  163, // Ryan Greer
  164, // Dan Steinberg
  204, // Shreyas Raikhelkar
  211, // Matt Whelan
  214, // Nick Viamin
  220, // James Kasakyan
];

@UntilDestroy()
@Directive({
  selector: '[featureToggle]'
  })
export class FeatureToggleDirective implements OnInit {
  @Input() featureToggle: string;

  superUserIds = [27, 32, 107, 128, 130];
  // Bullpen User IDs
  bullpenIds = [2, 26, 27, 29, 32, 33, 93, 107, 115, 128, 130];
  draftIds = [2, 4, 7, 8, 10, 11, 12, 15, 27, 67, 107];
  draftDataManagementIds = [2, 8, 10, 15, 27, 107];
  videoTaggingIds = [
    27, // Thomas Van de Houten
    32, // Will Burger
    107, // Boon Palipatana
    115, // Zoe Vernon
    128, // Kapil Kashyap
    130, // Allen Shufer
    161, // Ryan Nguyen
    201, // Jack Walker
  ];
  courtCamIds = [
    2, // Wynn Sullivan
    8, // Jesse Gould
    26, // Ezra Van Negri
    27, // Thomas Van de Houten
    29, // James Kopanidis
    32, // Will Burger
    33, // Greg Flood
    93, // Bailey Joseph
    107, // Boon Palipatana
    115, // Zoe Vernon
    128, // Kapil Kashyap
    130, // Allen Shufer
    146, // Vince Rozman
    149, // Acie Law
    166, // George Brosky
  ];
  videoDiagnosticsIds = [
    2, // Wynn Sullivan
    8, // Jesse Gould
    27, // Thomas Van de Houten
    32, // Will Burger
    107, // Boon Palipatana
    128, // Kapil Kashyap
    130, // Allen Shufer
  ];
  cutsFilteringIds = [
    2, // Wynn Sullivan
    8, // Jesse Gould
    26, // Ezra Van Negri
    27, // Thomas Van de Houten
    29, // James Kopanidis
    33, // Greg Flood
    93, // Bailey Joseph
    107, // Boon Palipatana
    115, // Zoe Vernon
    116, // Connor Johnson
    128, // Kapil Kashyap
    130, // Allen Shufer
  ];
  visionIds = [
    2, // Wynn Sullivan
    5, // Donnie Strack
    8, // Jesse Gould
    10, // Rob Hennigan
    12, // Glenn Wong
    13, // Sam Presti
    66, // Ayana Lawson
    74, // Matt Tumbleson
    75, // Marc St Yves
    114, // Ann Smith
    146, // Vince Rozman
  ];
  newShotChartingIds = [
    27, // Thomas Van de Houten
    33, // Greg Flood
    107, // Boon Palipatana
    128, // Kapil Kashyap
    130, // Allen Shufer
    171, // Kugan Suppiah
    172, // Cecil Brown
  ];
  onOffIds = [
    2, // Wynn Sullivan
    26, // Ezra Van Negri
    27, // Thomas Van de Houten
    32, // Will Burger
    107, // Boon Palipatana
    128, // Kapil Kashyap
    130, // Allen Shufer
    204, // Shreyas Raikhelkar
  ];
  teamDiagnosticsIds = [
    32, // Will Burger
    146, // Vince Rozman
    149, // Acie Law
    166, // George Brosky
  ];
  learningCenterIds = [5, 10, 11, 13, 20, 23, 27, 32, 33, 36, 38, 39, 40, 55, 56, 58, 63, 65, 74, 85, 89, 91, 98, 100, 103, 104, 105, 109, 114, 115, 120, 121, 125, 126, 163];
  currentUser: User;

  // Default availability if not in super user list
  // Usage <ng-container *featureToggle="'HIGHLIGHTS'">...Content to hide here...</ng-container>
  features = {
    'VIDEO_TAGGING': false,
    'PLAYER_LINEUPS': false,
    'COURT_CAMS': false,
    'CUTS_FILTER': false,
    'LEARNING_CENTER': false,
    'VISION': false,
    'TEAM_DIAGNOSTICS_FILTER': false,
    'LOCS_DIST': false,
    'PLAYER_EXPLORER': false,
    'SCHEDULE': false,
  };
  bullpenFeatures = [];
  draftFeatures = [];
  draftDataManagementFeatures = [];
  videoTaggingFeatures = ['VIDEO_TAGGING'];
  courtCamFeatures = ['COURT_CAMS'];
  videoDiagnosticsFeatures = ['VIDEO_DIAGNOSTICS'];
  cutsFilteringFeatures = ['CUTS_FILTER'];
  learningCenterFeatures = ['LEARNING_CENTER'];
  newShotChartingFeatures = ['NEW_SHOT_CHARTING'];
  visionFeatures = ['VISION'];
  onOffFeatures = ['ON-OFF'];
  teamDiagnosticsFeatures = ['TEAM_DIAGNOSTICS_FILTER'];
  locsDistFeatures = ['LOCS_DIST'];
  playerExplorerFeatures = ['PLAYER_EXPLORER'];
  scheduleFeatures = ['SCHEDULE'];

  constructor(public authService: AuthService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.authService.currentUserData.pipe(take(1), untilDestroyed(this)).subscribe((result: any) => {
      this.currentUser = result;
    });
    if (this.isEnabled()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  isEnabled() {
    if (window.location.hostname !== 'localhost') {
      return true;
    } else if (this.superUserIds.includes(this.currentUser.id) ||
      (this.featureToggle == null) ||
      (this.bullpenIds.includes(this.currentUser.id) && this.bullpenFeatures.includes(this.featureToggle)) ||
      (this.draftIds.includes(this.currentUser.id) && this.draftFeatures.includes(this.featureToggle)) ||
      (this.draftDataManagementIds.includes(this.currentUser.id) && this.draftDataManagementFeatures.includes(this.featureToggle)) ||
      (this.videoTaggingIds.includes(this.currentUser.id) && this.videoTaggingFeatures.includes(this.featureToggle)) ||
      (this.courtCamIds.includes(this.currentUser.id) && this.courtCamFeatures.includes(this.featureToggle)) ||
      (this.videoDiagnosticsIds.includes(this.currentUser.id) && this.videoDiagnosticsFeatures.includes(this.featureToggle)) ||
      (this.cutsFilteringIds.includes(this.currentUser.id) && this.cutsFilteringFeatures.includes(this.featureToggle)) ||
      (this.visionIds.includes(this.currentUser.id) && this.visionFeatures.includes(this.featureToggle)) ||
      (this.learningCenterIds.includes(this.currentUser.id) && this.learningCenterFeatures.includes(this.featureToggle)) ||
      (this.newShotChartingIds.includes(this.currentUser.id) && this.newShotChartingFeatures.includes(this.featureToggle)) ||
      (this.onOffIds.includes(this.currentUser.id) && this.onOffFeatures.includes(this.featureToggle)) ||
      (this.teamDiagnosticsIds.includes(this.currentUser.id) && this.teamDiagnosticsFeatures.includes(this.featureToggle)) ||
      (this.superUserIds.includes(this.currentUser.id) && this.locsDistFeatures.includes(this.featureToggle)) ||
      (playerExplorerIds.includes(this.currentUser.id) && this.playerExplorerFeatures.includes(this.featureToggle)) ||
      (scheduleIds.includes(this.currentUser.id) && this.scheduleFeatures.includes(this.featureToggle))) {
      return true;
    } else {
      return this.features[this.featureToggle];
    }
  }
}
