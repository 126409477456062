import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {untilDestroyed, UntilDestroy} from '@ngneat/until-destroy';
import {VideoService} from '@services/video.service';

@UntilDestroy()
@Component({
  selector: 'bild-video-diagnostics',
  templateUrl: './video-diagnostics.component.html',
  styleUrls: ['./video-diagnostics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class VideoDiagnosticsComponent implements OnInit, OnDestroy {
  isMobile: boolean;
  teamsData: any[] = [];

  constructor(
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected router: Router,
    protected title: Title,
    protected videoService: VideoService
  ) { }

  ngOnInit() {
    this.title.setTitle('Usage');

    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe(result => {
      this.isMobile = result.matches;
    });

    this.videoService.getVideoDiagnosticsData().pipe(untilDestroyed(this)).subscribe(
      data => {
        console.log(data);
        this.teamsData = data;
        this.updateComputedAttributes();
        this.cdr.markForCheck();
      },
      error => {
        console.log(error);
      }
    );
  }

  updateComputedAttributes() {
    this.teamsData.forEach(team => {
      let coveredMinutes = 0;
      let totalMinutes = 0;
      let coveredPeriods = 0;
      let totalPeriods = 0;
      let coveredGames = 0;
      let totalGames = 0;

      let teamGameTeams = team.games.map(teamGame => teamGame.home_team).concat(team.games.map(teamGame => teamGame.away_team))
      team.team = teamGameTeams.sort((a,b) => teamGameTeams.filter(v => v===a).length - teamGameTeams.filter(v => v===b).length).pop();

      team.maxGamePeriods = Math.max(...team.games.map(teamGame => teamGame.half));
      team.games.forEach(teamGame => {
        let coveredGameMinutes = 0;
        let totalGameMinutes = 0;
        for (let i = 0; i < teamGame.half; i++) {
          if (i < 2) {
            totalMinutes += 1200
            totalGameMinutes += 1200;
          }
          else {
            totalMinutes += 300;
            totalGameMinutes += 300;
          }
          totalPeriods += 1;
          totalGames += 1;
          if (teamGame.periods[i] && teamGame.periods[i].reliability != 'Poor') {
            coveredMinutes += teamGame.periods[i].first_time;
            coveredGameMinutes += teamGame.periods[i].first_time;
            coveredPeriods += 1;
          }
          if (teamGame.periods.filter(period => period.reliability != 'Poor').length > 0) {
            coveredGames += 1;
          }
        }
        teamGame.coveredGameMinutes = coveredGameMinutes;
        teamGame.totalGameMinutes = totalGameMinutes;
        teamGame.minutesCoverage = coveredGameMinutes / totalGameMinutes;
      });
      team.coveredMinutes = coveredMinutes;
      team.totalMinutes = totalMinutes;
      team.minutesCoverage = coveredMinutes / totalMinutes;
      team.coveredPeriods = coveredPeriods;
      team.totalPeriods = totalPeriods;
      team.periodsCoverage = coveredPeriods / totalPeriods;
      team.coveredGames = coveredGames;
      team.totalGames = totalGames;
      team.gamesCoverage = coveredGames / totalGames;
    });
  }

  getGamePeriod(availablePeriods, period) {
    return availablePeriods.find(availablePeriod => availablePeriod.period == period);
  }

  ngOnDestroy() {
  }
}
