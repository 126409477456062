import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {untilDestroyed, UntilDestroy} from '@ngneat/until-destroy';
import {isMoment} from 'moment';
import {VideoService} from '@services/video.service';
import * as _ from 'lodash';
import {getVideoFilterItems} from '@models/constants/video/video-filter-items';
import {videoSubjectToFilterName, videoSubjectToLabel } from '@models/constants/video/video-subjects';

@UntilDestroy()
@Component({
  selector: 'video-filter-panel',
  templateUrl: './video-filter-panel.component.html',
  styleUrls: ['./video-filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  })
export class VideoFilterPanelComponent implements OnInit, OnDestroy {
  @Input('filters') set filters(val) {
    this._filters = val;
    this.updateFilterItems();
  }
  get filters() {
    return this._filters;
  }
  @Input('isGeneratePlaylist') isGeneratePlaylist;
  @Input('isRecentGeneratedPlaylist') isRecentGeneratedPlaylist;
  @Input('filtersData') set filtersData(val) {
    if (!this.filtersData && (val?.filters?.length > 4 || this.isGeneratePlaylist)) {
      this.isCollapsed = true;
    }
    this._filtersData = val;
    if (val?.filters?.length) {
      this.populateDescriptorFields();
      this.updateFilterItems();
    }
  };
  get filtersData() {
    return this._filtersData;
  }
  excludedDescriptorFields = [];
  includedDescriptorFields = [];
  isCollapsed: boolean = false;
  isMobile: boolean;
  videoFilterItems = [];
  filtersCount = 0;
  _filters;
  _filtersData;

  readonly isMoment = isMoment;
  protected readonly videoSubjectToLabel = videoSubjectToLabel;
  protected readonly videoSubjectToFilterName = videoSubjectToFilterName;
  readonly potentiallyNestedPositions = {
    'offensiveLineupEntities':'nba_offensive_player_ids',
    'defensiveLineupEntities':'nba_defensive_player_ids'
  };

  constructor(
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected videoService: VideoService,
  ) {
  }

  ngOnInit() {
    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small,
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe((result) => {
      this.isMobile = result.matches;
    });

    this.videoService.activeFilterSubject.pipe(untilDestroyed(this)).subscribe((activeFilter) => {
      if (activeFilter) {
        this.isCollapsed = true;
      }
    });

    this.videoService.hideFilterPanelSubject.pipe(untilDestroyed(this)).subscribe(() => {
      this.hideFilterPanel();
    });
  }

  updateFilterItems() {
    if (this.filters && this.filtersData) {
      this.videoFilterItems = getVideoFilterItems(this.filtersData, this.filters, this.isRecentGeneratedPlaylist, this.includedDescriptorFields, this.excludedDescriptorFields);
      this.filtersCount = _.filter(this.videoFilterItems, (item) => item.conditional != null && item.conditional !== false).length;
      this.cdr.markForCheck();
    }
  }

  populateDescriptorFields() {
    this.includedDescriptorFields = [];
    this.excludedDescriptorFields = [];

    const includedDescriptorFilters = _.filter(this.filtersData.filters, (filt) => filt.name === 'descriptors' && !filt.not);
    const excludedDescriptorFilters = _.filter(this.filtersData.filters, (filt) => filt.name === 'descriptors' && filt.not);

    const includedDescriptorNames = _.uniq(_.flatMap(includedDescriptorFilters, 'args'));
    const excludedDescriptorNames = _.uniq(_.flatMap(excludedDescriptorFilters, 'args'));

    if (includedDescriptorNames?.length) {
      this.includedDescriptorFields = includedDescriptorNames.map((field) => this.synergyNameFormat(field));
    } else {
      this.includedDescriptorFields = [];
    }
    if (excludedDescriptorNames?.length) {
      this.excludedDescriptorFields = excludedDescriptorNames.map((field) => this.synergyNameFormat(field));
    } else {
      this.excludedDescriptorFields = [];
    }
    this.cdr.markForCheck();
  }

  hideFilterPanel() {
    if (this.isGeneratePlaylist) {
      this.isCollapsed = true;
      this.cdr.markForCheck();
    }
  }

  synergyNameFormat = (field) => {
    field = field.split(/(?=[A-Z])/).join(' ');
    field = field.replace('the', ' the');
    field = field.replace('and', '&');
    // Hand is the only instance where we don't want to replace "and" with "&"
    field = field.replace('H&', 'Hand');
    field = field.replace('h&', 'hand');
    field = field.replace('_', ' ');
    return field;
  }

  adjustedItemName(item, filter): string {
    let suffix = '';
    const nestedPositionKey = this.potentiallyNestedPositions[filter.filterName];
    if (nestedPositionKey) {
      const personIndex = this.filtersData.personLookup.findIndex(person => person.entity.id === item.id);
      if (personIndex !== -1) {
        const curNBAID = this.filtersData.personLookup[personIndex]?.nba_ids[0];
        const filterIndex = this.filtersData.filters.findIndex(f => 
          f.name === nestedPositionKey && f.args[0][0] === curNBAID
        );
        if (filterIndex !== -1 && this.filtersData.filters[filterIndex]?.args[1]) {
          const posIndex = this.filtersData.filters[filterIndex].args[1][0];
          if (posIndex !== -1) {
            suffix = this.getPositionFromPosNum(posIndex);
          }
        }
      }
    }
    return item.name + suffix;
  }  

  getPositionFromPosNum(posIndex: number): string {
    switch (posIndex) {
      case 1:
        return ' (PG)';
      case 2:
        return ' (SG)';
      case 3:
        return ' (SF)';
      case 4:
        return ' (PF)';
      case 5:
        return ' (C)';
    }
  }

  ngOnDestroy() {
  }
}
