import {Injectable} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {FormGroup, FormControl} from '@angular/forms';


@Injectable()
export class MaterialHelper {
  constructor() {

  }

  dataSourceSort(sort: MatSort, data: any[]): any[] {
    let sortedData: any[];

    if (!sort.active || sort.direction == '') {
      sortedData = data;
    } else {
      sortedData = data.sort((a, b) => {
        const isAsc = sort.direction == 'asc';
        switch (true) {
          case typeof a[sort.active] === 'number':
            return this._compare(+a[sort.active], +b[sort.active], isAsc);
          default:
            return this._compare(a[sort.active], b[sort.active], isAsc);
        }
      });
    }

    return sortedData;
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).map(x => formGroup.controls[x]).forEach((control: FormControl) => {
      control.markAsTouched();

      if (control['controls']) {
        control['controls'].forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

  protected _compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
