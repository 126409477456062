import * as moment from 'moment-timezone';

import {Type, Expose, Transform} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Person} from '@models/people';
import {PlayerDevelopmentKeyResultLog} from '@models/player-development-key-result-logs';
import {User} from '@models/users';
import {SkillDetail} from '@models/player-development-skill-assessments';
import {forwardRefMoment} from '@helpers/class-transformer.helper';

class AggregateLogs {
  @Type(() => PlayerDevelopmentKeyResultLog)
  lastFour: PlayerDevelopmentKeyResultLog[];
  @Type(() => PlayerDevelopmentKeyResultLog)
  all: PlayerDevelopmentKeyResultLog;
  @Type(() => PlayerDevelopmentKeyResultLog)
  weekly: PlayerDevelopmentKeyResultLog[];
}

export class Week {
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  startDate: moment.Moment;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  endDate: moment.Moment;
}

export class PlayerDevelopmentKeyResult {
  id: number;
  objectiveID: number;
  @Type(forwardRef(() => User) as any)
  author: User;
  @Type(forwardRef(() => Person) as any)
  about: Person;
  abbrevHeader: string;
  description: string;
  name: string;
  isAchieved: boolean;
  isCurrent = true;
  isActive = true;
  ratioDecimalPlaces: number;
  lastLogNumber: number;
  isRatioDisplayAsPercent: boolean;
  isRatioZeroToOne: boolean;
  isRatioDisplayLeadingZero: boolean;
  @Type(forwardRef(() => PlayerDevelopmentKeyResultLog) as any)
  logs: PlayerDevelopmentKeyResultLog[] = [];
  directionality: string;
  numerator: string;
  denominator: string;
  category: string;
  ratio: number;
  recentChange: number;
  targetDescription: string;
  yAxisMax: number;
  yAxisMin: number;
  YTDChange: number;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  startDate: moment.Moment;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  weeksStartDate: moment.Moment;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  endDate: moment.Moment;
  @Type(() => AggregateLogs)
  aggregateLogs: AggregateLogs;

  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(), {toClassOnly: true})
  createDatetime: moment.Moment;
  @Type(() => Week)
  weeks: Week[];
  skills: SkillDetail[];

  feedbackChannel: string;
  get shortName(): string {
    return this.abbrevHeader;
  }

  protected _targetNumber: number;
  protected _targetRatio: number;

  @Expose()
  get type(): string {
    switch (true) {
      case this.targetNumber != null:
        return 'number';
      case this.targetRatio != null:
        return 'ratio';
      default:
        return null;
    }
  }

  @Expose()
  get targetNumber(): number {
    return this._targetNumber;
  }

  set targetNumber(val: number) {
    // Clear the others
    if (val != null) {
      this._targetRatio = null;

      this._targetNumber = val;
    }
  }

  @Expose()
  get targetRatio(): number {
    return this._targetRatio;
  }

  set targetRatio(val: number) {
    // Clear the others
    if (val != null) {
      this._targetNumber = null;

      this._targetRatio = val;
    }
  }

  get isHistorical(): boolean {
    return this.endDate != null;
  }
}

export const numeratorSuggestions: string[] = ['FG', 'FT', 'SUC', 'NP2A'];
export const denominatorSuggestions: string[] = ['ATT', 'FGA', 'FTA'];
