import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[virtCell]',
})
export class VirtualCellDirective {

  constructor(
    private elementRef: ElementRef,
  ) {}

  @Input() set virtCell(column: string) {
    if (!this.elementRef.nativeElement.classList.contains('table-cell')) {
      this.elementRef.nativeElement.classList.add('table-cell');
      this.elementRef.nativeElement.classList.add('table-column-' + column);
    }
  }
}
