<mat-dialog-content class="search-dialog">
  <div class="search-container" [ngClass]="{'has-results': searchResults?.length}">
    <mat-icon class="search-icon">search</mat-icon>
    <search-input #searchInput class="search-input"
                  [debounce]="debounce"
                  [showUnderline]="showUnderline"
                  [placeholder]="placeholder"
                  (searchDebounce)="search($event)">
    </search-input>
  </div>
  <div class="search-results">
    <ng-container *ngFor="let result of searchResults; let i = index">
      <entity-context [ngClass]="{'active': i === activeIndex, 'no-shortcuts': isContractApp}" #entityContext
                      [entity]="result.isTag ? null : result"
                      [tag]="!result.isTag ? null : result"
                      [isSearch]="true"
                      [app]="isContractApp ? 'contract' : 'bild'"
                      [showShortcuts]="true"
                      (click)="handleOptionClicked(i, $event)"
                      (mouseenter)="activeIndex = i"
                      (subrouteSelected)="handleSubrouteSelected($event)"
      ></entity-context>
    </ng-container>
  </div>
</mat-dialog-content>
