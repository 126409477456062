import {
  ChangeDetectorRef,
  Component, ElementRef, EventEmitter, Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {DOMHelper} from '@helpers/dom.helper';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SearchBoxComponent} from '../../search-box/search-box.component';
import {EntityNavTabService} from '@services/entity-nav-menu.service';
import {NgxPermissionsObject, NgxPermissionsService} from 'ngx-permissions';
import {Entity} from '@models/entities';
import {Subscription} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {PostComposerService} from '../../post/post-composer/post-composer.service';
import {AuthService} from '@services/auth.service';
import {AutocompleteService} from '@services/autocomplete.service';
import {NotificationsService} from '@services/notifications.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import {take} from 'rxjs/operators';
import {parseURL, URL} from 'universal-parse-url';
import {PostHelper} from '@helpers/post.helper';
import {APPS_CONFIG} from '../../../../apps-config.constants';
import {IAppsConfig} from '../../../../apps-config.interface';
import {MatMenuTrigger} from "@angular/material/menu";
import {MatDialog} from '@angular/material/dialog';
import {BrowseVideoComponent} from 'apps/bild/video/browse-video/browse-video.component';
import {VideoRootComponent} from 'apps/bild/video/video-root.component';
import {RootStoreState} from 'apps/_store';
import {Store, select} from '@ngrx/store';
import * as actions from '@store/video-store/actions';
import {VideoNavigationTypes, VideoTabTypes} from '@models/video';
import {VideoService} from '@services/video.service';
import {VideoStoreSelectors} from '@store/video-store';

@UntilDestroy()
@Component({
  selector: 'base-menu',
  templateUrl: './base-menu.component.html',
  styleUrls: ['../nav-menu.component.scss', './base-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  })
export class BaseMenuComponent implements OnInit {
  @ViewChild('desktopSearch') desktopSearch: SearchBoxComponent;
  @ViewChild('mobileSearch') mobileSearch: SearchBoxComponent;
  @ViewChild('notificationsMenuTrigger') notificationsMenuTrigger: MatMenuTrigger;

  currentUser: any;
  isSearching: boolean = false;
  notificationCount: number = 0;
  perms: NgxPermissionsObject;
  searchResults: Entity[];
  searchResults$: Subscription;
  showSiteManagement: boolean = false;
  showDataEntry: boolean = false;
  siteManagementRoute: string = '';
  typingDebounceTime: number = environment.typingDebounceTime;

  @Input() navTabs;
  @Input() isPerson: boolean;
  @Input() isContractApp: boolean = false;
  @Input() isTeam: boolean;
  @Input() entity: any;
  @Input() scenarioList: any;
  @Input() selectedScenarioID: number;
  @Input() preventHotkey: boolean;
  @Input() showPlaylistMenu: boolean;
  @Input() tag: any;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() scenarioChange: EventEmitter<number> = new EventEmitter();


  get defaultTabs() {
    return this.navTabs?.filter((tab) => !tab.shouldHide);
  }
  get hiddenTabs() {
    return this.navTabs?.filter((tab) => tab.shouldHide);
  }

  constructor(
    @Inject(APPS_CONFIG) public config: IAppsConfig,
    public domHelper: DOMHelper,
    public postComposerService: PostComposerService,
    protected activatedRoute: ActivatedRoute,
    protected authService: AuthService,
    protected autocompleteService: AutocompleteService,
    protected cdr: ChangeDetectorRef,
    protected elementRef: ElementRef,
    protected notificationsService: NotificationsService,
    protected postHelper: PostHelper,
    protected matDialog: MatDialog,
    protected router: Router,
    protected store$: Store<RootStoreState.State>,
    protected permissionsService: NgxPermissionsService,
    protected entityNavTabService: EntityNavTabService,
    protected videoService: VideoService
  ) { }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.cdr.markForCheck();
      }
    });

    this.authService.currentUserData.pipe(untilDestroyed(this)).subscribe((result: any[]) => {
      this.currentUser = result;
      this.cdr.markForCheck();
    });

    this.perms = this.permissionsService.getPermissions();
    this.setShowSiteManagement();

    if (!this.isContractApp) {
      this.notificationsService.counter.pipe(untilDestroyed(this)).subscribe((count) => {
        this.notificationCount = count;
        this.cdr.markForCheck();
      });
    }

    this.store$.pipe(
      select(VideoStoreSelectors.selectSession),
      take(1),
      untilDestroyed(this)
    ).subscribe(session => {
      this.setVideoTabFromNavigation(session.currentNavigation);
    });

    this.videoService.selectedTabSubject.pipe(
      untilDestroyed(this),
    ).subscribe((currentTab) => {
      this.navTabs?.forEach(navTab => {
        if (navTab.isVideoTab && navTab.name != currentTab) {
          navTab.isActive = false;
        }
        if (navTab.isVideoTab && navTab.name == currentTab) {
          navTab.isActive = true;
        }
      })
    });
  }

  setShowSiteManagement(): void {
    if (this.perms.Admin) {
      this.showSiteManagement = true;
      this.siteManagementRoute = 'users';
    } else if (this.perms.editAllEntityCreation) {
      this.showSiteManagement = true;
      this.siteManagementRoute = 'entities';
    } else if (this.perms.viewAllDataQuality) {
      this.showSiteManagement = true;
      this.siteManagementRoute = 'data-quality';
    } else if (this.perms.editAllAnnouncements) {
      this.showSiteManagement = true;
      this.siteManagementRoute = 'moments';
    } else if (this.perms.viewAllShotCharting) {
      this.showDataEntry = true;
      this.siteManagementRoute = '';
    } else if (this.perms.editAllTagManagement) {
      this.showSiteManagement = true;
      this.siteManagementRoute = 'tags';
    } else {
      this.showDataEntry = false;
      this.showSiteManagement = false;
      this.siteManagementRoute = '';
    }
  }

  handleLogOut(): void {
    localStorage.setItem('logoutMethod 6', new Date().toString());
    console.log('6: Logging out');
    this.authService.logout(true, true, false);
  }

  search(val) {
    if (this.searchResults$) {
      this.searchResults$.unsubscribe();
    }
    this.filterEntities(val.value, val.autocomplete);
  }

  protected filterEntities(q: string, autocomplete: MatAutocomplete): void {
    if (q) {
      this.isSearching = true;
      this.searchResults$ = this.autocompleteService.getElasticSearchResults(
          q,
          null,
          20).pipe(take(1), untilDestroyed(this)).subscribe(
          (entities) => {
            this.searchResults = entities;
            this.cdr.markForCheck();
          },
      );
    } else {
      this.searchResults = [];
      this.cdr.markForCheck();
    }
  }

  handleClear(): void {
    this.searchResults = [];
    this.elementRef.nativeElement.ownerDocument.activeElement.blur();
    this.cdr.markForCheck();

    // Slight delay because the search bar takes about 200ms to close
    setTimeout(() => {
      this.isSearching = false;
      this.cdr.markForCheck();
    }, 200);
  }

  navigateToEntityHomepage(entity: Entity): void {
    switch (true) {
      case entity.isLeague:
        this.router.navigate(['bild', 'entities', 'leagues', entity.id]);
        break;
      case entity.isPerson:
        this.router.navigate(['bild', 'entities', 'people', entity.id]);
        break;
      case entity.isTeam:
        this.router.navigate(['bild', 'entities', 'teams', entity.id]);
        break;
      case entity.isSubscriptionOrganization:
        this.router.navigate(['bild', 'entities', 'subscription-organizations', entity.id]);
        break;
      default:
        this.router.navigate(['bild', 'entities', 'focus-groups', entity.id]);
    }
  }

  public handleOptionSelected({event, mouseEvent}: {event: MatAutocompleteSelectedEvent, mouseEvent: MouseEvent}) {
    const entity: Entity = event.option.value;

    // Clear both searches
    this.desktopSearch._searchInput.clearSearch();
    this.mobileSearch._searchInput.clearSearch();

    const currentUrl = this.router.url;
    let endRootUrlIndex = -1;
    const leaguesRootUrl = '/bild/entities/leagues/';
    const peopleRootUrl = '/bild/entities/people/';
    const teamsRootUrl = '/bild/entities/teams/';

    if (mouseEvent && mouseEvent.metaKey) {
      if (currentUrl.includes(leaguesRootUrl) && entity.isLeague) {
        endRootUrlIndex = currentUrl.indexOf(leaguesRootUrl) + leaguesRootUrl.length;
      } else if (currentUrl.includes(peopleRootUrl) && entity.isPerson) {
        endRootUrlIndex = currentUrl.indexOf(peopleRootUrl) + peopleRootUrl.length;
      } else if (currentUrl.includes(teamsRootUrl) && entity.isTeam) {
        endRootUrlIndex = currentUrl.indexOf(teamsRootUrl) + teamsRootUrl.length;
      }

      if (endRootUrlIndex > -1) {
        const urlAfterIdIndex = currentUrl.indexOf('/', endRootUrlIndex);
        const queryParametersIndex = currentUrl.indexOf('?', endRootUrlIndex);
        const newUrlAfterId = (urlAfterIdIndex > -1 ?
          (queryParametersIndex > -1 ? currentUrl.substring(urlAfterIdIndex, queryParametersIndex) : currentUrl.substring(urlAfterIdIndex)) : '');
        const newUrl = currentUrl.substring(0, endRootUrlIndex) + entity.id + newUrlAfterId;
        this.router.navigateByUrl(currentUrl.substring(0, endRootUrlIndex) + entity.id)
            .then((data) => {
              this.router.navigateByUrl(newUrl)
                  .then((data) => {
                    this.cdr.markForCheck();
                  })
                  .catch((e) => {
                    this.navigateToEntityHomepage(entity);
                  });
            })
            .catch((e) => {
              this.navigateToEntityHomepage(entity);
            });
      } else {
        this.navigateToEntityHomepage(entity);
      }
    } else {
      this.navigateToEntityHomepage(entity);
    }

    this.cdr.markForCheck();
  }

  onNavigateLearning() {
    window.open(`https://canvas.open.uts.edu.au/`, '_blank');
    this.cdr.markForCheck();
  }

  navigateNewTab(path) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(path)
    );

    window.open(url, '_blank');
  }

  isMissingInfo(): boolean {
    if (this.entity.primaryPersonType && this.entity.primaryPersonType.id !== this.config.PERSON_TYPE_PLAYER_ID) {
      return false;
    }

    // Try to determine draft year if we don't have it
    let computedDraftYear: number = new Date().getFullYear();
    if (!this.entity.draftYear && this.entity.birthdate) {
      computedDraftYear = this.entity.birthdate.year() + 21;
    } else if (this.entity.draftYear) {
      computedDraftYear = this.entity.draftYear;
    }
    return !this.entity.position1 || !this.entity.birthdate || (!this.isPrepPlayer() && this.entity.draftExpressIDs.length === 0) ||
      (!this.isPrepPlayer() && this.entity.sportradarIDs.length === 0 && computedDraftYear > 2013);
  }

  isMissingNBAInfo(): boolean {
    if (this.entity.primaryPersonType && this.entity.primaryPersonType.id !== this.config.PERSON_TYPE_PLAYER_ID) {
      return false;
    }

    // Can only be missing NBA info if your primary affiliation is NBA
    return this.entity.primaryAffiliation &&
      (this.entity.primaryAffiliation.league?.name === 'National Basketball Association' || this.entity.primaryAffiliation.league?.name === 'G League') &&
      (!this.entity.draftYear || this.entity.nbaIDs.length === 0 || this.entity.realGMIDs.length === 0 ||
        this.entity.rotowireIDs.length === 0);
  }

  isPrepPlayer(): boolean {
    return (this.entity.primaryAffiliation &&
      this.entity.primaryAffiliation.league?.name === 'Prep');
  }

  closeNotificationMenu(): void {
    this.notificationsMenuTrigger.closeMenu();
  }

  reload(event): void {
    event.preventDefault();
    const url: URL = parseURL(this.router.url);

    if (url.pathname.indexOf('/bild') === 0) {
      this.postHelper.triggerUpdate();
      this.router.navigate(['bild'], {queryParams: {}});
    }
    this.closeVideoDialog();
  }

  createPost() {
    this.postComposerService.openNewPostComposer();
    this.closeVideoDialog();
  }

  setVideoTabFromNavigation(currentNavigation) {
    let currentVideoTab;
    switch (currentNavigation) {
      case VideoNavigationTypes.EXPLORE:
        currentVideoTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.QUEUE:
        currentVideoTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_1:
        currentVideoTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_2:
        currentVideoTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_3:
        currentVideoTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_4:
        currentVideoTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_5:
        currentVideoTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.PLAYLIST_6:
        currentVideoTab = VideoTabTypes.BROWSE_VIDEO;
        break;
      case VideoNavigationTypes.GENERATE:
        currentVideoTab = VideoTabTypes.GENERATE_PLAYLIST;
        break;
      case VideoNavigationTypes.LIVE:
        currentVideoTab = VideoTabTypes.COURT_CAMS;
        break;
      case VideoNavigationTypes.UPLOAD:
        currentVideoTab = VideoTabTypes.UPLOAD;
        break;
      case VideoNavigationTypes.DIAGNOSTICS:
        currentVideoTab = VideoTabTypes.VIDEO_DIAGNOSTICS;
        break;
    }
    this.navTabs?.forEach(navTab => {
      if (navTab.isVideoTab && navTab.name != currentVideoTab) {
        navTab.isActive = false;
      }
      if (navTab.isVideoTab && navTab.name == currentVideoTab) {
        navTab.isActive = true;
      }
    });
    this.cdr.markForCheck();
  }

  navigateToVideoTab(tab) {
    const videoTabToNavigationName = {
      [VideoTabTypes.BROWSE_VIDEO]: VideoNavigationTypes.EXPLORE,
      [VideoTabTypes.GENERATE_PLAYLIST]: VideoNavigationTypes.GENERATE,
      [VideoTabTypes.UPLOAD]: VideoNavigationTypes.UPLOAD,
      [VideoTabTypes.COURT_CAMS]: VideoNavigationTypes.LIVE,
      [VideoTabTypes.VIDEO_DIAGNOSTICS]: VideoNavigationTypes.DIAGNOSTICS
    }
    this.store$.dispatch(new actions.SaveAction({'navigationChange': videoTabToNavigationName[tab.name]}))
    this.navTabs.forEach(navTab => {
      navTab.isActive = false;
    })
    tab.isActive = true;
    this.videoService.selectedTabSubject.next(tab.name);
  }

  navigateToVideo() {
    // this.router.navigate(['bild', 'video', 'browse-video']);
    this.videoService.videoDialogOpenedSubject.next(true);
    const videoDialogConfig = {
      panelClass: 'video-panel',
      backdropClass: 'video-panel-backdrop',
      height: '100%',
      width: '100%',
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
      closeOnNavigation: false,
    };
    const dialogRef = this.matDialog.open(VideoRootComponent, videoDialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
    });
  }

  closeVideoDialog() {
    this.videoService.videoDialogOpenedSubject.next(false);
  }
}
