export const touchStartTypeOptions: any[] = [
  {name: 'Pass', isSimpleCheckbox: true, value: 'pass'},
  {name: 'Inbounds', isSimpleCheckbox: true, value: 'inbounder'},
  {name: 'Def Reboound', isSimpleCheckbox: true, value: 'drb'},
  {name: 'Off Reboound', isSimpleCheckbox: true, value: 'orb'},
  {name: 'Other', isSimpleCheckbox: true, value: 'other'},
];

export const touchOutcomeOptions: any[] = [
  {name: 'Pass', isSimpleCheckbox: true, value: 'PASS'},
  {name: 'Turnover', isSimpleCheckbox: true, value: 'TO'},
  {name: 'Foul', isSimpleCheckbox: true, value: 'FOUL'},
  {name: 'Made Shot', isSimpleCheckbox: true, value: 'FGM'},
  {name: 'Missed Shot', isSimpleCheckbox: true, value: 'FGX'},
];

export const touchRegionOptions: any[] = [
  {name: 'Key', isSimpleCheckbox: true, value: 'key'},
  {name: 'Restricted Area', isSimpleCheckbox: true, value: 'ra'},
  {name: 'Left Corner 3', isSimpleCheckbox: true, value: 'left corner three'},
  {name: 'Right Corner 3', isSimpleCheckbox: true, value: 'right corner three'},
  {name: 'Left Wing 3', isSimpleCheckbox: true, value: 'left wing three'},
  {name: 'Right Wing 3', isSimpleCheckbox: true, value: 'right wing three'},
  {name: 'Center 3', isSimpleCheckbox: true, value: 'middle three'},
  {name: 'Left Corner 2', isSimpleCheckbox: true, value: 'left corner two'},
  {name: 'Right Corner 2', isSimpleCheckbox: true, value: 'right corner two'},
  {name: 'Left Wing 2', isSimpleCheckbox: true, value: 'left wing two'},
  {name: 'Right Wing 2', isSimpleCheckbox: true, value: 'right wing two'},
  {name: 'Center 2', isSimpleCheckbox: true, value: 'middle two'},
  {name: 'Backcourt', isSimpleCheckbox: true, value: 'backcourt'},
];

export const touchPotentialCatchAndShootOptions: any[] = [
  {name: 'Yes', isSimpleCheckbox: true, value: true},
  {name: 'No', isSimpleCheckbox: true, value: false},
];
