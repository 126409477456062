<div class="entity">
  <ng-container *ngIf="person">
    <a href="" [routerLink]="['', 'bild', 'entities', 'people', person.id]" [ngStyle]="{margin: margin}">
      <entity-avatar [entity]="person" [size]="size" [ngClass]="{'truncated-margin': isMenuAvatar}"></entity-avatar>
    </a>
    <div class="names" [ngClass]="{'compact': size < 40}">
      <span class="primary"><a href="" [routerLink]="['', 'bild', 'entities', 'people', person.id]">{{ person.nameDisplay }}</a></span>
      <ng-container *ngIf="person.primaryAffiliation">
        <a href="" class="secondary" [routerLink]="['', 'bild', 'entities', 'teams', person.primaryAffiliation.id]">{{ person.primaryAffiliation.name }}</a>
      </ng-container>
      <ng-container *ngIf="secondaryLineOverride">
        <span class="secondary">{{ secondaryLineOverride }}</span>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="team">
    <a href="" [routerLink]="['', 'bild', 'entities', 'teams', team.id]" [ngStyle]="{margin: isMenuAvatar ? margin : null}">
      <entity-avatar [ngClass]="{'truncated-margin': isMenuAvatar}" [entity]="team" [size]="size"></entity-avatar>
    </a>
    <div class="names">
      <span class="primary"><a href="" [routerLink]="['', 'bild', 'entities', 'teams', team.id]">{{ team.nameDisplay }}</a></span>
      <span class="secondary">
          <ng-container *ngIf="team.locationDisplay">
            {{ team.locationDisplay }}
          </ng-container>
          <ng-container *ngIf="team.locationDisplay && team.primaryAffiliation?.abbrev"> | </ng-container>
          <ng-container *ngIf="team.primaryAffiliation?.abbrev">
            <a href="" [routerLink]="['', 'bild', 'entities', 'leagues', team.primaryAffiliation.id]">{{ team.primaryAffiliation.abbrev }}</a>
          </ng-container>
          <ng-container *ngIf="team.locationDisplay && team.primaryAffiliation?.abbrev == null && team.primaryAffiliation?.name !== null"> | </ng-container>
          <ng-container *ngIf="team.primaryAffiliation?.abbrev == null && team.primaryAffiliation?.name !== null">
            <a href="" [routerLink]="['', 'bild', 'entities', 'leagues', team.primaryAffiliation.id]">{{ team.primaryAffiliation.name }}</a>
          </ng-container>
        </span>
    </div>
  </ng-container>
  <ng-container *ngIf="league">
    <entity-avatar [ngClass]="{'truncated-margin': isMenuAvatar}" [entity]="league" [size]="size" [ngStyle]="{margin: isMenuAvatar ? margin : null}"></entity-avatar>
    <div class="names">
      <span class="primary">{{ league.name }}</span>
      <span class="secondary">
          <ng-container *ngIf="league.country">
            {{ league.country }}
          </ng-container>
        </span>
    </div>
  </ng-container>
  <ng-container *ngIf="focusGroup">
    <mat-icon>group_work</mat-icon>
    <div class="names">
      <span class="primary"><a href="" [routerLink]="['', 'bild', 'entities', 'focus-groups', focusGroup.id]">{{ focusGroup.name }}</a></span>
    </div>
  </ng-container>
</div>
