export enum VideoMessageTypes {
  WEBSOCKET_CHANNEL_NAME = 'websocket_channel_name',
  SESSION_METADATA = 'session_metadata',
  PLAYLISTS_METADATA = 'playlists_metadata',
  GENERATED_PLAYLISTS_METADATA = 'generated_playlists_metadata',
  SAVED_VIDEOS_LOOKUP_METADATA = 'saved_videos_lookup_metadata',
  PLAYLIST_DATA = 'playlist_data',
  PLAYLIST_SAVED = 'playlist_saved',
  PLAYLIST_DELETED = 'playlist_deleted',
  GENERATED_PLAYLIST_DATA = 'generated_playlist_data',
  GENERATED_PLAYLIST_CREATED = 'generated_playlist_created',
  VIDEO_ACTION = 'video_action',
  NAVIGATION_CHANGE = 'navigation_change',
  EXPLORE_TYPE_CHANGE = 'explore_type_change',
  EXPLORE_PLAYLIST_CHANGE = 'explore_playlist_change',
  EXPLORE_GENERATED_PLAYLIST_CHANGE = 'explore_generated_playlist_change',
  PLAYLIST_1_CHANGE = 'playlist_1_change',
  PLAYLIST_2_CHANGE = 'playlist_2_change',
  PLAYLIST_3_CHANGE = 'playlist_3_change',
  PLAYLIST_4_CHANGE = 'playlist_4_change',
  PLAYLIST_5_CHANGE = 'playlist_5_change',
  PLAYLIST_6_CHANGE = 'playlist_6_change',
  VIDEO_SAVED = 'video_saved',
  VIDEO_DELETED = 'video_deleted',
  BULK_VIDEOS_SAVED = 'videos_bulk_saved',
  BULK_VIDEOS_REMOVED = 'videos_bulk_removed',
  BULK_VIDEOS_DELETED = 'videos_bulk_deleted',
  VIDEO_NOTES_SAVED = 'video_notes_saved',
  TAG_SAVED = 'tag_saved',
  TAG_DELETED = 'tag_deleted',
  CLIP_INDEX_CHANGE = 'clip_index_change',
  NOTES_SHOWN_CHANGE = 'notes_shown_change',
  IS_SHUFFLED_CHANGE = 'is_shuffled_change',
  SORT_COLUMN_CHANGE = 'sort_column_change',
  SORT_DIRECTION_CHANGE = 'sort_direction_change',
}

export enum VideoActionTypes {
  NAVIGATION_CHANGE = 'navigationChange',
  EXPLORE_TYPE_CHANGE = 'exploreTypeChange',
  EXPLORE_PLAYLIST_CHANGE = 'explorePlaylistChange',
  EXPLORE_GENERATED_PLAYLIST_CHANGE = 'exploreGeneratedPlaylistChange',
  PLAYLIST_1_CHANGE = 'playlist1Change',
  PLAYLIST_2_CHANGE = 'playlist2Change',
  PLAYLIST_3_CHANGE = 'playlist3Change',
  PLAYLIST_4_CHANGE = 'playlist4Change',
  PLAYLIST_5_CHANGE = 'playlist5Change',
  PLAYLIST_6_CHANGE = 'playlist6Change',
  VIDEO_SAVED = 'videoSaved',
  VIDEO_DELETED = 'videoDeleted',
  TAG_SAVED = 'tagSaved',
  TAG_DELETED = 'tagDeleted',
  BULK_VIDEOS_SAVED = 'videosBulkSaved',
  BULK_VIDEOS_REMOVED = 'videosBulkRemoved',
  BULK_VIDEOS_DELETED = 'videosBulkDeleted',
  VIDEO_NOTES_SAVED = 'videoNotesSaved',
  CLIP_INDEX_CHANGE = 'clipIndexChange',
  NOTES_SHOWN_CHANGE = 'notesShownChange',
  IS_SHUFFLED_CHANGE = 'isShuffledChange',
  SORT_COLUMN_CHANGE = 'sortColumnChange',
  SORT_DIRECTION_CHANGE = 'sortDirectionChange',
}

export enum VideoNavigationTypes {
  EXPLORE = 'EXPLORE',
  QUEUE = 'QUEUE',
  PLAYLIST_1 = 'PLAYLIST_1',
  PLAYLIST_2 = 'PLAYLIST_2',
  PLAYLIST_3 = 'PLAYLIST_3',
  PLAYLIST_4 = 'PLAYLIST_4',
  PLAYLIST_5 = 'PLAYLIST_5',
  PLAYLIST_6 = 'PLAYLIST_6',
  GENERATE = 'GENERATE',
  LIVE = 'LIVE',
  UPLOAD = 'UPLOAD',
  DIAGNOSTICS = 'DIAGNOSTICS',
}

export enum VideoExploreTypes {
  PLAYLIST = 'PLAYLIST',
  GENERATED_PLAYLIST = 'GENERATED_PLAYLIST',
}

export enum VideoTabTypes {
  BROWSE_VIDEO = 'Video',
  GENERATE_PLAYLIST = 'Generate',
  COURT_CAMS = 'Live',
  UPLOAD = 'Upload',
  VIDEO_DIAGNOSTICS = 'Diagnostics'
}

export enum VideoSharingTypes {
  PRIVATE = 'Private',
  LIMITED = 'Limited',
  PUBLIC = 'Public'
}
