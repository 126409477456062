import * as moment from 'moment-timezone';

import {Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Level} from '@models/constants/levels';
import {Location} from '@models/id-names/locations';
import {MetricValue} from '@models/metric-values';
import {Person} from '@models/people';
import {forwardRefMoment} from '@helpers/class-transformer.helper';

export class Anthro {
  @Type(forwardRef(() => Person) as any)
  about: Person;
  @Type(forwardRef(() => Person) as any)
  author: Person;
  @Type(forwardRef(() => MetricValue) as any)
  bodyFatDEXA: MetricValue;
  @Type(forwardRef(() => MetricValue) as any)
  bodyFatSkinfold: MetricValue;
  @Type(forwardRef(() => MetricValue) as any)
  handLength: MetricValue;
  @Type(forwardRef(() => MetricValue) as any)
  handWidth: MetricValue;
  @Type(forwardRef(() => MetricValue) as any)
  heightWithShoes: MetricValue;
  @Type(forwardRef(() => MetricValue) as any)
  heightWithoutShoes: MetricValue;
  @Type(forwardRef(() => MetricValue) as any)
  seatedHeight: MetricValue;
  id: number;
  isPro: boolean;
  @Type(forwardRef(() => Level) as any)
  level: Level;
  @Type(forwardRef(() => Location) as any)
  location: Location;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(), { toClassOnly: true })
  postDate: moment.Moment;
  @Type(forwardRef(() => MetricValue) as any)
  reach: MetricValue;
  @Type(forwardRef(() => MetricValue) as any)
  weight: MetricValue;
  @Type(forwardRef(() => MetricValue) as any)
  wingspan: MetricValue;

  get locationName(): string {
    return this.location !== undefined ? this.location.name : '';
  }
}
