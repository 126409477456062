import {take, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import {Subject} from 'rxjs';

import {NotificationsService} from 'apps/_services/notifications.service';
import {Notification} from 'apps/_models/notifications';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {VideoService} from "@services/video.service";

@UntilDestroy()
@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) overflowMenuTrigger: MatMenuTrigger;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  notifications: Notification[] = [];
  endResultReached: boolean = false;
  pageTokenDate: string;
  pageTokenId: string;
  queryLimit: number = 10;
  isLoading: boolean = true;

  protected initialSub: Subject<void> = new Subject<void>();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected router: Router,
    protected notificationsService: NotificationsService,
    protected videoService: VideoService) {
  }

  ngOnInit() {
    this.notificationsService.notifications.pipe(untilDestroyed(this), takeUntil(this.initialSub), ).subscribe(
      data => {
        if (data) {
          this.isLoading = false;
          this.pageTokenDate = this.notificationsService.nextDatePageToken;
          this.pageTokenId = this.notificationsService.nextIdPageToken;
          this.notifications = data;

          if (this.notifications.length < this.notificationsService.defaultQueryLimit) {
            this.endResultReached = true;
          }

          this.cdr.detectChanges();
          this.initialSub.next();
          this.initialSub.complete();
        }
      }
    );
  }

  scrolled(): void {
    if (this.endResultReached) {
      return;
    }

    this.getNotifications(this.pageTokenDate, this.queryLimit);
  }

  handleNotificationClick($event, notification: Notification) {
    $event.stopPropagation();

    this.notificationsService.deleteNotification(notification).pipe(take(1), untilDestroyed(this)).subscribe(
      (notifications: Notification[]) => {
        this.videoService.videoDialogOpenedSubject.next(false);
        setTimeout(() => {
          this.router.navigate(['bild', 'posts', notification.post.postID]);
          this.onClose.emit(true);
          this.notifications = this.notifications.filter(n => n.id !== notification.id);
          this.cdr.detectChanges();
        });
      }
    );
  }

  clearAllNotifications($event) {
    $event.stopPropagation();

    this.notificationsService.deleteNotifications().pipe(take(1), untilDestroyed(this)).subscribe(
      (notifications: Notification[]) => {
        this.onClose.emit(true);
        this.cdr.detectChanges();
      }
    );
  }

  clearNotification($event, notification: Notification) {
    $event.stopPropagation();

    this.notificationsService.deleteNotification(notification).pipe(take(1), untilDestroyed(this)).subscribe(
      (notifications: Notification[]) => {
        if (this.notifications.length > 1) {
          this.notifications = this.notifications.filter(n => n.id !== notification.id);
          this.cdr.detectChanges();
        } else {
          this.notificationsService.getNotifications(this.pageTokenDate, this.pageTokenId, this.queryLimit).pipe(take(1), untilDestroyed(this)).subscribe(
            data => {
              this.pageTokenDate = data.nextPageToken;
              this.pageTokenId = data.nextIdPageToken;
              if (data.results.length > 0) {
                this.notifications = this.notifications.filter(n => n.id !== notification.id).concat(data.results);
              }
              if (data.results.length === 0 || data.results.length < this.queryLimit) {
                if (data.results.length === 0) {
                  this.notifications = [];
                }
                this.endResultReached = true;
              }
              this.isLoading = false;
              this.cdr.detectChanges();
            },
            err => {
              this.isLoading = false;
              this.cdr.detectChanges();
            }
          );
        }
      }
    );
  }

  notificationsTrackBy(index, notification): number {
    return notification.id;
  }

  protected getNotifications(pageToken, queryLimit): void {
    this.isLoading = true;
    this.notificationsService.getNotifications(this.pageTokenDate, this.pageTokenId, this.queryLimit).pipe(take(1), untilDestroyed(this)).subscribe(
      data => {
        this.pageTokenDate = data.nextPageToken;
        this.pageTokenId = data.nextIdPageToken;
        if (data.results.length > 0) {
          this.notifications = this.notifications.concat(data.results);
        }
        if (data.results.length === 0 || data.results.length < this.queryLimit) {
          this.endResultReached = true;
        }
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      err => {
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
  }
}
