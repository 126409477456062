import * as moment from 'moment-timezone';

import {Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Comment} from './comments';
import {Person} from './people';
import {Post} from './posts';

export class Notification {
  action: string;
  @Type(forwardRef(() => Person) as any)
  actionAuthor: Person;
  @Type(forwardRef(() => Comment) as any)
  comment: Comment;
  @Type(forwardRef(() => Date) as any)
  createDatetime: Date;
  id: number;
  @Type(forwardRef(() => Post) as any)
  post: Post;
  isTagged: boolean = false;

  get createDatetimeFormatted(): string {
    return this.formatDatetime('createDatetime');
  }

  get title(): string {
    let title: string = '';

    switch (this.action) {
      case 'post':
        title = this.post.about?.length <= 2 ? this.post.aboutNames : `${this.post.about[0].name} +${this.post.about.length - 1} More`;
        break;
      case 'comment':
        title = `Re: ${this.post.aboutNames}`;
        break;
    }

    return title;
  }

  get message(): string {
    let message: string = '';

    switch (this.action) {
      case 'post':
        message = this.post.messageSnippet;
        break;
      case 'comment':
        message = this.comment.messageSnippet;
        break;
    }

    return message;
  }

  protected formatDatetime(prop: string): string {
    const now = moment();
    const dt = moment(this[prop]);
    let formattedDatetime: string = '';

    switch (true) {
      case now.isSame(dt, 'day'):
        formattedDatetime = dt.format('h:mm A');
        break;
      case now.isSame(dt, 'year'):
        formattedDatetime = dt.format('MMM D');
        break;
      default:
        formattedDatetime = dt.format('MMM D, YYYY');
    }

    return formattedDatetime;
  }
}
