import {delay, retryWhen, share, map, switchMap, take} from 'rxjs/operators';

import {BehaviorSubject, timer as observableTimer, Observable} from 'rxjs';

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {plainToClass} from 'class-transformer';

import {AuthService} from '@services/auth.service';
import {BaseService} from '@services/base.service';
import {EntitiesService} from '@services/entities.service';
import {Notification} from '@models/notifications';
import {BroadcastChannelService} from '@services/broadcast-channel.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class NotificationsService extends BaseService implements OnDestroy {

  public readonly defaultQueryLimit: number = 10;
  public nextDatePageToken: string = null;
  public nextIdPageToken: string = null;

  protected mostRecentPostDatetime: string = null;

  protected getEndpoint: string = `${this.baseUrl}/notifications`;
  protected getCountEndpoint: string = `${this.baseUrl}/notifications/count`;
  protected _counter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  protected _notifications$: BehaviorSubject<Notification[]>;

  get counter(): Observable<number> {
    return this._counter$.pipe(share());
  }

  get notifications(): Observable<Notification[]> {
    return this._notifications$.pipe(share());
  }

  constructor(protected authService: AuthService,
              protected entitiesService: EntitiesService,
              protected http: HttpClient,
              protected broadcastChannelService: BroadcastChannelService) {
    super(http, broadcastChannelService);

    this.initStream();
  }

  initStream(): void {

    this._notifications$ = new BehaviorSubject<Notification[]>(null);

    observableTimer(0, 45 * 1000).pipe(switchMap(() => {
      return this.get(this.getCountEndpoint);
    }), untilDestroyed(this),
      retryWhen(error => {
        // Clear them out incase the failure is from a logout
        // incase another user signs in, don't want them to see
        // previous notifications
        this._counter$.next(0);
        this._notifications$.next([]);
        return error.pipe(delay(45 * 1000));
      }), )
      .subscribe(data => {
          if (this.mostRecentPostDatetime !== data.mostRecentPostDatetime) {
            this.getNotifications(null, null, this.defaultQueryLimit).pipe(take(1), untilDestroyed(this)).subscribe(
              notificationData => {
                this.nextDatePageToken = notificationData.nextPageToken;
                this.nextIdPageToken = notificationData.nextIdPageToken;
                this._notifications$.next(notificationData.results);
              }
            );

            this.mostRecentPostDatetime = data.mostRecentPostDatetime;
            this._counter$.next(data.count);
          } else if (data.mostRecentPostDatetime === null) {
            this.mostRecentPostDatetime = null;
            this._notifications$.next([]);
          }
        },
        error => {
          this._notifications$.error(error);
        }
      );

    this.authService.didLogIn.subscribe(() => this.refreshStream());
  }

  refreshStream(forceAll: boolean = false): void {
    if (!this._counter$) {
      this._counter$ = new BehaviorSubject<number>(0);
    }

    this.get(this.getCountEndpoint).pipe(map(
      data => {
        if (forceAll || this.mostRecentPostDatetime !== data.mostRecentPostDatetime) {
          // To avoid running this on the first run, check if any lastCount set yet
          this.getNotifications(null, null, this.defaultQueryLimit).pipe(take(1), untilDestroyed(this)).subscribe(
            notificationData => {
              this.nextDatePageToken = notificationData.nextPageToken;
              this.nextIdPageToken = notificationData.nextIdPageToken;
              this._notifications$.next(notificationData.results);
            }
          );

          this.mostRecentPostDatetime = data.mostRecentPostDatetime;
          this._counter$.next(data.count);
        }
      }
    ), take(1), ).subscribe();
  }

  getNotifications(pageToken?: string, pageIdToken?: string, limit?: number): Observable<{results: Notification[], nextPageToken: string, nextIdPageToken: string}> {
    let params: HttpParams = new HttpParams();
    if (pageToken) {
      params = params.set('pageDate', pageToken);
    }
    if (pageIdToken) {
      params = params.set('pageID', pageIdToken);
    }
    if (limit) {
      params = params.set('limit', String(limit));
    } else {
      params = params.set('limit', String(this.defaultQueryLimit));
    }

    return this.get(this.getEndpoint, params).pipe(map(
      data => {
        data = {
          results: plainToClass(Notification, data.results),
          nextPageToken: data.nextPageDateToken,
          nextIdPageToken: data.nextPageIDToken
        };
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  deleteNotification(notification: any): Observable<any> {
    const endpoint: string = `${this.baseUrl}/notification/${notification.id}`;

    return this.delete(endpoint, notification).pipe(map(
      data => {
        this.refreshStream(true);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  deleteNotifications(): Observable<any> {
    const endpoint: string = `${this.baseUrl}/notifications`;

    return this.delete(endpoint).pipe(map(
      data => {
        this.mostRecentPostDatetime = null;
        this.nextDatePageToken = null;
        this.nextIdPageToken = null;
        this._counter$.next(0);
        this._notifications$.next([]);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  ngOnDestroy() {
  }
}
