import {Action} from '@ngrx/store';

import {Announcement} from '@models/moments';

export enum ActionTypes {
  LIST = '[Announcement] List',
  LIST_SUCCESS = '[Announcement] List Success',
  LIST_FAILED = '[Announcement] List Failed',
  GET = '[Announcement] Get',
  GET_SUCCESS = '[Announcement] Get Success',
  GET_FAILED = '[Announcement] Get Failed',
  SAVE = '[Announcement] Save',
  SAVE_SUCCESS = '[Announcement] Save Success',
  SAVE_FAILED = '[Announcement] Save Failed',
  DELETE = '[Announcement] Delete',
  DELETE_SUCCESS = '[Announcement] Delete Success',
  DELETE_FAILED = '[Announcement] Delete Failed'
}

export class GetAnnouncements implements Action {
  public readonly type = ActionTypes.LIST;
}

export class GetAnnouncementsSuccess implements Action {
  public readonly type = ActionTypes.LIST_SUCCESS;
  constructor(public payload: {date: string, posts: Announcement[]}) {}
}

export class GetAnnouncementsFailed implements Action {
  public readonly type = ActionTypes.LIST_FAILED;
  constructor(public payload: any) {}
}

export class GetAnnouncement implements Action {
  public readonly type = ActionTypes.GET;
  constructor(public payload: number) {}
}

export class GetAnnouncementSuccess implements Action {
  public readonly type = ActionTypes.GET_SUCCESS;
  constructor(public payload: Announcement) {}
}

export class GetAnnouncementFailed implements Action {
  public readonly type = ActionTypes.GET_FAILED;
  constructor(public payload: any) {}
}

export class SaveAnnouncement implements Action {
  public readonly type = ActionTypes.SAVE;
  constructor(public payload: Announcement) {}
}

export class SaveAnnouncementSuccess implements Action {
  public readonly type = ActionTypes.SAVE_SUCCESS;
  constructor(public payload: Announcement) {}
}

export class SaveAnnouncementFailed implements Action {
  public readonly type = ActionTypes.SAVE_FAILED;
  constructor(public payload: any) {}
}

export class DeleteAnnouncement implements Action {
  public readonly type = ActionTypes.DELETE;
  constructor(public payload: Announcement) {}
}

export class DeleteAnnouncementSuccess implements Action {
  public readonly type = ActionTypes.DELETE_SUCCESS;
  constructor() {}
}

export class DeleteAnnouncementFailed implements Action {
  public readonly type = ActionTypes.DELETE_FAILED;
  constructor(public payload: any) {}
}

export type Actions = GetAnnouncements | GetAnnouncementsSuccess | GetAnnouncementsFailed | GetAnnouncement | GetAnnouncementSuccess |
  SaveAnnouncement | SaveAnnouncementSuccess | SaveAnnouncementFailed | DeleteAnnouncement | DeleteAnnouncementSuccess |
  DeleteAnnouncementFailed;
