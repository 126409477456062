import {createEffect, ofType, Actions} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {map, catchError, concatMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {plainToClass} from 'class-transformer';

import * as actions from './actions';
import {ArticlesService} from '../../_services/articles/articles.service';
import {Article} from '@models/articles';

@Injectable()
export class Effects {

  getNewsGeneralPosts$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GetNewsArticles>(actions.ActionTypes.LIST),
    map(action => action.payload),
    switchMap(payload => this.articlesService.getArticles('news', payload.aboutID, payload.league, payload.maxDays, payload.page, payload.pageID, payload.limit, payload.injuryOccurrences)),
    switchMap((data: any) => {
      return of({
        aboutID: data.aboutID,
        leagueID: data.leagueID,
        prevPageDateToken: data.prevPageDateToken,
        nextPageDateToken: data.nextPageDateToken,
        prevPageIDToken: data.prevPageIDToken,
        nextPageIDToken: data.nextPageIDToken,
        results: plainToClass<Article, Object[]>(Article, data.results),
        filteredIds: data.results.map(p => p.postID)
      });
    }),
    concatMap(data => {
      return of(new actions.GetNewsArticlesSuccess(data));
    }),
    catchError(error => {
      return of({
        type: actions.ActionTypes.LIST_FAILED
      });
    })
  ));

  constructor(private articlesService: ArticlesService, private actions$: Actions) {}
}
