import * as moment from 'moment-timezone';

import {Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';
import {Entity} from '@models/entities';
import {forwardRefMoment} from '@helpers/class-transformer.helper';

export const TSA_SKILLS = ['generalEvaluation', 'knowsHowToPlay', 'shotCreation', 'playsHard', 'shooting', 'finishing', 'athleticism', 'defensiveImpact', 'size'];

export class SkillAssessment {
  @Type(forwardRef(() => Entity) as any)
  about: Entity[] = [];
  athleticismBull: number;
  athleticismNow: number;
  athleticismText: string[];
  athleticismVideo: any[];
  @Type(forwardRef(() => Entity) as any)
  author: Entity = null;
  defensiveImpactBull: number;
  defensiveImpactNow: number;
  defensiveImpactText: string[];
  defensiveImpactVideo: any[];
  finishingText: string[];
  finishingBull: number;
  finishingNow: number;
  finishingVideo: any[];
  generalEvaluation: string[];
  generalEvaluationVideo: any[];
  id: number;
  isActive: boolean;
  isPro: boolean;
  knowsHowToPlayBull: number;
  knowsHowToPlayNow: number;
  knowsHowToPlayText: string[];
  knowsHowToPlayVideo: any[];
  playsHardBull: number;
  playsHardNow: number;
  playsHardText: string[];
  playsHardVideo: any[];
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  postDatetime: moment.Moment;  postID: number;
  revisionID: number;
  sizeText: string[];
  sizeVideo: any[];
  shootingBull: number;
  shootingNow: number;
  shootingText: string[];
  shootingVideo: any[];
  shotCreationBull: number;
  shotCreationNow: number;
  shotCreationText: string[];
  shotCreationVideo: any[];
}
