import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {SignInComponent} from './sign-in.component';
import {ADFSCallbackComponent} from './adfs-callback/adfs-callback.component';
import {SignInResolver} from './sign-in.resolvers';

const routes: Routes = [
  { path: 'sign-in', component: SignInComponent, data: { title: 'Sign In'}, resolve: {signIn: SignInResolver} },
  { path: 'sign-in/adfs-callback', component: ADFSCallbackComponent, data: { title: 'Sign In'} },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
