import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {Article} from '@models/articles';
import {BaseService} from '../base.service';
import {EntitiesService} from '../entities.service';
import {flatMap} from 'rxjs/operators';
import {BroadcastChannelService} from '@services/broadcast-channel.service';

@Injectable({
  providedIn: 'root',
})
export class ArticlesService extends BaseService {

  constructor(protected http: HttpClient, protected broadcastChannelService: BroadcastChannelService, protected entitiesService: EntitiesService) {
    super(http, broadcastChannelService);
  }

  getArticles(articleType: string = 'news', aboutID: number = null, leagueID: number = null, maxDays?: number, pageDate?: string, pageID?: string, limit?: number, injuryOccurrences?: number[]): Observable<Article[]> {
    const endpoint = `${this.baseUrl}/articles`;

    let params: HttpParams = new HttpParams();

    if (articleType) {
      params = params.set('articleType', String(articleType));
    }
    if (aboutID) {
      params = params.set('aboutIDs', String(aboutID));
    }
    if (leagueID) {
      params = params.set('leagueID', String(leagueID));
    }
    if (maxDays) {
      params = params.set('maxDays', String(maxDays));
    }
    if (pageDate) {
      params = params.set('pageDate', pageDate);
    }
    if (pageID) {
      params = params.set('pageID', pageID);
    }
    if (limit) {
      params = params.set('limit', String(limit));
    } else {
      params = params.set('limit', '5');
    }
    if (injuryOccurrences) {
      params = params.set('injuryOccurrences', String(injuryOccurrences));
    }

    return this.get(endpoint, params).pipe(flatMap(res => {
      res.aboutID = aboutID;
      res.leagueID = leagueID;
      return of(res);
    }));
  }
}
