import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';

import {Effects} from './effects';
import {reducers} from './reducer';
import {VideoState} from './state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature<VideoState>('video', reducers),
    EffectsModule.forFeature([Effects])
  ],
  providers: [Effects]
})
export class VideoStoreModule {}
