import {Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';
import {Entity} from '@models/entities';
import * as moment from 'moment-timezone';
import {forwardRefMoment} from '@helpers/class-transformer.helper';

export class ScoutingReportComment {
  @Type(forwardRef(() => Entity) as any)
  author: Entity;
  id: number;
  isEditing: boolean;
  message: string;
}
export class Headline {
  id: number;
  body: string;
  comments: ScoutingReportComment[];
  headline: string;
  isAddingComment: boolean;
  isVisible: boolean;
  originalComments: ScoutingReportComment[];
  savedMessageData: string;
}

export class PersonnelSection {
  availability: string;
  body: string;
  comments: any;
  @Type(forwardRef(() => Entity) as any)
  entity: Entity;
  headline: string;
  isStarter: boolean;
  @Type(forwardRef(() => Entity) as any)
  matchup: Entity;
  originalComments: any;
  position: number;
  coverage: string;
  coverageColor: string;
}

export class SectionPreview {
  @Type(forwardRef(() => Entity) as any)
  author: Entity;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  editDatetime: moment.Moment;
  @Type(forwardRef(() => Headline) as any)
  defense: Headline[];
  @Type(forwardRef(() => Headline) as any)
  general: Headline[];
  isPosted: boolean;
  @Type(forwardRef(() => Headline) as any)
  offense: Headline[];
  @Type(forwardRef(() => PersonnelSection) as any)
  personnel: PersonnelSection[];
  personnelMetadata: any;
}

export class ScoutingPreview {
  @Type(forwardRef(() => SectionPreview) as any)
  analytics: SectionPreview;
  @Type(forwardRef(() => SectionPreview) as any)
  coaching: SectionPreview;
  gameID: number;
  @Type(forwardRef(() => SectionPreview) as any)
  players: SectionPreview;
  @Type(forwardRef(() => SectionPreview) as any)
  scout: SectionPreview;
  personnelMetadata: any;
  teamID: number;
}

export class GameScoutingPreview {
  away: ScoutingPreview;
  home: ScoutingPreview;
}
