import * as _ from 'lodash';
import {Type, Exclude, Transform} from 'class-transformer';


import {ENTITY_SUBTYPES, EntitySubtype} from './constants/entity-subtypes';
import {forwardRefConstant} from '@helpers/class-transformer.helper';
import {Person} from '@models/people';
import {Team} from "apps/_models/teams";
import {FocusGroupEntitySummary} from "apps/_models/focus-groups";
import {forwardRef} from '@angular/core';
import {LOC_RATING_POINTS} from './locs';
import {POSITION_NAMES} from "@models/constants/positions";
import {PlayerContract} from '@models/player-contract';
import {LOCSSlice} from '@models/ranked-people';


class Position {
  name: string;
  @Type(forwardRef(() => RosterEntity) as any)
  entities: RosterEntity[] = [];
}


export class Roster {
  leagueID: number;
  season: number;
  @Type(forwardRef(() => Team) as any)
  team: Team;
  @Type(forwardRef(() => RosterEntity) as any)
  entities: RosterEntity[];

  @Exclude()
  _positions: Position[];
  @Type(forwardRef(() => Position) as any)
  get positions(): Position[] {
    if (this._positions !== undefined) {
      return this._positions;
    }

    const defaultPositionsDict = {
      Guard: [],
      Wing: [],
      Hybrid: [],
      Big: [],
      Center: []
    };

    let positionsDict = _(this.entities.filter(entity => (entity.entity != null && entity.positionGroupName != null)))
                                       .groupBy((entity: RosterEntity) => entity.positionGroupName).value();
    positionsDict = Object.assign(defaultPositionsDict, positionsDict);

    const positions = Object.keys(positionsDict).map(key => {
      const label = key !== 'null' ? key : 'No Position';
      return {
        name: label,
        name_plural: `${label}s`,
        entities: positionsDict[key]
      }
    });

    return positions;
  }

  get lineupData(): RosterEntitySummary[] {
    return this.entities.map(
      entity => ({
        entityID: entity.entityID,
        playerID: entity.playerID,
        lineupOrder: entity.lineupOrder,
        position: entity.assignedPosition
      })
    )
  }

  syncLineupData() {
    this.entities.forEach((entity, index) => {
      entity.lineupOrder = index + 1;
      entity.assignedPosition = entity.displayedPosition;
    });
  }
}

class RosterEntitySummary {
  entityID: number;
  playerID: string;
  lineupOrder: number;
  position: number;
}

export class RosterEntity {
  entityID: number;
  playerID: string;
  lineupOrder: number;
  position: number;
  assignedPosition: number;
  @Type(forwardRef(() => Person) as any)
  entity: Person;
  fullName: string;
  gamesAvailable: number;
  minutesPerAvailableGame: number;
  gamesStartedPercent: number;
  nowLOC: number;
  lowLOC: number;
  midLOC: number;
  highLOC: number;
  @Type(forwardRef(() => FocusGroupEntitySummary) as any)
  focusGroups: FocusGroupEntitySummary[];

  get nowLOCLabel(): string {
    return LOC_RATING_POINTS[Math.round(this.nowLOC)];
  }

  get lowLOCLabel(): string {
    return LOC_RATING_POINTS[Math.round(this.lowLOC)];
  }

  get midLOCLabel(): string {
    return LOC_RATING_POINTS[Math.round(this.midLOC)];
  }

  get highLOCLabel(): string {
    return LOC_RATING_POINTS[Math.round(this.highLOC)];
  }

  get positionGroupName(): string {
    return POSITION_NAMES[Number(this.entity?.positionGroup)];
  }

  get displayedPosition(): number {
    if (this.assignedPosition) {
      return Number(this.assignedPosition);
    }
    if (this.entity && this.entity.position) {
      return Number(this.entity.position);
    }
    return this.position ? Number(this.position) : null;
  }

  get displayedName(): string {
    return (this.entity && this.entity.name) ? this.entity.name : this.fullName;
  }

  get numFocusGroups(): number {
    return this.focusGroups.length;
  }
}

export enum LeagueRosterViewType {
  AVAILABILITY = 'Availability',
  FREE_AGENCY = 'Free Agency'
}

export class LeagueRosterPlayers {
  id: number;
  imageType: string;
  imageUrl: string;
  lastMod: number;
  name: string;
  nameDisplay: string;
  firstNameInitial: string;
  lastName: string;
  type: string;
  injuryStatus: string;
  isFreeAgent: boolean;
  isOverflow: boolean;
  isTempAffil: boolean;
  onRoster: boolean;
  isActive: boolean;
  rightsHeld: boolean;
  twoWay: boolean;
  leagueRosterPositionGroup: number;
  position: number;
  positionGroup: any;
  @Transform(forwardRefConstant(ENTITY_SUBTYPES), { toClassOnly: true })
  subtype: EntitySubtype;
  @Type(forwardRef(() => LOCSSlice) as any)
  locsSlice: LOCSSlice;
  @Type(forwardRef(() => PlayerContract) as any)
  contract: PlayerContract;

  faStatusLabel(season): string {
    if (this.contract?.contractYears && this.contract?.contractYears[season]?.faStatus == 'R'
      && ((this.contract?.contractYears[season]?.apronAmount == 0 && !this.twoWay) || this.contract?.contractYears[season]?.isFaAmount)) {
      return 'RFA';
    }
    else if ((this.contract?.contractYears && this.contract?.contractYears[season]?.faStatus == 'U'
      && ((this.contract?.contractYears[season]?.apronAmount == 0 && !this.twoWay) || this.contract?.contractYears[season]?.isFaAmount)) || this.isFreeAgent) {
      return 'UFA';
    }
    return null
  }

  faStatusLabelAbbrev(season): string {
    if (this.contract?.contractYears && this.contract?.contractYears[season]?.faStatus == 'R'
      && ((this.contract?.contractYears[season]?.apronAmount == 0 && !this.twoWay) || this.contract?.contractYears[season]?.isFaAmount)) {
      return 'R';
    }
    else if ((this.contract?.contractYears && this.contract?.contractYears[season]?.faStatus == 'U'
      && ((this.contract?.contractYears[season]?.apronAmount == 0 && !this.twoWay) || this.contract?.contractYears[season]?.isFaAmount)) || this.isFreeAgent) {
      return 'U';
    }
    return null
  }
}

export class LeagueRosterTeam {
  id: number;
  abbrev: string;
  conference: string;
  imageType: string;
  imageUrl: string;
  lastMod: number;
  name: string;
  page: number;
  type: string;
  @Type(forwardRef(() => LeagueRosterPlayers) as any)
  players: LeagueRosterPlayers[];

  groupedPlayers(viewType: LeagueRosterViewType, season?: number): any {
    if (viewType == LeagueRosterViewType.FREE_AGENCY) {
      return this.groupedPlayersFreeAgency(season);
    }
    else {
      return this.groupedPlayersAvailability();
    }
  }

  overflowDisplayNumber(viewType: LeagueRosterViewType, season?: number): number {
    if (viewType == LeagueRosterViewType.FREE_AGENCY) {
      let groupedPlayers = this.groupedPlayersFreeAgency(season);
      return (groupedPlayers.positionGroup1.length + groupedPlayers.positionGroup2.length +
        groupedPlayers.positionGroup3.length + groupedPlayers.freeAgent.length + groupedPlayers.twoWay.length) + 1;
    }
    else {
      let groupedPlayers = this.groupedPlayersAvailability();
      return (groupedPlayers.positionGroup1.length + groupedPlayers.positionGroup2.length +
        groupedPlayers.positionGroup3.length) + 1;
    }
  }

  groupedPlayersAvailability(): any {
    let positionGroup1 = [];
    let positionGroup2 = [];
    let positionGroup3 = [];
    let twoWay = [];
    let notOnRoster = [];
    let overflow = [];

    this.players.forEach(player => {
      if (player.twoWay) {
        twoWay.push(player);
      }
      else if (!player.onRoster) {
        notOnRoster.push(player);
      }
      else if (player.isOverflow) {
        overflow.push(player);
      }
      else if (player.leagueRosterPositionGroup == 1) {
        positionGroup1.push(player);
      }
      else if (player.leagueRosterPositionGroup == 2) {
        positionGroup2.push(player);
      }
      else if (player.leagueRosterPositionGroup == 3) {
        positionGroup3.push(player);
      }
    })

    return {
      positionGroup1: positionGroup1,
      positionGroup2: positionGroup2,
      positionGroup3: positionGroup3,
      twoWay: twoWay,
      notOnRoster: notOnRoster,
      overflow: overflow
    };
  }

  groupedPlayersFreeAgency(season): any {
    let positionGroup1 = [];
    let positionGroup2 = [];
    let positionGroup3 = [];
    let freeAgent = [];
    let twoWay = [];
    let notOnRoster = [];
    let overflow = [];

    this.players.forEach(player => {
      if (player.contract?.contractYears && player.contract?.contractYears[season]?.faStatus == 'R'
        && ((player.contract?.contractYears[season]?.apronAmount == 0 && !player.twoWay) || player.contract?.contractYears[season]?.isFaAmount)) {
        if (player.isActive) {
          freeAgent.push(player);
        }
      }
      else if ((player.contract?.contractYears && player.contract?.contractYears[season]?.faStatus == 'U'
        && ((player.contract?.contractYears[season]?.apronAmount == 0 && !player.twoWay) || player.contract?.contractYears[season]?.isFaAmount)) || player.isFreeAgent) {
        if (player.isActive) {
          freeAgent.push(player);
        }
      }
      else if (player.twoWay) {
        twoWay.push(player);
      }
      else if (!player.onRoster) {
        notOnRoster.push(player);
      }
      else if (player.leagueRosterPositionGroup == 1) {
        positionGroup1.push(player);
      }
      else if (player.leagueRosterPositionGroup == 2) {
        positionGroup2.push(player);
      }
      else if (player.leagueRosterPositionGroup == 3) {
        positionGroup3.push(player);
      }
    });

    let playersBeforeOverflow = twoWay.length > 0 ? 15 : 16;
    let orderedPlayers = _.orderBy(positionGroup1.concat(positionGroup2, positionGroup3, freeAgent), player => player.locsSlice.calculatedMid || 0, ['desc']);
    if (orderedPlayers.length > playersBeforeOverflow + 1) {
      overflow = orderedPlayers.slice(playersBeforeOverflow);
    }

    positionGroup1 = positionGroup1.filter(player => !overflow.map(player => player.id).includes(player.id));
    positionGroup2 = positionGroup2.filter(player => !overflow.map(player => player.id).includes(player.id));
    positionGroup3 = positionGroup3.filter(player => !overflow.map(player => player.id).includes(player.id));
    freeAgent = freeAgent.filter(player => !overflow.map(player => player.id).includes(player.id));

    return {
      positionGroup1: positionGroup1,
      positionGroup2: positionGroup2,
      positionGroup3: positionGroup3,
      freeAgent: freeAgent,
      twoWay: twoWay,
      notOnRoster: notOnRoster,
      overflow: overflow
    };
  }
}

export class LeagueRosters {
  @Type(forwardRef(() => LeagueRosterTeam) as any)
  teams: LeagueRosterTeam[];

  get eastTeams(): LeagueRosterTeam[] {
    return _.filter(this.teams, ['conference', 'East']);
  }

  get westTeams(): LeagueRosterTeam[] {
    return _.filter(this.teams, ['conference', 'West']);
  }

  get orderedTeams(): LeagueRosterTeam[] {
    return this.eastTeams.concat(this.westTeams);
  }
}
