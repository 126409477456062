<mat-list infiniteScroll (scrolled)="scrolled()" [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500" [scrollWindow]="false">
  <mat-list-item role="listitem" class="notifications-list-header">
    <h4 mat-line class="notifications-title">Notifications</h4>
    <button mat-icon-button *ngIf="notifications?.length > 0" (click)="clearAllNotifications($event)">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-list-item>

  <mat-list-item *ngIf="!isLoading && notifications?.length <= 0" class="notification-item-empty">
    <h4 mat-line>All caught up!</h4>
  </mat-list-item>

  <mat-list-item *ngFor="let notification of notifications; trackBy: notificationsTrackBy" class="notification-item" (click)="handleNotificationClick($event, notification)" [fragment]="''+notification.comment?.commentID" disableRipple>
    <mat-icon matListIcon svgIcon="{{ notification.post.icon }}"></mat-icon>
    <h4 mat-line>{{ notification.title }}</h4>
    <p mat-line class="author-name">{{ notification.actionAuthor.name }}<span class="tagged" *ngIf="notification.isTagged">&nbsp;@You</span></p>
    <div class="notification-time">{{ notification.createDatetimeFormatted }}</div>
    <button mat-icon-button (click)="clearNotification($event, notification)">
      <mat-icon>close</mat-icon>
    </button>
  </mat-list-item>

  <mat-list-item *ngIf="!endResultReached" class="end-result">
    Loading...
  </mat-list-item>
</mat-list>
