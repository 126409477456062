<a href="/" class="bild-icon-button" [routerLink]="['', 'bild']" (click)="reload($event)" *ngIf="!isContractApp">
  <img src="assets/images/bild.v1.svg" class="grid-icon"/>
  <span class="bild-text">bild</span>
</a>

<a href="/" class="bild-icon-button" [routerLink]="['', 'contract', 'ledger']" (click)="reload($event)" *ngIf="isContractApp">
  <img src="assets/images/contract_logo.png" class="grid-icon contract-logo"/>
  <span class="contract-text">contract</span>
</a>

<div class="menu-items" *ngIf="!isContractApp">
  <div class="menu-button-item" [matMenuTriggerFor]="notificationsMenu" #notificationsMenuTrigger="matMenuTrigger">
    <mat-icon *ngIf="notificationCount == 0">notifications</mat-icon>
    <div *ngIf="notificationCount > 0" class="notification-count-badge">{{ notificationCount > 99 ? '99+' : notificationCount }}</div>
    <span class="menu-button-name" [ngStyle]="{color: notificationCount > 0 ? '#fa3e3e' : null}">Notifications</span>
  </div>
  <div class="menu-button-item" (click)="showPlaylistMenu ? null : navigateToVideo()">
    <mat-icon [ngStyle]="{marginLeft: notificationCount > 0 ? '2px': '0'}">video_library</mat-icon>
    <span class="menu-button-name post">Explore Video</span>
  </div>
  <div class="menu-button-item" (click)="createPost()">
    <mat-icon [ngStyle]="{marginLeft: notificationCount > 0 ? '2px': '0'}">email</mat-icon>
    <span class="menu-button-name post">Create Post</span>
  </div>
</div>

<search-box #desktopSearch
            [alwaysVisible]="false"
            [placeholder]="''"
            (searchDebounce)="search($event)"
            [debounce]="typingDebounceTime"
            (optionSelected)="handleOptionSelected($event)"
            (clear)="handleClear()"
            [preventHotkey]="preventHotkey"
            [searchResults]="searchResults"
            [isContractApp]="isContractApp"></search-box>

<div class="divider"></div>

<bild-entity-header *ngIf="entity" [entity]="entity" [size]="36" [margin]="isPerson || isTeam ? '0 0 0 20px' : '0 0 0 16px'"
                    [isMenuAvatar]="true" [ngStyle]="{'margin-bottom': isMissingInfo() || isMissingNBAInfo() ? '4px' : 0}"></bild-entity-header>

<div class="page-header" *ngIf="tag">
  <mat-icon>local_offer</mat-icon>
  <div class="entity">
    <div class="names">
      <span class="primary">{{ tag?.name }}</span>
      <span class="secondary tag-path">
            <span *ngIf="tag?.grandParent"><a href="" [routerLink]="['', 'bild', 'tags', tag.grandParent.id]" class="tag-link">{{tag.grandParent.name}}</a> > </span>
            <span *ngIf="tag?.parent"><a href="" [routerLink]="['', 'bild', 'tags', tag.parent.id]" class="tag-link">{{tag.parent.name}}</a> > </span>
            <span>{{tag?.name}}</span>
            <span *ngIf="tag?.childTags.length"> > <span [matMenuTriggerFor]="childTagMenu" [matMenuTriggerData]="{childTags: tag.childTags}" class="tag-link">ALL</span></span>
          </span>
    </div>
  </div>
</div>

<mat-list>
  <ng-container *ngIf="entity && hiddenTabs.length > 1; else defaultView" [ngTemplateOutlet]="collapsableView"></ng-container>

  <ng-template #defaultView>
    <ng-container *ngFor="let tab of navTabs">
      <ng-container *ngIf="!tab.isVideoTab">
        <ng-template *ngTemplateOutlet="genericTab; context: {tab: tab}"></ng-template>
      </ng-container>
      <ng-container *ngIf="tab.isVideoTab">
        <ng-template *ngTemplateOutlet="videoTab; context: {tab: tab}"></ng-template>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #collapsableView>
    <ng-container *ngFor="let tab of defaultTabs">
      <ng-template *ngTemplateOutlet="genericTab; context: {tab: tab}"></ng-template>
    </ng-container>
    <mat-list-item>
      <a (click)="entityNavTabService.toggleExpanded()">
        <span>{{entityNavTabService.expanded ? 'Less': 'More'}}</span>
        <mat-icon *ngIf="entityNavTabService.expanded">expand_less</mat-icon>
        <mat-icon *ngIf="!entityNavTabService.expanded">expand_more</mat-icon>
      </a>
    </mat-list-item>
    <ng-container *ngIf="entityNavTabService.expanded">
      <ng-container *ngFor="let tab of hiddenTabs">
        <ng-template *ngTemplateOutlet="genericTab; context: {tab: tab}"></ng-template>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-list>
<div class="divider" *ngIf="showPlaylistMenu"></div>
<playlist-menu *ngIf="showPlaylistMenu"></playlist-menu>

<ng-container *ngIf="isContractApp">
  <div class="divider"></div>
  <scenario-menu [scenarioList]="scenarioList" [selectedScenarioID]="selectedScenarioID" (scenarioChange)="scenarioChange.emit($event)"></scenario-menu>
</ng-container>

<div class="spacer"></div>
<div class="divider"></div>

<div class="menu-footer">
  <div class="menu-button-item" [matMenuTriggerFor]="applicationsMenu">
    <mat-icon>apps</mat-icon>
    <span class="menu-button-name">Other apps</span>
  </div>
  <div class="menu-button-item" [matMenuTriggerFor]="accountMenu">
    <entity-avatar *ngIf="currentUser && currentUser.optimizedImageUrl" [entity]="currentUser" [size]="24"></entity-avatar>
    <span class="menu-button-name" *ngIf="currentUser?.firstName">{{currentUser.firstName}} {{currentUser.lastName}}</span>
  </div>
</div>

<ng-template #genericTab let-tab="tab">
  <mat-list-item [class.active]="domHelper.isLinkActive(tab.activeRootPath)">
    <a href="" [routerLink]="tab.link" [queryParams]="isContractApp ? {scenarioID: selectedScenarioID} : null">{{tab.name}}</a>
  </mat-list-item>
</ng-template>

<ng-template #videoTab let-tab="tab">
  <mat-list-item [class.active]="tab.isActive" (click)="navigateToVideoTab(tab)">
    <a>{{tab.name}}</a>
  </mat-list-item>
</ng-template>

<mat-menu class="notifications-menu" #notificationsMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
  <ng-template matMenuContent>
    <notifications (onClose)="closeNotificationMenu()"></notifications>
  </ng-template>
</mat-menu>

<mat-menu class="primary-toolbar profile" #accountMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
  <ng-template matMenuContent>
    <div class="header" fxLayout="row">
      <div class="avatar">
        <mat-icon *ngIf="!currentUser || !currentUser.imageUrl">account_circle</mat-icon>
        <entity-avatar [entity]="currentUser" [size]="72"></entity-avatar>
      </div>
      <div class="details" fxLayout="column" *ngIf="currentUser">
        <div class="name">{{ currentUser.firstName }} {{ currentUser.lastName }}</div>
        <button *ngIf="!currentUser.isADFSOnly" mat-raised-button color="primary" [routerLink]="['../', 'bild', 'account', 'my-account']" class="myAccount">My Account</button>
      </div>
    </div>
    <div class="account-menu">
      <a href="" mat-menu-item [routerLink]="['/', 'bild', 'account', 'follows']">Manage Follows</a>
      <a href="" mat-menu-item [routerLink]="['/', 'bild', 'account', 'notifications']">Manage Notifications</a>
      <a href="" *ngIf="showSiteManagement || showDataEntry" mat-menu-item [routerLink]="showDataEntry ? ['/', 'bild', 'data-entry', 'shot-charting'] : ['/', 'bild', 'data-entry']">Data Entry</a>
      <a href="" *ngIf="showSiteManagement" mat-menu-item [routerLink]="['/', 'bild', 'site-management', siteManagementRoute]">Site Management</a>
    </div>
    <div class="footer logout-footer" fxLayoutAlign="end center">
      <button mat-raised-button (click)="handleLogOut()" ga-on="click" ga-event-category="Sign Out" ga-event-action="click">Log Out</button>
    </div>
  </ng-template>
</mat-menu>

<mat-menu class="primary-toolbar apps" #applicationsMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
  <ng-template matMenuContent>
    <mat-grid-list [cols]="3">
      <mat-grid-tile (click)="navigateNewTab(['/', 'bild'])" class="grid-tile" ga-on="click" ga-event-category="Apps" ga-event-action="click" ga-event-label="go to bild">
        <img src="assets/images/bild_logo.png" class="grid-icon"/>
        <mat-grid-tile-footer class="light-font">bild</mat-grid-tile-footer>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="perms?.Admin || perms?.viewAllDraftBoard" (click)="navigateNewTab(['/', 'draft', 'draft-order'])" class="grid-tile" ga-on="click" ga-event-category="Apps" ga-event-action="click" ga-event-label="go to draft">
        <img src="assets/images/draft-logo.png" class="grid-icon"/>
        <mat-grid-tile-footer class="light-font">draft</mat-grid-tile-footer>
      </mat-grid-tile>
      <mat-grid-tile (click)="navigateNewTab(['/', 'talent-portal'])" class="grid-tile" ga-on="click" ga-event-category="Apps" ga-event-action="click" ga-event-label="go to talent portal">
        <img src="assets/talent-portal/images/talent-logo.png" class="grid-icon"/>
        <mat-grid-tile-footer class="light-font">talent</mat-grid-tile-footer>
      </mat-grid-tile>
      <mat-grid-tile *featureToggle="'LEARNING_CENTER'" (click)="onNavigateLearning()" class="grid-tile" ga-on="click" ga-event-category="Apps" ga-event-action="click" ga-event-label="go to learning">
        <img src="assets/images/learning_logo.png" class="grid-icon"/>
        <mat-grid-tile-footer class="light-font">learning</mat-grid-tile-footer>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="perms?.Admin || perms?.viewAllContractApp" (click)="navigateNewTab(['../', 'contract', 'ledger'])" class="grid-tile" ga-on="click" ga-event-category="Apps" ga-event-action="click" ga-event-label="go to contract app">
        <img src="assets/images/contract_logo.png" class="grid-icon contract-logo"/>
        <mat-grid-tile-footer class="light-font">contract</mat-grid-tile-footer>
      </mat-grid-tile>
    </mat-grid-list>
  </ng-template>
</mat-menu>


<mat-menu #childTagMenu="matMenu" class="child-tag-menu">
  <ng-template matMenuContent let-childTags="childTags">
    <a mat-menu-item *ngFor="let tag of childTags" href="" [routerLink]="['', 'bild', 'tags', tag.id]" class="tag-menu-item">
      {{ tag.name }}
    </a>
  </ng-template>
</mat-menu>
