export const chartingCategoryOptions: any[] = [
  {name: '1-on-1', isSimpleCheckbox: true, value: '1-on-1'},
  {name: 'Coverages', isSimpleCheckbox: true, value: 'Coverages'},
  {name: 'Shell', isSimpleCheckbox: true, value: 'Shell'},
];

export const chartingResultOptions: any[] = [
  {name: 'Success', isSimpleCheckbox: true, value: 'Success'},
  {name: 'Fail', isSimpleCheckbox: true, value: 'Fail'},
];
