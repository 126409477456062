import * as _ from 'lodash';

import {Type, Exclude} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {RankedPerson} from '@models/ranked-people';


export class Position {
  name: string;
  @Type(forwardRef(() => RankedPerson) as any)
  entities: RankedPerson[] = [];
}

export class FocusGroupGrouping {
  id: number;
  name: string;
  isRanked: boolean;
  rank: number;
  @Type(forwardRef(() => RankedPerson) as any)
  entities: RankedPerson[] = [];
  isHidden: boolean;

  @Exclude()
  _positions: Position[];
  @Type(forwardRef(() => Position) as any)
  get positions(): Position[] {
    if (this._positions !== undefined) {
      return this._positions;
    }

    const defaultPositionsDict = {
      Guard: [],
      Wing: [],
      Hybrid: [],
      Big: [],
      Center: []
    };

    let positionsDict = _(this.entities).groupBy((entity: RankedPerson) => entity.position.name).value();
    positionsDict = Object.assign(defaultPositionsDict, positionsDict);

    const positions = Object.keys(positionsDict).map(key => {
      const label = key !== 'null' ? key : 'No Position';
      return {
        name: label,
        name_plural: `${label}s`,
        entities: positionsDict[key]
      };
    });

    return positions;
  }
}

export const ALL_POSITIONS = [
  {
    'name': 'Guard',
    'id': 1
  }, {
    'name': 'Wing',
    'id': 2
  }, {
    'name': 'Hybrid',
    'id': 3
  }, {
    'name': 'Big',
    'id': 4
  }, {
    'name': 'Center',
    'id': 5
  },
];
