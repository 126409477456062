import {NavOption} from '@models/nav-option';

export class TagPostFeedNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Post Feed';
  shouldHide= false;
  constructor(tagID) {
    super();

    this.activeRootPath = ['', 'bild', 'tags', tagID];
    this.link = ['', 'bild', 'entities', 'tags', tagID];
  }
}

export class TagHighlightingNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Highlights';
  shouldHide= false;
  constructor(tagID) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'tags', tagID, 'highlighting'];
    this.link = ['', 'bild', 'entities', 'tags', tagID, 'highlighting'];
  }
}
