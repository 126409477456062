import {plainToClass} from 'class-transformer';

import {IdName} from '@models/id-names/_id-names';

export const COMMUNICATION_SETTING_IDS = {
  PHONE: 61,
  TEXT: 62,
  EMAIL_LETTER: 63,
  IN_PERSON: 64,
  VIDEO_CALL: 65,
};

export class CommunicationSetting extends IdName {}

export const COMMUNICATION_SETTINGS = {};
COMMUNICATION_SETTINGS[COMMUNICATION_SETTING_IDS.PHONE] = plainToClass(CommunicationSetting, {id: COMMUNICATION_SETTING_IDS.PHONE, name: 'Phone', displayOrder: 1});
COMMUNICATION_SETTINGS[COMMUNICATION_SETTING_IDS.VIDEO_CALL] = plainToClass(CommunicationSetting, {id: COMMUNICATION_SETTING_IDS.VIDEO_CALL, name: 'Video Call', displayOrder: 2});
COMMUNICATION_SETTINGS[COMMUNICATION_SETTING_IDS.TEXT] = plainToClass(CommunicationSetting, {id: COMMUNICATION_SETTING_IDS.TEXT, name: 'Text', displayOrder: 3});
COMMUNICATION_SETTINGS[COMMUNICATION_SETTING_IDS.EMAIL_LETTER] = plainToClass(CommunicationSetting, {id: COMMUNICATION_SETTING_IDS.EMAIL_LETTER, name: 'Email/Letter', displayOrder: 4});
COMMUNICATION_SETTINGS[COMMUNICATION_SETTING_IDS.IN_PERSON] = plainToClass(CommunicationSetting, {id: COMMUNICATION_SETTING_IDS.IN_PERSON, name: 'In-Person', displayOrder: 5});
