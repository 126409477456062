<div class="dialog-content">
  <div class="title-row">
    <div class="title-label">Scenario</div>
    <button class="close-button" mat-icon-button mat-dialog-close>
      <mat-icon aria-label="Close">close</mat-icon>
    </button>
  </div>

  <form [formGroup]="form">
    <mat-form-field class="name">
      <input matInput formControlName="name" placeholder="Name">
      <mat-error *ngIf="form.get('name').hasError('required')">
        Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <div class="editors">
      <mat-form-field class="members-section">
        <label>
          Editors
          <mat-icon matTooltip="Editors have the ability to create temporary transactions and edit the scenario's settings">info</mat-icon>
        </label>
        <mat-chip-list #editorChipList>
          <ng-container *ngFor="let userGroup of scenario.distribution.userGroups">
            <mat-chip *ngIf="userGroup.isEditor" class="user-group-chip" (removed)="removeUserGroup(userGroup, 'isEditor')" [removable]="true" [selectable]="true" bildTooltip="{{ userGroup.membersTooltipHTML }}" [bildTooltipIsHTML]="true">
              <mat-icon>group</mat-icon>
              {{ userGroup.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <ng-container *ngFor="let user of scenario.distribution.users">
            <mat-chip *ngIf="user.isEditor" class="user-chip" (removed)="removeUserEntity(user, 'isEditor')" [selectable]="user.id != owner.id" [removable]="user.id != owner.id" [disabled]="user.id == owner.id">
              {{ user.name }}
              <mat-icon *ngIf="user.id != owner.id" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <input #editorInput matInput [matChipInputFor]="editorChipList" [matAutocomplete]="editorAutocomplete" [formControl]="editorControl">
        </mat-chip-list>
        <mat-autocomplete #editorAutocomplete="matAutocomplete" (optionSelected)="addUserEntity($event, true, true)">
          <mat-option *ngFor="let user of filteredUsers" [value]="user">
            {{ user.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="add-user-group-button" (click)="openUserGroupsSelectDialog(true, true)">
        <mat-icon>add_circle</mat-icon>
        <div>Add User Group</div>
        <mat-icon>group</mat-icon>
      </div>
    </div>

    <div class="viewers">
      <mat-form-field class="members-section">
        <label>
          Viewers
          <mat-icon matTooltip="Viewers have the ability to view all pages of a scenario">info</mat-icon>
        </label>
        <mat-chip-list #viewersChipList>
          <ng-container *ngFor="let userGroup of scenario.distribution.userGroups">
            <mat-chip *ngIf="userGroup.isMainViewer" class="user-group-chip" (removed)="removeUserGroup(userGroup, 'isMainViewer')" [removable]="true" [selectable]="true" bildTooltip="{{ userGroup.membersTooltipHTML }}" [bildTooltipIsHTML]="true">
              <mat-icon>group</mat-icon>
              {{ userGroup.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <ng-container *ngFor="let user of scenario.distribution.users">
            <mat-chip *ngIf="user.isMainViewer" class="user-chip" (removed)="removeUserEntity(user, 'isMainViewer')" [selectable]="user.id != owner.id && !user.isEditor" [removable]="user.id != owner.id && !user.isEditor" [disabled]="user.id == owner.id || user.isEditor">
              {{ user.name }}
              <mat-icon *ngIf="user.id != owner.id && !user.isEditor" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <input #viewersInput matInput [matChipInputFor]="viewersChipList" [matAutocomplete]="viewersAutocomplete" [formControl]="viewersControl">
        </mat-chip-list>
        <mat-autocomplete #viewersAutocomplete="matAutocomplete" (optionSelected)="addUserEntity($event, null, true)">
          <mat-option *ngFor="let user of filteredUsers" [value]="user">
            {{ user.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="add-user-group-button" (click)="openUserGroupsSelectDialog(null, true)">
        <mat-icon>add_circle</mat-icon>
        <div>Add User Group</div>
        <mat-icon>group</mat-icon>
      </div>
    </div>

    <div class="button-row">
      <button mat-raised-button class="delete-button" [disabled]="!scenario.id" (click)="onDelete()" color="warn">Delete Scenario</button>
      <button #doneButton mat-raised-button color="primary" (click)="saveScenario()">Save</button>
    </div>
  </form>
</div>
