import {Action} from '@ngrx/store';
import {Affiliation} from '@models/affiliations';

export enum ActionTypes {
  LIST = '[Affiliation] List',
  LIST_SUCCESS = '[Affiliation] List Success',
  LIST_FAILED = '[Affiliation] List Failed',
  SAVE = '[Affiliation] Save',
  SAVE_SUCCESS = '[Affiliation] Save Success',
  SAVE_FAILED = '[Affiliation] Save Failed',
  UPDATE = '[Affiliation] Update'
}
export class GetAffiliations implements Action {
  public readonly type = ActionTypes.LIST;
  constructor(public payload: {id: number}) {}
}
export class GetAffiliationsSuccess implements Action {
  public readonly type = ActionTypes.LIST_SUCCESS;
  constructor(public payload: Affiliation[]) {}
}
export class GetAffiliationsFailed implements Action {
  public readonly type = ActionTypes.LIST_FAILED;
  constructor(public payload: any) {}
}

export class SaveAffiliations implements Action {
  public readonly type = ActionTypes.SAVE;
  constructor(public payload: {affiliations: Affiliation[], id: number}) {}
}

export class SaveAffiliationsSuccess implements Action {
  public readonly type = ActionTypes.SAVE_SUCCESS;
  constructor(public payload: Affiliation[]) {}
}

export class SaveAffiliationsFailed implements Action {
  public readonly type = ActionTypes.SAVE_FAILED;
  constructor(public payload: any) {}
}

export class UpdateAffiliations implements Action {
  public readonly type = ActionTypes.UPDATE;
  constructor(public payload: any) {}
}

export type Actions =
  GetAffiliations | GetAffiliationsSuccess | GetAffiliationsFailed |
  SaveAffiliations | SaveAffiliationsSuccess | SaveAffiliationsFailed |
  UpdateAffiliations;
