import {Type, Exclude, Transform} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {ENTITY_SUBTYPES, EntitySubtype} from './constants/entity-subtypes';
import {forwardRefConstant} from '@helpers/class-transformer.helper';

const nullImageEntityTypes = ['FocusGroup', 'Playcall']

export class Entity {
  id: number;
  name: string;
  lastName: string;
  firstNameInitial: string;
  imageUrl: string;
  imageType: string;
  lastMod: number;
  type: string;
  @Transform(forwardRefConstant(ENTITY_SUBTYPES), { toClassOnly: true })
  subtype: EntitySubtype;
  @Type(forwardRef(() => Entity) as any)
  team: Entity;
  position: number;
  age: number;
  @Type(forwardRef(() => Entity) as any)
  affiliation: Entity;
  @Type(forwardRef(() => Entity) as any)
  league: Entity;
  userID: number;
  nbaID: number;
  location: string;
  country: string;
  abbrev: string;
  draftYear: number;
  hideCommunityBoard: boolean;
  hideDevelopment: boolean;
  hideFocusGroups: boolean;
  hideLOCs: boolean;
  hideMetrics: boolean;
  hidePhysical: boolean;
  excludeFromCommunityBoard: boolean;
  isInternational: boolean;
  isInTalentPipeline: boolean;
  isTag: boolean;
  hasContent: boolean;
  isDraft: boolean;

  // Computed
  @Exclude()
  protected _optimizedImageUrl: string;

  get optimizedImageUrl() {
    if (this._optimizedImageUrl) {
      return this._optimizedImageUrl;
    }

    let url = `${this.imageUrl}?`;

    if (nullImageEntityTypes.includes(this.type)) {
      return null;
    } else {
      url += 'auto=enhance';

      if (this.type === 'Person') {
        url += '&fit=crop&crop=faces&mask=corners';
      } else {
        url += '&pad=2&bg=00FFFFFF&fit=fill';
      }

      this._optimizedImageUrl = url;

      return url;
    }


  }

  get isLeague(): boolean {
    return this.type === 'League';
  }

  get isPerson(): boolean {
    return this.type === 'Person';
  }

  get isTeam(): boolean {
    return this.type === 'Team';
  }

  get isSubscriptionOrganization(): boolean {
    return this.type === 'SubscriptionOrganization';
  }

  get teamName(): string {
    return this.type === 'Person' && this.affiliation !== undefined && this.affiliation.type === 'Team' ? this.affiliation.name : '';
  }

  get firstInitialLastName(): string {
    return `${this.firstNameInitial}. ${this.lastName}`;
  }
}
