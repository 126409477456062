
<div [hidden]="isGeneratingPlaylist" class="browse-video-container" [class.full-screen]="enteredFullScreen" [class.clips-on-side]="clipsOnSide" [class.is-saving]="isSaving">
  <div class="clips-table-container">
    <div class="clip-detail" [class.saving-clip]="isSaving || isSavingMany" (mouseup)="endDragging()" (mousemove)="onTimeDotDrag($event)" (mouseleave)="mouseLeavePlayer()">
      <video-player #videoPlayer
                    [clipsOnSide]="clipsOnSide"
                    [columns]="columns"
                    [currentClip1]="isLoading ? null : currentClip1"
                    [currentClip2]="isLoading ? null : currentClip2"
                    [currentVideoIndex]="currentVideoIndex"
                    [defaultPadding]="getDefaultPadding()"
                    [displayedClips]="displayedClips"
                    [fullQuarters]="fullQuarters"
                    [heightOffset]="-9"
                    [isFullScreen]="enteredFullScreen"
                    [isSaving]="isSaving"
                    [isTagging]="isTagging"
                    [tagEditing]="tagEditing"
                    [shouldAutoPlay]="false"
                    [showAutoPlayToggle]="true"
                    [showClipNumber]="true"
                    [showEndPadding]="true"
                    [showEvents]="true"
                    [showHotKeys]="true"
                    [showInsights]="true"
                    [showNextButton]="true"
                    [showPreviousButton]="true"
                    [showReportButton]="true"
                    [showSaveButton]="true"
                    [showSizeButton]="true"
                    [showStartPadding]="true"
                    [isDialog]="true"
                    (onToggleFullScreen)="onToggleFullScreen($event)"
                    (onToggleShowEpv)="onToggleShowEpv($event)"
                    (onToggleShow2dCourt)="onToggleShow2dCourt($event)"
                    (onClipChange)="changeToClip($event)"
                    (onEditSavedClip)="editVideo($event)"
                    (onReloadClipUrl)="reloadClipUrl()"
                    (onSavingUpdate)="onSavingUpdate($event)"
                    (onTagSecondsChange)="onTagSecondsChange($event)"
      ></video-player>
    </div>
    <div *ngIf="enteredFullScreen" class="full-screen-current-clip-actions">
      <div class="clip-name">{{ currentClip.name ? currentClip.name : currentClip.description }}</div>
      <button class="action-button" mat-flat-button *ngIf="!currentClip?.id" (click)="saveVideo(currentClip)">
        <div class="inner-button-container">
          <mat-icon>bookmark_border</mat-icon> Save Video
        </div>
      </button>
      <button class="action-button" mat-flat-button *ngIf="currentClip?.id && currentClip.author.userID == user.id" (click)="editVideo(currentClip)">
        <div class="inner-button-container">
          <mat-icon>edit</mat-icon> Edit Saved Video
        </div>
      </button>
      <button *ngIf="isClipDownloadable(currentClip)" class="action-button" mat-flat-button (click)="downloadCurrentClip()">
        <div class="inner-button-container">
          <mat-icon>download</mat-icon> Download
        </div>
      </button>
    </div>
    <ngx-skeleton-loader *ngIf="!displayedClipsLoaded" [theme]="{height: '196px', width: '100%', marginTop: '0', marginBottom: '-4px', borderRadius: '8px 8px'}" />
    <div class="no-text-indicator" *ngIf="selectedGeneratedPlaylist?.clips && displayedClipsLoaded && !unfilteredClips?.length">There are no clips for the selected filters</div>
    <div *ngIf="displayedClipsLoaded" class="clip-table-outer"
      [class.side-collapsed]="clipsOnSideCollapsed"
      [class.dark-mode]="enteredFullScreen"
      [class.notes-visible]="notesVisible">
      <video-clip-table #videoClipTable *ngIf="unfilteredClips?.length"
                        [class.side-collapsed]="clipsOnSideCollapsed"
                        [class.dark-mode]="enteredFullScreen"
                        [class.notes-visible]="notesVisible"
                        [class.expanded]="isTableExpanded"
                        [activeFilters]="activeFilters"
                        [user]="user"
                        [clipsOnSide]="enteredFullScreen"
                        [isDarkMode]="enteredFullScreen"
                        [notesVisible]="notesVisible"
                        [page]="page"
                        [displayedClips]="displayedClips"
                        [columns]="columns"
                        [currentClip]="currentClip"
                        [currentVideoIndex]="currentVideoIndex"
                        [filters]="displayedFilters"
                        [fullQuarters]="fullQuarters"
                        [isSaving]="isSaving"
                        [isSavingMany]="isSavingMany"
                        [isShuffled]="isShuffled"
                        [pageSize]="10000"
                        [savedVideosLookup]="savedVideosLookup"
                        [selectedLeague]="selectedLeague"
                        [selectedClips]="selectedClips"
                        [showPaginationBar]="false"
                        [showFooterBar]="selectedClips?.length"
                        [sortBy]="sortBy"
                        [sortDirection]="sortDirection"
                        [tableClassName]="'clips-table'"
                        [unfilteredClips]="unfilteredClips"
                        [showExpansionButton]="!enteredFullScreen"
                        [isTableExpanded]="isTableExpanded"
                        (onChangeToClip)="changeToClip($event)"
                        (onFilterUpdate)="filterData($event)"
                        (onToggleAllSelected)="toggleAllSelected($event)"
                        (onUpdateSelectedClips)="updateIndividualClipSelection($event)"
                        (onSortUpdate)="updateTableSortOrder($event)"
                        (onClipNotesUpdate)="updateVideoNotes($event)"
                        (onSaveClip)="changeToClipAndSave($event)"
                        (onToggleTableExpanded)="toggleClipTableExpanded()"
      ></video-clip-table>
    </div>
    <div *ngIf="enteredFullScreen && clipsOnSide" class="collapse-button" [class.collapsed]="clipsOnSideCollapsed" [class.notes-visible]="notesVisible" (click)="clipsOnSideCollapsed = !clipsOnSideCollapsed">
      <mat-icon *ngIf="!clipsOnSideCollapsed">chevron_right</mat-icon>
      <mat-icon *ngIf="clipsOnSideCollapsed">chevron_left</mat-icon>
    </div>
    <div *ngIf="displayedClips?.length" class="clip-footer" [class.side-collapsed]="clipsOnSideCollapsed" [class.notes-visible]="notesVisible">
      <button mat-button class="clip-footer-button" (click)="toggleShuffle()" [ngClass]="{active: isShuffled}">
        <mat-icon class="clip-footer-icon">shuffle</mat-icon>
        <span class="clip-footer-label">Shuffle</span>
      </button>
<!--      <div>-->
       <button *ngIf="selectedPlaylist?.id" mat-button class="clip-footer-button notes" (click)="onToggleNotesVisibility()">
         <mat-icon class="clip-footer-icon">notes</mat-icon>
         <span class="clip-footer-label">{{ notesVisible ? 'Hide Notes' : 'Show Notes' }}</span>
       </button>
<!--        <button mat-button class="clip-footer-button">-->
<!--          <mat-icon class="clip-footer-icon">settings</mat-icon>-->
<!--          <span class="clip-footer-label">Columns</span>-->
<!--        </button>-->
<!--      </div>-->
    </div>
  </div>
  <div class="playlist-detail-container" *ngIf="!isSaving && !isSavingMany && !isEditingPlaylist && !isTagging">
    <div class="toolbar">
      <div>
        <ngx-skeleton-loader *ngIf="!selectedPlaylist && !selectedGeneratedPlaylist" [theme]="{height: '24px', width: '200px', marginTop: '10px', marginBottom: '0', borderRadius: '4px'}" />
        <div class="header" *ngIf="selectedPlaylist || selectedGeneratedPlaylist" [class.capitalize]="!selectedPlaylist">{{ selectedPlaylist ? selectedPlaylist.name : determineGPHeader(selectedGeneratedPlaylist) }}</div>
        <div class="sub-header" *ngIf="selectedPlaylist?.id && selectedPlaylist?.id != -1">
          {{selectedPlaylist.author?.name}}
          <span class="bullet">&bull;</span>
          {{selectedPlaylist.editDatetime | date:'shortDate'}}
          <ng-container *ngIf="selectedPlaylist.folder">
            <span class="bullet">&bull;</span>
            <mat-icon class="folder-icon">folder</mat-icon>
            {{selectedPlaylist.folder}}
          </ng-container>
        </div>
        <div class="sub-header" *ngIf="!selectedPlaylist && selectedGeneratedPlaylist">
          <mat-icon>video_settings</mat-icon>
          Generated Playlist
        </div>
      </div>
      <button class="close-button" mat-mini-fab (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ngx-skeleton-loader *ngIf="!selectedPlaylist && !selectedGeneratedPlaylist" [theme]="{height: '28px', width: '300px', marginTop: '8px', marginBottom: '0', borderRadius: '12px'}" />
    <div class="buttons-container" *ngIf="selectedPlaylist || selectedGeneratedPlaylist">
      <button class="action-button" mat-flat-button (click)="modifyGeneratedPlaylistFilters()" *ngIf="!selectedPlaylist?.name">
        <div class="inner-button-container">
          <mat-icon>video_settings</mat-icon> <span>Modify Filters</span>
        </div>
      </button>
      <button class="action-button distribution" mat-flat-button (click)="editPlaylist()" *ngIf="selectedPlaylist?.id && selectedPlaylist?.id != -1" [matTooltip]="getSelectedDistributionTooltip()">
        <div class="inner-button-container">
          <ng-container *ngIf="selectedPlaylist?.visibility == VideoSharingTypes.PRIVATE">
            <mat-icon>lock</mat-icon> Private Playlist
          </ng-container>
          <ng-container *ngIf="selectedPlaylist?.visibility == VideoSharingTypes.LIMITED">
            <ng-container *ngIf="getSelectedPlaylistEditors().length > 0">
              <div class="editors-icon">
                <mat-icon>group</mat-icon>
                <mat-icon class="sub-icon">edit</mat-icon>
              </div>
              <ng-container *ngFor="let user of getSelectedPlaylistEditors(); let i = index">
                <entity-avatar *ngIf="(getSelectedPlaylistEditors().length - getSelectedPlaylistAdditionalEditors()) > i" class="avatar" [entity]="user" [size]="24" [fitClamp]="true"></entity-avatar>
              </ng-container>
              <div *ngIf="getSelectedPlaylistAdditionalEditors() > 0" class="additional-users">+{{ getSelectedPlaylistAdditionalEditors() }}</div>
            </ng-container>
            <ng-container *ngIf="getSelectedPlaylistViewers().length > 0">
              <div class="viewers-icon">
                <mat-icon>group</mat-icon>
                <mat-icon class="sub-icon">visibility</mat-icon>
              </div>
              <ng-container *ngFor="let user of getSelectedPlaylistViewers(); let i = index">
                <entity-avatar *ngIf="(getSelectedPlaylistViewers().length - getSelectedPlaylistAdditionalViewers()) > i" class="avatar" [entity]="user" [size]="24" [fitClamp]="true"></entity-avatar>
              </ng-container>
              <div *ngIf="getSelectedPlaylistAdditionalViewers() > 1" class="additional-users">+{{ getSelectedPlaylistAdditionalViewers() }}</div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="selectedPlaylist?.visibility == VideoSharingTypes.PUBLIC">
            <mat-icon>public</mat-icon> Public Playlist
          </ng-container>
        </div>
      </button>
      <button *ngIf="sessionMetadata?.currentNavigation == VideoNavigationTypes.EXPLORE && sessionMetadata.currentExploreType == VideoExploreTypes.GENERATED_PLAYLIST" class="action-button" mat-flat-button (click)="bulkSavePlaylistVideosToPlaylists()">
        <div class="inner-button-container">
          <mat-icon>playlist_add</mat-icon> Add to Playlist
        </div>
      </button>
      <button *ngIf="sessionMetadata?.currentNavigation == VideoNavigationTypes.EXPLORE && sessionMetadata.currentExploreType == VideoExploreTypes.GENERATED_PLAYLIST && selectedGeneratedPlaylist?.id" class="action-button" mat-flat-button (click)="copyLink()">
        <div class="inner-button-container">
          <mat-icon>link</mat-icon> Copy Link
        </div>
      </button>
      <button *ngIf="sessionMetadata?.currentNavigation != VideoNavigationTypes.EXPLORE || sessionMetadata?.currentExploreType != VideoExploreTypes.GENERATED_PLAYLIST" class="action-button" mat-flat-button [matMenuTriggerFor]="playlistOptions">
        <div class="inner-button-container">
          <mat-icon class="no-margin">more_horiz</mat-icon>
        </div>
      </button>
    </div>
    <div class="playlist-description" *ngIf="selectedPlaylist?.description">{{ selectedPlaylist.description }}</div>
    <video-filter-panel *ngIf="!selectedPlaylist && selectedGeneratedPlaylist"
      [filters]="displayedFilters"
      [filtersData]="filtersData"
    ></video-filter-panel>
    <ngx-skeleton-loader *ngIf="!displayedClipsLoaded && !currentClip && !selectedPlaylist && !selectedGeneratedPlaylist" [theme]="{height: '16px', marginTop: '36px', marginBottom: '0', borderRadius: '4px'}" />
    <ngx-skeleton-loader *ngIf="!displayedClipsLoaded && !currentClip && (selectedPlaylist || selectedGeneratedPlaylist)" [theme]="{height: '16px', marginTop: '20px', marginBottom: '0', borderRadius: '4px'}" />
    <ngx-skeleton-loader *ngIf="!displayedClipsLoaded && !currentClip" [theme]="{height: '16px', marginTop: '8px', marginBottom: '0', borderRadius: '4px'}" />
    <ngx-skeleton-loader *ngIf="!displayedClipsLoaded && !currentClip" [theme]="{height: '26px', width: '160px', marginTop: '4px', marginBottom: '0', borderRadius: '12px'}" />
    <div *ngIf="currentClip" class="clip-detail-container">
      <div class="divider">
        <div class="divider-label">Currently Watching</div>
      </div>
      <div class="clip-name">{{ currentClip.name ? currentClip.name : currentClip.description }}</div>
      <div class="buttons-container">
        <button class="action-button" mat-flat-button *ngIf="!currentClip?.id" (click)="saveVideo(currentClip)">
          <div class="inner-button-container">
            <mat-icon>bookmark_border</mat-icon> Save Video
          </div>
        </button>
        <button class="action-button" mat-flat-button *ngIf="currentClip?.id && currentClip.author.userID == user.id" (click)="editVideo(currentClip)">
          <div class="inner-button-container">
            <mat-icon>edit</mat-icon> Edit Saved Video
          </div>
        </button>
        <!-- <button class="action-button" mat-flat-button *ngIf="currentClip?.id">
          <div class="inner-button-container">
            <mat-icon>sell</mat-icon> Add Tag
          </div>
        </button> -->
        <button *ngIf="currentClip.author?.userID == user.id || isClipDownloadable(currentClip)" class="action-button" mat-flat-button [matMenuTriggerFor]="videoOptions">
          <div class="inner-button-container">
            <mat-icon class="no-margin">more_horiz</mat-icon>
          </div>
        </button>
      </div>
      <div class="clip-commentary">{{ currentClip.commentary }}</div>
      <ng-container *ngIf="currentClip.id && currentClip.author?.userID == user.id && currentClip.notes">
        <div class="attribute-header">Notes (Private)</div>
        <div class="clip-commentary">{{ currentClip.notes }}</div>
      </ng-container>
      <div class="metadata-chips">
        <div *ngFor="let tag of currentClip.tags; let i = index" class="metadata-container">
          <div *ngIf="i == 0" class="attribute-header">Tags</div>
          <mat-chip class="tag-chip" [ngStyle]="{marginTop: i > 0 ? '26px' : 0}">
            <div class="header">
              <div class="tag-type">{{ tag.definition.name }}</div>
              <button class="action-button" mat-flat-button [matMenuTriggerFor]="tagOptions" [matMenuTriggerData]="{tag: tag}">
                <div class="inner-button-container">
                  <mat-icon class="no-margin">more_horiz</mat-icon>
                </div>
              </button>
            </div>
            <div *ngFor="let metadata of tag.metadata" class="tag-meta">
              <div class="label">{{ metadata.definition.name }}</div>
              <div *ngIf="metadata.definition.type == 'Person'" class="value">{{ metadata.entity.name }}</div>
              <div *ngIf="metadata.definition.type == 'Selection'" class="value">{{ metadata.option.name }}</div>
            </div>
          </mat-chip>
        </div>
        <div class="metadata-container">
          <div class="attribute-header">Game</div>
          <mat-chip class="game-chip">
            <div class="date-time-details">
              <div class="date">{{ (currentClip.gameStartTime || currentClip.game?.date) | date: 'longDate' }}</div>
            </div>
            <div class="team-details" [class.emphasis]="currentClip.awayFinalPoints > currentClip.homeFinalPoints">
              <entity-avatar *ngIf="currentClip.awayTeamImage" class="logo" [imageUrl]="'https://docs-public.imgix.net/' + currentClip.awayTeamImage" [size]="16" [maskCorners]="true"></entity-avatar>
              <entity-avatar *ngIf="currentClip.game?.awayTeam?.imageUrl" class="logo" [imageUrl]="currentClip.game?.awayTeam?.imageUrl" [size]="16" [maskCorners]="true"></entity-avatar>
              <div class="name">{{ currentClip.awayTeamName || currentClip.game?.awayTeam.name }}</div>
              <div class="points">{{ currentClip.awayFinalPoints }}</div>
            </div>
            <div class="team-details" [class.emphasis]="currentClip.homeFinalPoints > currentClip.awayFinalPoints">
              <entity-avatar *ngIf="currentClip.homeTeamImage" class="logo" [imageUrl]="'https://docs-public.imgix.net/' + currentClip.homeTeamImage" [size]="16" [maskCorners]="true"></entity-avatar>
              <entity-avatar *ngIf="currentClip.game?.homeTeam?.imageUrl" class="logo" [imageUrl]="currentClip.game?.homeTeam?.imageUrl" [size]="16" [maskCorners]="true"></entity-avatar>
              <div class="name">{{ currentClip.homeTeamName || currentClip.game?.homeTeam.name }}</div>
              <div class="points">{{ currentClip.homeFinalPoints }}</div>
            </div>
          </mat-chip>
        </div>
        <div *ngIf="currentClip?.qefg" class="metadata-container">
          <div class="attribute-header">Shot</div>
          <mat-chip class="grouped-chip">
            <div class="name-value" *ngIf="currentClip?.shotDistance">
              <div class="name">Distance</div>
              <div class="value">{{ currentClip.shotDistance | number: "1.1-1" }} ft</div>
            </div>
            <div class="name-value" *ngIf="currentClip?.qefg">
              <div class="name">qFG</div>
              <div class="value" [ngClass]="'background-percentile-text-' + roundNumber(100*currentClip.qefg)">{{ currentClip.qfg | number: "1.3-3" }}</div>
            </div>
            <div class="name-value" *ngIf="currentClip?.xefg">
              <div class="name">xFG</div>
              <div class="value" [ngClass]="'background-percentile-text-' + roundNumber(100*currentClip.xefg)">{{ currentClip.xfg | number: "1.3-3" }}</div>
            </div>
          </mat-chip>
        </div>
        <ng-container *ngFor="let attribute of getFilteredAttributes(currentClip, currentClipMetadataAttributes)">
          <div *ngIf="currentClip && ((attribute.isChance && chanceViewerIDs.includes(user?.id) && (currentClip[attribute.nbaKey] || currentClip[attribute.synergyKey])) ||
            (
              (!isSynergyClip && currentClip[attribute.nbaKey] && currentClip.nbaEntityMap && currentClip.nbaEntityMap[currentClip[attribute.nbaKey]]) ||
              (isSynergyClip && currentClip[attribute.synergyKey] && currentClip.synergyEntityMap && currentClip.synergyEntityMap[currentClip[attribute.synergyKey]])
            ))"
            class="metadata-container">
            <div class="attribute-header">{{ isSynergyClip && attribute.synergyLabel ? attribute.synergyLabel : attribute.label }}</div>
            <mat-chip class="entity-chip" *ngIf="!attribute.isChance">
              <entity-avatar class="avatar" [entity]="isSynergyClip ? currentClip.synergyEntityMap[currentClip[attribute.synergyKey]] : currentClip.nbaEntityMap[currentClip[attribute.nbaKey]]" [size]="24" [maskCorners]="true"></entity-avatar>
              {{ isSynergyClip ? currentClip.synergyEntityMap[currentClip[attribute.synergyKey]].name : currentClip.nbaEntityMap[currentClip[attribute.nbaKey]].name }}
            </mat-chip>
            <mat-chip class="entity-chip" *ngIf="attribute.isChance" (click)="copyChance(attribute)">
              <mat-icon class="link">file_copy</mat-icon>
              {{ isSynergyClip ? currentClip[attribute.synergyKey]?.substring(0, 8) : currentClip[attribute.nbaKey]?.substring(0, 8) }}
            </mat-chip>
          </div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="selectedClips.length > 0" class="selection-options"
      [class.side-collapsed]="clipsOnSideCollapsed"
      [class.notes-visible]="notesVisible">
      <div class="selection-label">
        <ng-container *ngIf="selectedClips.length == 1">1 Clip Selected</ng-container>
        <ng-container *ngIf="selectedClips.length != 1">{{ selectedClips.length }} Clips Selected</ng-container>
        <button class="close-button" mat-mini-fab (click)="clearVideoSelections()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="buttons-container">
        <button class="action-button" mat-flat-button (click)="bulkSaveVideosToPlaylists()">
          <div class="inner-button-container">
            <mat-icon>playlist_add</mat-icon> Add to Playlist
          </div>
        </button>
        <button *ngIf="areSelectedClipsDownloadable()" class="action-button" mat-flat-button [matTooltip]="selectedClips.length > 100 ? 'Max number of clips is 100' : null" (click)="downloadSelectedClips()" [class.disabled]="selectedClips.length > 100">
          <div class="inner-button-container">
            <mat-icon>download</mat-icon> Download
          </div>
        </button>
        <button *ngIf="selectedPlaylist?.id  && selectedPlaylist?.id != -1 && canEditPlaylist(selectedPlaylist)" class="action-button" mat-flat-button (click)="bulkRemoveVideosFromPlaylist()">
          <div class="inner-button-container">
            <mat-icon>playlist_remove</mat-icon> Remove from Playlist
          </div>
        </button>
        <button *ngIf="areSelectedClipsDeletable() && ((selectedPlaylist?.id && selectedPlaylist?.id != -1 && canEditPlaylist(selectedPlaylist)) || selectedPlaylist?.id == -1)" class="action-button mat-elevation-z4" mat-flat-button (click)="bulkDeleteVideos()">
          <div class="inner-button-container">
            <mat-icon>delete</mat-icon> Remove from All Playlists
          </div>
        </button>
      </div>
    </div>
  </div>

  <div #editPlaylistPanel class="edit-playlist-panel" *ngIf="isEditingPlaylist">
    <div class="toolbar">
      <div class="header">Edit Playlist</div>
      <button class="close-button" mat-mini-fab (click)="cancelEditPlaylist()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="selectedPlaylist">
      <div class="label">Name</div>
      <mat-form-field>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20" [(ngModel)]="selectedPlaylist.name"
          [ngModelOptions]="{standalone: true}"></textarea>
      </mat-form-field>
      <div class="label">Description</div>
      <mat-form-field>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20"
          [(ngModel)]="selectedPlaylist.description" [ngModelOptions]="{standalone: true}"></textarea>
      </mat-form-field>
      <div class="label">Editors</div>
      <div class="sharing-options-container">
        <div class="sharing-option-button" [class.disabled]="selectedPlaylist.author.userID != user.id" [class.selected]="selectedPlaylist.editability == VideoSharingTypes.PRIVATE" (click)="togglePlaylistEditability(VideoSharingTypes.PRIVATE)">Only Me</div>
        <div class="sharing-option-button" [class.selected]="selectedPlaylist.editability == VideoSharingTypes.LIMITED" (click)="togglePlaylistEditability(VideoSharingTypes.LIMITED)">Some People</div>
        <div class="sharing-option-button" [class.selected]="selectedPlaylist.editability == VideoSharingTypes.PUBLIC" (click)="togglePlaylistEditability(VideoSharingTypes.PUBLIC)">Everyone</div>
      </div>
      <mat-chip-list *ngIf="selectedPlaylist.editability == VideoSharingTypes.LIMITED" #editorsChipList>
        <ng-container *ngFor="let user of selectedPlaylist?.sharedUsers">
          <mat-chip *ngIf="user.isEditor" class="user-chip" (removed)="removeUserEntity(user, 'isEditor')">
            <entity-avatar class="avatar" [entity]="user" [size]="24"></entity-avatar>
            <div class="name">{{ user.name }}</div>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </ng-container>
        <mat-form-field>
          <input #editorsInput matInput [matChipInputFor]="editorsChipList" [matAutocomplete]="editorsAutocomplete"
            [formControl]="editorsControl" autocomplete="off">
        </mat-form-field>
      </mat-chip-list>
      <mat-autocomplete #editorsAutocomplete="matAutocomplete">
        <mat-option *ngFor="let user of filteredEditors" [value]="user" (click)="addUserEntity(user, true, true)">
          {{ user.name }}
        </mat-option>
      </mat-autocomplete>
      <div class="label">Viewers</div>
      <div class="sharing-options-container">
        <div class="sharing-option-button" [class.disabled]="selectedPlaylist.editability != VideoSharingTypes.PRIVATE || selectedPlaylist.author.userID != user.id" [class.selected]="selectedPlaylist.visibility == VideoSharingTypes.PRIVATE" (click)="togglePlaylistVisibility(VideoSharingTypes.PRIVATE)">Only Me</div>
        <div class="sharing-option-button" [class.disabled]="selectedPlaylist.editability == VideoSharingTypes.PUBLIC" [class.selected]="selectedPlaylist.visibility == VideoSharingTypes.LIMITED" (click)="togglePlaylistVisibility(VideoSharingTypes.LIMITED)">Some People</div>
        <div class="sharing-option-button" [class.selected]="selectedPlaylist.visibility == VideoSharingTypes.PUBLIC" (click)="togglePlaylistVisibility(VideoSharingTypes.PUBLIC)">Everyone</div>
      </div>
      <mat-chip-list *ngIf="selectedPlaylist.visibility == VideoSharingTypes.LIMITED" #viewersChipList>
        <ng-container *ngFor="let user of selectedPlaylist?.sharedUsers">
          <mat-chip class="user-chip" [class.disabled]="user.isEditor" (removed)="removeUserEntity(user, 'isViewer')">
            <entity-avatar class="avatar" [entity]="user" [size]="24"></entity-avatar>
            <div class="name">{{ user.name }}</div>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </ng-container>
        <mat-form-field>
          <input #viewersInput matInput [matChipInputFor]="viewersChipList" [matAutocomplete]="viewersAutocomplete"
            [formControl]="viewersControl" autocomplete="off">
        </mat-form-field>
      </mat-chip-list>
      <mat-autocomplete #viewersAutocomplete="matAutocomplete">
        <mat-option *ngFor="let user of filteredViewers" [value]="user" (click)="addUserEntity(user, null, true)">
          {{ user.name }}
        </mat-option>
      </mat-autocomplete>
      <div class="options-toolbar">
        <button class="save-button" mat-flat-button color="accent" (click)="confirmSavePlaylist()"
          [class.disabled]="savingInProgress">{{ savingInProgress ? 'Updating...' : 'Update' }}</button>
      </div>
    </ng-container>
  </div>

  <div #savePanel class="save-panel" *ngIf="isSaving" [ngClass]="{'side-expanded': clipsOnSide && !clipsOnSideCollapsed, 'side-collapsed': clipsOnSide && clipsOnSideCollapsed, 'notes-visible': clipsOnSide && !clipsOnSideCollapsed && notesVisible}">
    <div class="toolbar">
      <div class="header">Save Video</div>
      <button class="close-button" mat-mini-fab (click)="cancelSaveVideo()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="currentClip">
      <div class="label">Playlists</div>
      <mat-form-field>
        <mat-select multiple [(ngModel)]="currentClip.playlists" [compareWith]="compareIDs"
          [ngModelOptions]="{standalone: true}">
          <div class="new-playlist" (click)="createPlaylist()">
            <div class="add-playlist-container">
              <div><mat-icon class="add-playlist-button">add</mat-icon></div>
              <div>New Playlist</div>
            </div>
          </div>
          <ng-container *ngFor="let userPlaylist of userPlaylists">
            <mat-option *ngIf="canEditPlaylist(userPlaylist)" [value]="userPlaylist">
              {{ userPlaylist.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <div class="label">Name</div>
      <mat-form-field>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20" [(ngModel)]="currentClip.name"
          [ngModelOptions]="{standalone: true}"></textarea>
      </mat-form-field>
      <div class="label">Commentary</div>
      <mat-form-field>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20"
          [(ngModel)]="currentClip.commentary" [ngModelOptions]="{standalone: true}"></textarea>
      </mat-form-field>
      <div class="label">Players ({{ getCurrentNumberOfPlayersSelected() +
        currentClip.additionalTaggedEntities?.length }} selected)</div>
      <div class="player-options-container">
        <div *ngFor="let onCourtEntity of currentClip?.onCourtEntities" class="on-court-player-option"
          [class.selected]="onCourtEntity.selected" (click)="onCourtEntity.selected = !onCourtEntity.selected">
          <entity-avatar class="avatar" [entity]="onCourtEntity" [size]="24"></entity-avatar>
          <div class="name">{{ onCourtEntity.name }}</div>
        </div>
        <mat-chip-list #entitiesChipList>
          <ng-container *ngFor="let entity of currentClip.additionalTaggedEntities">
            <mat-chip class="entity-chip" (removed)="removeTaggedEntity(entity)">
              <entity-avatar class="avatar" [entity]="entity" [size]="24"></entity-avatar>
              {{ entity.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
          <input #entitiesInput matInput placeholder="Add Players" [matChipInputFor]="entitiesChipList"
            [matAutocomplete]="entitiesAutocomplete" [formControl]="entitiesControl">
        </mat-chip-list>
        <mat-autocomplete #entitiesAutocomplete="matAutocomplete" (optionSelected)="addTaggedEntity($event)">
          <mat-option *ngFor="let entity of filteredEntities" [value]="entity">
            <entity-context [entity]="entity"></entity-context>
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="label">Notes (Private)</div>
      <mat-form-field>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20"
          [(ngModel)]="currentClip.notes" [ngModelOptions]="{standalone: true}"></textarea>
      </mat-form-field>
      <div class="label">Label (Private)</div>
      <mat-form-field>
        <mat-select disableRipple [(value)]="currentClip.label">
          <mat-option></mat-option>
          <mat-option class="save-panel__color-option" value="Blue"><div class="color-indicator" style="background-color: #9acce3"></div> Blue</mat-option>
          <mat-option class="save-panel__color-option" value="Green"><div class="color-indicator" style="background-color: #13c16d"></div> Green</mat-option>
          <mat-option class="save-panel__color-option" value="Red"><div class="color-indicator" style="background-color: #e27061"></div> Red</mat-option>
          <mat-option class="save-panel__color-option" value="Yellow"><div class="color-indicator" style="background-color: #ecea17"></div> Yellow</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="options-toolbar">
        <button class="save-button" mat-flat-button color="accent" (click)="confirmSaveVideo()"
          [class.disabled]="savingInProgress">{{ savingInProgress ? (currentClip?.id ? 'Updating...' : 'Saving...') :
          (currentClip?.id ? 'Update' : 'Save') }}</button>
      </div>
    </ng-container>
  </div>

  <div #bulkSavePanel class="bulk-save-panel" *ngIf="isSavingMany">
    <div class="toolbar">
      <div class="header">Add {{ selectedClips.length }} Videos to Playlist</div>
      <button class="close-button" mat-mini-fab (click)="cancelBulkSaveVideosToPlaylists()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="currentClip">
      <div class="add-playlist-container" (click)="createPlaylist()">
        <mat-icon class="add-playlist-button">add</mat-icon>
        <div>New Playlist</div>
      </div>
      <div class="thin-divider"></div>
      <ng-container *ngFor="let userPlaylist of userPlaylists">
        <mat-checkbox *ngIf="canEditPlaylist(userPlaylist)"
          class="playlist-destination-option"
          [checked]="isBulkSaveDestinationPlaylist(userPlaylist)"
          (change)="updateBulkSaveDestinationPlaylists(userPlaylist, $event.checked)">
          {{ userPlaylist.name }}
        </mat-checkbox>
      </ng-container>
      <div class="options-toolbar">
        <button class="save-button" mat-flat-button color="accent" (click)="confirmBulkSaveVideosToPlaylists()"
          [class.disabled]="savingInProgress">{{ savingInProgress ? 'Adding...' : 'Add To ' + bulkSaveDestinationPlaylists.length + ' Playlists' }}</button>
      </div>
    </ng-container>
  </div>

  <div #tagPanel class="tag-panel" *ngIf="isTagging" [ngClass]="{'side-expanded': clipsOnSide && !clipsOnSideCollapsed, 'side-collapsed': clipsOnSide && clipsOnSideCollapsed, 'notes-visible': clipsOnSide && !clipsOnSideCollapsed && notesVisible}">
    <div class="toolbar">
      <div class="header">{{ tagEditing ? 'Edit Tag' : 'Save Tag' }}</div>
      <button class="close-button" mat-mini-fab (click)="cancelSaveTag()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="label">Tag Type</div>
    <mat-form-field>
      <mat-select [(ngModel)]="selectedTagType" [compareWith]="compareIDs"
        [ngModelOptions]="{standalone: true}">
        <ng-container *ngFor="let tagDefinition of tagDefinitions">
          <mat-option [value]="tagDefinition">
            {{ tagDefinition.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="selectedTagType">
      <ng-container *ngFor="let metadataDefinition of selectedTagType.metadataDefinitions">
        <div class="label">{{ metadataDefinition.name }}</div>
        <ng-container *ngIf="metadataDefinition.type == 'Person'">
          <div class="player-options-container">
            <div *ngFor="let onCourtEntity of currentClip?.onCourtEntities" class="on-court-player-option" [class.selected]="onCourtEntity?.id && metadataDefinition.value?.id == onCourtEntity?.id" [class.enabled]="onCourtEntity.id" [class.disabled]="!onCourtEntity.id" (click)="metadataDefinition.value?.id == onCourtEntity?.id ? metadataDefinition.value = null : metadataDefinition.value = onCourtEntity">
              <entity-avatar class="avatar" [entity]="onCourtEntity" [size]="24"></entity-avatar>
              <div class="name">{{ onCourtEntity.name }}</div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="metadataDefinition.type == 'Selection'">
          <div class="selection-options-container">
            <div *ngFor="let selectionOption of metadataDefinition.options" class="selection-option" [class.selected]="metadataDefinition.value?.id == selectionOption?.id" (click)="metadataDefinition.value?.id == selectionOption?.id ? metadataDefinition.value = null : metadataDefinition.value = selectionOption">
              <div class="name">{{ selectionOption.name }}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="options-toolbar">
      <button class="save-button" mat-flat-button color="accent" (click)="confirmSaveTag()"
        [class.disabled]="savingInProgress">{{ savingInProgress ? (currentClip?.id ? 'Updating...' : 'Saving...') :
        (currentClip?.id ? 'Update' : 'Save') }}</button>
    </div>
  </div>
</div>

<mat-menu class="filter-options" #filterOptions="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent>
    <div (click)="$event.stopPropagation();">
      <div class="filter-row">
        <div class="filter-label">Availability</div>
        <div class="filter-input">
          <mat-select multiple [(ngModel)]="selectedClipAvailabilities" [ngModelOptions]="{standalone: true}" (selectionChange)="updateDisplayedClips()">
            <mat-option [value]="true">Available Clips</mat-option>
            <mat-option [value]="false">Unavailable Clips</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="filter-row">
        <div class="filter-label">Possession</div>
        <div class="filter-input">
          <mat-select multiple [(ngModel)]="selectedPossessionTeams" [ngModelOptions]="{standalone: true}" (selectionChange)="updateDisplayedClips()">
            <mat-option *ngFor="let team of possessionTeamOptions" [value]="team" [disabled]="selectedPossessionTeams.length <= 1 && selectedPossessionTeams.includes(team)">
              {{ team }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </ng-template>
</mat-menu>

<mat-menu class="playlist-options" #playlistOptions="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent>
    <div class="playlist-options-button-container" (click)="$event.stopPropagation();">
      <button class="action-button mat-elevation-z4" mat-flat-button (click)="editPlaylist()" *ngIf="selectedPlaylist?.id && selectedPlaylist?.id != -1 && canEditPlaylist(selectedPlaylist)">
        <div class="inner-button-container">
          <mat-icon>edit</mat-icon> Edit Playlist
        </div>
      </button>
      <button class="action-button mat-elevation-z4" mat-flat-button (click)="bulkSavePlaylistVideosToPlaylists()">
        <div class="inner-button-container">
          <mat-icon>playlist_add</mat-icon> Add to Playlist
        </div>
      </button>
      <!-- <button class="action-button" mat-flat-button>
        <div class="inner-button-container">
          <mat-icon>ondemand_video</mat-icon> Add to Queue
        </div>
      </button> -->
      <button class="action-button mat-elevation-z4" mat-flat-button (click)="copyLink()" *ngIf="selectedPlaylist?.id && selectedPlaylist?.id != -1">
        <div class="inner-button-container">
          <mat-icon>link</mat-icon> Copy Link
        </div>
      </button>
      <button *ngIf="selectedPlaylist?.author?.userID == user.id" class="action-button mat-elevation-z4" mat-flat-button (click)="deletePlaylist()">
        <div class="inner-button-container">
          <mat-icon>delete</mat-icon> Delete
        </div>
      </button>
    </div>
  </ng-template>
</mat-menu>

<mat-menu class="video-options" #videoOptions="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent>
    <div class="video-options-button-container" (click)="$event.stopPropagation();">
      <button class="action-button mat-elevation-z4" mat-flat-button (click)="saveTag()">
        <div class="inner-button-container">
          <mat-icon>sell</mat-icon> Save Tag
        </div>
      </button>
      <button *ngIf="isClipDownloadable(currentClip)" class="action-button mat-elevation-z4" mat-flat-button (click)="downloadCurrentClip()">
        <div class="inner-button-container">
          <mat-icon>download</mat-icon> Download
        </div>
      </button>
      <button *ngIf="currentClip.author?.userID == user.id && selectedPlaylist?.id && selectedPlaylist?.id != -1 && canEditPlaylist(selectedPlaylist)" class="action-button mat-elevation-z4" mat-flat-button (click)="removeVideoFromPlaylist()">
        <div class="inner-button-container">
          <mat-icon>playlist_remove</mat-icon> Remove from Playlist
        </div>
      </button>
      <button *ngIf="currentClip.author?.userID == user.id && ((selectedPlaylist?.id && selectedPlaylist?.id != -1 && canEditPlaylist(selectedPlaylist)) || selectedPlaylist?.id == -1)" class="action-button mat-elevation-z4" mat-flat-button (click)="deleteVideo()">
        <div class="inner-button-container">
          <mat-icon>delete</mat-icon> Remove from All Playlists
        </div>
      </button>
    </div>
  </ng-template>
</mat-menu>

<mat-menu class="tag-options" #tagOptions="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent let-tag="tag">
    <div class="tag-options-button-container" (click)="$event.stopPropagation();">
      <button class="action-button mat-elevation-z4" mat-flat-button (click)="editTag(tag)">
        <div class="inner-button-container">
          <mat-icon>edit</mat-icon> Edit Tag
        </div>
      </button>
      <button class="action-button mat-elevation-z4" mat-flat-button (click)="deleteTag(tag)">
        <div class="inner-button-container">
          <mat-icon>delete</mat-icon> Delete Tag
        </div>
      </button>
    </div>
  </ng-template>
</mat-menu>

<div class="bottom-toolbar" *ngIf="!isGeneratingPlaylist" [class.full-screen]="enteredFullScreen">
  <button mat-button class="bottom-toolbar-button browse" (click)="switchToExploreView()"
          [ngClass]="{selected: sessionMetadata?.currentNavigation == VideoNavigationTypes.EXPLORE}">
    <mat-icon class="bottom-toolbar-icon">video_library</mat-icon>
    <span class="bottom-toolbar-label">Explore</span>
  </button>

  <div class="playlist-hotkeys-container" *ngIf="userPlaylists?.length && isHotkeysIntialized">
    <ng-container *ngFor="let _ of [].constructor(6); let i = index">
      <div class="bottom-toolbar-playlist-hotkey" [ngClass]="{'selected-playlist-hotkey': this['selectedPlaylist' + (i + 1)]?.id == selectedPlaylist?.id && selectedPlaylist?.id, disabled: this['selectedPlaylist' + (i + 1)] == null}">
        {{i + 1}}
      </div>
      <button mat-button class="bottom-toolbar-button" [ngClass]="{'selected-playlist-hotkey': this['selectedPlaylist' + (i + 1)]?.id == selectedPlaylist?.id && selectedPlaylist?.id, disabled: this['selectedPlaylist' + (i + 1)] == null}" (click)="selectPlaylistHotkey(i + 1)">
        <mat-icon class="bottom-toolbar-icon">playlist_play</mat-icon>
        <span class="bottom-toolbar-label">{{this['selectedPlaylist' + (i + 1)]?.name}}</span>
      </button>
      <div class="bottom-toolbar-playlist-trigger" tabindex="1" (blur)="blurPlaylistHokeyMenu($event, i)"
          [ngClass]="{'selected-playlist-hotkey': this['selectedPlaylist' + (i + 1)]?.id == selectedPlaylist?.id  && this['selectedPlaylist' + (i + 1)]?.id}"
          cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="this['playlistMenu' + i + 'Open'] = !this['playlistMenu' + i + 'Open']">
        <mat-icon>{{this['playlistMenu' + i + 'Open'] ? 'arrow_drop_up' : 'arrow_drop_down'}} </mat-icon>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="this['playlistMenu' + i + 'Open']"
          [cdkConnectedOverlayPositions]="positions">
          <div class="playlist-options-list" tabindex="2" (blur)="blurPlaylistHokeyMenu($event, i)">
            <playlist-menu [isHotkeyMenu]="true" (updateHotkey)="switchPlaylistHotkey(i+1, $event)"></playlist-menu>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
  <button *ngIf="enteredFullScreen" mat-button class="bottom-toolbar-button table-position-button" (click)="clipsOnSide = !clipsOnSide">
    <ng-container *ngIf="!clipsOnSide">
      <mat-icon class="bottom-toolbar-icon">view_sidebar</mat-icon>
      <span class="bottom-toolbar-label">Move Table To Side</span>
    </ng-container>
    <ng-container *ngIf="clipsOnSide">
      <mat-icon class="bottom-toolbar-icon">call_to_action</mat-icon>
      <span class="bottom-toolbar-label">Move Table To Bottom</span>
    </ng-container>
  </button>
</div>

<bild-generate-playlist *ngIf="isGeneratingPlaylist" #generatePlaylistComponent
                        [entityGLG]="entityGLG"
                        [entityNBA]="entityNBA"
                        [entityNCAA]="entityNCAA"
                        [displayedFilters]="displayedFilters"
                        [filtersData]="filtersData"
                        [gameOptions]="gameOptions"
                        [userGeneratedPlaylists]="userGeneratedPlaylists"
                        (generatedPlaylistParameters)="updateGeneratedPlaylist($event)"
></bild-generate-playlist>
