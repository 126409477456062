import {Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {EVALUATION_SETTING_DETAIL_EVENTS, EvaluationSettingDetailEvent} from '@models/constants/evaluation-setting-detail-events';
import {Team} from './teams';
import {forwardRefConstant, forwardRefMoment} from '@helpers/class-transformer.helper';
import * as moment from 'moment-timezone';
import {PersonnelSection} from '@models/scoreboard/scouting-preview';
import { EvaluationSetting } from './constants/evaluation-settings';

export class EvaluationSettingDetail {
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  date: moment.Moment;
  @Type(forwardRef(() => EvaluationSetting) as any)
  setting: EvaluationSetting;
  @Type(forwardRef(() => Team) as any)
  awayTeam: Team;
  @Transform(forwardRefConstant(EVALUATION_SETTING_DETAIL_EVENTS), { toClassOnly: true })
  event: EvaluationSettingDetailEvent;
  @Type(forwardRef(() => Team) as any)
  homeTeam: Team;
  @Type(forwardRef(() => PersonnelSection) as any)
  personnelMetadata: PersonnelSection;
  @Type(forwardRef(() => Team) as any)
  team: Team;
  gameId: string;
  league: string;
}
