export const TYPE_IDS = {
    PERSON_NBA_TEAM: 1,
    PERSON_G_LEAGUE_TEAM: 2,
    PERSON_NCAA_TEAM: 3,
    PERSON_INTERNATIONAL_TEAM: 4,
    PERSON_AAU_TEAM: 5,
    PERSON_FIBA_TEAM: 6,
    PERSON_PREP_TEAM: 7,
    PERSON_EVENT_TEAM: 8,
    PERSON_AGENCY: 9,
    PERSON_AGENT: 10,
    PERSON_NBA_LEAGUE: 11,
    PERSON_G_LEAGUE: 12,
    PERSON_NCAA_LEAGUE: 13,
    PERSON_INTERNATIONAL_LEAGUE: 14,
    PERSON_AAU_LEAGUE: 15,
    PERSON_FIBA_LEAGUE: 16,
    PERSON_PREP_LEAGUE: 17,
    PERSON_EVENT_LEAGUE: 18,
    TEAM_NBA: 19,
    TEAM_G_LEAGUE: 20,
    TEAM_NCAA: 21,
    TEAM_INTERNATIONAL: 22,
    TEAM_AAU: 23,
    TEAM_FIBA: 24,
    TEAM_PREP: 25,
    TEAM_EVENT: 26
};

export const PERSON_TEAM_TYPE_IDS = [
    1, 2, 3, 4, 5, 6, 7, 8
];

export const PERSON_LEAGUE_TYPE_IDS = [
    11, 12, 13, 14, 15, 16, 17, 18
];

export const TEAM_LEAGUE_TYPE_IDS = [
    19, 20, 21, 22, 23, 24, 25, 26
];

export const SOURCE_IDS = {
    PCMS: 1,
    DRAFT_EXPRESS: 2,
    INITIAL_PRIMARY: 3,
    USER: 4,
    REALGM: 5
};

export const THUNDER_BILD_ID = 2;
export const BLUE_BILD_ID = 11569;
