
export enum EntityType {
  LEAGUE = 'League',
  TEAM = 'Team',
  PERSON = 'Person',
  LEAGUE_PERSON = 'League Person',
  LEAGUE_TEAM = 'League Team',
}

export const EntityTypeAPIPathMap = {
  [EntityType.TEAM]: 'teams',
  [EntityType.PERSON]: 'people',
  [EntityType.LEAGUE]: 'leagues',
  [EntityType.LEAGUE_PERSON]: 'leagues',
  [EntityType.LEAGUE_TEAM]: 'leagues'
};

export const EntityTypeIDMap = {
  [EntityType.PERSON]: 1,
  [EntityType.TEAM]: 2,
  [EntityType.LEAGUE]: 4,
}
