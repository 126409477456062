import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Roster} from '@models/roster-entity';

export const featureAdapter: EntityAdapter<Roster> = createEntityAdapter<Roster>({
  selectId: model => {
    return model.team.id;
  },
});

export interface State extends EntityState<Roster> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState(
  {
    isLoading: false,
    error: null,
  }
);
