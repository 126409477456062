import {IdName} from '../id-names/_id-names';
import {plainToClass} from 'class-transformer';

export const BILD_USER_TYPE_IDS = {
  DENY: 0,
  USER: 1,
  ADMIN: 3
};

export class BildUserType extends IdName {}

export const BILD_USER_TYPES = {};
BILD_USER_TYPES[BILD_USER_TYPE_IDS.DENY] = plainToClass(BildUserType, {id: BILD_USER_TYPE_IDS.DENY, name: 'Deny'});
BILD_USER_TYPES[BILD_USER_TYPE_IDS.USER] = plainToClass(BildUserType, {id: BILD_USER_TYPE_IDS.USER, name: 'User'});
BILD_USER_TYPES[BILD_USER_TYPE_IDS.ADMIN] = plainToClass(BildUserType, {id: BILD_USER_TYPE_IDS.ADMIN, name: 'Admin'});
