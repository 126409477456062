import {plainToClass} from 'class-transformer';

import {IdName} from '@models/id-names/_id-names';

export const LEAGUE_IDS = {
  NBA: 41,
  G_LEAGUE: 44,
  GLG: 44,
  NCAA: 42,
  INTERNATIONAL: 43,
  AAU: 45,
  PREP: 47,
};

export const LEAGUE_IDS_TO_BILD_IDS = {
  41: 1,
  42: 101,
  44: 2,
};

export const BILD_LEAGUE_IDS = {
  NBA: 1,
  G_LEAGUE: 2,
  VEGAS_SUMMER: 7,
  ORLANDO_SUMMER: 8,
  UTAH_SUMMER: 9,
  SACRAMENTO_SUMMER: 13,
  NCAA: 101,
  INTERNATIONAL_PREP: 102,
};

export const AMATEUR_LEAGUES = [
  BILD_LEAGUE_IDS.INTERNATIONAL_PREP,
  BILD_LEAGUE_IDS.NCAA,
];

export const PRO_LEAGUES = [
  BILD_LEAGUE_IDS.NBA,
  BILD_LEAGUE_IDS.G_LEAGUE,
  BILD_LEAGUE_IDS.VEGAS_SUMMER,
  BILD_LEAGUE_IDS.ORLANDO_SUMMER,
  BILD_LEAGUE_IDS.UTAH_SUMMER,
  BILD_LEAGUE_IDS.SACRAMENTO_SUMMER,
];

export class Leagues extends IdName {
  abbrev: string;
  imageUrl: string;
}

export const LEAGUES = {};
LEAGUES[LEAGUE_IDS.NBA] = plainToClass(Leagues, {id: LEAGUE_IDS.NBA, abbrev: 'NBA', name: 'NBA', imageUrl: 'https://docs-public.imgix.net/images/sportslogosnet/leagues/8147__national_basketball_association-primary-2018.png'});
LEAGUES[LEAGUE_IDS.G_LEAGUE] = plainToClass(Leagues, {id: LEAGUE_IDS.G_LEAGUE, abbrev: 'GLG', name: 'G League', imageUrl: 'https://docs-public.imgix.net/images/international_leagues/44.png'});
LEAGUES[LEAGUE_IDS.NCAA] = plainToClass(Leagues, {id: LEAGUE_IDS.NCAA, abbrev: 'NCAA', name: 'NCAA', imageUrl: 'https://docs-public.imgix.net/images/sportslogosnet/leagues/5953__ncaa_-primary-2000.png'});
LEAGUES[LEAGUE_IDS.INTERNATIONAL] = plainToClass(Leagues, {id: LEAGUE_IDS.INTERNATIONAL, name: 'International'});
LEAGUES[LEAGUE_IDS.AAU] = plainToClass(Leagues, {id: LEAGUE_IDS.AAU, name: 'AAU'});
LEAGUES[LEAGUE_IDS.PREP] = plainToClass(Leagues, {id: LEAGUE_IDS.PREP, name: 'PREP'});

export const bildIDToLeagueID = (leagueID) => {
  switch (leagueID) {
    case LEAGUE_IDS.NBA:
      return BILD_LEAGUE_IDS.NBA;
    case LEAGUE_IDS.G_LEAGUE:
      return BILD_LEAGUE_IDS.G_LEAGUE;
    case LEAGUE_IDS.INTERNATIONAL:
      return BILD_LEAGUE_IDS.INTERNATIONAL_PREP;
    case LEAGUE_IDS.NCAA:
      return BILD_LEAGUE_IDS.NCAA;
    default:
      return leagueID;
  }
};
