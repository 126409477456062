import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';

import {Article} from '@models/articles';
import {adapter, State} from './state';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getNextPageToken = (state: State): string => state.nextPageDateToken;
export const getPrevPageToken = (state: State): string => state.prevPageDateToken;
export const getNextPageIDToken = (state: State): string => state.nextPageIDToken;
export const getPrevPageIDToken = (state: State): string => state.prevPageIDToken;
export const getFilteredIds = (state: State): number[] => state.filteredIds;

export const selectInjuryArticleState: MemoizedSelector<object, State> = createFeatureSelector<State>('injury-article');

export const selectAllInjuryArticles: (state: object) => Article[] = adapter.getSelectors(selectInjuryArticleState).selectAll;

export const selectInjuryArticleFilteredIds: MemoizedSelector<object, number[]> = createSelector(selectInjuryArticleState, getFilteredIds);

export const selectFilteredInjuryArticles = createSelector(
  selectAllInjuryArticles,
  selectInjuryArticleFilteredIds,
  (articles: Article[], ids: number[]) => {
    return articles.filter(article => ids.indexOf(article.postID) >= 0);
  }
);

export const selectInjuryArticleNextPage: MemoizedSelector<object, any> = createSelector(selectInjuryArticleState, getNextPageToken);
export const selectInjuryArticlePrevPage: MemoizedSelector<object, any> = createSelector(selectInjuryArticleState, getPrevPageToken);
export const selectInjuryArticleNextPageID: MemoizedSelector<object, any> = createSelector(selectInjuryArticleState, getNextPageIDToken);
export const selectInjuryArticlePrevPageID: MemoizedSelector<object, any> = createSelector(selectInjuryArticleState, getPrevPageIDToken);
