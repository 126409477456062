<ng-container *ngIf="filtersData?.filters?.length">
  <button class="action-button" mat-flat-button (click)="isCollapsed = !isCollapsed" [ngClass]="{'is-generate-playlist': isGeneratePlaylist, 'is-recent-generated-playlist': isRecentGeneratedPlaylist}">
    <div class="inner-button-container">
      <mat-icon class="filters-icon">tune</mat-icon>
      <span class="filter-count">{{ filtersCount }} Filters</span>
    </div>
  </button>
</ng-container>

<div class="generated-playlist-filters" (mouseleave)="hideFilterPanel()"
     *ngIf="!isCollapsed"
     [ngClass]="{'is-generate-playlist': isGeneratePlaylist, 'is-recent-generated-playlist': isRecentGeneratedPlaylist}">
  <video-filter-chip
    [displayText]="videoSubjectToLabel(filtersData.subject)"
    [header]="'Event'"
    [ignoreClickEvents]="isRecentGeneratedPlaylist"
  ></video-filter-chip>

  <ng-container *ngFor="let filter of videoFilterItems">
    <ng-container *ngIf="filter.conditional">
      <ng-container *ngIf="filter.iterable">
        <ng-container *ngFor="let item of filter.iterable; let first = first">
          <video-filter-chip
            [displayText]="adjustedItemName(item, filter)"
            [entity]="item"
            [filterName]="filter.filterName"
            [first]="first"
            [header]="filter.header"
            [ignoreClickEvents]="filter.isRecentGeneratedPlaylist"
          ></video-filter-chip>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!filter.iterable">
        <video-filter-chip
                           [displayText]="filter.displayText"
                           [entity]="filter.entity"
                           [filterName]="filter.filterName"
                           [games]="filter.games"
                           [header]="filter.header"
                           [ignoreClickEvents]="filter.isRecentGeneratedPlaylist"
                           [minDate]="filter.minDate"
                           [maxDate]="filter.maxDate"
        ></video-filter-chip>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
