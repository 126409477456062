import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';

import {Article} from '@models/articles';
import {adapter, State} from './state';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getNextPageToken = (state: State): string => state.nextPageDateToken;
export const getPrevPageToken = (state: State): string => state.prevPageDateToken;
export const getNextPageTokenID = (state: State): string => state.nextPageIDToken;
export const getPrevPageTokenID = (state: State): string => state.prevPageIDToken;
export const getFilteredIds = (state: State): number[] => state.filteredIds;

export const selectNewsArticleState: MemoizedSelector<object, State> = createFeatureSelector<State>('news-article');

export const selectAllNewsArticles: (state: object) => Article[] = adapter.getSelectors(selectNewsArticleState).selectAll;

export const selectNewsArticleFilteredIds: MemoizedSelector<object, number[]> = createSelector(selectNewsArticleState, getFilteredIds);

export const selectFilteredNewsArticles = createSelector(
  selectAllNewsArticles,
  selectNewsArticleFilteredIds,
  (newsPosts: Article[], ids: number[]) => {
    return newsPosts.filter(newsPost => ids.indexOf(newsPost.postID) >= 0);
  }
);

export const selectNewsArticleNextPage: MemoizedSelector<object, any> = createSelector(selectNewsArticleState, getNextPageToken);
export const selectNewsArticlePrevPage: MemoizedSelector<object, any> = createSelector(selectNewsArticleState, getPrevPageToken);
export const selectNewsArticleNextPageID: MemoizedSelector<object, any> = createSelector(selectNewsArticleState, getNextPageTokenID);
export const selectNewsArticlePrevPageID: MemoizedSelector<object, any> = createSelector(selectNewsArticleState, getPrevPageTokenID);
