<div mat-dialog-title>
  <h2>User Groups</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
</div>

<mat-table [ngClass]="{'hidden': userGroupsDataSource.data.length === 0}" #table [dataSource]="userGroupsDataSource" matSort>

  <!-- Select Column -->
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="isAllSelected"
                    [indeterminate]="selection.selected.length > 0 && !isAllSelected">
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row.id) : null"
                    [checked]="selection.isSelected(row.id)">
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="members">
    <mat-header-cell *matHeaderCellDef>Members</mat-header-cell>
    <mat-cell *matCellDef="let element" bildTooltip="{{ getNamesListHTML(element.members) }}" [bildTooltipIsHTML]="true">{{ displayLastNames(element.members) }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<mat-paginator #paginator [length]="userGroupsDataSource.data.length" [pageIndex]="0" [pageSize]="10"></mat-paginator>
