import {plainToClass} from 'class-transformer';

import {IdName} from '@models/id-names/_id-names';

export const TIER_IDS = {
  TIER_1: 1400,
  TIER_2: 1401,
  TIER_3_IN_PROGRESS: 1402,
  TIER_3_BLUE: 1403,
  TIER_4_BAROMETER: 1404,
  TIER_5_FIRST_ROUND_TALENT: 1405,
  TIER_6_SECOND_ROUND_TALENT: 1406,
  TIER_7_TWO_WAY_TARGET: 1407,
  TIER_8_EXHIBIT_10_TARGET: 1408,
  TIER_9_BLUE_TARGET: 1409,
  TIER_10_NBA_DRAFT_POTENTIAL: 1410,
};

export class Tier extends IdName {}

export const TIERS = {};
TIERS[TIER_IDS.TIER_1] = plainToClass(Tier, {id: TIER_IDS.TIER_1, name: 'Tier 1'});
TIERS[TIER_IDS.TIER_2] = plainToClass(Tier, {id: TIER_IDS.TIER_2, name: 'Tier 2'});
TIERS[TIER_IDS.TIER_3_IN_PROGRESS] = plainToClass(Tier, {id: TIER_IDS.TIER_3_IN_PROGRESS, name: 'Tier 3 - In Progress'});
TIERS[TIER_IDS.TIER_3_BLUE] = plainToClass(Tier, {id: TIER_IDS.TIER_3_BLUE, name: 'Tier 3 - Blue'});
TIERS[TIER_IDS.TIER_4_BAROMETER] = plainToClass(Tier, {id: TIER_IDS.TIER_4_BAROMETER, name: 'Tier 4 - Barometer'});
TIERS[TIER_IDS.TIER_5_FIRST_ROUND_TALENT] = plainToClass(Tier, {id: TIER_IDS.TIER_5_FIRST_ROUND_TALENT, name: 'First Round Talent'});
TIERS[TIER_IDS.TIER_6_SECOND_ROUND_TALENT] = plainToClass(Tier, {id: TIER_IDS.TIER_6_SECOND_ROUND_TALENT, name: 'Second Round Talent'});
TIERS[TIER_IDS.TIER_7_TWO_WAY_TARGET] = plainToClass(Tier, {id: TIER_IDS.TIER_7_TWO_WAY_TARGET, name: 'Two-Way Target'});
TIERS[TIER_IDS.TIER_8_EXHIBIT_10_TARGET] = plainToClass(Tier, {id: TIER_IDS.TIER_8_EXHIBIT_10_TARGET, name: 'Exhibit 10 Target'});
TIERS[TIER_IDS.TIER_9_BLUE_TARGET] = plainToClass(Tier, {id: TIER_IDS.TIER_9_BLUE_TARGET, name: 'Blue Target'});
TIERS[TIER_IDS.TIER_10_NBA_DRAFT_POTENTIAL] = plainToClass(Tier, {id: TIER_IDS.TIER_10_NBA_DRAFT_POTENTIAL, name: 'Grassroots - NBA Draft Potential'});
