import * as moment from 'moment-timezone';

import {Transform, Type, plainToClass} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {AuthService} from '@services/auth.service';
import {IdName} from './id-names/_id-names';
import {Person} from './people';
import {forwardRefConstant, forwardRefMoment} from '@helpers/class-transformer.helper';
import {Entity} from '@models/entities';

export const LOC_RATING_IDS = {
  ML: 12,
  RO: 13,
  RE: 14,
  KR: 15,
  S: 16,
  TS: 17,
  C: 18,
  F: 73
};

export const LOC_RATING_POINTS = {
  1: 'ML',
  2: 'RO',
  3: 'RE',
  4: 'KR',
  5: 'S',
  6: 'TS',
  7: 'C',
  8: 'F'
};

export const LOC_LABEL_TO_NAME = {
  ML: 'Minor League',
  RO: 'Roster',
  RE: 'Reserve',
  KR: 'Key Reserve',
  S: 'Starter',
  TS: 'Top Starter',
  C: 'Core',
  F: 'Franchise'
};

export const LOCS_COLORS = ['#4575B4', '#90C3DD', '#c6e6f1', '#e7f5f9', '#fec779', '#f57446', '#e95d40', '#cb2e25'];

export const TSA_TO_LOC = [
  {tsa: 7.40389300782484, loc: 8},
  {tsa: 7.2908345734819, loc: 7.83928571428571},
  {tsa: 6.5873598709036, loc: 7},
  {tsa: 6.15212288881655, loc: 6},
  {tsa: 5.48855943912278, loc: 5},
  {tsa: 5.04674470796262, loc: 4},
  {tsa: 4.59376475837316, loc: 3},
  {tsa: 3.98744647869279, loc: 2},
  {tsa: 3.57290194656322, loc: 1.12907766186455},
  {tsa: 3.5193935076172, loc: 1}
];

export class LOCRating extends IdName {
  value: number;
}

export const LOC_RATINGS = {};
LOC_RATINGS[LOC_RATING_IDS.ML] = plainToClass(LOCRating, {id: LOC_RATING_IDS.ML, name: 'ML', value: 1});
LOC_RATINGS[LOC_RATING_IDS.RO] = plainToClass(LOCRating, {id: LOC_RATING_IDS.RO, name: 'RO', value: 2});
LOC_RATINGS[LOC_RATING_IDS.RE] = plainToClass(LOCRating, {id: LOC_RATING_IDS.RE, name: 'RE', value: 3});
LOC_RATINGS[LOC_RATING_IDS.KR] = plainToClass(LOCRating, {id: LOC_RATING_IDS.KR, name: 'KR', value: 4});
LOC_RATINGS[LOC_RATING_IDS.S] = plainToClass(LOCRating, {id: LOC_RATING_IDS.S, name: 'S', value: 5});
LOC_RATINGS[LOC_RATING_IDS.TS] = plainToClass(LOCRating, {id: LOC_RATING_IDS.TS, name: 'TS', value: 6});
LOC_RATINGS[LOC_RATING_IDS.C] = plainToClass(LOCRating, {id: LOC_RATING_IDS.C, name: 'C', value: 7});
LOC_RATINGS[LOC_RATING_IDS.F] = plainToClass(LOCRating, {id: LOC_RATING_IDS.F, name: 'F', value: 8});

export const LOC_RATING_ARRAY = [
  plainToClass(LOCRating, {id: LOC_RATING_IDS.ML, name: 'ML', value: 1}), plainToClass(LOCRating, {id: LOC_RATING_IDS.RO, name: 'RO', value: 2}),
  plainToClass(LOCRating, {id: LOC_RATING_IDS.RE, name: 'RE', value: 3}),plainToClass(LOCRating, {id: LOC_RATING_IDS.KR, name: 'KR', value: 4}),
  plainToClass(LOCRating, {id: LOC_RATING_IDS.S, name: 'S', value: 5}),plainToClass(LOCRating, {id: LOC_RATING_IDS.TS, name: 'TS', value: 6}),
  plainToClass(LOCRating, {id: LOC_RATING_IDS.C, name: 'C', value: 7}),plainToClass(LOCRating, {id: LOC_RATING_IDS.F, name: 'F', value: 8})
];

export class LOCMetadata {
  nextPageDateToken: string;
  prevPageDateToken: string;
  nextPageIDToken: number;
  prevPageIDToken: number;
}

export class LOC {
  id: number;
  @Transform(forwardRefConstant(LOC_RATINGS), { toClassOnly: true })
  low: LOCRating = null;
  @Transform(forwardRefConstant(LOC_RATINGS), { toClassOnly: true })
  mid: LOCRating = null;
  @Transform(forwardRefConstant(LOC_RATINGS), { toClassOnly: true })
  high: LOCRating = null;
  @Transform(forwardRefConstant(LOC_RATINGS), { toClassOnly: true })
  now: LOCRating = null;
  noThunder: boolean = false;
  @Type(forwardRef(() => Person) as any)
  about: Person;
  @Type(forwardRef(() => Person) as any)
  author: Person;
  postID: number;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  postDatetime: moment.Moment;
  age: number;
  usedInSummaryCalc: any[];

  // TODO Would probably be better if we just had the API return the
  // permissions already setup on the object for the currently signed in user
  editPermissions(authService: AuthService): string [] {
    return authService.getPermissionsForAttribute('LOCs', this, 'Edit');
  }
  editDeletePermissions(authService: AuthService): string [] {
    return authService.getPermissionsForAttribute('LOCs', this, 'EditDelete');
  }
  deletePermissions(authService: AuthService): string[] {
    return authService.getPermissionsForAttribute('LOCs', this, 'Delete');
  }

  get noThunderString(): string {
    return this.noThunder ? 'NT' : '';
  }

  get aboutName(): string {
    return this.about !== undefined ? this.about.name : '';
  }

  get authorName(): string {
    return this.author !== undefined ? this.author.name : '';
  }

  get lowName(): string {
    return this.low !== undefined && this.low !== null ? this.low.name : '';
  }

  get midName(): string {
    return this.mid !== undefined && this.mid !== null ? this.mid.name : '';
  }

  get highName(): string {
    return this.high !== undefined && this.high !== null ? this.high.name : '';
  }

  get nowName(): string {
    return this.now !== undefined && this.now !== null ? this.now.name : '';
  }
}

export class LOCWrapper {
  id: number;
  @Type(forwardRef(() => LOC) as any)
  locs: LOC[];
}

export class LOCAuthorsWrapper {
  id: number;
  @Type(forwardRef(() => Entity) as any)
  authors: Entity[];
}

export class LOCSProbabilitiesWrapper {
  id: number;
  @Type(forwardRef(() => LOCSProbabilities) as any)
  locsProbabilities: LOCSProbabilities[];
}


export class LOCSProbabilities {
  author: Entity;
  authorFunction: string;
  bullLabel: string;
  bullValue: number;
  id: number;
  isActive: boolean;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  postDatetime: moment.Moment;
  probC: number;
  probF: number;
  probKR: number;
  probML: number;
  probMinC: number;
  probMinF: number;
  probMinKR: number;
  probMinML: number;
  probMinRE: number;
  probMinRO: number;
  probMinS: number;
  probMinTS: number;
  probRE: number;
  probRO: number;
  probS: number;
  probTS: number;
}
