import {Action} from '@ngrx/store';

import {Article} from '@models/articles';

export enum ActionTypes {
  LIST = '[Injury Article] List',
  LIST_SUCCESS = '[Injury Article] List Success',
  LIST_FAILED = '[Injury Article] List Failed'
}

export class GetInjuryArticles implements Action {
  public readonly type = ActionTypes.LIST;
  constructor(public payload: {aboutID?: number, league: number, maxDays: number, page: string, pageID: string, limit: number, injuryOccurrences?: number[]}) {}
}

export class GetInjuryArticlesSuccess implements Action {
  public readonly type = ActionTypes.LIST_SUCCESS;
  constructor(public payload: {aboutID: number, leagueID: number, prevPageDateToken: string, nextPageDateToken: string, prevPageIDToken: string, nextPageIDToken: string, results: Article[], filteredIds: number[]}) {}
}

export class GetInjuryArticlesFailed implements Action {
  public readonly type = ActionTypes.LIST_FAILED;
  constructor(public payload: any) {}
}

export type Actions = GetInjuryArticles | GetInjuryArticlesSuccess | GetInjuryArticlesFailed;
