import {environment} from '../../environments/environment';
import {BroadcastChannelService} from '@services/broadcast-channel.service';
import {Subject} from 'rxjs';

export class ThunderWebSocket extends WebSocket {
  readonly heartbeatMsg = JSON.stringify('heartbeat');
  heartBeatInterval: NodeJS.Timeout;
  missedHeartBeats: number;
  private messageSubject: Subject<any>;
  private channelUrl: string;

  get isOpen() {
    return this.readyState === WebSocket.OPEN;
  }

  get messages(): Subject<any> {
    return this.messageSubject;
  }

  constructor(url,
              protected broadcastChannelService: BroadcastChannelService,
              protocols= ['jwt-authenticate', localStorage.getItem('access')]) {
    const websocketUrl = `${environment.socketHost}/ws/${url}`;

    super(websocketUrl, protocols);
    this.messageSubject = new Subject<any>();
    this.channelUrl = url;
    this.initializeHeartBeat();
  }

  onmessage = (message) => {
    this.messageSubject.next(message);
    this.resetHeartBeat();
    this.broadcastChannelService.postMessage(this.channelUrl);
  }

  heartBeatFunction() {
    try {
      if (this.isOpen) {
        this.missedHeartBeats++;
        if (this.missedHeartBeats > 3) {
          throw new Error('Too many missed heartbeats.');
        }
        this.send(this.heartbeatMsg);
      }
    } catch (e) {
      clearInterval(this.heartBeatInterval);
      this.heartBeatInterval = null;
      console.log('Closing connection. Reason: ' + e.message);
      this.missedHeartBeats = 0;
      this.close(3008, e.message);
    }
  };

  initializeHeartBeat() {
    if (this.heartBeatInterval == null) {
      this.resetHeartBeat();
    }
  };

  resetHeartBeat() {
    this.missedHeartBeats = 0;
    clearInterval(this.heartBeatInterval);
    this.heartBeatInterval = setInterval(() => this.heartBeatFunction(), 30000);
  }
}

export const createThunderWebSocket = (websocket: ThunderWebSocket, websocketUrl: string, broadcastChannelService: BroadcastChannelService): ThunderWebSocket => {
  if (!websocket || websocket.readyState === WebSocket.CLOSED || websocket.readyState === WebSocket.CLOSING) {
    websocket = new ThunderWebSocket(websocketUrl, broadcastChannelService);
  }
  return websocket;
};
