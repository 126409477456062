
import {SportradarEntity} from '@models/sportradar-entities';
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Hashtag} from '@models/hashtags';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';


import {BaseService} from './base.service';
import {Entity} from '../_models/entities';
import {FocusGroup} from '../_models/focus-groups';
import {plainToClass} from 'class-transformer';
import {BroadcastChannelService} from '@services/broadcast-channel.service';

@Injectable()
export class AutocompleteService extends BaseService {

  constructor(protected http: HttpClient, protected broadcastChannelService: BroadcastChannelService) {
    super(http, broadcastChannelService);
  }

  getEntities(query: string, entityType?: string, isUser?: boolean, postType?: string, postCategories?: any[],
              limit?: number, isUserGroup?: boolean, draftYear?: number): Observable<Entity[]> {
    const ttl = 300;
    let params: HttpParams = new HttpParams();

    if (query) {
      params = params.set('q', query);
    }
    if (entityType) {
      params = params.set('entityType', entityType);
    }
    if (isUser) {
      params = params.set('isUser', isUser.toString());
    }
    if (isUserGroup) {
      params = params.set('isUserGroup', isUserGroup.toString());
    }
    if (postType) {
      params = params.set('postType', postType);
    }
    if (postCategories) {
      params = params.set('postCategories', postCategories.map((category) => category.name).join(','));
    }
    if (limit) {
      params = params.set('count', limit.toString());
    }
    if (draftYear) {
      params = params.set('draftBoardYear', draftYear.toString());
    }

    const endpoint = `${this.baseUrl}/entities/autocomplete`;
    return this.get(endpoint, params).pipe(map(
      data => {
        data = data.map(d => plainToClass(Entity, d));
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getSportradarEntities(query: string): Observable<SportradarEntity[]> {
    const ttl = 300;
    let params: HttpParams = new HttpParams();

    if (query) {
      params = params.set('q', query);
    }

    const endpoint = `${this.baseUrl}/entities/autocomplete/sportradar`;
    return this.get(endpoint, params).pipe(map(
      res => {
        return res;
      },
      error => {
        return error;
      }
    ));
  }

  getFocusGroups(query: string): Observable<any[]> {
    const ttl = 300;
    let params: HttpParams = new HttpParams();

    if (query) {
      params = params.set('q', query);
    }

    const endpoint = `${this.baseUrl}/focusGroups/autocomplete`;
    return this.get(endpoint, params).pipe(map(
      data => {
        data = data.map(d => plainToClass(FocusGroup, d));
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getElasticSearchResults(query: string, entityType?: string, limit?: number, leagues?: string, subtypes?: string): Observable<any> {
    const ttl = 300;
    let params: HttpParams = new HttpParams();

    if (query) {
      params = params.set('q', query);
    }

    if (entityType) {
      params = params.set('entityType', entityType);
    }

    if (limit) {
      params = params.set('count', limit.toString());
    }

    if (leagues) {
      params = params.set('leagues', leagues);
    }

    if (subtypes) {
      params = params.set('subtypes', subtypes);
    }

    const endpoint = `${this.baseUrl}/esAutocomplete`;
    return this.get(endpoint, params).pipe(map(
      data => {
        data = data.map(d => plainToClass(Entity, d));
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getSearchResults(query: string, entityType?: string, isUser?: boolean, isUserGroup?: boolean,
                   postType?: string, postCategories?: any[], limit?: number, allowDraftEntities: boolean = false): Observable<any> {
    const ttl = 300;
    let params: HttpParams = new HttpParams();

    if (query) {
      params = params.set('q', query);
    }
    if (entityType) {
      params = params.set('entityType', entityType);
    }
    if (isUser) {
      params = params.set('isUser', isUser.toString());
    }
    if (isUserGroup) {
      params = params.set('isUserGroup', isUserGroup.toString());
    }
    if (postType) {
      params = params.set('postType', postType);
    }
    if (postCategories) {
      params = params.set('postCategories', postCategories.map((category) => category.name).join(','));
    }
    if (limit) {
      params = params.set('count', limit.toString());
    }
    if (allowDraftEntities) {
      params = params.set('allowDraftEntities', allowDraftEntities.toString() )
    }

    const endpoint = `${this.baseUrl}/autocomplete`;
    return this.get(endpoint, params).pipe(map(
      data => {
        data = data.map(d => plainToClass(Entity, d));
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getHashtags(query: string, entity?: Entity, limit?: number): Observable<any> {
    const ttl = 300;
    let params: HttpParams = new HttpParams();

    if (query) {
      params = params.set('q', query);
    }
    if (entity) {
      params = params.set('aboutID', String(entity.id));
    }
    if (limit) {
      params = params.set('count', limit.toString());
    }

    const endpoint = `${this.baseUrl}/hashtags/autocomplete`;
    return this.get(endpoint, params).pipe(map(
      data => {
        data = data.map(d => plainToClass(Hashtag, d));
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getScenarios(query: string): Observable<any[]> {
    let params: HttpParams = new HttpParams();

    if (query) {
      params = params.set('q', query);
    }

    const endpoint = `${this.baseUrl}/contract/autocomplete`;
    return this.get(endpoint, params).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }
}
