import * as _ from 'lodash';

import {BaseService} from '../base.service';

export class IdNamesService extends BaseService {

  objects: any[] = [];
  indexedObjects: {} = {};
  ordinalField: string;

  constructor(private type: any, protected objectValues: any) {
    super(null, null);

    if (this.objects.length === 0) {
      this.getList();
    }
  }

  getObject(id: number) {
    return this.indexedObjects[id];
  }

  getList(skipIds: number[] = []): any[] {
    if (this.objects.length === 0) {
      for (const objectValue in this.objectValues) {
        this.objects.push(this.objectValues[objectValue]);
        this.indexedObjects[this.objectValues[objectValue]['id']] = this.objectValues[objectValue];
      }
    }

    if (skipIds.length > 0) {
      return this.objects.filter(o => skipIds.indexOf(o.id) < 0);
    } else {
      return this.objects;
    }
  }

  getOrderedList(skipIds: number[] = []): any[] {
    if (this.ordinalField) {
      return _(this.getList(skipIds)).sortBy(this.ordinalField).value();
    } else {
      return this.getList(skipIds);
    }
  }
}
