import {plainToClass} from 'class-transformer';

import {IdName} from '@models/id-names/_id-names';

export const TRADE_ASSET_IDS = {
  PICKS_1ST_ROUND: 69,
  PICKS_2ND_ROUND: 70,
  CASH: 71,
  TPE: 72
};

export class TradeAsset extends IdName {
  shortName: string;
}

export const TRADE_ASSETS = {};
TRADE_ASSETS[TRADE_ASSET_IDS.PICKS_1ST_ROUND] = plainToClass(TradeAsset, {id: TRADE_ASSET_IDS.PICKS_1ST_ROUND, name: 'Picks - 1st Round', shortName: 'Picks 1st'});
TRADE_ASSETS[TRADE_ASSET_IDS.PICKS_2ND_ROUND] = plainToClass(TradeAsset, {id: TRADE_ASSET_IDS.PICKS_2ND_ROUND, name: 'Picks - 2nd Round', shortName: 'Picks 2nd'});
TRADE_ASSETS[TRADE_ASSET_IDS.CASH] = plainToClass(TradeAsset, {id: TRADE_ASSET_IDS.CASH, name: 'Cash', shortName: 'Cash'});
TRADE_ASSETS[TRADE_ASSET_IDS.TPE] = plainToClass(TradeAsset, {id: TRADE_ASSET_IDS.TPE, name: 'TPE', shortName: 'TPE'});
