
import {filter, debounceTime} from 'rxjs/operators';
import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';

@Directive({
  selector: '[scroll]'
})
export class ScrollDirective {

  @Input() scrollDebounceTime: number = 500;
  @Input() toTopOnRouteChange: boolean = false;

  @Output() scrollTopPosition: EventEmitter<number> = new EventEmitter<number>();
  @Output() atTop: EventEmitter<boolean> = new EventEmitter<boolean>();

  private scrollSubject = new Subject<number>();
  private scrollObservable: Observable<number>;

  @HostListener('scroll') scrolling() {
    this.scrollSubject.next(this.elementRef.nativeElement.scrollTop);
  }

  constructor(protected elementRef: ElementRef, protected router: Router) {
    let scrollTop: number;

    this.scrollObservable = this.scrollSubject.asObservable().pipe(debounceTime(this.scrollDebounceTime));

    this.scrollObservable.subscribe(() => {
      scrollTop = this.elementRef.nativeElement.scrollTop;
      this.scrollTopPosition.emit(scrollTop);
      this.atTop.emit(scrollTop === 0);
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (this.toTopOnRouteChange) {
        this.elementRef.nativeElement.scrollTo(0, 0);
      }
    });
  }
}
