
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, mergeMap} from 'rxjs/operators';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {parseURL, URL} from 'universal-parse-url';

import {AuthService} from 'apps/_services/auth.service';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Injectable()
export class BildInterceptor implements HttpInterceptor {

  tokenEndpoint: string = '/api/v1/jwt-token';
  adfsConfigEndpoint: string = '/api/v1/adfs-config';
  adfsCallbackEndpoint: string = '/api/v1/adfs-callback';
  refreshEndpoint: string = '/api/v1/jwt-token/refresh';
  shotChartingEndpoint: string = '/api/v1/shotCharting';

  constructor(protected injector: Injector) {}

  wrapRequestInToken(req) {
    return req.clone({
      headers: req.headers.set('Authorization', 'JWT ' + localStorage.getItem('access'))
    });
  }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url: URL = parseURL(req.url);
    const authService = this.injector.get(AuthService);

    const isTokenRequest: boolean = url.pathname === this.tokenEndpoint;
    const isADFSConfigRequest: boolean = url.pathname === this.adfsConfigEndpoint;
    const isAdFSCallbackRequest: boolean = url.pathname === this.adfsCallbackEndpoint;
    const isRefreshEndpoint: boolean = url.pathname === this.refreshEndpoint;
    const isUserForAuthEndpoint: boolean = coerceBooleanProperty(url.pathname.match(/\/api\/v1\/users\/(\D\S+)$/));

    if (isTokenRequest || isADFSConfigRequest || isAdFSCallbackRequest || isUserForAuthEndpoint) {
      return next.handle(req);
    } else if (isRefreshEndpoint) {
      return next.handle(req).pipe(
        catchError((err, _) => {
          if (err.status === 400 || err.status === 401) {
            localStorage.setItem('logoutMethod 2', new Date().toString());
            console.log('2: Logging out');
            authService.logout(true, false, true);
            return observableThrowError(err);
          } else {
            return observableThrowError(err);
          }
        })) as any;

    } else if (authService.shouldRefresh && authService.canRefresh) {
      const o = authService.jwt().pipe(mergeMap(_ => {
        return next.handle(this.wrapRequestInToken(req));
      }));

      if (!authService.refreshingToken) {
        authService.refreshingToken = true;
        authService.refreshJwt();
      }

      return o;
    } else if (window.location.pathname.includes('wellness-questionnaire') || window.location.pathname.includes('fatigue-cohesion-questionnaire') || window.location.pathname.includes('ion-connect')) {
      return next.handle(req);
    } else if (authService.shouldRefresh && !(window.location.pathname.includes('kiosk')) &&
      (localStorage.getItem('autoLogoutDisabled') == null || (Date.now() - Number(localStorage.getItem('autoLogoutDisabled'))) > 60000)) {
      localStorage.setItem('logoutMethod 3', new Date().toString());
      console.log('3: Logging out');
      authService.logout(true, false, false);
    } else {
      // Everything is still good, regular request, tokens still fresh
      return next.handle(this.wrapRequestInToken(req)).pipe(
        catchError((err, _) => {
          if (err.status === 401) {
            localStorage.setItem('logoutMethod 4 error', err.toString());
            localStorage.setItem('logoutMethod 4', new Date().toString());
            console.log('4: Logging out');
            authService.logout(true, false, true);
            return observableThrowError(err);
          } else {
            return observableThrowError(err);
          }
        })) as any;
    }
  }
}
