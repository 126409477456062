import {UserForAuth} from '@models/users';
import {UsersService} from '@services/users.service';
import {take} from 'rxjs/operators';
import {Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import * as Sentry from '@sentry/angular';

import {AuthService} from 'apps/_services/auth.service';
import {LocalStorageExpiry} from '@ng-idle/core';
import {DOMHelper} from '@helpers/dom.helper';
import {OnDestroy} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignInComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @ViewChild('emailControl', { static: true }) emailControl: ElementRef;
  @ViewChild('passwordControl', { static: true }) passwordControl: ElementRef;

  signInForm: FormGroup;
  currentUser: any;
  error = '';
  returnUrl: string;
  adfsUrl: string;
  userForAuth: UserForAuth;
  shouldADFSPromptLogin: boolean = true;

  protected get emailFormControl(): FormControl {
    return this.signInForm.get('email') as FormControl;
  }

  constructor(protected authService: AuthService,
              protected localStorageExpiry: LocalStorageExpiry,
              protected route: ActivatedRoute,
              protected router: Router,
              protected usersService: UsersService,
              protected elementRef: ElementRef,
              protected domHelper: DOMHelper) {}

  ngOnInit() {
    this.authService.getADFSConfig().pipe(take(1), untilDestroyed(this)).subscribe(
      result => {
        this.adfsUrl = result;
      }
    );

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/bild';

    this.signInForm = new FormGroup({
      'email': new FormControl('', Validators.required),
      'password': new FormControl('', Validators.required)
    });

    this.setupCurrentUser();

    // Clear out any idle/expiry values
    this.localStorageExpiry.last(null);
    this.localStorageExpiry.idling(false);

    this.stepper.selectionChange.subscribe((event) => {
      setTimeout(() => {
        switch (event.selectedIndex) {
          case 0:
            this.emailControl.nativeElement.focus();
            this.emailControl.nativeElement.select();
            break;
          case 1:
            this.passwordControl.nativeElement.focus();
            this.passwordControl.nativeElement.select();
            break;
        }
      }, 250);
    });
  }

  goToStep2(): void {
    let email: string = this.signInForm.get('email').value;

    if (email !== '') {
      if (this.currentUser && email !== this.currentUser.email) {
        localStorage.removeItem('user');
        this.setupCurrentUser();
      } else if (this.currentUser && email === this.currentUser.email) {
        this.shouldADFSPromptLogin = false;
      }

      // TODO: Determine if this breadcrumb is necessary or if previous sign-in breadcrumb is preserved
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Authenticated user: ' + email,
        level: 'info',
      });
      Sentry.setUser({email: email});

      this.usersService.getUserForAuth(email).pipe(take(1), untilDestroyed(this)).subscribe(
        (userForAuth: UserForAuth) => {
          if (userForAuth.isADFSOnly) {
            if (email.indexOf('@') === -1) {
              email = `${email}@okcthunder.com`;
            }
            let href = `${this.adfsUrl}&username=${email}`;
            if (this.shouldADFSPromptLogin) {
              href = `${href}`;
            }
            localStorage.setItem('isLoggedInViaADFS', 'true');
            window.location.href = href;
          } else {
            this.stepper.selectedIndex = 1;
          }
        }
      );
    }
  }

  startOver(): void {
    localStorage.setItem('logoutMethod 11', new Date().toString());
    console.log('11: Logging out');
    this.authService.logout(false, false, false);
    this.currentUser = undefined;
    this.shouldADFSPromptLogin = true;
    this.error = '';
    localStorage.removeItem('user');
    this.stepper.selectedIndex = 0;
  }

  get email() { return this.signInForm.get('email'); }
  get password() { return this.signInForm.get('password'); }

  signIn() {
    const email = this.signInForm.get('email').value;
    const password = this.signInForm.get('password').value;

    localStorage.setItem('isLoggedInViaADFS', 'false');
    this.authService.login(email, password).pipe(untilDestroyed(this)).subscribe(
      result => {
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'Authenticated user: ' + email,
          level: 'info',
        });
        Sentry.setUser({email: email});
        this.router.navigate([`..${this.returnUrl}`], { relativeTo: this.route });
      },
      error => {
        if (error.response.error.non_field_errors) {
          this.error = 'Username or password is incorrect. Try again.';
        }
        if (error.response.error.username) {
          this.error = error.response.error.username[0];
        }
      }
    );
  }

  protected setupCurrentUser(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    if (this.currentUser) {
      const email = this.currentUser.email;
      this.signInForm.patchValue({email: this.currentUser.email});
      localStorage.setItem('logoutMethod 12', new Date().toString());
      console.log('12: Logging out');
      this.authService.logout(false, false, false);
      this.usersService.getUserForAuth(email).pipe(take(1), untilDestroyed(this)).subscribe(
        (userForAuth: UserForAuth) => {
          if (userForAuth.isADFSOnly) {
            this.stepper.selectedIndex = 0;
          } else {
            this.stepper.selectedIndex = 1;
          }
        }
      );
    } else {
      localStorage.setItem('logoutMethod 13', new Date().toString());
      console.log('13: Logging out');
      this.authService.logout(false, false, false);
    }
  }

  ngAfterViewInit(): void {
    this.domHelper.hideInitialLoading(this.elementRef);
  }

  ngOnDestroy() {
  }
}
