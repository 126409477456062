import {createEffect, ofType, Actions} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {switchMap, map, catchError, mergeMap, concatMap, filter, withLatestFrom, tap} from 'rxjs/operators';

import * as actions from './actions';
import {EntitiesService} from '@services/entities.service';
import {Roster, RosterEntity} from '@models/roster-entity';
import {MergedRoute} from '../../route-serializer';
import {Store} from '@ngrx/store';
import {RootStoreState} from '@store/index';
import {classToPlain} from 'class-transformer';

@Injectable()
export class Effects {

  constructor(
    protected entitiesService: EntitiesService,
    protected actions$: Actions,
    protected store$: Store<RootStoreState.State>,
  ) {}

  saveLineup$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SaveLineup>(actions.ActionTypes.SAVE_LINEUP),
    map(action => action.payload),
    concatMap(payload => {
      return this.entitiesService.saveRosterLineup(payload).pipe(
        mergeMap(roster => {
          return of({
            type: actions.ActionTypes.SAVE_LINEUP_SUCCESS,
            payload: classToPlain(roster)
          });
        }),
        catchError(error => {
          return of({
            type: actions.ActionTypes.SAVE_LINEUP_FAILED,
            payload: error
          });
        })
      );
    })
  ));
}
