import {plainToClass} from 'class-transformer';

import {IdName} from '@models/id-names/_id-names';

export const POST_TYPE_IDS = {
  EVALUATION: 1,
  MUSING: 2,
  GOSSIP_RUMOR_NEWS: 3,
  MEDICAL_EXPERT_SUMMARY: 4,
  REPRESENTATION: 5,
  TRADE: 6,
  DEVELOPMENT: 9,
  CORRESPONDENCE: 10,
  TRANSACTION: 11,
  ADVANCED_EVALUATION: 12,
};

export class PostType extends IdName {}

export const POST_TYPES = {};
POST_TYPES[POST_TYPE_IDS.DEVELOPMENT] = plainToClass(PostType, {id: POST_TYPE_IDS.DEVELOPMENT, name: 'Development', ordinal: 0});
POST_TYPES[POST_TYPE_IDS.EVALUATION] = plainToClass(PostType, {id: POST_TYPE_IDS.EVALUATION, name: 'Evaluation', ordinal: 1});
POST_TYPES[POST_TYPE_IDS.ADVANCED_EVALUATION] = plainToClass(PostType, {id: POST_TYPE_IDS.ADVANCED_EVALUATION, name: 'Advanced Evaluation', ordinal: 2});
POST_TYPES[POST_TYPE_IDS.GOSSIP_RUMOR_NEWS] = plainToClass(PostType, {id: POST_TYPE_IDS.GOSSIP_RUMOR_NEWS, name: 'Intel', ordinal: 3});
POST_TYPES[POST_TYPE_IDS.CORRESPONDENCE] = plainToClass(PostType, {id: POST_TYPE_IDS.CORRESPONDENCE, name: 'League Correspondence', ordinal: 4});
POST_TYPES[POST_TYPE_IDS.MEDICAL_EXPERT_SUMMARY] = plainToClass(PostType, {id: POST_TYPE_IDS.MEDICAL_EXPERT_SUMMARY, name: 'Medical Expert Summary', ordinal: 5});
POST_TYPES[POST_TYPE_IDS.MUSING] = plainToClass(PostType, {id: POST_TYPE_IDS.MUSING, name: 'Musing', ordinal: 6});
POST_TYPES[POST_TYPE_IDS.REPRESENTATION] = plainToClass(PostType, {id: POST_TYPE_IDS.REPRESENTATION, name: 'Representation', ordinal: 7});
POST_TYPES[POST_TYPE_IDS.TRADE] = plainToClass(PostType, {id: POST_TYPE_IDS.TRADE, name: 'Trade', ordinal: 8});
POST_TYPES[POST_TYPE_IDS.TRANSACTION] = plainToClass(PostType, {id: POST_TYPE_IDS.TRANSACTION, name: 'Transaction', ordinal: 9});
