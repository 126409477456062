import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class PrimaryToolbarService {
  reloaded: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  reload(): void {
    this.reloaded.next();
  }
}
