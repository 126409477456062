import * as moment from 'moment-timezone';
import {Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';
import {forwardRefMoment} from '@helpers/class-transformer.helper';

export class EventSummary {
    liveCount: number;
    digitalCount: number;
    @Type(forwardRef(() => moment) as any)
    @Transform(forwardRefMoment(moment.ISO_8601))
    digitalMostRecent: moment.Moment;
    @Type(forwardRef(() => moment) as any)
    @Transform(forwardRefMoment(moment.ISO_8601))
    liveMostRecent: moment.Moment;
}