import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'time',
  })
export class TimePipe implements PipeTransform {
  transform(value: any): string {
    // Parse the time string to get hours and minutes
    const [hours, minutes] = value.split(':').map((part: string) => parseInt(part, 10));

    // Create a Date object and set hours and minutes
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format the time using date pipe format in Angular
    return new DatePipe('en-US').transform(date, 'h:mm a');
  }
}
