import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';

import {AuthService} from '@services/auth.service';

@Injectable()
export class SignInResolver implements Resolve<any> {
  constructor(protected authService: AuthService, protected router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    if (this.authService.loggedIn()) {
      this.router.navigate(['bild']);
    }
  }
}
