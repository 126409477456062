
import {Component, NgZone, ViewEncapsulation, OnInit} from '@angular/core';

import {AuthService} from 'apps/_services/auth.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {Observable, Observer, Subject} from 'rxjs';

@Component({
  selector: 'apps-component',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppsComponent implements OnInit {

  public messages: Subject<string>;

  constructor(
    protected authService: AuthService,
    protected snackBar: MatSnackBar,
    protected zone: NgZone
  ) {}

  ngOnInit() {
    if (this.authService.permissions) {
      this.authService.setupPermissions();
    }
  }

  start(): void {
    this.messages.next('start');
  }

  stop(): void {
    this.messages.next('stop');
  }

  private connect(url): Subject<MessageEvent> {
    const ws = new WebSocket(url);

    const observable = Observable.create(
      (obs: Observer<MessageEvent>) => {
        ws.onmessage = obs.next.bind(obs);
        ws.onerror = obs.error.bind(obs);
        ws.onclose = obs.complete.bind(obs);
        return ws.close.bind(ws);
      });
    const observer = {
      next: (data: string) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(data);
          if (data === 'stop') {
            ws.close(1000, 'bye');
          }
        }
      }
    };
    return Subject.create(observer, observable);
  }
}
