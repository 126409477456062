import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BroadcastChannelService {
  // This Service is used for communication between browsing contexts on the same origin (inter tab/window communication)

  channel: BroadcastChannel;

  constructor() {}

  createChannel(channelName) {
    this.channel = new BroadcastChannel(channelName);
  }

  postMessage(msg: string) {
    try {
      if (!this.channel) {
        this.createChannel('idle-channel');
      }
      this.channel.postMessage(msg);
    } catch (e) {
      console.warn('This is not a modern browser, so we will fall back to traditional idle logout');
    }
  }
}
