import {plainToClass} from 'class-transformer';

import {IdName} from '@models/id-names/_id-names';
import { EVENT_LEVEL_IDS } from '@models/constants/event-levels';

export const LEVEL_IDS = {
  GENERAL: 1300,
  AMATEUR: 1301,
  PRO: 1302
};

export const EVENT_IDS = {
  GAME: 1,
  PRACTICE: 2,
  SHOOTAROUND: 3,
  OTHER: 4
};

export class Level extends IdName {}

export const LEVELS = {};
LEVELS[LEVEL_IDS.GENERAL] = plainToClass(Level, {id: LEVEL_IDS.GENERAL, name: 'General'});
LEVELS[LEVEL_IDS.AMATEUR] = plainToClass(Level, {id: LEVEL_IDS.AMATEUR, name: 'Amateur'});
LEVELS[LEVEL_IDS.PRO] = plainToClass(Level, {id: LEVEL_IDS.PRO, name: 'Pro'});

export const EVENT_LEVELS = {};
EVENT_LEVELS[EVENT_IDS.GAME] = plainToClass(Level, {id: EVENT_IDS.GAME, name: 'Game'});
EVENT_LEVELS[EVENT_IDS.PRACTICE] = plainToClass(Level, {id: EVENT_IDS.PRACTICE, name: 'Practice'});
EVENT_LEVELS[EVENT_IDS.SHOOTAROUND] = plainToClass(Level, {id: EVENT_IDS.SHOOTAROUND, name: 'Shootaround'});
EVENT_LEVELS[EVENT_IDS.OTHER] = plainToClass(Level, {id: EVENT_IDS.OTHER, name: 'Other'});

export const POST_TO_EVENT_LEVEL_IDS = {}
POST_TO_EVENT_LEVEL_IDS[LEVEL_IDS.PRO] = EVENT_LEVEL_IDS.PRO;
POST_TO_EVENT_LEVEL_IDS[LEVEL_IDS.AMATEUR] = EVENT_LEVEL_IDS.AMATEUR;
POST_TO_EVENT_LEVEL_IDS[LEVEL_IDS.GENERAL] = EVENT_LEVEL_IDS.GENERAL;
