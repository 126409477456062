import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, HostListener, Input,
  OnChanges, 
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {untilDestroyed, UntilDestroy} from '@ngneat/until-destroy';
import {Entity} from "@models/entities";
import {isMoment} from "moment";
import {VideoService} from "@services/video.service";
import * as _ from 'lodash';

@UntilDestroy()
@Component({
  selector: 'video-filter-chip',
  templateUrl: './video-filter-chip.component.html',
  styleUrls: ['./video-filter-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class VideoFilterChipComponent implements OnInit, OnDestroy, OnChanges {
  @Input('displayText') displayText: string;
  @Input('entity') entity: Entity;
  @Input('filterName') filterName: string;
  @Input('first') first: boolean = true;
  @Input('games') games;
  @Input('header') header: string;
  @Input('ignoreClickEvents') ignoreClickEvents: boolean;
  @Input('maxDate') maxDate;
  @Input('minDate') minDate;
  isMobile: boolean;
  formattedDisplayText: string;
  years: number[];

  @HostListener('click', ['$event']) clickHandler(event) {
    if (!this.ignoreClickEvents) {
      this.videoService.activeFilterSubject.next(this.filterName);
    }
  }
  readonly isMoment = isMoment;

  constructor(
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected videoService: VideoService,
  ) {
  }

  ngOnInit() {
    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small,
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe((result) => {
      this.isMobile = result.matches;
    });

    this.formattedDisplayText = this.formatDisplayText(this.displayText);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.displayText) {
      this.formattedDisplayText = this.formatDisplayText(this.displayText);
      this.cdr.markForCheck();
    }
  }

  formatDisplayText(displayText: string): string {
    if (!displayText) return '';

    const years = this.parseYears(displayText);
    this.years = years;
    if (years.length > 1) {
      return this.formatYears(years);
    } else {
      return displayText;
    }
  }

  parseYears(displayText: string): number[] {
    // Match on 4-digit numbers optionally separated by commas and optional whitespace
    const yearPattern = /^\d{4}(,\s*\d{4})*$/;
    const trimmedText = displayText.trim();
    if (yearPattern.test(trimmedText)) {
      return trimmedText.split(',').map(year => parseInt(year.trim()));
    }
    return [];
  }  

  formatYears(years: number[]): string {
    years = _.sortBy(years);
    if (this.areYearsContinuous(years)) {
      return `${years[0]}-${years[years.length - 1]}`;
    } else {
      return `${years.length} Selected Seasons`;
    }
  }

  areYearsContinuous(years: number[]): boolean {
    if (years.length <= 1) return true;
    for (let i = 0; i < years.length - 1; i++) {
      if (years[i] + 1 !== years[i + 1]) {
        return false;
      }
    }
    return true;
  }

  getTooltipContent(): string {
    const years = this.parseYears(this.displayText);
    return years.length > 0 ? years.join(', ') : '';
  }

  containsSeasons(): boolean {
    return this.parseYears(this.displayText).length > 0;
  }
  
  ngOnDestroy() {
  }
}
