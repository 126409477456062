import * as moment from 'moment-timezone';

import {TransformationType} from 'class-transformer';
import {forwardRef, resolveForwardRef} from '@angular/core';


export function forwardRefConstant(t) {
  let ts = forwardRef(() => t) as any;
  ts = resolveForwardRef(ts);

  return ({ value, key, obj, type }) => {
    return value ? ts[value.id] : value;
  };
}
export function forwardRefConstants(t) {
  let ts = forwardRef(() => t) as any;
  ts = resolveForwardRef(ts);

  return ({ value, key, obj, type }) => {
    return value.length > 0 ? value.map(v => ts[v.id]) : value;
  };
}
export function forwardRefMoment(format?) {
  let ts = forwardRef(() => moment) as any;
  ts = resolveForwardRef(ts);

  return ({ value, key, obj, type }) => {
    if (typeof format === 'function') {
      format = null;
    }

    switch (type) {
      case TransformationType.PLAIN_TO_CLASS:
        if (value && value instanceof Array) {
          value = value.map(val => {
            return format ? ts(val, format) : ts(val);
          });
          return value;
        }
        if (value) {
          return format ? ts(value, format) : ts(value);
        }
      case TransformationType.CLASS_TO_PLAIN:
        if (value && value instanceof Array) {
          value = value.map(val => {
            const t = ts(value);
            t.locale('en');
            const f = format ? t.format(format) : t.toISOString();
            return f;
          });
          return value;
        }
        if (value) {
          // INFO: The moment object here isn't an actual moment clone because it gets
          // INFO: copied around forms, etc, so convert it to one
          const t = ts(value);
          t.locale('en');
          const f = format ? t.format(format) : t.toISOString();
          return f;
        }
      case TransformationType.CLASS_TO_CLASS:
        if (value && value instanceof Array) {
          value = value.map(val => {
            const t = ts(value);
            t.locale('en');
            return format ? ts(t.toISOString(), format) : ts(t.toISOString());
          });
          return value;
        }
        if (value) {
          // INFO: The moment object here isn't an actual moment clone because it gets
          // INFO: copied around forms, etc, so convert it to one
          const t = ts(value);
          t.locale('en');
          return format ? ts(t.toISOString(), format) : ts(t.toISOString());
        }
    }
    return value;
  };
}
