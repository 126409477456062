import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {plainToClass} from 'class-transformer';

import {BaseService} from './base.service';
import {BildUserTypesService} from './id-names/bild-user-types.service';
import {EntitySubtypesService} from './id-names/entity-subtypes.service';
import {User, UserForAuth} from '@models/users';
import {PermissionOverrides} from '@models/permission-overrides';
import {BroadcastChannelService} from '@services/broadcast-channel.service';

@Injectable()
export class UsersService extends BaseService {
  userDataSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(protected http: HttpClient, protected broadcastChannelService: BroadcastChannelService) {
    super(http, broadcastChannelService);
  }

  get userData(): Observable<any[]> {
    return this.userDataSubject.asObservable();
  }

  saveUser(user: User): Observable<User> {
    let endpoint: string;
    let method: any;

    if (user.id) {
      endpoint = `${this.baseUrl}/users/${user.id}`;
      method = this.put.bind(this);
    } else {
      endpoint = `${this.baseUrl}/users`;
      method = this.post.bind(this);
    }

    return method(endpoint, user).pipe(map(
      data => {
        return plainToClass(User, data);
      },
      error => {
        return error;
      }
    ));
  }

  getUser(id: number): Observable<User> {
    return this.get(`${this.baseUrl}/users/${id}`).pipe(map(
      data => {
        return plainToClass(User, <object>data);
      },
      error => {
        return error;
      }
    ));
  }

  getUserForAuth(email: string): Observable<UserForAuth> {
    return this.get(`${this.baseUrl}/users/${email}`).pipe(map(
      data => {
        return plainToClass(UserForAuth, <Object>data);
      },
      error => {
        return error;
      }
    ));
  }

  getUsers(isEventBoard?: boolean): Observable<User[]> {
    const endpoint = `${this.baseUrl}/users`;
    let params: HttpParams = new HttpParams();

    if (isEventBoard) {
      params = params.set('status', 'eventboard');
    }

    return this.get(endpoint, params).pipe(map(
      data => {
        data = plainToClass(User, data);
        this.userDataSubject.next(data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  changePassword(userID: number, username: string, currentPassword: string, newPassword: string) {
    const endpoint = `${this.baseUrl}/users/${userID}/password`;
    return this.put(endpoint, {username: username, currentPassword: currentPassword, newPassword: newPassword}).pipe(
      map(
        error => {
          return error;
        }
      ));
  }

  getUserPemissions(userID: number): Observable<any> {
    const endpoint = `${this.baseUrl}/users/${userID}/accessLevels`;
    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  saveUserPermissions(userID: number, data: any) {
    const endpoint = `${this.baseUrl}/users/${userID}/accessLevels`;
    return this.put(endpoint, data).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getUserPostPermissions(userID: number): Observable<any> {
    const endpoint = `${this.baseUrl}/users/${userID}/postPermissions`;
    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  saveUserPostPermissions(userID: number, data: any): Observable<any> {
    const endpoint = `${this.baseUrl}/users/${userID}/postPermissions`;
    return this.put(endpoint, data).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getUserPermissionOverrides(userID: number): Observable<PermissionOverrides> {
    const endpoint = `${this.baseUrl}/users/${userID}/permissionOverrides`;
    return this.get(endpoint).pipe(map(
      data => {
        return plainToClass(PermissionOverrides, <Object>data);
      },
      error => {
        return error;
      }
    ));
  }

  saveUserPermissionOverrides(userID: number, data: any): Observable<PermissionOverrides> {
    const endpoint = `${this.baseUrl}/users/${userID}/permissionOverrides`;
    return this.put(endpoint, data).pipe(map(
      data => {
        return plainToClass(PermissionOverrides, <Object>data);
      },
      error => {
        return error;
      }
    ));
  }

  getUserGroups(userID: number): Observable<any> {
    const endpoint = `${this.baseUrl}/users/${userID}/userGroups`;
    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }
}
