import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {UserGroup} from '@models/user-groups';

export const featureAdapter: EntityAdapter<UserGroup> = createEntityAdapter<UserGroup>({
  selectId: model => {
    return model.id || 0;
  },
  sortComparer: (a: UserGroup, b: UserGroup): number => {
    return a.name.toString().localeCompare(b.name.toString());
  }
});

export interface State extends EntityState<UserGroup> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState(
  {
    isLoading: false,
    error: null,
  }
);
