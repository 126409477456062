
import {produce} from 'immer';

import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {debounceTime, takeUntil, take} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';

import {AuthService} from 'apps/_services/auth.service';
import {AutocompleteService} from 'apps/_services/autocomplete.service';
import {Entity} from 'apps/_models/entities';
import {ErrorsSnackbarComponent} from 'apps/_snackbars/errors-snackbar/errors-snackbar.component';
import {RootStoreState} from '@store/index';
import {environment} from 'environments/environment';
import {select, Store} from '@ngrx/store';
import {ConfirmDialogComponent} from '@bild-dialogs/confirm-dialog/confirm-dialog.component';
import {Router} from '@angular/router';
import {UserGroupsSelectDialogComponent} from '@bild-dialogs/user-groups-select/user-groups-select.dialog.component';
import {UserGroup} from '@models/user-groups';
import * as UserGroupStoreSelectors from '@store/user-group-store/selectors';
import * as UserGroupStoreActions from '@store/user-group-store/actions';
import {MaterialHelper} from '@helpers/material.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TempTransactionAction } from 'apps/contract/_constants/constants';
import * as _ from 'lodash';
import * as moment from 'moment';


@UntilDestroy()
@Component({
  selector: 'scenario-dialog',
  templateUrl: './scenario-dialog.component.html',
  styleUrls: ['./scenario-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScenarioDialogComponent implements OnInit, OnDestroy {

  @ViewChild('editorAutocomplete', { static: true }) editorAutocomplete: MatAutocomplete;
  @ViewChild('viewersAutocomplete', { static: true }) viewersAutocomplete: MatAutocomplete;
  @ViewChild('editorInput') editorInput: ElementRef;
  @ViewChild('viewersInput') viewersInput: ElementRef;
  @ViewChild('doneButton', { read: ElementRef, static: true }) doneButton: ElementRef;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;


  currentUser: any;
  form: FormGroup;
  owner: Entity;
  scenario: any = {};
  scenarioRef: any;

  editorControl: FormControl = new FormControl();
  viewersControl: FormControl = new FormControl();

  // Autocomplete results
  filteredUsers: any[];
  filteredUserGroups: any[];
  filteredOwners: any[];

  constructor(
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ScenarioDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected autocompleteService: AutocompleteService,
    protected authService: AuthService,
    protected matDialog: MatDialog,
    protected materialHelper: MaterialHelper,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private store$: Store<RootStoreState.State>,
  ) {
    if (data) {
      this.scenarioRef = data;
      this.scenario = _.cloneDeep(data);
    }
    else {
      this.scenario.distribution = {users: [], userGroups: []};
    }
  }

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
    });

    this.form.patchValue({
        name: this.scenario.name,
    });

    this.authService.currentUserData.pipe(untilDestroyed(this)).subscribe((result: any[]) => {
      this.currentUser = result;

      if (!this.scenario.owner) {
        this.owner = this.currentUser.entity;
      } else {
        this.owner = this.scenario.owner;
      }

      if (!this.scenario.distribution.users.find(existingUser => existingUser.id == this.owner.id)) {
        let ownerUser = this.owner;
        ownerUser['isEditor'] = true;
        ownerUser['isMainViewer'] = true;
        this.scenario.distribution.users.push(ownerUser);
      }
    });

    this.editorControl.valueChanges.pipe(
      debounceTime(environment.typingDebounceTime),
      untilDestroyed(this), )
      .subscribe(
        q => {
          this.filterUsers(q);
        }
      );

    this.viewersControl.valueChanges.pipe(
      debounceTime(environment.typingDebounceTime),
      untilDestroyed(this), )
      .subscribe(
        q => {
          this.filterUsers(q);
        }
      );
  }

  filterUsers(q: string): void {
    this.filteredUsers = undefined;
    if (q) {
      this.autocompleteService
        .getEntities(q, null, true).pipe(take(1), untilDestroyed(this))
        .subscribe(
          entities => {
            this.filteredUsers = entities;
            if (this.filteredUsers.length > 0) {
              setTimeout(() => this.editorAutocomplete._keyManager.setFirstItemActive(), 50);
            }
          }
        );
    }
  }

  addUserEntity(event, isAdmin, isMainViewer): void {
    let entity = event.option.value;
    let existingUser = this.scenario.distribution.users.find(user => user.id == entity.id);
    if (existingUser) {
      existingUser.isEditor = (isAdmin == null) ? existingUser.isEditor : isAdmin;
      existingUser.isMainViewer = (isMainViewer == null) ? existingUser.isMainViewer : isMainViewer;
    }
    else {
      entity.isEditor = Boolean(isAdmin);
      entity.isMainViewer = Boolean(isMainViewer);
      this.scenario.distribution.users.push(entity);
    }
    if (isAdmin) {
      this.editorInput.nativeElement.value = '';
    }
    else if (isMainViewer) {
      this.viewersInput.nativeElement.value = '';
    }
  }

  removeUserEntity(entity: any, role): void {
    entity[role] = false;
    if (!entity.isEditor && !entity.isMainViewer) {
      this.scenario.distribution.users = this.scenario.distribution.users.filter(user => user.id != entity.id);
    }
  }

  openUserGroupsSelectDialog(isAdmin, isMainViewer): void {
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      data: {
        userGroups: this.scenario.distribution.userGroups.filter(userGroup =>
          (isAdmin && userGroup.isEditor) ||
          (!isAdmin && isMainViewer && userGroup.isMainViewer)),
      }
    };

    const dialogRef = this.matDialog.open(UserGroupsSelectDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(userGroups => {
      this.scenario.distribution.userGroups.forEach((existingUserGroup) => {
        if (!userGroups.map(selectedUserGroup => selectedUserGroup.id).includes(existingUserGroup.id)) {
          this.removeUserGroup(existingUserGroup, (isAdmin ? 'isEditor' : 'isMainViewer'));
        }
      });
      userGroups.forEach((userGroup) => {
        this.addUserGroup(userGroup, isAdmin, isMainViewer);
      });
      this.cdr.markForCheck();
    });
  }

  addUserGroup(userGroup: any, isAdmin, isMainViewer): void {
    let existingUserGroup = this.scenario.distribution.userGroups.find(existingUserGroup => existingUserGroup.id == userGroup.id);
    if (existingUserGroup) {
      existingUserGroup.isEditor = (isAdmin == null) ? existingUserGroup.isEditor : isAdmin;
      existingUserGroup.isMainViewer = (isMainViewer == null) ? existingUserGroup.isMainViewer : isMainViewer;
    }
    else {
      userGroup = this.setupUserGroupEntity(userGroup);
      userGroup.isEditor = Boolean(isAdmin);
      userGroup.isMainViewer = Boolean(isMainViewer);
      this.scenario.distribution.userGroups.push(userGroup);
    }
    this.cdr.markForCheck();
  }

  private setupUserGroupEntity(entity): any {
    let members: any[] = [];

    this.store$.dispatch(new UserGroupStoreActions.GetUserGroup(entity.id));
    this.store$.pipe(select(UserGroupStoreSelectors.selectUserGroup(entity.id))).subscribe(
      (userGroup: UserGroup) => {
        if (userGroup) {
          members = [...userGroup.members];
        }
      }
    );

    const memberNames = members.map(member => member.name);
    const membersTooltipHTML = memberNames.join('<br/>');
    entity['membersTooltipHTML'] = membersTooltipHTML;
    entity['members'] = members;
    entity['type'] = 'UserGroup';

    return entity;
  }

  removeUserGroup(userGroup: any, role): void {
    userGroup[role] = false;
    if (!userGroup.isEditor && !userGroup.isMainViewer) {
      this.scenario.distribution.userGroups = this.scenario.distribution.userGroups.filter(existingUserGroup => existingUserGroup != userGroup);
    }
  }

  saveScenario() {
    if (this.form.valid) {
      if (!this.scenario.id) {
        this.scenario.createDatetime = moment();
      }
      this.scenario.owner = plainToClass(Entity, this.owner);
      this.scenario = produce(this.scenario, draftState => {
        Object.assign(draftState, ...[this.form.value]);
      });
      if (this.scenarioRef) {
        _.extend(this.scenarioRef, this.scenario);
      }

      let actionID = this.scenario.id ? TempTransactionAction.EDIT_SCENARIO : this.scenario.copyScenarioID ? TempTransactionAction.COPY_SCENARIO : TempTransactionAction.CREATE_SCENARIO;
      this.dialogRef.close({scenarioAction: actionID, payload: this.scenario});
    } else {
      this.materialHelper.markFormGroupTouched(this.form);
      this.snackBar.openFromComponent(
        ErrorsSnackbarComponent, { data: { errors: ['Name is required'] } }
      );
    }
  }

  onDelete(): void {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Delete Scenario',
        message: 'Are you sure you want to delete the following scenario?\n\n- ' + (this.scenario.name) +'\n\nThis action cannot be undone.',
        acceptButtonTitle: 'Yes, delete',
        cancelButtonTitle: 'Cancel',
        buttonClass: 'warn',
      }
    };

    const dialogRef = this.matDialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(take(1), untilDestroyed(this)).subscribe(result => {
      if (result) {
        this.dialogRef.close({scenarioAction: TempTransactionAction.DELETE_SCENARIO, payload: this.scenario});
      }
    });
  }

  ngOnDestroy() {
  }
}
