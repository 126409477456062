import {Action} from '@ngrx/store';

import {Roster, RosterEntity} from '@models/roster-entity';

export enum ActionTypes {
    GET = '[Team Roster] Get',
    GET_SUCCESS = '[Team Roster] Get Success',
    GET_FAILED = '[Team Roster] Get Failed',
    SAVE_LINEUP = '[Team Roster] Save Lineup',
    SAVE_LINEUP_SUCCESS = '[Team Roster] Save Lineup Success',
    SAVE_LINEUP_FAILED = '[Team Roster] Save Lineup Failed',
}

export class SaveLineup implements Action {
    public readonly type = ActionTypes.SAVE_LINEUP;
    constructor(public payload: Roster) {}
}

export class SaveLineupSuccess implements Action {
    public readonly type = ActionTypes.SAVE_LINEUP_SUCCESS;
    constructor(public payload: Roster) {}
}

export class SaveLineupFailed implements Action {
    public readonly type = ActionTypes.SAVE_LINEUP_FAILED;
    constructor(public payload: any) {}
}

export type Actions = SaveLineup | SaveLineupSuccess | SaveLineupFailed;
