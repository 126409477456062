import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject, Subject} from "rxjs";


@UntilDestroy()
@Injectable()
export class PostHelper {
  constructor(protected router: Router) {
  }

  readonly leftOffset = 34;

  postUpdateSubject: Subject<boolean> = new Subject<boolean>();
  scrollClearSubject: Subject<boolean> = new Subject<boolean>();
  scrollValueSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  positionActionsButtons(thisRef): void {
    const boundingBox = thisRef.postComponent.nativeElement.getBoundingClientRect();
    const position = boundingBox.x + boundingBox.width - this.leftOffset;
    if (thisRef?.postActions && !thisRef.isMobile) {
      thisRef.postActions.nativeElement.style.left = position + 'px';
    }
    if (thisRef?.scrollToTop && !thisRef.isMobile) {
      thisRef.scrollToTop.nativeElement.style.left = position + 'px';
    }
    thisRef.cdr.markForCheck();
  }

  selectedPostListener(thisRef): void {
    thisRef.postList.selectedPost.pipe(untilDestroyed(this)).subscribe((post) => {
      if (thisRef.postList.isHighlighting) {
        // We need to null the post for a short time so that froala works correctly
        thisRef.selectedPost = null;
        setTimeout((_) => {
          thisRef.selectedPost = post;
          if (post) {
            thisRef.cdr.detectChanges();
            if (thisRef.selectedPost) {
              thisRef.editPermissions = thisRef.selectedPost.editPermissions(thisRef.authService);
              thisRef.deletePermissions = thisRef.selectedPost.deletePermissions(thisRef.authService);
            }
            if (thisRef.isMobile && thisRef.contentMiddle?.nativeElement?.scrollTop) {
              thisRef.contentMiddle.nativeElement.scrollTop = 0;
            }
            thisRef.cdr.markForCheck();
          }
        }, 10);
      }
    });
  }

  triggerUpdate(): void {
    this.postUpdateSubject.next(true);
  }
}
