import {Action} from '@ngrx/store';

import {Article} from '@models/articles';

export enum ActionTypes {
  LIST = '[Transaction Article] List',
  LIST_SUCCESS = '[Transaction Article] List Success',
  LIST_FAILED = '[Transaction Article] List Failed'
}

export class GetTransactionArticles implements Action {
  public readonly type = ActionTypes.LIST;
  constructor(public payload: {aboutID?: number, league: number, maxDays: number, page: string, pageID: string, limit: number}) {}
}

export class GetTransactionArticlesSuccess implements Action {
  public readonly type = ActionTypes.LIST_SUCCESS;
  constructor(public payload: {aboutID: number, leagueID: number, prevPageDateToken: string, nextPageDateToken: string, prevPageIDToken: string, nextPageIDToken: string, results: Article[], filteredIds: number[]}) {}
}

export class GetTransactionArticlesFailed implements Action {
  public readonly type = ActionTypes.LIST_FAILED;
  constructor(public payload: any) {}
}

export type Actions = GetTransactionArticles | GetTransactionArticlesSuccess | GetTransactionArticlesFailed;
