import * as moment from 'moment-timezone';

import {Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {forwardRefMoment} from '@helpers/class-transformer.helper';
import {Entity} from './entities';
import { Person } from './people';

class IdentifyingClass {
  name: string;
  id: number;
}

export class Affiliation {
  @Type(forwardRef(() => Entity) as any)
  affiliation: Entity;
  @Type(forwardRef(() => IdentifyingClass) as any)
  affiliationType: IdentifyingClass;
  @Type(forwardRef(() => Entity) as any)
  approver: Entity;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  createDatetime: moment.Moment;
  @Type(forwardRef(() => Entity) as any)
  creator: Entity;
  description: string;
  dirty: boolean;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  editDatetime: moment.Moment;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  endDatetime: moment.Moment;
  @Type(forwardRef(() => Entity) as any)
  entity: Entity;
  forceResetPrimary: boolean;
  id: number;
  isApproved: boolean;
  isApprovedByUser: boolean;
  isLegacyAffiliation: boolean;
  isPrimaryAffiliation: boolean;
  isPrimaryAgent: boolean;
  isReversed: boolean;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  rosterStartDatetime: moment.Moment;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  rosterEndDatetime: moment.Moment;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  expiryDatetime: moment.Moment;
  shortDescription: string;
  @Type(forwardRef(() => IdentifyingClass) as any)
  source: IdentifyingClass;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  startDatetime: moment.Moment;
  @Type(forwardRef(() => IdentifyingClass) as any)
  state: IdentifyingClass;
}

export class AffiliationRelationshipSource {
  name: string;
  @Type(forwardRef(() => Person) as any)
  entity: Person;
  isActive: boolean;
  lastUpdatedTooltip: string;
  sourceTooltip: string;
}

export class AffiliationRelationship {
  affiliationIDs: number[] = [];
  @Type(forwardRef(() => Entity) as any)
  @Type(forwardRef(() => Person) as any)
  affiliationEntity: Entity | Person;
  @Type(forwardRef(() => AffiliationRelationshipSource) as any)
  sources: AffiliationRelationshipSource[] = [];
  duration: string;
  lastUpdated: string;
  seasons: string;
  isActive: boolean;
  isPrimary: boolean;
}
