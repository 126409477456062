<mat-table
  class="teams-table"
  *ngIf="teamsData"
  [dataSource]="teamsData" multiTemplateDataRows>

  <ng-container matColumnDef="teamName">
    <mat-header-cell *matHeaderCellDef>team</mat-header-cell>
    <mat-cell *matCellDef="let teamData">{{ teamData.team }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="minutesCoverage">
    <mat-header-cell *matHeaderCellDef>Coverage</mat-header-cell>
    <mat-cell *matCellDef="let teamData">
      <div class="colored-indicator" [class.yellow]="teamData.minutesCoverage < 0.8" [class.red]="teamData.minutesCoverage < 0.5" matTooltip="Minutes Coverage">{{ teamData.minutesCoverage | number: "0.2-2" }}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="periodsCoverage">
    <mat-header-cell *matHeaderCellDef>Periods Coverage</mat-header-cell>
    <mat-cell *matCellDef="let teamData">
      <div class="colored-indicator" [class.yellow]="teamData.periodsCoverage < 0.8" [class.red]="teamData.periodsCoverage < 0.5">{{ teamData.periodsCoverage | number: "0.2-2" }}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="gamesCoverage">
    <mat-header-cell *matHeaderCellDef>Games Coverage</mat-header-cell>
    <mat-cell *matCellDef="let teamData">
      <div class="colored-indicator" [class.yellow]="teamData.gamesCoverage < 0.8" [class.red]="teamData.gamesCoverage < 0.5">{{ teamData.gamesCoverage | number: "0.2-2" }}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let teamData" [attr.colspan]="2">
      <div class="team-detail">
        <div *ngFor="let game of teamData.games" class="team-game">
          <div class="game-minutes-coverage">
            <div class="colored-indicator" [class.yellow]="game.minutesCoverage < 0.8" [class.red]="game.minutesCoverage < 0.5" matTooltip="Minutes Coverage">{{ game.minutesCoverage | number: "0.2-2" }}</div>
          </div>
          <div class="game-date">{{ game.date }}</div>
          <div class="game-name">{{ game.away_team }} @ {{ game.home_team }}</div>
          <ng-container *ngFor="let period of [].constructor(teamData.maxGamePeriods); let i = index">
            <ng-container *ngIf="i < game.half">
              <div *ngIf="getGamePeriod(game.periods, i + 1)" class="game-period">
                <div class="period-first-time" [class.yellow]="i <= 1 && getGamePeriod(game.periods, i + 1).first_time < 1100" [class.red]="i <= 1 && getGamePeriod(game.periods, i + 1).first_time < 800" [matTooltip]="'Period ' + (i + 1) + ' First Time Available'">{{ getGamePeriod(game.periods, i + 1).first_time }}</div>
                <div class="period-reliability" [class.yellow]="getGamePeriod(game.periods, i + 1).reliability == 'Medium'" [class.red]="getGamePeriod(game.periods, i + 1).reliability == 'Poor'" [matTooltip]="'Period ' + (i + 1) + ' Reliability'">{{ getGamePeriod(game.periods, i + 1).reliability }}</div>
              </div>
              <div *ngIf="!getGamePeriod(game.periods, i + 1)" class="game-period missing">Missing</div>
            </ng-container>
            <ng-container *ngIf="i >= game.half">
              <div class="game-period"></div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['minutesCoverage', 'teamName']"></mat-header-row>
  <mat-row *matRowDef="let teamData; columns: ['minutesCoverage', 'teamName'];"
      class="team-row"
      [class.expanded-row]="teamData.isExpanded"
      (click)="teamData.isExpanded = teamData.isExpanded ? false : true">
  </mat-row>
  <mat-row *matRowDef="let teamData; columns: ['expandedDetail']" class="team-detail-row"
    [@detailExpand]="teamData.isExpanded ? 'expanded' : 'collapsed'"
    style="overflow: hidden"></mat-row>
</mat-table>
