import {plainToClass} from 'class-transformer';

import {IdName} from '@models/id-names/_id-names';

export const EVENT_LEVEL_IDS = {
  PRO: 1,
  AMATEUR: 2,
  GENERAL: 3,
};

export class EventLevel extends IdName {}

export const EVENT_LEVELS = {};
EVENT_LEVELS[EVENT_LEVEL_IDS.PRO] = plainToClass(EventLevel, {id: EVENT_LEVEL_IDS.PRO, displayName: 'Pro'});
EVENT_LEVELS[EVENT_LEVEL_IDS.AMATEUR] = plainToClass(EventLevel, {id: EVENT_LEVEL_IDS.AMATEUR, displayName: 'Amateur'});
EVENT_LEVELS[EVENT_LEVEL_IDS.GENERAL] = plainToClass(EventLevel, {id: EVENT_LEVEL_IDS.GENERAL, displayName: 'General'});
