import * as moment from 'moment-timezone';

import { Transform, Type, plainToClass } from 'class-transformer';
import { forwardRef } from '@angular/core';

import { forwardRefMoment } from '@helpers/class-transformer.helper';

export enum ContractAmountTypes {
  CAP = 'Cap',
  TAX = 'Tax',
  APRON = 'Apron'
}

export class ContractYear {
  capAmount: number;
  taxAmount: number;
  apronAmount: number;
  isFaAmount: boolean;
  option: string;
  optionDecision: string;
  protectionCoverage: string;
  faStatus: string;

  get displayAmount(): Record<string, string> {
    const displayAmounts = {};
    displayAmounts[ContractAmountTypes.CAP] = (this.capAmount > 0 && !this.isFaAmount) ? String(this.capAmount) : null;
    displayAmounts[ContractAmountTypes.TAX] = (this.taxAmount > 0 && !this.isFaAmount) ? String(this.taxAmount) : null;
    displayAmounts[ContractAmountTypes.APRON] = (this.apronAmount > 0 && !this.isFaAmount) ? String(this.apronAmount) : null;
    return displayAmounts;
  }

  get displayFaStatus(): string {
    let displayFaStatus = '';
    if (this.faStatus == 'U') {
      displayFaStatus = 'UFA';
    } else if (this.faStatus == 'R') {
      displayFaStatus = 'RFA';
    }
    return displayFaStatus;
  }

  get amountClassList(): string[] {
    const classList = [];
    if (this.isFaAmount) {
      classList.push('fa-amount');
    }
    if (this.option == 'PLYR' && !this.optionDecision) {
      classList.push('player-option');
    } else if (this.option == 'TEAM' && !this.optionDecision) {
      classList.push('team-option');
    } else if (this.protectionCoverage == 'PART' || this.protectionCoverage == 'PTCND') {
      classList.push('partial-guarantee');
    }
    return classList;
  }

  get faStatusClassList(): string[] {
    const classList = [];
    if (this.faStatus == 'U') {
      classList.push('fa-status-ufa');
    } else if (this.faStatus == 'R') {
      classList.push('fa-status-rfa');
    }
    return classList;
  }

  get amountTooltip(): string {
    let tooltip = '';
    if (this.option == 'PLYR' && !this.optionDecision) {
      tooltip = 'Player Option';
    } else if (this.option == 'TEAM' && !this.optionDecision) {
      tooltip = 'Team Option';
    } else if (this.protectionCoverage == 'PART' || this.protectionCoverage == 'PTCND') {
      tooltip = 'Partial Guarantee';
    }
    return tooltip;
  }

  get faStatusTooltip(): string {
    let tooltip = '';
    if (this.faStatus == 'U') {
      tooltip = 'Unrestricted Free Agent';
    } else if (this.faStatus == 'R') {
      tooltip = 'Restricted Free Agent';
    }
    return tooltip;
  }
}

export enum BadgeType {
  BASE_YEAR = 'Base Year',
  SIGN_AND_TRADE = 'Sign And Trade',
  TRADE_RESTRICTION = 'Trade Restriction',
  TRADE_BONUS = 'Trade Bonus',
  PLAYER_CONSENT = 'Player Consent',
  MIN_CONTRACT = 'Min Contract',
  POISON_PILL = 'Poison Pill',
  NO_TRADE = 'No Trade Clause',
  INCENTIVE_BONUS = 'Incentive Bonus',
  TWO_WAY = 'Two-Way Player',
  REACQUISITION_RESTRICTIONS = 'Re-acquisition Restrictions',
  TWC_GLG_SIGNING_RESTRICTION = 'TWC/GLG Signing Restriction'
}

class ReacquisitionRestrictionMetaData {
  rule: string;
  team: string;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  endDate: moment.Moment;
}

class BadgeMetaData {
  amount: number;
  nbaDays: number;
  nbaDaysAllowed: number;
  unreportedDays: number;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  endDate: moment.Moment;
  description: string;
  @Type(forwardRef(() => ReacquisitionRestrictionMetaData) as any)
  reacquisitionRestrictions: ReacquisitionRestrictionMetaData[];
}

export class Badge {
  badgeType: BadgeType;
  @Type(forwardRef(() => BadgeMetaData) as any)
  metadata: BadgeMetaData;

  get styleClass(): string {
    switch (this.badgeType) {
      case BadgeType.BASE_YEAR: {
        return 'base-year';
      }
      case BadgeType.SIGN_AND_TRADE: {
        return 'sign-and-trade';
      }
      case BadgeType.TRADE_RESTRICTION: {
        return 'trade-restriction';
      }
      case BadgeType.TRADE_BONUS: {
        return 'trade-bonus';
      }
      case BadgeType.PLAYER_CONSENT: {
        return 'player-consent';
      }
      case BadgeType.MIN_CONTRACT: {
        return 'min-contract';
      }
      case BadgeType.POISON_PILL: {
        return 'poison-pill';
      }
      case BadgeType.NO_TRADE: {
        return 'no-trade';
      }
      case BadgeType.INCENTIVE_BONUS: {
        return 'incentive-bonus';
      }
      case BadgeType.TWO_WAY: {
        return 'two-way';
      }
      case BadgeType.REACQUISITION_RESTRICTIONS: {
        return 'reacquisition-restrictions';
      }
    }
  }

  get tooltip(): string {
    let tooltip = String(this.badgeType);
    switch (this.badgeType) {
      case BadgeType.TRADE_RESTRICTION: {
        tooltip += '\n' + this.metadata.description;
        tooltip += '\n' + 'End: ' + this.metadata.endDate.format('MM/DD/YYYY');
        return tooltip;
      }
      case BadgeType.PLAYER_CONSENT: {
        tooltip += '\n' + this.metadata.description;
        tooltip += '\n' + 'End: ' + this.metadata.endDate.format('MM/DD/YYYY');
        return tooltip;
      }
      case BadgeType.POISON_PILL: {
        tooltip += '\n' + this.metadata.amount.toLocaleString();
        return tooltip;
      }
      case BadgeType.TWO_WAY: {
        tooltip += '\n' + 'NBA Days Accrued: ' + this.metadata.nbaDays + ' of ' + this.metadata.nbaDaysAllowed;
        tooltip += '\n' + 'Unreported Days: ' + this.metadata.unreportedDays;
        return tooltip;
      }
      case BadgeType.REACQUISITION_RESTRICTIONS: {
        this.metadata.reacquisitionRestrictions.forEach((restriction, i) => {
          tooltip += '\n' + restriction.rule + ':' + '\n' + restriction.team + ' (' + restriction.endDate.format('MM/DD/YYYY') + ')';
        });
        return tooltip;
      }
      case BadgeType.TRADE_BONUS: {
        tooltip += this.metadata.amount ? ' ' + this.metadata.amount + '%' : '';
        return tooltip;
      }
    }
    return this.badgeType;
  }
}

export enum ExpectedSalaryTypes {
  SMOOTH = 'Pred Smooth',
  ADJUSTED = 'Pred Adjusted'
}

export class ExpectedSalary {
  predSmooth: number;
  predAdjFinal: number;

  get displayAmount(): string {
    return (this.predSmooth > 0) ? String(this.predSmooth) : null;
  }
}

export class ContractYearMap {
  [year: number]: ContractYear;
}

export class ExpectedSalaryMap {
  [year: number]: ExpectedSalary;
}

export class PlayerContract {
  @Type(forwardRef(() => ContractYearMap) as any)
  @Transform(forwardRefContractYearMap())
  contractYears: ContractYearMap;
  @Type(forwardRef(() => Badge) as any)
  badges: Badge[];
  @Type(forwardRef(() => ExpectedSalaryMap) as any)
  @Transform(forwardRefExpectedSalaryMap())
  expectedSalary: ExpectedSalaryMap;

  get hiddenBadgesTooltip(): string {
    let tooltip = '';
    this.badges.forEach((badge, i) => {
      if (i >= 2) {
        tooltip += badge.tooltip;
        tooltip += '\n\n';
      }
    });
    return tooltip;
  }
}

export function forwardRefContractYearMap() {
  return ({ value, key, obj, type }) => {
    Object.keys(value).forEach(year => {
      value[year] = plainToClass(ContractYear, value[year]);
    });
    return value;
  };
}

export function forwardRefExpectedSalaryMap() {
  return ({ value, key, obj, type }) => {
    Object.keys(value).forEach(year => {
      value[year] = plainToClass(ExpectedSalary, value[year]);
    });
    return value;
  };
}
