import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Announcement} from '@models/moments';

export const featureAdapter: EntityAdapter<Announcement> = createEntityAdapter<Announcement>({
  selectId: model => {
    return model.id || 0;
  },
  sortComparer: false
});

export interface State extends EntityState<Announcement> {
  isLoading?: boolean;
  error?: any;
  date?: string;
}

export const initialState: State = featureAdapter.getInitialState(
  {
    isLoading: false,
    error: null,
    date: null,
  }
);
