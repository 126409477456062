import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LEAGUE_IDS} from '@models/constants/leagues';
import {NavOption} from '@models/nav-option';
import {NgxPermissionsObject, NgxPermissionsService} from 'ngx-permissions';
import {EntityNavTabService} from '@services/entity-nav-menu.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DOMHelper} from '@helpers/dom.helper';

import * as _ from 'lodash';

@UntilDestroy()
@Component({
  selector: 'video-menu',
  templateUrl: './video-menu.component.html',
  styleUrls: ['../nav-menu.component.scss', './video-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  })
export class VideoMenuComponent implements OnInit, AfterViewInit {
  readonly LEAGUE_IDS = LEAGUE_IDS;
  isMobile: boolean;
  navTabs: NavOption[] = [];
  perms: NgxPermissionsObject;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected domHelper: DOMHelper,
    protected entityNavTabService: EntityNavTabService,
    protected permissionsService: NgxPermissionsService,
    protected router: Router,
  ) {}

  ngOnInit() {
    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small,
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe((result) => {
      this.isMobile = result.matches;
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        setTimeout(() => {
          this.cdr.markForCheck();
        }, 100);
      }
    });

    this.perms = this.permissionsService.getPermissions();
    this.navTabs = this.entityNavTabService.getVideoNavTabs(this.perms);
  }

  ngAfterViewInit() {
    this.cdr.markForCheck();
  }

  isUrlActive(url) {
    if (this.router.url.includes('?')) {
      return url === this.router.url.split('?')[0];
    } else {
      return url === this.router.url;
    }
  }

  navigate(path: string) {
    // If we are navigating post feed <--> highlights
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (this.activatedRoute.snapshot.url.length === 0 || this.activatedRoute.snapshot.url[0].path === 'highlights') {
      this.router.navigate(['bild', path], {queryParams: _.omit(queryParams, ['seasons', 'gameTypes', 'position', 'minDate', 'maxDate', 'minGame', 'maxGame', 'divisions', 'conferences', 'entities'])});
    } else {
      this.router.navigate(['bild', path], {queryParams: {}});
    }
  }
}
