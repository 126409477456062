import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

import {Article} from '@models/articles';

export const adapter: EntityAdapter<Article> = createEntityAdapter<Article>({
  selectId: model => {
    return model.postID;
  },
  sortComparer: false
});

export interface State extends EntityState<Article> {
  error?: any;
  aboutID: number;
  leagueID: number;
  filteredIds: number[];
  isLoading?: boolean;
  nextPageDateToken: string;
  prevPageDateToken: string;
  nextPageIDToken: string;
  prevPageIDToken: string;
}

export const initialState: State = adapter.getInitialState(
  {
    error: null,
    aboutID: null,
    leagueID: null,
    filteredIds: [],
    isLoading: false,
    nextPageDateToken: null,
    prevPageDateToken: null,
    nextPageIDToken: null,
    prevPageIDToken: null,
  }
);
