export const POSITION_IDS = {
  GUARD: 1,
  WING: 2,
  HYBRID: 3,
  BIG: 4,
  CENTER: 5,
};

export const POSITION_NAMES = {
  [POSITION_IDS.GUARD]: 'Guard',
  [POSITION_IDS.WING]: 'Wing',
  [POSITION_IDS.HYBRID]: 'Hybrid',
  [POSITION_IDS.BIG]: 'Big',
  [POSITION_IDS.CENTER]: 'Center',
};

export const POSITIONS = [
  {
    id: 1,
    name: 'Point Guard',
  },
  {
    id: 2,
    name: 'Shooting Guard',
  },
  {
    id: 3,
    name: 'Small Forward',
  },
  {
    id: 4,
    name: 'Power Forward',
  },
  {
    id: 5,
    name: 'Center',
  },
];

export class Position {
  id: number;

  eq(obj: Position): boolean {
    return this.id === obj.id;
  }

  get name(): string {
    return POSITION_NAMES[this.id];
  }
}
