import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';

import {Article} from '@models/articles';
import {adapter, State} from './state';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getNextPageToken = (state: State): string => state.nextPageDateToken;
export const getPrevPageToken = (state: State): string => state.prevPageDateToken;
export const getNextPageIDToken = (state: State): string => state.nextPageIDToken;
export const getPrevPageIDToken = (state: State): string => state.prevPageIDToken;
export const getFilteredIds = (state: State): number[] => state.filteredIds;

export const selectSpotlightArticleState: MemoizedSelector<object, State> = createFeatureSelector<State>('spotlight-article');

export const selectAllSpotlightArticles: (state: object) => Article[] = adapter.getSelectors(selectSpotlightArticleState).selectAll;

export const selectSpotlightArticleFilteredIds: MemoizedSelector<object, number[]> = createSelector(selectSpotlightArticleState, getFilteredIds);

export const selectFilteredSpotlightArticles = createSelector(
  selectAllSpotlightArticles,
  selectSpotlightArticleFilteredIds,
  (articles: Article[], ids: number[]) => {
    return articles.filter(article => ids.indexOf(article.postID) >= 0);
  }
);

export const selectSpotlightArticleNextPage: MemoizedSelector<object, any> = createSelector(selectSpotlightArticleState, getNextPageToken);
export const selectSpotlightArticlePrevPage: MemoizedSelector<object, any> = createSelector(selectSpotlightArticleState, getPrevPageToken);
export const selectSpotlightArticleNextPageID: MemoizedSelector<object, any> = createSelector(selectSpotlightArticleState, getNextPageIDToken);
export const selectSpotlightArticlePrevPageID: MemoizedSelector<object, any> = createSelector(selectSpotlightArticleState, getPrevPageIDToken);
