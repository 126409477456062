import {NavOption} from '@models/nav-option';

export class LeaguePostFeedNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Post Feed';
  shouldHide= false;
  constructor(league) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'leagues', league.id];
    this.link = ['', 'bild', 'entities', 'leagues', league.id];
  }
}

export class LeagueHighlightsNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Highlights';
  shouldHide= false;
  constructor(league) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'leagues', league.id, 'highlighting'];
    this.link = ['', 'bild', 'entities', 'leagues', league.id, 'highlighting'];
  }
}

export class LeagueRostersNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Rosters';
  shouldHide= false;
  constructor(league) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'leagues', league.id, 'rosters'];
    this.link = ['', 'bild', 'entities', 'leagues', league.id, 'rosters'];
  }
}

export class LeagueEvaluationNavOption extends NavOption {
  activeRootPath;
  link;
  name= 'Evaluation';
  shouldHide= false;
  constructor(league) {
    super();

    this.activeRootPath = ['', 'bild', 'entities', 'leagues', league.id, 'evaluation'];
    this.link = ['', 'bild', 'entities', 'leagues', league.id, 'evaluation'];
  }
}
