import {Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {AuthService} from '@services/auth.service';
import {Person} from './people';

export const MEDICAL_RATINGS = ['generalEvaluation', 'performanceSummary', 'medicalHistory', 'medicalPresentation'];

export class MedicalRating {
  id: number;
  isActive: boolean = true;
  @Type(forwardRef(() => Person) as any)
  author: Person;
  authorName: string;
  medicalRating: number;
  generalEvaluation: string[] = [''];
  performanceSummary: string[] = [''];
  medicalHistory: string[] = [''];
  medicalPresentation: string[] = [''];
  generalEvaluationVideo: any[] = [];
  performanceSummaryVideo: any[] = [];
  medicalHistoryVideo: any[] = [];
  medicalPresentationVideo: any[] = [];
  postID: number;
  @Type(forwardRef(() => Date) as any)
  postDatetime: Date;
  @Type(forwardRef(() => Person) as any)
  about: Person;
  aboutName: string;

}

export const MEDICAL_RISK = {
  '1': 'No current Risk',
  '2': 'Time loss not expected. No history of recurrent problems or past surgeries in problem areas',
  '3': 'Occasional loss of practice and game expected. Problem likely to worsen over time. More susceptible to traumatic events. Intervention possible to maintain level of activity. Usually able to play at high level during games',
  '4': 'Practice time limited and typically playing with a game minute restriction. Functional, but availability unpredictable. Irreversible problems; expect limited improvement with surgery or other interventions',
  '5': 'Extremely limited time of participation. Worsening of condition is usually catastrophic / career ending'
};
