import * as moment from 'moment-timezone';

import {Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Entity} from './entities';
import {forwardRefConstant, forwardRefConstants, forwardRefMoment} from '@helpers/class-transformer.helper';
import {Level, LEVELS} from '@models/constants/levels';
import {POST_CATEGORIES, PostCategory} from '@models/constants/post-categories';
import {PostType, POST_TYPES, POST_TYPE_IDS} from './constants/post-types';



class Injury {
  status: string;
  location: string;
}

export const ROTO_AUTHOR_ID: number = 50;
export const REALGM_AUTHOR_ID: number = 51;
export const PCMS_AUTHOR_ID: number = 52;

export class Article {
  @Type(forwardRef(() => Entity) as any)
  about: Entity[] = [];
  @Type(forwardRef(() => Entity) as any)
  author: Entity;
  message: string = '';
  @Type(forwardRef(() => Entity) as any)
  originalLeague: Entity;
  sourceDescription: string = '';
  sourceUrl: string = '';
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  postDatetime: moment.Moment;
  postID: number;
  revisionID: number;
  @Type(forwardRef(() => Injury) as any)
  injuries: Injury[];
  memos: any[];
  topic: string = '';
  transactionID: number;
  @Transform(forwardRefConstant(LEVELS), { toClassOnly: true })
  level: Level;
  @Transform(forwardRefConstants(POST_CATEGORIES), { toClassOnly: true })
  postCategories: PostCategory[] = [];
  @Transform(forwardRefConstant(POST_TYPES), { toClassOnly: true })
  postType: PostType;

  get aboutNames() {
    return this.about.map(about => about.name).join(', ');
  }

  get postDatetimeFormatted() {
    return this.formatDatetime('postDatetime');
  }

  get isSpotlight(): Boolean {
    return Boolean(this.author && this.postType && this.author.id == REALGM_AUTHOR_ID && this.postType.id == POST_TYPE_IDS.GOSSIP_RUMOR_NEWS);
  }

  get isTransactionTrade(): Boolean {
    return Boolean(this.author && this.author.id == PCMS_AUTHOR_ID && this.postType && this.postType.id == POST_TYPE_IDS.TRANSACTION && this.topic.startsWith('Trade between'));
  }

  get isTransaction(): Boolean {
    return Boolean(this.author && this.author.id == PCMS_AUTHOR_ID && this.postType && this.postType.id == POST_TYPE_IDS.TRANSACTION);
  }

  formatDatetime(prop: string): string {
    const now = moment();
    const dt = moment(this[prop]);
    let formattedDatetime = '';

    switch (true) {
      case now.isSame(dt, 'day'):
        formattedDatetime = dt.format('h:mm A');
        break;
      case now.isSame(dt, 'year'):
        formattedDatetime = dt.format('MMM D');
        break;
      default:
        formattedDatetime = dt.format('MMM D, YYYY');
    }

    return formattedDatetime;
  }

  get typeName(): string {
    switch (true) {
      case this.author.id == REALGM_AUTHOR_ID && this.postType && this.postType.id == POST_TYPE_IDS.GOSSIP_RUMOR_NEWS:
        return 'Spotlight';
      case this.author.id != ROTO_AUTHOR_ID:
        return 'Transaction';
      case this.injuries.length == 0:
        return 'Game Night';
      default:
        return 'Injury';
    }
  }
}
