<div class="playlist-menu-container" [ngClass]="{'hotkey-menu': isHotkeyMenu}">
  <div class="playlist-flex-container space-between title-row" *ngIf="!isHotkeyMenu">
    <span class="playlist-menu-text">Playlists</span>
    <mat-icon *ngIf="!isPostMenu" class="playlist-icon pointer" (click)="createNewPlaylist()">add</mat-icon>
  </div>

  <div class="playlist-flex-container space-between top-margin">
    <div class="playlist-filter-button shared" [matMenuTriggerFor]="authorMenu" #authorMenuTrigger="matMenuTrigger">
      <mat-icon class="playlist-icon margin-right">people</mat-icon>
      <span class="author-text">{{ authorDisplay }}</span>
      <mat-icon class="playlist-icon">arrow_drop_down</mat-icon>
    </div>

    <div class="playlist-flex-container recents" (click)="toggleSorting()">
      <span class="playlist-menu-text margin-right">{{ sortingHeader }}</span>
      <mat-icon class="playlist-icon">sort</mat-icon>
    </div>

<!--    TODO: Add with player folder logic-->
<!--    <div class="playlist-filter-button">-->
<!--      <mat-icon class="playlist-icon margin-right">folder</mat-icon>-->
<!--      <span class="playlist-menu-text">All</span>-->
<!--      <mat-icon class="playlist-icon">arrow_drop_down</mat-icon>-->
<!--    </div>-->

  </div>

  <div class="playlist-flex-container space-between top-margin search-row">
    <div class="search-container" [ngClass]="{'active': isSearchActive || isHotkeyMenu}">
      <mat-icon class="playlist-icon pointer" (click)="setSearchActive(true)">search</mat-icon>
      <input #playlistSearch tabindex="1" (blur)="setSearchActive(false, $event)" class="playlist-search" matInput
             *ngIf="isSearchActive || isHotkeyMenu" type="text" [(ngModel)]="searchValue" placeholder="Search Playlists" (ngModelChange)="filterPlaylists()"/>
    </div>

    <!--    TODO: Add back with introduction of player folder logic-->
<!--    <div class="playlist-flex-container recents" (click)="toggleSorting()">-->
<!--      <span class="playlist-menu-text margin-right">{{ sortingHeader }}</span>-->
<!--      <mat-icon class="playlist-icon">sort</mat-icon>-->
<!--    </div>-->
  </div>

  <div class="playlist-flex-container top-margin saved-videos" *ngIf="!isHotkeyMenu">
    <div class="playlist-option" (click)="changeSelectedPlaylist(null, true, true)" [ngClass]="{active: selectedPlaylist && selectedPlaylist.id == -1}">
      <mat-icon class="playlist-icon margin-right">push_pin</mat-icon>
      <span class="playlist-menu-text">All Saved Videos</span>
    </div>
  </div>

  <div class="playlist-options-container" [ngClass]="{'hotkey-menu': isHotkeyMenu}">
    <ng-container *ngFor="let playlist of filteredPlaylists">
      <div *ngIf="playlist.id != -1" class="playlist-option" (click)="changeSelectedPlaylist(playlist)" [ngClass]="{active: !isHotkeyMenu && selectedPlaylist?.id === playlist.id}">
        <span class="playlist-menu-text">{{playlist.name}}</span>
        <mat-icon *ngIf="playlist.isShared" class="playlist-icon">people</mat-icon>
      </div>
    </ng-container>
  </div>
</div>


<mat-menu class="author-menu" #authorMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
  <ng-template matMenuContent>
    <div class="playlist-author-option" (click)="filterPlaylists(playlistAuthors.ALL)">
      <div>All Authors</div>
    </div>
    <div class="playlist-author-option" (click)="filterPlaylists(playlistAuthors.MINE)">
      <div>By you</div>
    </div>
    <div class="playlist-author-option" (click)="filterPlaylists(playlistAuthors.SHARED)">
      <div>Shared with Me</div>
    </div>
  </ng-template>
</mat-menu>
