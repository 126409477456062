import {Component, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-errors-snackbar',
  templateUrl: './errors-snackbar.component.html',
  styleUrls: ['./errors-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorsSnackbarComponent {

  errors: string[];

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { errors: string[] },
              public snackBar: MatSnackBarRef<ErrorsSnackbarComponent>) {
    this.errors = data.errors;
  }
}
