import * as _ from 'lodash';

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation, Inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Person} from '@models/people';
import {Team} from '@models/teams';
import {Entity} from '@models/entities';
import {League} from '@models/leagues';
import {FocusGroup} from '@models/focus-groups';
import {untilDestroyed, UntilDestroy} from '@ngneat/until-destroy';
import {take} from 'rxjs/operators';
import {MissingInfoDialogComponent} from './missing-info-dialog/missing-info-dialog.component';
import { plainToClass } from 'class-transformer';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarDismiss as MatSnackBarDismiss } from '@angular/material/legacy-snack-bar';
import { APPS_CONFIG } from 'apps/apps-config.constants';
import { IAppsConfig } from 'apps/apps-config.interface';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@UntilDestroy()
@Component({
  selector: 'bild-entity-header',
  templateUrl: './entity-header.component.html',
  styleUrls: ['./entity-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EntityHeaderComponent implements OnInit {

  _person: Person;
  team: Entity;
  league: League;
  focusGroup: Entity;
  isMobile: boolean;
  ownerPersonTypeId: number = 10;

  @Input() isMenuAvatar: boolean = false;
  @Input() size: number = 56;
  @Input() margin: string = '0 0 0 0';
  @Input() secondaryLineOverride: string;

  @Input()
  set person(val) {
    this._person = val;
    this.cdr.detectChanges();
  }
  get person(): Person {
    return this._person;
  }

  @Input()
  set entity(entity: Entity) {
    if (entity instanceof Person) {
      this.person = entity;
    }
    // TODO Teams aren't being unloaded as actual Team models, should probably fix
    if (entity.type === 'Team') {
      this.team = entity;
    }
    if (entity instanceof League) {
      this.league = entity;
    }
    // TODO FocusGroups aren't being unloaded as actual FocusGroup models, should probably fix
    if (entity.type === 'FocusGroup') {
      this.focusGroup = entity;
    }
    this.cdr.detectChanges();
  }

  constructor(
    @Inject(APPS_CONFIG) public config: IAppsConfig,
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    public matDialog: MatDialog,
    public matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {
    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  isDraftElligiblePlayer(): boolean {
    return (
      (this.person.primaryAffiliation && this.person.primaryAffiliation.league?.name === 'NCAA') ||
      (this.person.primaryAffiliation && this.person.primaryAffiliation.league?.name === 'International' && !this.person.draftYear));
  }

  isPrepPlayer(): boolean {
    return (this.person.primaryAffiliation &&
      this.person.primaryAffiliation.league?.name === 'Prep');
  }
}
