import {ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot} from '@angular/router';
import {RouterStateSerializer} from '@ngrx/router-store';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface MergedRoute {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
  path: string;
}

export class MergedRouterStateSerializer implements RouterStateSerializer<MergedRoute> {
  serialize(routerState: RouterStateSnapshot): MergedRoute {
    return {
      url: routerState.url,
      params: mergeRouteParams(routerState.root, r => r.params),
      queryParams: mergeRouteParams(routerState.root, r => r.queryParams),
      data: mergeRouteData(routerState.root),
      path: '/' + mergeRoutePath(routerState.root).join('/')
    };
  }
}

function mergeRoutePath(route: ActivatedRouteSnapshot): any {
  if (!route) {
    return [];
  }

  let currentPath: string = '';

  if (route.routeConfig) {
    currentPath = route.routeConfig.path;
  }
  const primaryChild = route.children.find(c => c.outlet === 'primary') || route.firstChild;

  if (currentPath === '') {
    return mergeRoutePath(primaryChild);
  } else {
    return [currentPath].concat(mergeRoutePath(primaryChild));
  }
}

function mergeRouteParams(route: ActivatedRouteSnapshot, getter: (r: ActivatedRouteSnapshot) => Params): Params {
  if (!route) {
    return {};
  }
  const currentParams = getter(route);
  const primaryChild = route.children.find(c => c.outlet === 'primary') || route.firstChild;
  return {...currentParams, ...mergeRouteParams(primaryChild, getter)};
}

function mergeRouteData(route: ActivatedRouteSnapshot): Data {
  if (!route) {
    return {};
  }

  const currentData = route.data;
  const primaryChild = route.children.find(c => c.outlet === 'primary') || route.firstChild;
  return {...currentData, ...mergeRouteData(primaryChild)};
}
