import {
  ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output, ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {trigger, state, style, transition, animate, AUTO_STYLE} from '@angular/animations';

import {SearchInputComponent} from '../search-input/search-input.component';
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/legacy-autocomplete';
import {SearchDialogComponent} from '@bild-dialogs/search/search.dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PostComposerService} from '../post/post-composer/post-composer.service';
import {Router} from "@angular/router";
import {VideoRootComponent} from "../../video/video-root.component";
import {VideoService} from "@services/video.service";

@UntilDestroy()
@Component({
  selector: 'search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss' ],
  animations: [
  trigger('inputState', [
    state('0', style({
      width: '0%',
      margin: '0px',
      })),
    state('1', style({
      width: '100%',
      margin: AUTO_STYLE,
      })),
    transition('0 => 1', animate('200ms ease-in')),
    transition('1 => 0', animate('200ms ease-out')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
  })
export class SearchBoxComponent {
  private _searchVisible: boolean = false;
  @ViewChild(SearchInputComponent, { static: true }) _searchInput: SearchInputComponent;

  set value(value: any) {
    this._searchInput.value = value;
  }
  get value(): any {
    return this._searchInput.value;
  }

  get searchVisible(): boolean {
    return this._searchVisible;
  }

  isDialogOpen = false;

  @HostListener('window:keydown', ['$event'])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    if (!event.metaKey || this.preventHotkey) {
      return;
    } else if (event.key === 's' || event.key === 'ß') {
      this.searchClicked();
      event.preventDefault();
    } else if (event.key === 'p') {
      this.postComposerService.openNewPostComposer();
      event.preventDefault();
    } else if (event.key === 'e') {
      // this.exploreVideo();
      event.preventDefault();
    }
  }

  /**
   * backIcon?: string
   * The icon used to close the search toggle, only shown when [alwaysVisible] is false.
   * Defaults to 'search' icon.
   */
  @Input('backIcon') backIcon: string = 'search';

  /**
   * searchIcon?: string
   * The icon used to open/focus the search toggle.
   * Defaults to 'search' icon.
   */
  @Input('searchIcon') searchIcon: string = 'search';

  /**
   * clearIcon?: string
   * The icon used to clear the search input.
   * Defaults to 'cancel' icon.
   */
  @Input('clearIcon') clearIcon: string = 'cancel';

  /**
   * showUnderline?: boolean
   * Sets if the input underline should be visible. Defaults to 'false'.
   */
  @Input('showUnderline') showUnderline: boolean = false;

  /**
   * debounce?: number
   * Debounce timeout between keypresses. Defaults to 400.
   */
  @Input('debounce') debounce: number = 400;

  /**
   * alwaysVisible?: boolean
   * Sets if the input should always be visible. Defaults to 'false'.
   */
  @Input('alwaysVisible') alwaysVisible: boolean = false;

  /**
   * placeholder?: string
   * Placeholder for the underlying input component.
   */
  @Input('placeholder') placeholder: string;

  /**
   * isContractApp?: boolean
   * Whether the searchbar is contained in the contract app.
   */
  @Input('isContractApp') isContractApp: boolean = false;

  @Input('searchResults') searchResults: any[];
  @Input('preventHotkey') preventHotkey: boolean;

  /**
   * searchClicked: function($log)
   * Event emitted after the search is open/closed.
   */
  @Output('searchClicked') onSearchClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * searchDebounce: function($log)
   * Event emitted after the [debounce] timeout.
   */
  @Output('searchDebounce') onSearchDebounce: EventEmitter<string> = new EventEmitter<string>();

  /**
   * search: function($log)
   * Event emitted after the key enter has been pressed.
   */
  @Output('search') onSearch: EventEmitter<string> = new EventEmitter<string>();

  /**
   * clear: function()
   * Event emitted after the clear icon has been clicked.
   */
  @Output('clear') onClear: EventEmitter<void> = new EventEmitter<void>();

  @Output('optionSelected') onOptionSelected: EventEmitter<{event: MatAutocompleteSelectedEvent, mouseEvent: MouseEvent}> = new EventEmitter<{event: MatAutocompleteSelectedEvent, mouseEvent: MouseEvent}>();


  constructor(
    public postComposerService: PostComposerService,
    protected dialog: MatDialog,
    protected matDialog: MatDialog,
    protected router: Router,
    protected videoService: VideoService,
  ) {}

  /**
   * Method executed when the search icon is clicked.
   */
  searchClicked(): void {
    let dialogRef;
    if (!this.isDialogOpen) {
      dialogRef = this.dialog.open(SearchDialogComponent, {
        disableClose: false,
        position: {top: '100px'},
        panelClass: 'no-padding-dialog',
        data: {isContractApp: this.isContractApp},
      });
      this.isDialogOpen = true;
    }

    dialogRef?.afterClosed().pipe(untilDestroyed(this)).subscribe((result) => {
      this.isDialogOpen = false;
    });
  }

  toggleVisibility(): void {
    this._searchVisible = !this._searchVisible;
  }

  handleSearchDebounce(value: any): void {
    this.onSearchDebounce.emit(value);
  }

  handleSearch(value: string): void {
    this.onSearch.emit(value);
  }

  handleClear(): void {
    this.onClear.emit(undefined);
  }

  handleOptionSelected({event, mouseEvent}: {event: MatAutocompleteSelectedEvent, mouseEvent: MouseEvent}): void {
    this.value = event.option.value.name;
    this.onOptionSelected.emit({event: event, mouseEvent: mouseEvent});
  }

  exploreVideo() {
    const videoDialogConfig = {
      panelClass: 'video-panel',
      backdropClass: 'video-panel-backdrop',
      height: '100%',
      width: '100%',
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
      closeOnNavigation: false,
    };
    this.videoService.videoDialogOpenedSubject.next(true);
    const dialogRef = this.matDialog.open(VideoRootComponent, videoDialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
    });
  }
}
