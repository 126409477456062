

import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  HostListener, ViewChildren,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators, FormArray} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

import {EntitiesService} from '@services/entities.service';
import {IAppsConfig} from 'apps/apps-config.interface';
import {APPS_CONFIG} from '../../../../apps-config.constants';
import {AutocompleteService} from '@services/autocomplete.service';
import {untilDestroyed, UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import {take} from 'rxjs/operators';
import {Entity} from '@models/entities';
import {Router} from '@angular/router';
import { ENTITY_SUBTYPE_IDS } from '@models/constants/entity-subtypes';


@UntilDestroy()
@Component({
  selector: 'search-dialog',
  templateUrl: './search.dialog.component.html',
  styleUrls: ['./search.dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
  })
export class SearchDialogComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput;
  @ViewChildren('entityContext') entityContext;

  activeIndex = 0;
  debounce = 400;
  hasRouted = false;
  isMetaPressed = false;
  isContractApp = false;
  isSearching = false;
  placeholder = 'Search bild';
  searchResults = [];
  searchResults$: Subscription;
  showUnderline = false;

  @HostListener('window:keydown', ['$event'])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    if (event.key === 'Meta') {
      this.isMetaPressed = true;
    } else if (event.key === 'Enter') {
      this.handleOptionSelected(this.searchResults[this.activeIndex], this.isMetaPressed);
    }
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyboardUpEvent(event: KeyboardEvent) {
    if (event.key === 'Meta') {
      this.isMetaPressed = false;
    } else if (event.key === 'ArrowDown') {
      this.moveToNextEntity();
    } else if (event.key === 'ArrowUp') {
      this.moveToPreviousEntity();
    }
  }

  constructor(
              @Inject(APPS_CONFIG) public config: IAppsConfig,
              public dialogRef: MatDialogRef<SearchDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              protected cdr: ChangeDetectorRef,
              protected entitiesService: EntitiesService,
              protected autocompleteService: AutocompleteService,
              protected router: Router,
              protected snackBar: MatSnackBar) {
    this.isContractApp = data.isContractApp;
    if (this.isContractApp) {
      this.placeholder = 'Search Contract App';
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.searchInput.focus();
  }

  moveToNextEntity() {
    if (this.activeIndex == this.searchResults?.length - 1) {
      this.activeIndex = 0;
    } else if (this.searchResults?.length) {
      this.activeIndex++;
    }
  }

  moveToPreviousEntity() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    } else if (this.searchResults?.length) {
      this.activeIndex = this.searchResults.length - 1;
    }
  }

  search(val) {
    if (this.searchResults$) {
      this.searchResults$.unsubscribe();
    }
    this.filterEntities(val.value, val.autocomplete);
    this.cdr.markForCheck();
  }

  filterEntities(q: string, autocomplete: MatAutocomplete): void {
    if (q) {
      this.isSearching = true;
      let entityTypes = this.isContractApp ? 'Person,Team' : 'Person,Team,League,Tag';
      let leagues = this.isContractApp ? 'NBA' : null;
      let subtypes = this.isContractApp ? String(ENTITY_SUBTYPE_IDS.PLAYER) : null;
      this.autocompleteService.getElasticSearchResults(
          q,
          entityTypes,
          8,
          leagues,
          subtypes).pipe(take(1), untilDestroyed(this)).subscribe(
          (entities) => {
            this.searchResults = entities;
            setTimeout(() => {
              this.activeIndex = 0;
            }, 50);
            this.cdr.markForCheck();
          },
      );
    } else {
      this.searchResults = [];
      this.cdr.markForCheck();
    }
  }

  handleSubrouteSelected({entity, subroute}) {
    this.handleOptionSelected(entity, false, subroute);
  }

  handleOptionClicked(index, mouseEvent: MouseEvent) {
    this.handleOptionSelected(this.searchResults[index], mouseEvent && mouseEvent.metaKey);
  }

  navigatingToSameEntityType(currentUrl, entity): boolean {
    return (currentUrl.includes('leagues') && entity.isLeague) ||
      (currentUrl.includes('people') && entity.isPerson) || (currentUrl.includes('teams') && entity.isTeam);
  }

  handleOptionSelected(entity: Entity, isMetaSelection: boolean, subroute?: string) {
    if (this.isContractApp && !subroute) {
      this.navigateToEntityContractPage(entity);
    }
    else if (this.isContractApp && subroute.includes('pcms')) {
      window.open(subroute, '_blank');
    }
    else {
      const currentUrl = this.router.url;
      let endRootUrlIndex = -1;
      const leaguesRootUrl = '/bild/entities/leagues/';
      const peopleRootUrl = '/bild/entities/people/';
      const teamsRootUrl = '/bild/entities/teams/';

      if (isMetaSelection || subroute) {
        if ((currentUrl.includes(leaguesRootUrl) && entity.isLeague) || (subroute && entity.isLeague)) {
          endRootUrlIndex = currentUrl.indexOf(leaguesRootUrl) + leaguesRootUrl.length;
        } else if ((currentUrl.includes(peopleRootUrl) && entity.isPerson) || (subroute && entity.isPerson)) {
          endRootUrlIndex = currentUrl.indexOf(peopleRootUrl) + peopleRootUrl.length;
        } else if ((currentUrl.includes(teamsRootUrl) && entity.isTeam) || (subroute && entity.isTeam)) {
          endRootUrlIndex = currentUrl.indexOf(teamsRootUrl) + teamsRootUrl.length;
        }

        if (endRootUrlIndex > -1) {
          let baseUrl = currentUrl.substring(0, endRootUrlIndex) + entity.id;
          // We need to trigger a data change if the subpage is the same with a different entity
          let isAdditionalRouteRequired = true;
          const urlAfterIdIndex = currentUrl.indexOf('/', endRootUrlIndex);
          const queryParametersIndex = currentUrl.indexOf('?', endRootUrlIndex);
          const newUrlAfterId = (urlAfterIdIndex > -1 ?
            (queryParametersIndex > -1 ? currentUrl.substring(urlAfterIdIndex, queryParametersIndex) : currentUrl.substring(urlAfterIdIndex)) : '');
          let newUrl = currentUrl.substring(0, endRootUrlIndex) + entity.id + newUrlAfterId;
          if (subroute) {
            baseUrl = '/' + this.getEntityRoute(entity).join('/');
            newUrl = '/' + this.getEntityRoute(entity, subroute).join('/');
            isAdditionalRouteRequired = currentUrl.substring(urlAfterIdIndex).includes(subroute) &&
              this.navigatingToSameEntityType(currentUrl, entity);
          }
          if (this.isContractApp) {
            window.open(newUrl, '_blank');
          }
          else {
            this.router.navigateByUrl(isAdditionalRouteRequired ? baseUrl : newUrl)
            .then((data) => {
              this.dialogRef.close();
              if (isAdditionalRouteRequired) {
                this.router.navigateByUrl(newUrl)
                    .then((data) => {
                      this.cdr.markForCheck();
                    })
                    .catch((e) => {
                      this.navigateToEntityPage(entity);
                    });
              }
            })
            .catch((e) => {
              this.navigateToEntityPage(entity);
            });
          }
        } else {
          this.navigateToEntityPage(entity);
        }
      } else {
        this.navigateToEntityPage(entity, subroute);
      }
    }

    this.cdr.markForCheck();
  }

  getEntityRoute(entity: Entity, subroute?: string): string[] {
    // This can occur if an enter key is pressed outside the scope of the search dialog
    if (!entity) {
      return null;
    }
    let route: any[];
    switch (true) {
      case entity.isLeague:
        route = ['bild', 'entities', 'leagues', entity.id];
        break;
      case entity.isPerson:
        route = ['bild', 'entities', 'people', entity.id];
        break;
      case entity.isTeam:
        route = ['bild', 'entities', 'teams', entity.id];
        break;
      case entity.isSubscriptionOrganization:
        route = ['bild', 'entities', 'subscription-organizations', entity.id];
        break;
      case entity.isTag:
        route = ['bild', 'tags', entity.id];
        break;
      default:
        route = ['bild', 'entities', 'focus-groups', entity.id];
    }

    if (subroute) {
      route.push(subroute);
    }
    return route;
  }

  navigateToEntityPage(entity: Entity, subroute?: string): void {
    const route = this.getEntityRoute(entity, subroute);

    // Subroute click will also trigger the general entity context click
    if (route && !this.hasRouted) {
      this.hasRouted = true;
      this.router.navigate(route);
      this.dialogRef.close();
    }
  }

  navigateToEntityContractPage(entity) {
    let route;
    if (entity.isPerson) {
      let url = this.router.serializeUrl(this.router.createUrlTree(this.getEntityRoute(entity)));
      window.open(url, '_blank');
    }
    else if (entity.isTeam) {
      route = ['contract', 'team', entity.id];
      this.hasRouted = true;
      this.router.navigate(route);
    }
    this.dialogRef.close();
  }

  ngOnDestroy() {
  }

  protected readonly event = event;
}
