import {Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Team} from 'apps/_models/teams';
import {Person} from '@models/people';

export class PlayerInterest {
  @Type(forwardRef(() => Person) as any)
  player: Person;
  @Type(forwardRef(() => Team) as any)
  team: Team;
  interest: number;
  isDuplicate: boolean;
}
