import {EntityType} from '@models/constants/entity-types';
import {Expose, Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Entity} from './entities';
import {Team} from 'apps/_models/teams';
import * as moment from 'moment-timezone';
import {forwardRefMoment} from '@helpers/class-transformer.helper';
import {Person} from '@models/people';
import {PlayerDevelopmentKeyResult} from '@models/player-development-key-results';
import {POST_TYPE_IDS, POST_TYPES} from '@models/constants/post-types';
import * as _ from 'lodash';
import {Skill} from '@models/player-development-skill-assessments';


export class RelevantActLogs {
  date: string;
  indivOther: number;
  team: number;
  indivSkill: number;
  video: number;
  data: number;
  engagement: number;
  intensity: number;
}
export class RelevantAct {
  logs: RelevantActLogs[];
  maxMins: any;
}
export class RelevantActWrapper {
  1: RelevantAct;
  7: RelevantAct;
  28: RelevantAct;
}
export class PlayerDevelopmentObjective {
  id: number;
  name: string;
  description: string;
  @Type(forwardRef(() => Person) as any)
  about: Person;
  season: number;
  category: string;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  startDate: moment.Moment;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  endDate: moment.Moment;
  isActive: boolean;
  skills: any[];
  @Type(forwardRef(() => PlayerDevelopmentKeyResult) as any)
  keyResults: PlayerDevelopmentKeyResult[] = [];
  relevantActivities: RelevantActWrapper;
  _isAchieving: boolean;
  progress: number;


  objectiveSkills(allSkillOptions): any[] {
    // If we have overall, we want to ensure that all sub-skills are included
    const allSkills = [];
    this.keyResults.forEach((keyResult) => {
      keyResult.skills.forEach((skill) => {
        allSkills.push(skill);
        if (skill.skill === Skill.OVERALL) {
          allSkills.push(...allSkillOptions.filter((skillOption) => skillOption.skillArea === skill.skillArea));
        }
      });
    });
    // Remove dupe skills
    const distinctSkills = _.uniqBy(_.flatMap(this.keyResults, 'skills'), 'id');
    // We want overall skills to be first
    return [
      ...distinctSkills.filter((skill) => skill.skill === Skill.OVERALL),
      ...distinctSkills.filter((skill) => skill.skill !== Skill.OVERALL),
    ];
  }
}
