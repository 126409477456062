import {Transform, Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import * as moment from 'moment-timezone';
import {forwardRefMoment} from '@helpers/class-transformer.helper';
import {AnnouncementType} from '@models/announcement-type';

export class AnnouncementPosts {
  date: string;
  @Type(forwardRef(() => Announcement) as any)
  posts: Announcement[] = [];
}

class Image {
  description: string;
  id: number;
  textColor: string;
  typeID: number;
  url: string;
}

export class Announcement {
  id: number;
  message: string;
  author: string;
  headline: string;
  distributionOverride: string;
  @Type(forwardRef(() => Image) as any)
  image: Image;
  @Type(forwardRef(() => AnnouncementType) as any)
  announcementType: AnnouncementType;

  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  maxDate: moment.Moment;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  minDate: moment.Moment;

  get imageUrl(): string {
    return `${this.image.url}?txtfont64=cm9ib3Rv&txtalign=bottom%2Ccenter&txtpad=22&txtclr=${this.image.textColor}&txtsize=62&txt64=${btoa(this.headline)}&txtfit=max&txt-font=HelveticaNeue`;
  }
}

export class UserForAuth {
  isADFSOnly: boolean;
}
