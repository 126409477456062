import {NavOption} from '@models/nav-option';
import {LEAGUE_IDS} from '@models/constants/leagues';

export class SiteManagementCacheNavOption extends NavOption {
  name= 'Cache';
  shouldHide= false;
  activeRootPath = ['/bild/site-management/cache'];
  link = ['/bild/site-management/cache'];
}

export class SiteManagementDataQualityNavOption extends NavOption {
  name= 'Data Quality';
  shouldHide= false;
  activeRootPath = ['/bild/site-management/data-quality'];
  link = ['/bild/site-management/data-quality'];
}

export class SiteManagementEntitiesNavOption extends NavOption {
  name= 'Entities';
  shouldHide= false;
  activeRootPath = ['/bild/site-management/entities'];
  link = ['/bild/site-management/entities'];
}

export class SiteManagementMomentsNavOption extends NavOption {
  name= 'Moments';
  shouldHide= false;
  activeRootPath = ['/bild/site-management/moments'];
  link = ['/bild/site-management/moments'];
}

export class SiteManagementTagManagementNavOption extends NavOption {
  name= 'Tag Management';
  shouldHide= false;
  activeRootPath = ['/bild/site-management/tags'];
  link = ['/bild/site-management/tags'];
}

export class SiteManagementUsersNavOption extends NavOption {
  name= 'Users';
  shouldHide= false;
  activeRootPath = ['/bild/site-management/users'];
  link = ['/bild/site-management/users'];
}

export class SiteManagementUserGroupsNavOption extends NavOption {
  name= 'User Groups';
  shouldHide= false;
  activeRootPath = ['/bild/site-management/user-groups'];
  link = ['/bild/site-management/user-groups'];
}
