import {IdName} from '../id-names/_id-names';
import {plainToClass} from 'class-transformer';

export const ENTITY_SUBTYPE_IDS = {
  PLAYER: 1,
  STAFF: 3,
  AGENT: 4,
  MEDIA: 5,
  OWNER: 10,
};

export class EntitySubtype extends IdName {}

export const ENTITY_SUBTYPES = {};
ENTITY_SUBTYPES[ENTITY_SUBTYPE_IDS.PLAYER] = plainToClass(EntitySubtype, {id: ENTITY_SUBTYPE_IDS.PLAYER, name: 'Player'});
ENTITY_SUBTYPES[ENTITY_SUBTYPE_IDS.STAFF] = plainToClass(EntitySubtype, {id: ENTITY_SUBTYPE_IDS.STAFF, name: 'Staff'});
ENTITY_SUBTYPES[ENTITY_SUBTYPE_IDS.AGENT] = plainToClass(EntitySubtype, {id: ENTITY_SUBTYPE_IDS.AGENT, name: 'Agent'});
ENTITY_SUBTYPES[ENTITY_SUBTYPE_IDS.MEDIA] = plainToClass(EntitySubtype, {id: ENTITY_SUBTYPE_IDS.MEDIA, name: 'Media'});
ENTITY_SUBTYPES[ENTITY_SUBTYPE_IDS.OWNER] = plainToClass(EntitySubtype, {id: ENTITY_SUBTYPE_IDS.OWNER, name: 'Owner'});
