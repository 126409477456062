<div class="search-box" [ngClass.lt-md]="['lt-md']" (click)="searchClicked()" [ngClass]="{'has-results': searchResults && searchResults.length > 0 }">
  <div class="search-icon-container">
    <mat-icon class="search-icon" *ngIf="searchVisible && !alwaysVisible">{{backIcon}}</mat-icon>
    <mat-icon class="search-icon" *ngIf="!searchVisible || alwaysVisible">{{searchIcon}}</mat-icon>
  </div>
  <div class="search-placeholder">Search <span class="sub-text">CMD+S</span></div>

</div>

<div *ngIf="searchVisible" (blur)="toggleVisibility()">
    <div class="search-container">
      <search-input #searchInput
                    [@inputState]="alwaysVisible || searchVisible"
                    [debounce]="debounce"
                    [showUnderline]="showUnderline"
                    [placeholder]="placeholder"
                    [clearIcon]="clearIcon"
                    (searchDebounce)="handleSearchDebounce($event)"
                    (search)="handleSearch($event)"
                    (clear)="handleClear(); toggleVisibility()"
                    (optionSelected)="handleOptionSelected($event)"
                    [searchResults]="searchResults">
      </search-input>
    </div>
</div>
