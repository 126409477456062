
export class Attachment {
  id: number;
  name: string;
  link: string;
}

export class FileAttachment {
    id: number;
    name: string;
    link: string;
    file: File;
}
