import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {NgModule} from '@angular/core';
// import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreModule} from '@ngrx/store';
import {NavigationActionTiming, routerReducer, StoreRouterConnectingModule} from '@ngrx/router-store';

import {AffiliationStoreModule} from '@store/affiliation-store';
import {AnnouncementStoreModule} from '@store/announcements-store';
import {FocusGroupStoreModule} from './focus-group-store';
import {InjuryArticleStoreModule} from '@store/injury-article-store';
import {MergedRouterStateSerializer} from '../route-serializer';
import {NewsArticleStoreModule} from '@store/news-article-store';
import {RosterStoreModule} from './team-roster-store';
import {SpotlightArticleStoreModule} from '@store/spotlight-article-store';
import {TransactionArticleStoreModule} from '@store/transaction-article-store';
import {UserGroupStoreModule} from '@store/user-group-store';
import {PersonPhysicalInjuryStoreModule} from './person-physical-injuries-store';
import {VideoStoreModule} from './video-store';
import {extModules} from '../../build-specifics';

@NgModule({
  imports: [
    CommonModule,

    AffiliationStoreModule,
    AnnouncementStoreModule,
    FocusGroupStoreModule,
    InjuryArticleStoreModule,
    NewsArticleStoreModule,
    PersonPhysicalInjuryStoreModule,
    RosterStoreModule,
    SpotlightArticleStoreModule,
    TransactionArticleStoreModule,
    UserGroupStoreModule,
    VideoStoreModule,

    // TODO: Moment currently isn't compatible with strict immutability checks (https://github.com/ngrx/platform/issues/2690.)
    // TODO: (Cont...) Once moment is in compliance, we should remove the runtimeCheck option disabling strictImmutability
    StoreModule.forRoot({
      router: routerReducer,
    },
    {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    extModules,
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
      serializer: MergedRouterStateSerializer,
    }),
  ],
  declarations: [],
})
export class RootStoreModule {}
