<base-menu *ngIf="!isMobile"
   [navTabs]="navTabs"
   [preventHotkey]="true"
   [showPlaylistMenu]="true"
>
</base-menu>
<mat-list *ngIf="isMobile">
  <ng-template [ngxPermissionsOnly]="['viewAllPosts', 'viewOwnerPosts']">
    <mat-list-item [class.active]="isUrlActive('/bild')">
      <a (click)="navigate('')">Post Feed</a>
    </mat-list-item>
  </ng-template>
  <ng-container>
    <mat-list-item [class.active]="isUrlActive('/bild/highlights')">
      <a (click)="navigate('highlights')">Highlights</a>
    </mat-list-item>
  </ng-container>
  <ng-template [ngxPermissionsOnly]="['viewAllMetrics']">
    <mat-list-item routerLinkActive="active">
      <a href="" [routerLink]="['', 'bild', 'scoreboard']">Scoreboard</a>
    </mat-list-item>
  </ng-template>
  <ng-template [ngxPermissionsOnly]="['viewAllFocusGroups', 'viewOwnerFocusGroups']">
    <mat-list-item routerLinkActive="active">
      <a href="" [routerLink]="['', 'bild', 'focus-groups']">Focus Groups</a>
    </mat-list-item>
  </ng-template>
  <ng-template [ngxPermissionsOnly]="['viewAllMetrics']">
    <mat-list-item [class.active]="domHelper.isLinkActive(['', 'bild', 'metrics'])">
      <a href="" [routerLink]="['', 'bild', 'entities', 'leagues', LEAGUE_IDS.NBA, 'metrics', 'team-index']">Metrics</a>
    </mat-list-item>
  </ng-template>
  <ng-template [ngxPermissionsOnly]="['viewAllEvents', 'viewOwnerEvents']">
    <mat-list-item [class.active]="domHelper.isLinkActive(['', 'bild', 'events'])">
      <a href="" [routerLink]="['', 'bild', 'events', 'calendar']" [queryParams]="{duration: 'weekly'}">Events</a>
    </mat-list-item>
  </ng-template>
  <ng-template [ngxPermissionsOnly]="['viewAllTalentPipeline']">
    <mat-list-item routerLinkActive="active">
      <a href="" [routerLink]="['', 'bild', 'talent-pipeline']">Talent Pipeline</a>
    </mat-list-item>
  </ng-template>
  <ng-container>
    <mat-list-item routerLinkActive="active">
      <a href="" [routerLink]="['', 'bild', 'video']">Video</a>
    </mat-list-item>
  </ng-container>
</mat-list>
