import {Component, OnInit, ViewEncapsulation, Inject, Input} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bild-saved-playlist-dialog',
  templateUrl: './saved-playlist.component.html',
  styleUrls: ['./saved-playlist.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SavedPlaylistDialogComponent implements OnInit {
  name: string;
  existingPlaylist: boolean;

  constructor(public dialogRef: MatDialogRef<SavedPlaylistDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.name = data.name;
    this.existingPlaylist = data.existingPlaylist;
  }

  ngOnInit() {
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  save(): void {
    this.dialogRef.close(this.name);
  }
}
