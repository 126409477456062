import {Actions, ActionTypes} from './actions';
import {adapter, initialState, State} from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LIST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.LIST_SUCCESS: {
      const draft = {
        ...state,
        aboutID: action.payload.aboutID,
        leagueID: action.payload.leagueID,
        filteredIds: action.payload.filteredIds,
        prevPageDateToken: action.payload.prevPageDateToken,
        nextPageDateToken: action.payload.nextPageDateToken,
        prevPageIDToken: action.payload.prevPageIDToken,
        nextPageIDToken: action.payload.nextPageIDToken,
        isLoading: false,
        error: null
      };
      if (state.aboutID !== action.payload.aboutID || state.leagueID !== action.payload.leagueID) {
        return adapter.setAll(action.payload.results, draft);
      } else {
        return adapter.addMany(action.payload.results, draft);
      }
    }

    default: {
      return state;
    }
  }
}
