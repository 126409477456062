<div tabindex="1" class="video-outer" [ngClass]="{'full-screen': isFullScreen}" (mouseup)="endDragging()" (mousemove)="onTimeDotDrag($event)" (mouseleave)="mouseLeavePlayer()" (blur)="isVideoPlayerFocused = false" (focus)="isVideoPlayerFocused = true">
  <div class="video-players">
    <video class="placeholder-video-player" src="assets/videos/placeholder.mp4"></video>
    <video #video1 class="video-player" webkit-playsinline playsinline
           [src]="currentClip1?.clipUrl + (isSaving ? '' : '#t=' + ((currentClip1?.startTrim && currentClip1?.startTrim > 0) ? currentClip1?.startTrim : '') + ((currentClip1?.endTrim && currentClip1?.endTrim > 0) ? ',' + (currentVideoDuration1 - currentClip1?.endTrim) : ''))"
           (click)="isVideoPlaying ? pauseVideo() : playVideo()"
           (loadedmetadata)="onVideoMetadataLoaded(1)"
           (canplay)="onVideoCanPlay(1)"
           (timeupdate)="onVideoPlaying()"
           (play)="playVideo()"
           (pause)="pauseVideo()"
           (ended)="onVideoEnded(1)"
           (drag)="$event.preventDefault()"
           [class.hidden]="currentVideoIndex % 2 == 1"></video>
    <div #timeDot class="time-dot" [class.hidden]="!initialVideoLoaded || !isDotVisible || isSaving" (mousedown)="startDragging()" [ngStyle]="{top: dotVerticalPosition, left: dotHorizontalPosition}" (mouseenter)="mouseEnterDot()" (mouseleave)="mouseLeaveDot()"></div>
    <video #video2 class="video-player" webkit-playsinline playsinline
          [src]="currentClip2?.clipUrl + (isSaving ? '' : '#t=' + ((currentClip2?.startTrim && currentClip2?.startTrim > 0) ? currentClip2?.startTrim : '') + ((currentClip2?.endTrim && currentClip2?.endTrim > 0) ? ',' + (currentVideoDuration2 - currentClip2?.endTrim) : ''))"
           (click)="isVideoPlaying ? pauseVideo() : playVideo()"
           (loadedmetadata)="onVideoMetadataLoaded(2)"
           (canplay)="onVideoCanPlay(2)"
           (timeupdate)="onVideoPlaying()"
           (play)="playVideo()"
           (pause)="pauseVideo()"
           (ended)="onVideoEnded(2)"
           (drag)="$event.preventDefault()"
           [class.hidden]="currentVideoIndex % 2 == 0"></video>
  </div>
  <div *ngIf="currentClip?.sectionCaption" class="section-caption" [ngClass]="{'full-screen': isFullScreen}">
    {{ currentClip.sectionCaption }}
  </div>
  <span *ngIf="currentClip?.caption" class="video-caption" (click)="videoCaptionExpanded = !videoCaptionExpanded" [ngClass]="{'expanded-caption': videoCaptionExpanded, 'full-screen': isFullScreen}">
    <mat-icon>chat_bubble</mat-icon><span class="caption-text">{{ currentClip.caption }}</span>
    <mat-icon [ngClass]="{'expanded': videoCaptionExpanded, 'collapsed': !videoCaptionExpanded}">keyboard_arrow_down</mat-icon>
  </span>
  <div *ngIf="eventsVisibility" class="video-events-metadata">
    <ng-container *ngFor="let actionEvent of currentClip?.actions; let i=index">
      <div *ngIf="actionEvent.startTimestamp >= (currentClip?.startTimestamp - (1000 * currentClip?.startPadding))" class="event-metadata" (click)="changeCurrentTime((actionEvent.startTimestamp - currentClip?.startTimestamp)/1000 + currentClip?.startPadding)"
           [style.left.%]="100 * ((actionEvent.startTimestamp - currentClip?.startTimestamp)/1000 + currentClip?.startPadding) / currentVideoDuration"
           [class.active]="
              (((actionEvent.startTimestamp - currentClip?.startTimestamp)/1000 + currentClip?.startPadding) <= currentVideoTime) &&
              ((((actionEvent.endTimestamp && (actionEvent.endTimestamp - actionEvent.startTimestamp) > 1000 ? (actionEvent.endTimestamp - currentClip?.startTimestamp)/1000 : ((actionEvent.startTimestamp + 1000) - currentClip?.startTimestamp)/1000) + currentClip?.startPadding) >= currentVideoTime))
            ">
        <div class="event-name">{{
          actionEvent.action == 'actionBallScreens' && actionEvent.type == 'pick' ? 'On-Ball Screen' :
            actionEvent.action == 'actionBallScreens' && actionEvent.type == 'handoff' ? 'Handoff' :
              actionEvent.action == 'actionDrives' ? 'Drive' :
                actionEvent.action == 'actionIsolations' ? 'Iso' :
                  actionEvent.action == 'actionOffBallScreens' ? 'Off-Ball Screen' :
                    actionEvent.action == 'actionPosts' ? 'Post-Up' :
                      actionEvent.action == 'actionShots' ? 'Shot' :
                        actionEvent.action == 'actionCuts' ? 'Cut' : ''
          }}</div>
        <div *ngIf="actionEvent.ballhandler" class="event-player">
          <mat-icon>sports_basketball</mat-icon>
          <entity-avatar class="avatar" [entity]="actionEvent.ballhandler" [size]="16"></entity-avatar>
          <div class="name">{{ actionEvent.ballhandler.name }}</div>
        </div>
        <div *ngIf="actionEvent.screener" class="event-player">
          <mat-icon>boy</mat-icon>
          <entity-avatar class="avatar" [entity]="actionEvent.screener" [size]="16"></entity-avatar>
          <div class="name">{{ actionEvent.screener.name }}</div>
        </div>
        <div *ngIf="actionEvent.cutter" class="event-player">
          <mat-icon>directions_run</mat-icon>
          <entity-avatar class="avatar" [entity]="actionEvent.cutter" [size]="16"></entity-avatar>
          <div class="name">{{ actionEvent.cutter.name }}</div>
        </div>
        <div *ngIf="actionEvent.qefg || actionEvent.xefg" class="event-metadata-row">
          <div *ngIf="actionEvent.qefg" class="qefg-value">
            <div class="label">qEFG</div>
            <div class="value" [ngClass]="'background-percentile-text-' + roundNumber(100*actionEvent.qefg)">{{ actionEvent.qefg | number: "1.3-3" }}</div>
          </div>
          <div *ngIf="actionEvent.xefg" class="xefg-value">
            <div class="label">xEFG</div>
            <div class="value" [ngClass]="'background-percentile-text-' + roundNumber(100*actionEvent.xefg)">{{ actionEvent.xefg | number: "1.3-3" }}</div>
          </div>
        </div>
      </div>
      <div class="event-time-span"
           [style.left.%]="100 * ((actionEvent.startTimestamp - currentClip?.startTimestamp)/1000 + currentClip?.startPadding) / currentVideoDuration"
           [style.width.%]="100 * (actionEvent.endTimestamp && (actionEvent.endTimestamp - actionEvent.startTimestamp) > 1000 ? ((actionEvent.endTimestamp - actionEvent.startTimestamp)/1000) : 1) / currentVideoDuration"
           [class.active]="
              (((actionEvent.startTimestamp - currentClip?.startTimestamp)/1000 + currentClip?.startPadding) <= currentVideoTime) &&
              ((((actionEvent.endTimestamp && (actionEvent.endTimestamp - actionEvent.startTimestamp) > 1000 ? (actionEvent.endTimestamp - currentClip?.startTimestamp)/1000 : ((actionEvent.startTimestamp + 1000) - currentClip?.startTimestamp)/1000) + currentClip?.startPadding) >= currentVideoTime))
            ">
        <div class="event-time-span-inner" [style.width.%]="100 * (1000 * (currentVideoTime - currentClip?.startPadding) - (actionEvent.startTimestamp - currentClip?.startTimestamp)) / (actionEvent.endTimestamp && (actionEvent.endTimestamp - actionEvent.startTimestamp) > 1000 ? (actionEvent.endTimestamp - actionEvent.startTimestamp) : 1000)"></div>
      </div>
    </ng-container>
  </div>
  <div #videoControls class="video-controls" [class.disabled]="!currentClip">
    <div *ngIf="isSaving" class="trim-slider-container">
      <ngx-slider [(value)]="startTrimSeconds" [(highValue)]="endTrimSeconds" [options]="trimSliderOptions" (valueChange)="onTrimStartChange($event)" (highValueChange)="onTrimEndChange($event)"></ngx-slider>
    </div>
    <div *ngIf="isTagging" class="tag-trim-slider-container">
      <ngx-slider [value]="tagStartSeconds" [highValue]="tagEndSeconds" [options]="tagSliderOptions" (userChangeStart)="onTagSliderUserUpdated($event)" (userChangeEnd)="onTagSliderUserUpdated($event)"></ngx-slider>
      <div class="tag-metadata" [style.left.%]="100 * (((tagStartSeconds - (currentClip?.startTrim || 0)) + (tagEndSeconds - (currentClip?.startTrim || 0)))/2)/(currentVideoDuration - (currentClip?.endTrim || 0) - (currentClip?.startTrim || 0))">
        <div class="event-name">{{ tagEditing ? 'This Tag' : 'Next Tag' }}</div>
        <div class="tag-tip"></div>
      </div>
    </div>
    <div *ngIf="showEpv && currentClip?.zelusData" class="video-epv-metadata">
      <div class="current-epv">
        <div class="label"><b>Current EPV</b></div>
        <div *ngIf="currentEpv > 0" class="value"><b>{{ currentEpv | number: "1.3-3" }}</b></div>
        <div *ngIf="currentEpv <= 0" class="value">-----</div>
      </div>
      <div *ngIf="maxClipEpv > 0" class="epv-summary-stats">
        <div class="min-epv-display">MIN: {{ minClipEpv | number: "1.3-3" }}</div>
        <div class="max-epv-display">MAX: {{ maxClipEpv | number: "1.3-3" }}</div>
        <div class="avg-epv-display">AVG: {{ avgClipEpv | number: "1.3-3" }}</div>
      </div>
      <div class="epv-linechart" id="epv-linechart"></div>
      <div class="epv-progress-linechart" id="epv-progress-linechart"></div>
    </div>
    <div class="progress-bar-outer" (mouseenter)="isDotVisible = true" (mouseleave)="hideTimeDot($event)">
      <div #progressBar class="progress-bar-background" (mousedown)="onProgressBarClicked($event)" (mouseup)="endDragging()">
        <div *ngIf="!isSaving" class="progress-bar-inner" [style.width.%]="100 * (currentVideoTime - (currentClip?.startTrim || 0)) / trimmedVideoDuration"></div>
        <ng-container *ngIf="isSaving">
          <div class="progress-bar-inner" [style.width.%]="100 * currentVideoTime / currentVideoDuration"></div>
          <div class="progress-bar-disabled-start" [style.width.%]="100 * (currentClip?.startTrim || 0) / currentVideoDuration"></div>
          <div class="progress-bar-disabled-end" [style.width.%]="100 * (currentClip?.endTrim || 0) / currentVideoDuration"></div>
        </ng-container>
      </div>
    </div>
    <div class="controls-row">
      <button mat-icon-button class="play-button" (click)="isVideoPlaying ? pauseVideo() : playVideo()" [matTooltip]="isVideoPlaying ? 'Pause (k/space)' : 'Play (k/space)'">
        <mat-icon *ngIf="!isVideoPlaying">play_arrow</mat-icon>
        <mat-icon *ngIf="isVideoPlaying">pause</mat-icon>
      </button>
      <div class="volume-container">
        <button mat-icon-button class="volume-button" (click)="volume == 0 ? changeVolume(1) : changeVolume(0)" [matTooltip]="volume == 0 ? 'Unmute (m)' : 'Mute (m)'">
          <mat-icon *ngIf="volume >= 0.5">volume_up</mat-icon>
          <mat-icon *ngIf="volume > 0 && volume < 0.5">volume_down</mat-icon>
          <mat-icon *ngIf="volume == 0">volume_off</mat-icon>
        </button>
        <mat-slider class="volume-slider" (input)="changeVolume($event.value)" min="0" max="1" step="0.01" [value]="volume">
          <input matSliderThumb>
        </mat-slider>
      </div>
      <div class="video-time" *ngIf="currentClip && trimmedVideoDuration">{{ isSaving ? formatTime(currentVideoTime) : formatTime(currentVideoTime - (currentClip?.startTrim || 0)) }} / {{ isSaving ? formatTime(currentVideoDuration) : formatTime(trimmedVideoDuration) }}</div>
      <div class="end-of-row-buttons">
        <mat-slide-toggle *ngIf="showAutoPlayToggle" class="autoplay-toggle" [ngClass]="'autoplay-' + (isAutoplaying ? 'on' : 'off')"  [class.disabled]="isSaving || isSavingMany" [checked]="isAutoplaying" [matTooltip]="isAutoplaying ? 'Autoplay is on (w)' : 'Autoplay is off (w)'" (change)="isAutoplaying = !isAutoplaying"></mat-slide-toggle>
        <button *ngIf="showPreviousButton" mat-icon-button class="previous-button" [class.disabled]="isSaving || isSavingMany" (click)="determineClipNavigation(false)" matTooltip="Previous (e)">
          <mat-icon>skip_previous</mat-icon>
        </button>
        <div *ngIf="showClipNumber" class="clip-number">{{ currentVideoIndex + 1 }} / {{ displayedClips.length }}</div>
        <button *ngIf="showNextButton" mat-icon-button class="next-button" [class.disabled]="isSaving || isSavingMany" (click)="determineClipNavigation(true)" matTooltip="Next (r)">
          <mat-icon>skip_next</mat-icon>
        </button>
        <button mat-icon-button class="options-button" matTooltip="Options" [matMenuTriggerFor]="settingOptions">
          <mat-icon>settings</mat-icon>
        </button>
        <button *ngIf="currentClip?.startTimestamp && showInsights" mat-icon-button class="insights-button" matTooltip="Insights" [matMenuTriggerFor]="insightOptions">
          <mat-icon>insights</mat-icon>
        </button>
        <button mat-icon-button *ngIf="showHotKeys" class="options-button" matTooltip="Hot Keys" (click)="hotKeysShown = !hotKeysShown" cdkOverlayOrigin #hotKeyTrigger="cdkOverlayOrigin">
          <mat-icon>keyboard</mat-icon>
        </button>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="hotKeyTrigger"
          [cdkConnectedOverlayOpen]="hotKeysShown"
        >
          <div class="hot-keys-list" [class.expanded]="true" (drag)="$event.preventDefault()">
            <div *ngFor="let hotKey of hotKeys" class="hot-key-row">
              <div *ngFor="let key of hotKey.keys" class="key">{{ key }}</div>
              <div class="action">{{ hotKey.action }}</div>
            </div>
          </div>
        </ng-template>
        <button *ngIf="showReportButton && !currentClip?.url" mat-icon-button class="report-button" matTooltip="Report issue" [matMenuTriggerFor]="reportOptions" #reportButton="matMenuTrigger" (menuOpened)="onReportIssueMenuOpened()" (menuClosed)="onReportIssueMenuClosed()"
                [class.disabled]="selectedClips?.length > 1">
          <mat-icon>flag</mat-icon>
        </button>
        <button *ngIf="showSizeButton" mat-icon-button class="size-button" (click)="toggleFullScreen()" [matTooltip]="isFullScreen ? 'Exit full screen (q)' : 'Full screen (q)'" [class.disabled]="isSaving || isSavingMany">
          <mat-icon *ngIf="!isFullScreen">fullscreen</mat-icon>
          <mat-icon *ngIf="isFullScreen">fullscreen_exit</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="show2dCourt && currentClip?.zelusData" [ngClass]="(isFullScreen ? 'fullscreen' : 'minimized')" class="court-diagram-container">
    <div class="court-diagram" id="court-plot" [ngClass]="(displayingHalfCourt ? 'half-court' : 'full-court')">
      <div class="court-options">
        <div class="toggle-button-group">
          <div class="label"><b>Court Orientation</b></div>
          <div class="button-left" [ngClass]="(displayingHalfCourt ? 'checked' : 'unchecked')" (click)="!displayingHalfCourt ? toggleCourtOrientation() : null">Half</div>
          <div class="button-right" [ngClass]="(displayingHalfCourt ? 'unchecked' : 'checked')" (click)="displayingHalfCourt ? toggleCourtOrientation() : null">Full</div>
        </div>
        <div class="toggle-button-group">
          <div class="label"><b>Player Trails</b></div>
          <div class="button-left" [ngClass]="(showPlayerTrails ? 'checked' : 'unchecked')" (click)="!showPlayerTrails ? togglePlayerTrails() : null">On</div>
          <div class="button-right" [ngClass]="(showPlayerTrails ? 'unchecked' : 'checked')" (click)="showPlayerTrails ? togglePlayerTrails() : null">Off</div>
        </div>
        <div class="toggle-button-group">
          <div class="label"><b>Offense Region</b></div>
          <div class="button-left" [ngClass]="(showOffenseHull ? 'checked' : 'unchecked')" (click)="!showOffenseHull ? toggleOffenseHull() : null">On</div>
          <div class="button-right" [ngClass]="(showOffenseHull ? 'unchecked' : 'checked')" (click)="showOffenseHull ? toggleOffenseHull() : null">Off</div>
        </div>
        <div class="toggle-button-group">
          <div class="label"><b>Defense Region</b></div>
          <div class="button-left" [ngClass]="(showDefenseHull ? 'checked' : 'unchecked')" (click)="!showDefenseHull ? toggleDefenseHull() : null">On</div>
          <div class="button-right" [ngClass]="(showDefenseHull ? 'unchecked' : 'checked')" (click)="showDefenseHull ? toggleDefenseHull() : null">Off</div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-menu class="setting-options" #settingOptions="matMenu" xPosition="before" yPosition="above">
  <ng-template matMenuContent>
    <div (click)="$event.stopPropagation();">
      <div class="setting-row">
        <div class="setting-label">Playback</div>
        <div class="setting-input">
          <mat-select [(ngModel)]="playbackSpeed" [ngModelOptions]="{standalone: true}" (selectionChange)="changePlaybackSpeed($event.value)">
            <mat-option *ngFor="let playbackSpeedOption of playbackSpeedOptions" [value]="playbackSpeedOption">
              {{ playbackSpeedOption == 1 ? 'Normal' : playbackSpeedOption }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="setting-row" *ngIf="showStartPadding && currentClip && !currentClip.url">
        <div class="setting-label">Start Padding</div>
        <div class="setting-input">
          <mat-select [(ngModel)]="currentClip.startPadding" [ngModelOptions]="{standalone: true}" (selectionChange)="changeVideoStartPadding($event.value)">
            <mat-option *ngFor="let paddingOption of paddingOptions" [value]="paddingOption">
              {{ paddingOption === 1 ? '1 second' : paddingOption + ' seconds' }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="setting-row" *ngIf="showEndPadding && currentClip && !currentClip.url">
        <div class="setting-label">End Padding</div>
        <div class="setting-input">
          <mat-select [(ngModel)]="currentClip.endPadding" [ngModelOptions]="{standalone: true}" (selectionChange)="changeVideoEndPadding($event.value)">
            <ng-container *ngFor="let paddingOption of paddingOptions">
              <mat-option *ngIf="(paddingOption - initialVideoDuration) > -1" 
                          [value]="paddingOption">
                {{ (paddingOption - initialVideoDuration) === 1 ? '1 second' : (paddingOption - initialVideoDuration) + ' seconds' }}
              </mat-option>
            </ng-container>
          </mat-select>
        </div>
      </div>
    </div>
  </ng-template>
</mat-menu>

<mat-menu class="setting-options" #insightOptions="matMenu" xPosition="before" yPosition="above">
  <ng-template matMenuContent>
    <div (click)="$event.stopPropagation();">
      <div class="setting-row">
        <div class="setting-label">EPV Overlay</div>
        <div class="setting-input">
          <mat-select [(ngModel)]="showEpv" [ngModelOptions]="{standalone: true}" (selectionChange)="changeShowEpv($event.value)">
            <mat-option [value]="true">Show</mat-option>
            <mat-option [value]="false">Hide</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="setting-row">
        <div class="setting-label">2D Court Overlay</div>
        <div class="setting-input">
          <mat-select [(ngModel)]="show2dCourt" [ngModelOptions]="{standalone: true}" (selectionChange)="changeShow2dCourt($event.value)">
            <mat-option [value]="true">Show</mat-option>
            <mat-option [value]="false">Hide</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </ng-template>
</mat-menu>

<mat-menu class="report-options" #reportOptions="matMenu" xPosition="before" yPosition="above">
  <ng-template matMenuContent>
    <div (click)="$event.stopPropagation();">
      <mat-form-field class="report-form-field">
        <mat-label>Feedback (What's wrong with this clip?)</mat-label>
        <textarea [(ngModel)]="reportClipFeedback" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>
      <button mat-flat-button class="report-button" (click)="onReportButtonClicked()">Report Issue</button>
    </div>
  </ng-template>
</mat-menu>

<mat-menu class="save-options" #saveOptions="matMenu" xPosition="before" yPosition="above">
  <ng-template matMenuContent>
    <div>
      <div *ngFor="let savedVideo of currentClip?.savedVideos" class="existing-saved-video-option" (click)="onEditExistingSaveButtonClicked(savedVideo)">
        <div class="name">{{ savedVideo.name }}</div>
        <button mat-icon-button class="edit-button">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <div class="create-new-saved-video-option" (click)="onSaveButtonClicked()">
        <div class="name">Create New Save</div>
        <button mat-icon-button class="add-button">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </ng-template>
</mat-menu>

<mat-menu class="tag-preset-options" #tagPresetOptions="matMenu" xPosition="before" yPosition="above">
  <ng-template matMenuContent>
    <div>
      <div *ngFor="let tagPreset of tagPresets; let i = index" (click)="assignSelectionsToPreset(i)" class="tag-preset-option">
        <div class="preset-number">{{ i }}</div>
        <div class="preset-status">{{ tagPreset ? 'Assigned' : 'Unassigned' }}</div>
      </div>
    </div>
  </ng-template>
</mat-menu>
