import {Action} from '@ngrx/store';
import {InjuryOccurrence} from '@models/physical/injuryOccurrences';

export enum ActionTypes {
  LIST = '[InjuryOccurrence] List',
  LIST_SUCCESS = '[InjuryOccurrence] List Success',
  LIST_FAILED = '[InjuryOccurrence] List Failed',
  SAVE = '[InjuryOccurrence] Save',
  SAVE_SUCCESS = '[InjuryOccurrence] Save Success',
  SAVE_FAILED = '[InjuryOccurrence] Save Failed',
}

export class GetInjuryOccurences implements Action {
  public readonly type = ActionTypes.LIST;
  constructor(public payload: {id: number}) {}
}
export class GetInjuryOccurencesSuccess implements Action {
  public readonly type = ActionTypes.LIST_SUCCESS;
  constructor(public payload: InjuryOccurrence[]) {}
}
export class GetInjuryOccurencesFailed implements Action {
  public readonly type = ActionTypes.LIST_FAILED;
  constructor(public payload: any) {}
}

export class SaveInjuryOccurences implements Action {
  public readonly type = ActionTypes.SAVE;
  constructor(public payload: {injuryOccurences: InjuryOccurrence[], id: number}) {}
}

export class SaveInjuryOccurencesSuccess implements Action {
  public readonly type = ActionTypes.SAVE_SUCCESS;
  constructor(public payload: InjuryOccurrence[]) {}
}

export class SaveInjuryOccurencesFailed implements Action {
  public readonly type = ActionTypes.SAVE_FAILED;
  constructor(public payload: any) {}
}

export type Actions = GetInjuryOccurences | GetInjuryOccurencesSuccess | GetInjuryOccurencesFailed | SaveInjuryOccurences | SaveInjuryOccurencesSuccess | SaveInjuryOccurencesFailed;
