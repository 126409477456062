import * as _ from 'lodash';
import * as moment from 'moment-timezone';


import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';

import {FocusGroup} from '@models/focus-groups';
import {featureAdapter, State} from './state';
import {plainToClass} from 'class-transformer';
import {Position, RankedPerson} from '@models/ranked-people';
import {PostsSummary} from '@models/posts-summary';
import {Person} from '@models/people';
import {Entity} from '@models/entities';
import produce from 'immer';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getIsHistorical = (state: State): boolean => state.isHistorical;

export const getIsTalentPipeline = (state: State): boolean => state.isTalentPipeline;

export const getChannelName = (state: State): string => state.channelName;

export const getMyEvaluationChannelName = (state: State): string => state.myEvaluationChannelName;

export const getActiveUsers = (state: State): any[] => state.activeUsers;

export const selectFocusGroupState: MemoizedSelector<object, State> = createFeatureSelector<State>('focus-group');

export const selectAllFocusGroups: (state: object) => FocusGroup[] = featureAdapter.getSelectors(selectFocusGroupState).selectAll;

export const selectFocusGroups = createSelector(
  selectAllFocusGroups,
  (focusGroups: any[]) => {
    return plainToClass(FocusGroup, focusGroups);
  }
);

export const selectFocusGroup = (id: number) => {
  return createSelector(
    selectAllFocusGroups,
    focusGroups => {
      const focusGroup = focusGroups.find(p => p.id === id);
      return focusGroup ? plainToClass(FocusGroup, focusGroup) : focusGroup;
    }
  );
};

export const selectFocusGroupEntitiesAgents = (id: number) => {
  return createSelector(
    selectFocusGroup(id),
    (focusGroup: FocusGroup) => {
      if (!focusGroup) {
        return [];
      }

      const agents = [];
      focusGroup.groupings.map(grouping => {
        grouping.entities.map((entity: RankedPerson) => {
          if (entity.agent) {
            agents.push(entity.agent);
          }
        });
      });

      const returnAgents = _(agents).uniqBy('id').value();
      return _.orderBy(returnAgents, ['lastName'], ['asc']);
    }
  );
};

export const selectFocusGroupEntities = (id: number) => {
  return createSelector(
    selectFocusGroup(id),
    (focusGroup: FocusGroup) => {
      if (!focusGroup) {
        return [];
      }

      const entities = [];
      focusGroup.groupings.forEach(grouping => {
        entities.push(...grouping.entities);
      });

      return entities;
    }
  );
};

export const selectFocusGroupEntitiesPositions = (id: number) => {
  return createSelector(
    selectFocusGroup(id),
    (focusGroup: FocusGroup) => {
      if (!focusGroup) {
        return [];
      }

      const positions = [];
      focusGroup.groupings.map(grouping => {
        grouping.entities.map((entity: RankedPerson) => {
          positions.push(entity.position);
        });
      });

      const returnPositions = plainToClass(Position, _(positions).uniqBy('name').value());
      return _.orderBy(returnPositions, ['id'], ['asc']);
    }
  );
};

export const selectFocusGroupEntitiesAgeRange = (id: number) => {
  return createSelector(
    selectFocusGroup(id),
    (focusGroup: FocusGroup) => {
      if (!focusGroup) {
        return [];
      }

      let min: number;
      let max: number;
      const ageLimits = [];
      focusGroup.groupings.map(grouping => {
        if (grouping.entities.length > 0) {
          const temp = _(grouping.entities).filter(entity => !!entity.age).maxBy('age');
          if (temp) {
            min = _(grouping.entities).filter(entity => !!entity.age).minBy('age').age;
            max = _(grouping.entities).filter(entity => !!entity.age).maxBy('age').age;
            ageLimits.push(...[min, max]);
          }
        }
      });

      return [_(ageLimits).min(), _(ageLimits).max()];
    }
  );
};

export const searchFocusGroupEntities = (id: number,
                                         positions: string[], agents: Person[], agencies: Entity[],
                                         locsScopes: string[], locsRange: number[], mrsRange: number[],
                                         ageRange: number[], careerStages: string[],
                                         intelCountRange: number[], intelMinDate: moment.Moment, intelMaxDate: moment.Moment,
                                         evalCountRange: number[], evalMinDate: moment.Moment, evalMaxDate: moment.Moment,
                                         yosRange: number[]) => {
  return createSelector(
    selectFocusGroup(id),
    (focusGroup: FocusGroup) => {
      if (!focusGroup) {
        return [];
      }

      return produce(focusGroup, draftState => {

        draftState.groupings.forEach((grouping, i) => {
          if (grouping.entities.length === 0) {
            return;
          }

          draftState.groupings[i].entities = _(grouping.entities).filter((entity: RankedPerson) => {
            let pred1: boolean = true;
            if (positions.length > 0) {
              pred1 = _.findIndex(positions, (position: string) => {
                return position === entity.position.name;
              }) >= 0;
            }
            let pred2: boolean = true;
            if (agents.length > 0) {
              pred2 = _.intersectionBy(agents, [entity.agent], 'id').length > 0;
            }

            let pred3: boolean = true;
            if (agencies.length > 0) {
              if (entity.agent && entity.agent.affiliation) {
                pred3 = _.intersectionBy(agencies, [entity.agent.affiliation], 'id').length > 0;
              } else {
                pred3 = false;
              }
            }

            let pred4: boolean = false;
            const locsRangeMin = locsRange[0];
            const locsRangeMax = locsRange[1];
            if (locsRangeMin === 1 && locsRangeMax === 8) {
              pred4 = true;
            } else {
              const adjustedLOCSRangeMin = locsRangeMin - 0.5;
              const adjustedLOCSRangeMax = locsRangeMax + 0.5;
              if (locsScopes.length === 0 && entity.locsSlice) {
                pred4 =
                  (adjustedLOCSRangeMin <= entity.locsSlice.calculatedNow && entity.locsSlice.calculatedNow < adjustedLOCSRangeMax) ||
                  (adjustedLOCSRangeMin <= entity.locsSlice.calculatedLow && entity.locsSlice.calculatedLow < adjustedLOCSRangeMax) ||
                  (adjustedLOCSRangeMin <= entity.locsSlice.calculatedMid && entity.locsSlice.calculatedMid < adjustedLOCSRangeMax) ||
                  (adjustedLOCSRangeMin <= entity.locsSlice.calculatedHigh && entity.locsSlice.calculatedHigh < adjustedLOCSRangeMax);
              } else {
                locsScopes.forEach(locsScope => {
                  if (pred4 || !entity.locsSlice) {
                    // Skip this loop, already matched this entity
                    return true;
                  }

                  switch (locsScope) {
                    case 'now':
                      pred4 = adjustedLOCSRangeMin <= entity.locsSlice.calculatedNow && entity.locsSlice.calculatedNow < adjustedLOCSRangeMax;
                      break;
                    case 'low':
                      pred4 = adjustedLOCSRangeMin <= entity.locsSlice.calculatedLow && entity.locsSlice.calculatedLow < adjustedLOCSRangeMax;
                      break;
                    case 'mid':
                      pred4 = adjustedLOCSRangeMin <= entity.locsSlice.calculatedMid && entity.locsSlice.calculatedMid < adjustedLOCSRangeMax;
                      break;
                    case 'high':
                      pred4 = adjustedLOCSRangeMin <= entity.locsSlice.calculatedHigh && entity.locsSlice.calculatedHigh < adjustedLOCSRangeMax;
                      break;
                  }
                });
              }
            }
            const pred5 = entity.MRS === null || (mrsRange[0] <= entity.MRS && entity.MRS <= mrsRange[1]);
            const pred6 = entity.age === null || (ageRange[0] <= entity.age && entity.age <= ageRange[1]);

            let pred7: boolean = true;
            if (careerStages.length > 0) {
              pred7 = careerStages.includes(entity.careerStage);
            }

            const pred8 = ((intelCountRange[0] == 0 && !entity.intelPostSummary) ||
                           (entity.intelPostSummary
                            && (intelCountRange[0] <= entity.intelPostSummary.countL6M)
                            && (intelCountRange[1] == 20 || entity.intelPostSummary.countL6M <= intelCountRange[1])));

            const pred9 = ((evalCountRange[0] == 0 && !entity.evalPostSummary) ||
                           (entity.evalPostSummary
                            && (evalCountRange[0] <= entity.evalPostSummary.countL6M)
                            && (evalCountRange[1] == 20 || entity.evalPostSummary.countL6M <= evalCountRange[1])));

            const pred10 = (intelMinDate === null ||
                            (entity.intelPostSummary &&
                              entity.intelPostSummary.lastPostDatetime.isSameOrAfter(intelMinDate, 'day'))) &&
                          (intelMaxDate === null ||
                            (entity.intelPostSummary &&
                              entity.intelPostSummary.lastPostDatetime.isSameOrBefore(intelMaxDate, 'day')));

            const pred11 = (evalMinDate === null ||
                            (entity.evalPostSummary &&
                              entity.evalPostSummary.lastPostDatetime.isSameOrAfter(evalMinDate, 'day'))) &&
                           (evalMaxDate === null ||
                            (entity.evalPostSummary &&
                              entity.evalPostSummary.lastPostDatetime.isSameOrBefore(evalMaxDate, 'day')));

            const pred12 = entity.yearsOfService === null || (yosRange[0] <= entity.yearsOfService && (yosRange[1] == 10 || entity.yearsOfService <= yosRange[1]));

            return pred1 && pred2 && pred3 && pred4 && pred5 && pred6 && pred7 && pred8 && pred9 && pred10 && pred11 && pred12;
          }).value();
        });
      });
    }
  );
};

export const selectFocusGroupError: MemoizedSelector<object, any> = createSelector(selectFocusGroupState, getError);

export const selectFocusGroupIsLoading: MemoizedSelector<object, boolean> = createSelector(selectFocusGroupState, getIsLoading);

export const selectFocusGroupIsHistorical: MemoizedSelector<object, boolean> = createSelector(selectFocusGroupState, getIsHistorical);

export const selectFocusGroupIsTalentPipeline: MemoizedSelector<object, boolean> = createSelector(selectFocusGroupState, getIsTalentPipeline);

export const selectFocusGroupChannelName: MemoizedSelector<object, string> = createSelector(selectFocusGroupState, getChannelName);

export const selectFocusGroupMyEvaluationChannelName: MemoizedSelector<object, string> = createSelector(selectFocusGroupState, getMyEvaluationChannelName);

export const selectFocusGroupActiveUsers: MemoizedSelector<object, any[]> = createSelector(selectFocusGroupState, getActiveUsers);
