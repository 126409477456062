import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';

import {Effects} from './effects';
import {featureReducer} from './reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('spotlight-article', featureReducer),
    EffectsModule.forFeature([Effects])
  ],
  providers: [Effects]
})
export class SpotlightArticleStoreModule {}
