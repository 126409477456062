import * as moment from 'moment-timezone';

import {Type, Transform} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {forwardRefMoment} from '@helpers/class-transformer.helper';


export class InjuryOccurrence {
  id: number;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  startDate: moment.Moment;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.HTML5_FMT.DATE))
  endDate: moment.Moment;
  side: string;
  type: string;
  detail: string;
  location: string;
  requiredSurgery: boolean;
  risk: boolean;
  gamesMissed: number;
  comment: string;

  get locationName(): string {
    let name = '';
    if (this.side) {
      name = `${this.detail.charAt(0).toUpperCase() + this.detail.substring(1)} ${this.side === 'right' ? ' (R)' : (this.side === 'left' ? ' (L)' : '')}`;
    } else {
      name = this.detail.charAt(0).toUpperCase() + this.detail.substring(1);
    }
    return name;
  }

}
