import {Component, OnInit, ViewEncapsulation, Inject, Input} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements OnInit {

  title: string = 'Confirm';
  message: string = 'Are you sure?';
  cancelButtonTitle: string = 'Cancel';
  acceptButtonTitle: string = 'Accept';
  confirmName: string;
  confirmInput: string;
  buttonClass;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title || this.title;
    this.message = data.message || this.message;
    this.acceptButtonTitle = data.acceptButtonTitle || this.acceptButtonTitle;
    this.confirmName = data.confirmName;
    this.buttonClass = data.buttonClass;
  }

  ngOnInit() {
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  accept(): void {
    this.dialogRef.close(true);
  }
}
